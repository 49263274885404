import type { FC, ReactElement, SVGAttributes } from 'react';
import React, { useMemo, useContext } from 'react';
import { ThemeContext } from 'styled-components';
import type { InputSizes } from './Styles/Input.styles';
import { inputIconBySize } from './Styles/Input.styles';

export interface InputIconProps {
  $size: InputSizes;
  icon: FC<SVGAttributes<SVGElement>> | ReactElement;
  disabled?: boolean;
  color?: string;
}

export const InputIcon: FC<InputIconProps> = ({ icon: Icon, disabled, $size }) => {
  const { input: inputTheme } = useContext(ThemeContext);

  const iconSize = useMemo(() => inputIconBySize[$size], [$size]);
  const iconColor = useMemo(() => {
    if (disabled) {
      return inputTheme.disabled.iconColor;
    }

    return inputTheme.iconColor;
  }, [disabled, inputTheme]);

  return typeof Icon === 'function' ? (
    <Icon color={iconColor} width={`${iconSize}px`} height={`${iconSize}px`} className={'buttonIcon'} />
  ) : (
    React.cloneElement(Icon, {
      sx: {
        color: iconColor,
        width: `${iconSize}px`,
        height: `${iconSize}px`,
      },
      color: iconColor,
      width: `${iconSize}px`,
      height: `${iconSize}px`,
    })
  );
};
