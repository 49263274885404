import { allowedFileTypes } from '../DocumentBox.js';
import { isDocumentPasswordProtected } from '../services/isDocumentPasswordProtected.js';

export const isFileValid = async (file: File): Promise<{ result: boolean; message?: string }> => {
  if (!allowedFileTypes.includes(file.type)) {
    return {
      result: false,
      message: 'File type not supported, please pick a different file type (pdf, doc, docx, xls, xlsx, ppt, pptx, png, jpg, jpeg).',
    };
  }

  if (await isDocumentPasswordProtected(file)) {
    return { result: false, message: 'Password protected documents are not currently supported.' };
  }

  return { result: true };
};
