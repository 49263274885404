import { useCallback } from 'react';
import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import type { Alert } from '@lama/alerts-service-client';
import { getApplicationAlerts } from '../../shared/clients/applicationService';
import { useAuthentication } from '../authentication/useAuthentication';

export const useApplicationAlertsQuery = (applicationId: string | null | undefined, options?: UseQueryOptions<Alert[] | null>) => {
  const { token, isAuthenticated } = useAuthentication();

  const getApplicationAlertsQuery = useCallback(async () => {
    if (!applicationId || !isAuthenticated) {
      return null;
    }

    return getApplicationAlerts(applicationId, token);
  }, [applicationId, isAuthenticated, token]);

  return useQuery<Alert[] | null>({
    queryKey: ['application', applicationId, 'alerts'],
    queryFn: getApplicationAlertsQuery,
    ...options,
  });
};
