import { Flex } from '@lama/design-system';
import type { FC } from 'react';
import React, { useMemo } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { AccountMenu } from '../managementScreen/AccountMenu';
import { getLogoUrl } from '../../shared/utils/getLogoUrl';
import { partnerState } from '../../state/appState';

interface SimpleHeaderProps {
  showAccountSettings: boolean;
}

export const SimpleHeader: FC<SimpleHeaderProps> = ({ showAccountSettings }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const partner = useRecoilValue(partnerState);

  const logoUrl = useMemo(() => (partner ? getLogoUrl(partner.id, isMobile ? 'small' : 'big') : ''), [isMobile, partner]);

  return (
    <Flex justifyContent={'space-between'} alignItems={'center'} p={4} borderBottom={'1px solid #E0E0E0'}>
      <Flex>
        <img height={'28px'} src={logoUrl} />
      </Flex>
      <AccountMenu showAccountSettings={showAccountSettings} popoverLocation={{ horizontal: 'center', vertical: 'bottom' }} />
    </Flex>
  );
};
