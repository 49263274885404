import React, { useCallback, useContext } from 'react';
import type { FC } from 'react';
import type { Document } from '@lama/document-service-client';
import { DocumentBox } from '@lama/app-components';
import { Flex, Text, greyPalette } from '@lama/design-system';
import { useDeleteDocumentMutation } from '../../hooks/react-query/useDeleteDocumentMutation';
import { ApplicationContext } from '../../shared/contexts/ApplicationContext';
import { UploadArbitraryDocumentButton } from '../shared/genericRequirements/documentRequirement/UploadArbitraryDocumentButton';

interface DocumentsCategoryListProps {
  miscellaneousDocuments?: Document[];
}

interface MiscellaneousDocumentBoxProps {
  description: string;
  document: Document;
}

const MiscellaneousDocumentBox: FC<MiscellaneousDocumentBoxProps> = ({ description, document }) => {
  const {
    application: { id: applicationId },
  } = useContext(ApplicationContext);
  const { mutateAsync: deleteDocument } = useDeleteDocumentMutation(applicationId);
  const onDeleteDocument = useCallback(async () => {
    await deleteDocument({ documentId: document?.id });
  }, [deleteDocument, document]);

  return <DocumentBox description={description} document={document} onDocumentRemoved={onDeleteDocument} />;
};

export const MiscellaneousDocumentsList: FC<DocumentsCategoryListProps> = ({ miscellaneousDocuments }) => {
  const {
    application: { id: applicationId },
  } = useContext(ApplicationContext);

  return (
    <Flex flexDirection={'column'} gap={6}>
      <Text color={greyPalette[500]}>{'Miscellaneous Documents'}</Text>
      <Flex flexDirection={'column'} gap={6}>
        {miscellaneousDocuments?.map((document) => (
          <MiscellaneousDocumentBox key={document.id} description={document.filename} document={document} />
        ))}
        <UploadArbitraryDocumentButton applicationId={applicationId} entityId={applicationId} entityType={'application'} />
      </Flex>
    </Flex>
  );
};
