import { useTheme } from '@mui/material';
import type { FC } from 'react';
import React from 'react';

export const EquipmentFinancingLogo: FC<{ selected: boolean }> = ({ selected }) => {
  const theme = useTheme();

  return (
    <svg width={'153'} height={'102'} viewBox={'0 0 153 102'} fill={'none'} xmlns={'http://www.w3.org/2000/svg'}>
      <path
        d={
          'M1.88765 101.143L64.5936 101.902C67.5849 101.938 70.2037 99.9363 70.4772 97.2468C70.7058 95.0004 69.3056 92.9119 66.0786 91.0002C63.2335 87.349 57.1614 86.0454 54.1866 88.1103C48.3761 82.446 39.8478 85.4514 31.3148 88.4733C24.4153 83.7307 19.0338 83.9098 15.0832 88.7303L12.825 88.7633C6.26023 88.8623 0.78684 93.3268 0.0136818 99.2127C-0.120678 100.231 0.751482 101.127 1.88529 101.141L1.88765 101.143Z'
        }
        fill={selected ? theme.palette.primary.main : '#0C2007'}
      />
      <path
        d={
          'M85.4241 91.7193L75.3047 87.0332C73.0206 85.9702 71.5285 83.6719 71.4601 81.1143L71.4012 78.9245C71.4955 75.0234 74.6423 71.9189 78.468 71.9543L143.26 71.1411C146.824 71.0963 149.523 74.4105 148.825 77.9746L146.348 90.602L85.4218 91.7193H85.4241Z'
        }
        fill={'#BDBDBD'}
      />
      <path
        d={
          'M77.6973 65.1911L66.9178 58.5084C65.8595 57.872 65.1924 56.7523 65.1429 55.5266L64.8529 48.4621C64.7374 45.6759 66.0009 43.0099 68.2379 41.3198L96.8612 19.6855C98.6409 18.3396 100.807 17.5947 103.044 17.5523L139.609 16.8923C143.166 16.8286 146.494 18.6248 148.371 21.6231L150.428 26.6887L77.6996 65.1887L77.6973 65.1911Z'
        }
        fill={theme.palette.primary.light}
      />
      <path
        d={
          'M77.6972 65.5449C77.6336 65.5449 77.5676 65.526 77.511 65.4907L66.7316 58.808C65.5719 58.1127 64.8435 56.8893 64.7893 55.541L64.4993 48.4765C64.3791 45.5771 65.6968 42.7956 68.0257 41.0372L96.649 19.4029C98.5018 18.0027 100.71 17.2414 103.037 17.1989L139.602 16.5389C143.272 16.4706 146.737 18.3492 148.67 21.4372C148.682 21.4537 148.691 21.4725 148.698 21.4914L150.756 26.557C150.824 26.7243 150.756 26.9176 150.593 27.0025L77.8646 65.5025C77.8127 65.5307 77.7562 65.5425 77.6996 65.5425L77.6972 65.5449ZM139.788 17.2461C139.729 17.2461 139.672 17.2461 139.614 17.2461L103.049 17.9061C100.871 17.9462 98.8059 18.658 97.0733 19.9663L68.45 41.6006C66.3073 43.2199 65.0957 45.7799 65.2041 48.4482L65.4941 55.5127C65.5389 56.6229 66.1399 57.6294 67.0993 58.2046L77.7114 64.7835L149.978 26.5263L148.052 21.786C146.28 18.9739 143.128 17.2437 139.788 17.2437V17.2461Z'
        }
        fill={'#666666'}
      />
      <path d={'M130.908 57.373H83.9957V79.9926H130.908V57.373Z'} fill={theme.palette.primary.light} />
      <path d={'M132.896 57.373H85.9827V79.9926H132.896V57.373Z'} fill={theme.palette.primary.light} />
      <path
        d={
          'M132.896 80.3463H85.9828C85.7871 80.3463 85.6292 80.1884 85.6292 79.9927V57.3731C85.6292 57.1775 85.7871 57.0195 85.9828 57.0195H132.896C133.091 57.0195 133.249 57.1775 133.249 57.3731V79.9927C133.249 80.1884 133.091 80.3463 132.896 80.3463ZM86.3364 79.6391H132.542V57.7267H86.3364V79.6391Z'
        }
        fill={'#666666'}
      />
      <path d={'M16.3514 30.0056L51.2567 22.7454L50.4199 19.9521L14.3973 27.7709L16.3514 30.0056Z'} fill={'#BDBDBD'} />
      <path
        d={
          'M16.3514 30.3596C16.2501 30.3596 16.1534 30.3172 16.0851 30.2394L14.1309 28.0048C14.0484 27.9105 14.0225 27.7808 14.0602 27.6606C14.1003 27.5428 14.1993 27.4532 14.3219 27.4249L50.3445 19.6061C50.5236 19.566 50.7051 19.6721 50.757 19.8489L51.5938 22.6422C51.622 22.7365 51.6103 22.8402 51.5584 22.925C51.5089 23.0099 51.424 23.0712 51.3274 23.09L16.4221 30.3502C16.3986 30.3549 16.375 30.3572 16.3491 30.3572L16.3514 30.3596ZM15.0573 27.9906L16.4811 29.6171L50.8065 22.4772L50.1724 20.3675L15.055 27.9906H15.0573Z'
        }
        fill={'#666666'}
      />
      <path d={'M67.0805 45.643L58.9883 20.2301L60.6595 19.6738L68.4053 41.9045L67.0805 45.643Z'} fill={theme.palette.primary.light} />
      <path
        d={
          'M67.0805 45.9969H67.0758C66.9249 45.9945 66.7906 45.8955 66.7434 45.7518L58.6488 20.3389C58.5899 20.155 58.6913 19.957 58.8751 19.8957L60.5464 19.3394C60.7302 19.2782 60.9282 19.3772 60.9919 19.5587L68.74 41.7893C68.7659 41.8648 68.7659 41.9473 68.74 42.0227L67.4152 45.7612C67.3657 45.9026 67.2314 45.9969 67.0829 45.9969H67.0805ZM59.4291 20.4544L67.097 44.5331L68.0281 41.9025L60.4356 20.1173L59.4267 20.4544H59.4291Z'
        }
        fill={'#666666'}
      />
      <path
        d={
          'M5.7521 16.5934L6.8788 16.2993C8.20392 15.9534 9.55858 16.7472 9.90449 18.0723L17.2478 46.2033L10.4944 47.9662L3.36668 20.6609C2.90169 18.8796 3.97023 17.0561 5.75151 16.5911L5.7521 16.5934Z'
        }
        fill={'#666666'}
      />
      <path
        d={
          'M20.7263 68.822L6.44648 22.0695C5.91847 20.3416 6.96506 18.5266 8.72588 18.1165L13.6312 16.9756C14.831 23.8067 15.6348 26.6943 19.5925 29.405C17.7916 35.1236 22.0228 53.2928 25.304 67.1955L20.7287 68.8173L20.7263 68.822Z'
        }
        fill={'#BDBDBD'}
      />
      <path
        d={
          'M20.7264 69.1751C20.6721 69.1751 20.6179 69.1633 20.5684 69.1374C20.4812 69.095 20.4176 69.0172 20.3893 68.9253L6.10707 22.1728C5.82185 21.2417 5.93499 20.2658 6.42057 19.4219C6.90615 18.5781 7.69581 17.9935 8.6434 17.7719L13.5487 16.631C13.643 16.6098 13.742 16.6263 13.8245 16.6805C13.9047 16.7347 13.9612 16.8196 13.9777 16.9139C15.1681 23.6932 15.9766 26.5006 19.7906 29.1123C19.9178 29.1996 19.9744 29.3622 19.9273 29.5107C18.1217 35.241 22.6498 54.4215 25.6458 67.1149C25.6882 67.2894 25.5892 67.4685 25.4195 67.5298L20.8442 69.1516C20.8065 69.1657 20.7664 69.1728 20.7264 69.1728V69.1751ZM13.346 17.4065L8.80369 18.4626C8.04939 18.637 7.42002 19.1037 7.03344 19.7755C6.64686 20.4473 6.55729 21.2252 6.78358 21.9677L20.9574 68.3666L24.8844 66.9735C21.7423 53.6578 17.5206 35.6795 19.1777 29.5555C15.392 26.8801 14.5175 24.0137 13.3436 17.4089L13.346 17.4065Z'
        }
        fill={'#666666'}
      />
      <path
        d={
          'M74.7837 0.997923L72.9781 0.696203C72.6528 0.641987 72.3205 0.740991 72.0777 0.962566L68.7093 4.03634C68.3628 4.3522 67.9385 4.57378 67.4812 4.67985L12.6058 17.2083C10.2981 17.7269 8.86729 20.0534 9.47309 22.3611C10.0482 24.558 12.3229 25.8568 14.5387 25.3595L74.1449 11.9848L74.1921 12.0437L75.6583 2.20244C75.7431 1.62729 75.3542 1.09221 74.7814 0.995563L74.7837 0.997923Z'
        }
        fill={'#BDBDBD'}
      />
      <path
        d={
          'M13.6005 25.8195C11.5474 25.8195 9.65695 24.4594 9.1313 22.4511C8.81544 21.2465 8.99694 19.9972 9.64517 18.9341C10.291 17.871 11.3164 17.1356 12.5304 16.8645L67.4034 4.33605C67.8018 4.24648 68.1695 4.05318 68.4712 3.77739L71.8396 0.703624C72.1649 0.406618 72.601 0.276972 73.0371 0.350045L74.8427 0.651765C75.6017 0.779054 76.125 1.498 76.0118 2.25937L74.5457 12.1006C74.5245 12.2397 74.4231 12.3529 74.2887 12.3906C74.2086 12.4118 74.1261 12.4047 74.0554 12.3741L14.6188 25.711C14.2794 25.7865 13.9376 25.8242 13.6029 25.8242L13.6005 25.8195ZM72.799 1.03599C72.6222 1.03599 72.4501 1.10199 72.3158 1.22456L68.9473 4.29834C68.556 4.65663 68.0752 4.90649 67.559 5.02435L12.6836 17.5528C11.6582 17.7838 10.7931 18.4038 10.2463 19.3018C9.69938 20.1976 9.54617 21.2536 9.81253 22.2719C10.3288 24.2449 12.4125 25.4753 14.4586 25.0157L73.888 11.6811L75.307 2.15329C75.3636 1.77143 75.102 1.41078 74.7201 1.34713L72.9145 1.04541C72.8744 1.03834 72.8344 1.03599 72.7943 1.03599H72.799Z'
        }
        fill={'#666666'}
      />
      <path d={'M11.0453 46.0566L17.9331 70.2178L19.5902 70.2414L13.0112 46.5375L11.0453 46.0566Z'} fill={theme.palette.primary.light} />
      <path
        d={
          'M19.5902 70.5946C19.5902 70.5946 19.5878 70.5946 19.5855 70.5946L17.9307 70.571C17.7751 70.571 17.6384 70.4649 17.596 70.3141L10.7059 46.1505C10.6706 46.0303 10.7059 45.8983 10.7908 45.8087C10.878 45.7168 11.0077 45.6814 11.1302 45.7097L13.0961 46.1906C13.2211 46.2212 13.3177 46.3155 13.3531 46.4404L19.932 70.1467C19.9626 70.2551 19.939 70.3683 19.8707 70.4578C19.8047 70.5451 19.701 70.5946 19.5902 70.5946ZM18.2018 69.8685L19.1235 69.8827L12.726 46.8317L11.5522 46.5441L18.2018 69.8685Z'
        }
        fill={'#666666'}
      />
      <path
        d={
          'M16.4787 69.7154L19.9178 86.9158C20.493 89.7963 22.803 92.0121 25.7047 92.4741C30.6996 93.2685 34.9473 93.7493 41.5733 93.0045C43.8928 92.7428 45.92 91.3144 46.9548 89.2235L51.4405 75.8041L42.8391 85.7396C41.3824 86.9889 39.4684 87.5664 37.5637 87.3354L34.5513 86.9677C31.7863 86.6306 29.752 84.2192 29.884 81.4377L30.3908 70.8045C30.438 69.7909 30.2635 68.7749 29.851 67.8509C27.812 63.2874 22.9492 62.2408 19.5525 63.7188C17.2424 64.7253 15.9837 67.2475 16.4787 69.7201V69.7154Z'
        }
        fill={'#666666'}
      />
      <path
        d={
          'M10.0483 47.5265C9.91628 46.8453 10.3641 46.1853 11.0454 46.0557L16.5306 44.9996C17.2118 44.8676 17.8718 45.3155 18.0014 45.9967C18.1334 46.678 17.6856 47.338 17.0043 47.4676L11.5192 48.5236C10.8379 48.6556 10.1779 48.2078 10.0483 47.5265Z'
        }
        fill={'#BDBDBD'}
      />
      <path
        d={
          'M11.2787 48.9007C10.9605 48.9007 10.6493 48.8064 10.3783 48.6225C10.0223 48.3821 9.7819 48.0167 9.6994 47.5948C9.6169 47.1728 9.70647 46.7438 9.9469 46.3879C10.1873 46.032 10.5527 45.7915 10.9746 45.709L16.4598 44.653C16.8818 44.5729 17.3108 44.6601 17.6667 44.9005C18.0226 45.1409 18.2631 45.5063 18.3456 45.9282C18.4281 46.3502 18.3385 46.7792 18.0981 47.1351C17.8576 47.4911 17.4923 47.7315 17.0703 47.814L11.5852 48.87C11.4814 48.8889 11.3801 48.9007 11.2764 48.9007H11.2787ZM16.7686 45.3295C16.712 45.3295 16.6531 45.3342 16.5965 45.346L11.1114 46.402C10.8756 46.4468 10.6706 46.5835 10.5338 46.7815C10.3995 46.9819 10.35 47.2223 10.3948 47.4581C10.4396 47.6938 10.5763 47.8989 10.7743 48.0356C10.9746 48.1699 11.2151 48.2194 11.4508 48.1746L16.936 47.1186C17.1717 47.0738 17.3768 46.9371 17.5135 46.7391C17.6478 46.5388 17.6973 46.2983 17.6526 46.0626C17.6078 45.8245 17.4711 45.6218 17.273 45.4851C17.1222 45.3837 16.9478 45.3295 16.7686 45.3295Z'
        }
        fill={'#666666'}
      />
      <path
        d={
          'M16.9407 31.8046C17.7114 31.8046 18.3361 31.1798 18.3361 30.4091C18.3361 29.6384 17.7114 29.0137 16.9407 29.0137C16.17 29.0137 15.5452 29.6384 15.5452 30.4091C15.5452 31.1798 16.17 31.8046 16.9407 31.8046Z'
        }
        fill={theme.palette.primary.light}
      />
      <path
        d={
          'M30.2494 26.9623L62.9813 19.9567C63.7285 19.7965 64.1764 19.0327 63.9548 18.302L63.87 18.0239C63.672 17.3733 63.0049 16.9867 62.3425 17.1352L29.6954 24.4166C28.7266 24.6334 28.3471 25.8073 29.0048 26.5498C29.3159 26.901 29.7921 27.059 30.2517 26.96L30.2494 26.9623Z'
        }
        fill={'#BDBDBD'}
      />
      <path
        d={
          'M71.1254 19.7518L49.2271 23.8038C48.3078 23.9735 47.4239 23.3654 47.2542 22.4461C47.0326 21.2439 47.827 20.0865 49.0291 19.8649L70.4136 15.9072L71.1254 19.7518Z'
        }
        fill={'#BDBDBD'}
      />
      <path
        d={
          'M48.9183 24.1859C47.9519 24.1859 47.0891 23.4953 46.9053 22.51C46.6483 21.1169 47.57 19.7733 48.9631 19.5164L70.3475 15.5586C70.5408 15.5233 70.7247 15.6506 70.76 15.8415L71.4719 19.6861C71.4884 19.778 71.4696 19.8746 71.4153 19.9501C71.3611 20.0279 71.281 20.0797 71.189 20.0962L49.2908 24.1482C49.1658 24.1718 49.0409 24.1836 48.9183 24.1836V24.1859ZM70.1307 16.32L49.0951 20.2141C48.6072 20.3037 48.1805 20.5794 47.9 20.9896C47.6171 21.3998 47.5134 21.8948 47.603 22.3827C47.7374 23.1087 48.4375 23.5919 49.1658 23.4576L70.7153 19.4692L70.133 16.32H70.1307Z'
        }
        fill={'#666666'}
      />
      <path
        d={
          'M74.0624 41.0512L71.2692 58.7679L65.0391 10.6836C65.0391 8.24624 67.1017 6.26855 69.6474 6.26855C72.1932 6.26855 74.2557 8.24388 74.2557 10.6836L74.0624 41.0512Z'
        }
        fill={'#BDBDBD'}
      />
      <path
        d={
          'M71.2692 59.1215C71.2692 59.1215 71.2669 59.1215 71.2645 59.1215C71.0877 59.1192 70.9416 58.9872 70.918 58.8127L64.688 10.7284C64.688 10.7143 64.6856 10.6978 64.6856 10.6836C64.6856 8.05537 66.9108 5.91504 69.6475 5.91504C72.3842 5.91504 74.6094 8.05301 74.6094 10.6836L74.4161 41.0537C74.4161 41.0702 74.4161 41.089 74.4114 41.1055L71.6181 58.8222C71.5898 58.9942 71.4437 59.1215 71.2692 59.1215ZM65.3928 10.6624L71.3022 56.2811L73.7066 41.023L73.8999 10.6836C73.8999 8.44666 71.9905 6.62456 69.6451 6.62456C67.2997 6.62456 65.4045 8.43488 65.3904 10.6624H65.3928Z'
        }
        fill={'#666666'}
      />
      <path
        d={
          'M32.4486 24.2707L52.4729 19.8792C52.9302 19.7779 53.2885 19.0566 53.2508 18.3141L53.2367 18.0312C53.2013 17.3712 52.8619 16.9351 52.4541 17.0294L32.4439 21.7037C31.8499 21.8428 31.4798 22.9789 31.7697 23.7662C31.9065 24.1387 32.1657 24.332 32.4486 24.2707Z'
        }
        fill={'#BDBDBD'}
      />
      <path
        d={
          'M66.3544 35.1822L64.0868 35.9389C63.7333 36.0568 63.54 36.4363 63.6531 36.7898L70.727 59.4448L69.0369 39.7811L67.005 35.4628C66.8895 35.2176 66.6114 35.0997 66.3544 35.1846V35.1822Z'
        }
        fill={'#BDBDBD'}
      />
      <path
        d={
          'M14.1899 22.0223C14.1899 22.7412 13.6053 23.3258 12.8864 23.3258C12.1674 23.3258 11.5828 22.7412 11.5828 22.0223C11.5828 21.3033 12.1674 20.7188 12.8864 20.7188C13.6053 20.7188 14.1899 21.3033 14.1899 22.0223Z'
        }
        fill={theme.palette.primary.light}
      />
      <path
        d={
          'M69.176 23.7853C69.1195 23.3775 69.4023 23.0003 69.8101 22.9438L72.04 22.6302C72.4478 22.5737 72.825 22.8565 72.8815 23.2643C72.9381 23.6721 72.6552 24.0493 72.2474 24.1059L70.0175 24.4194C69.6097 24.4759 69.2326 24.1931 69.176 23.7853Z'
        }
        fill={'#BDBDBD'}
      />
      <path
        d={
          'M69.9115 24.78C69.6758 24.78 69.4448 24.7046 69.2538 24.5585C69.0205 24.3817 68.8673 24.1247 68.8272 23.8348C68.7423 23.2361 69.1619 22.6798 69.7606 22.5949L71.9905 22.2814C72.5893 22.1966 73.1456 22.6161 73.2304 23.2149C73.3153 23.8136 72.8957 24.3722 72.297 24.4547L70.0671 24.7683C70.0152 24.7753 69.9634 24.78 69.9115 24.78ZM72.1438 22.9768C72.1249 22.9768 72.1084 22.9768 72.0895 22.9815L69.8596 23.295C69.6451 23.3257 69.4966 23.5237 69.5273 23.7358C69.5414 23.8395 69.5956 23.9315 69.6805 23.9927C69.7654 24.054 69.8667 24.0823 69.9704 24.0682L72.2003 23.7547C72.4148 23.724 72.5633 23.526 72.5327 23.3139C72.5044 23.1182 72.337 22.9768 72.1461 22.9768H72.1438Z'
        }
        fill={'#666666'}
      />
      <path
        d={
          'M142.914 92.2783H88.6982C83.1282 92.2783 78.6118 87.7619 78.6118 82.1919C78.6118 76.6219 83.1282 72.1055 88.6982 72.1055H142.914C148.484 72.1055 153 76.6219 153 82.1919C153 87.7619 148.484 92.2783 142.914 92.2783Z'
        }
        fill={'#BDBDBD'}
      />
      <path
        d={
          'M142.39 90.1376H89.502C85.1837 90.1376 81.6832 86.6372 81.6832 82.3188C81.6832 78.0004 85.1837 74.5 89.502 74.5H142.39C146.709 74.5 150.209 78.0004 150.209 82.3188C150.209 86.6372 146.709 90.1376 142.39 90.1376Z'
        }
        fill={theme.palette.primary.light}
      />
      <path
        d={
          'M92.673 85.0531C94.2729 83.4532 94.2729 80.8593 92.673 79.2594C91.0731 77.6595 88.4791 77.6595 86.8792 79.2594C85.2793 80.8593 85.2793 83.4532 86.8792 85.0531C88.4791 86.653 91.0731 86.653 92.673 85.0531Z'
        }
        fill={'#BDBDBD'}
      />
      <path
        d={
          'M146.446 83.2748C146.966 81.0727 145.602 78.8662 143.4 78.3465C141.198 77.8267 138.992 79.1905 138.472 81.3926C137.952 83.5947 139.316 85.8012 141.518 86.321C143.72 86.8407 145.927 85.4769 146.446 83.2748Z'
        }
        fill={'#BDBDBD'}
      />
      <path
        d={
          'M75.4414 63.9118L70.9675 61.0902C69.9845 60.4703 69.3905 59.3907 69.3905 58.231V46.9117C69.3905 45.7826 69.9444 44.7266 70.8732 44.0831L104.194 21.0204C105.477 20.1553 106.983 19.6815 108.529 19.6532L141.763 19.0757C145.252 18.9979 148.519 20.7799 150.339 23.7571L151.213 25.1855L75.4414 63.9094V63.9118Z'
        }
        fill={'#BDBDBD'}
      />
      <path
        d={
          'M75.4414 64.2649C75.3754 64.2649 75.3094 64.2461 75.2528 64.2107L70.7789 61.3892C69.6875 60.7009 69.0369 59.5199 69.0369 58.2305V46.9113C69.0369 45.6667 69.6474 44.4999 70.6705 43.7927L103.992 20.73C105.338 19.8225 106.903 19.3298 108.522 19.3015L141.756 18.724C141.832 18.724 141.907 18.7217 141.98 18.7217C145.495 18.7217 148.797 20.565 150.638 23.5751L151.513 25.0059C151.564 25.0908 151.579 25.1922 151.55 25.2864C151.524 25.3807 151.458 25.4609 151.371 25.5057L75.5993 64.2296C75.5498 64.2555 75.4933 64.2673 75.4391 64.2673L75.4414 64.2649ZM141.98 19.4288C141.909 19.4288 141.841 19.4288 141.77 19.4288L108.536 20.0063C107.054 20.0323 105.621 20.4825 104.392 21.3122L71.0735 44.3726C70.2415 44.9478 69.7441 45.8977 69.7441 46.909V58.2282C69.7441 59.2748 70.2721 60.2318 71.156 60.7881L75.4579 63.5012L150.711 25.0437L150.035 23.9405C148.323 21.1425 145.25 19.4265 141.978 19.4265L141.98 19.4288Z'
        }
        fill={'#666666'}
      />
      <path
        d={
          'M145.662 66.5868H80.7569C77.4733 66.5868 74.8121 64.1942 74.8121 61.2407V47.4629C74.8121 45.8152 75.6583 44.2571 77.1033 43.2459L105.958 23.0353C107.002 22.3046 108.287 21.9062 109.609 21.9062H145.66C148.943 21.9062 151.605 24.2988 151.605 27.2524V61.2383C151.605 64.1919 148.943 66.5844 145.66 66.5844L145.662 66.5868Z'
        }
        fill={'#BDBDBD'}
      />
      <path
        d={
          'M145.662 66.9404H80.7569C77.2848 66.9404 74.4585 64.3829 74.4585 61.2407V47.463C74.4585 45.7116 75.3707 44.0262 76.9005 42.956L105.755 22.7455C106.853 21.977 108.221 21.5527 109.609 21.5527H145.66C149.132 21.5527 151.958 24.1103 151.958 27.2524V61.2384C151.958 64.3805 149.134 66.9381 145.66 66.9381L145.662 66.9404ZM109.611 22.2599C108.367 22.2599 107.141 22.637 106.163 23.323L77.3083 43.5335C75.9482 44.4858 75.168 45.919 75.168 47.463V61.2407C75.168 63.9939 77.6761 66.2333 80.7593 66.2333H145.664C148.748 66.2333 151.256 63.9939 151.256 61.2407V27.2548C151.256 24.5016 148.748 22.2623 145.664 22.2623H109.614L109.611 22.2599Z'
        }
        fill={'#666666'}
      />
      <path
        d={
          'M133.325 40.9312L81.4735 40.778L88.4555 35.9363L132.853 35.9033C134.371 35.9033 135.602 37.1338 135.602 38.6518C135.602 39.9105 134.581 40.9289 133.325 40.9289V40.9312Z'
        }
        fill={theme.palette.primary.light}
      />
    </svg>
  );
};
