/* eslint-disable @typescript-eslint/naming-convention */
import { ChevronRight, ChevronLeft } from '@mui/icons-material';
import { ToggleButton, Button } from '@mui/material';
import React, { useCallback, useMemo } from 'react';
import { Text, Flex } from '@lama/design-system';
import { orderBy } from 'lodash-es';
import type { Category } from '../shared/types/services';
import { categories } from '../shared/types/services';
import { BadgeCounter } from '../shared/BadgeCounter';

interface CategoryServicesSelectionProps {
  categoryId?: Category;
  services: { name: string; logo: string; selected: boolean; weight?: number }[];
  onViewModeClick?: (categoryId: Category) => void;
  onSelectClick: (serviceName: string) => void;
  viewMode?: 'compact' | 'expanded';
}

export const CategoryServicesSelection: React.FC<CategoryServicesSelectionProps> = ({
  categoryId,
  services,
  onViewModeClick,
  onSelectClick,
  viewMode = 'compact',
}) => {
  const viewModeClick = useCallback(() => {
    onViewModeClick?.(categoryId!);
  }, [categoryId, onViewModeClick]);

  const toggleClick = useCallback(
    (_e: any, serviceName: string) => {
      onSelectClick(serviceName);
    },
    [onSelectClick],
  );

  const orderedServices = useMemo(
    () => orderBy(services, ['weight'], ['asc']).slice(0, viewMode === 'compact' ? 5 : services.length),
    [services, viewMode],
  );

  const buttonText = useMemo(() => (viewMode === 'compact' ? 'Show More' : 'Show Less'), [viewMode]);
  const buttonStartIconProp = useMemo(() => (viewMode !== 'compact' ? <ChevronLeft /> : null), [viewMode]);
  const buttonEndIconProp = useMemo(() => (viewMode === 'compact' ? <ChevronRight /> : null), [viewMode]);

  const categoryName = categoryId ? categories[categoryId] : undefined;

  return (
    <Flex flexDirection={'column'} gap={2} flex={'0 0 auto'}>
      {categoryName ? (
        <Flex flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'}>
          <Flex justifyContent={'center'} alignItems={'center'} gap={2}>
            <Text variant={'h5'}>{categoryName}</Text>
            {services.some(({ selected }) => selected) ? <BadgeCounter count={services.filter(({ selected }) => selected).length} /> : null}
          </Flex>
          <Button startIcon={buttonStartIconProp} endIcon={buttonEndIconProp} onClick={viewModeClick} color={'primary'}>
            {buttonText}
          </Button>
        </Flex>
      ) : null}
      <Flex flexDirection={'row'} gap={4} overflow={'auto'} maxWidth={'100%'} flexWrap={viewMode === 'compact' ? 'nowrap' : 'wrap'}>
        {orderedServices.map(({ name, logo, selected }) => (
          <Flex key={`${categoryName}-${name}`} pt={4} flex={'0 1 calc(20% - 16px)'}>
            <Flex flexDirection={'column'} gap={4} alignItems={'center'}>
              <ToggleButton
                color={'primary'}
                value={name}
                onClick={toggleClick}
                sx={{
                  '&.Mui-selected': {
                    borderColor: 'primary.main',
                  },
                }}
                selected={selected}
              >
                <img src={`/logos/${logo}`} alt={name} style={{ height: '70px', width: '100px' }} />
              </ToggleButton>
              <Text variant={'body1'}>{name}</Text>
            </Flex>
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
};
