import axios from 'axios';
import type { Account } from '@lama/contracts';
import { bankServiceBaseUrl } from '../../framework/environment';

export const getLinkToken = async (businessId: string, redirectUri: string, userToken: string): Promise<string> => {
  const {
    data: { linkToken },
  } = await axios.get<{ linkToken: string }>(`${bankServiceBaseUrl}/banks/linkToken`, {
    params: { businessId, redirectUri },
    headers: { authorization: `Bearer ${userToken}` },
  });

  return linkToken;
};

export const exchangePublicToken = async (publicToken: string, businessId: string, userToken: string): Promise<void> =>
  axios.post(
    `${bankServiceBaseUrl}/banks/exchangePublicToken`,
    { publicToken, businessId },
    {
      headers: { authorization: `Bearer ${userToken}` },
    },
  );

export const getAccounts = async (businessId: string, userToken: string): Promise<Account[]> => {
  try {
    const {
      data: { accounts },
    } = await axios.get<{ accounts: Account[] }>(`${bankServiceBaseUrl}/banks/getAccounts`, {
      params: { businessId },
      headers: {
        authorization: `Bearer ${userToken}`,
      },
    });

    return accounts;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response?.status === 404) {
      return [];
    }

    throw error;
  }
};
