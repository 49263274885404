/* eslint-disable @typescript-eslint/naming-convention */
import type { FC } from 'react';
import React, { useCallback, useMemo } from 'react';
import type { SxProps, TextFieldProps, Theme } from '@mui/material';
import { TextField } from '@mui/material';
import { useField, useFormikContext } from 'formik';
import { useToggle } from 'react-use';
import type { PropertyValue } from '@lama/properties';
import { useSelectedPropertyValue } from './hooks/useSelectedValueSource';
import { FormikMultiSourceHelperText } from './HelperTexts/FormikMultiSourceHelperText';
import { HelperText } from './HelperTexts/HelperText';

const isEmpty = (value: any): boolean => value === undefined || value === null || value === '';

const hideNumberSpinnersSx: SxProps<Theme> = {
  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
    display: 'none',
  },
  '& input[type=number]': {
    MozAppearance: 'textfield',
  },
};

export type FormikInputProps = TextFieldProps & {
  name: string;
  highlight?: boolean;
  pattern?: string;
  sourcesValues?: PropertyValue[];
  handleBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
};

export const PropertyFormikInput: FC<FormikInputProps> = ({
  name,
  variant = 'outlined',
  InputProps,
  sourcesValues,
  helperText,
  onBlur,
  ...restOfProps
}) => {
  const { submitCount } = useFormikContext();
  const [{ value: fieldValue, ...field }, { error, touched }] = useField(name);
  const [isFocused, toggleIsFocused] = useToggle(false);

  const shrinkLabel = useMemo(
    () => isFocused || !isEmpty(fieldValue) || InputProps?.startAdornment !== undefined,
    [isFocused, fieldValue, InputProps?.startAdornment],
  );

  const selectedPropertyValue = useSelectedPropertyValue(sourcesValues, fieldValue);

  const onBlurInner = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      field.onBlur(e);
      onBlur?.(e);
      toggleIsFocused();
    },
    [field, onBlur, toggleIsFocused],
  );

  const onFocusInternal = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      if (restOfProps?.onFocus) {
        restOfProps.onFocus(e);
      }
      toggleIsFocused(true);
    },
    [restOfProps, toggleIsFocused],
  );

  const inputError = useMemo(() => (!!touched || submitCount > 0) && Boolean(error), [error, submitCount, touched]);

  const inputHelperText = useMemo(() => {
    if (inputError) {
      return error;
    }

    return (
      <HelperText>
        <FormikMultiSourceHelperText selectedPropertyValue={selectedPropertyValue} sourcesValues={sourcesValues} />
        {helperText}
      </HelperText>
    );
  }, [error, helperText, inputError, selectedPropertyValue, sourcesValues]);

  return (
    <TextField
      type={'text'}
      error={inputError}
      variant={variant}
      value={fieldValue}
      {...field}
      InputLabelProps={{
        shrink: shrinkLabel,
      }}
      {...restOfProps}
      InputProps={{
        ...InputProps,
        ...(restOfProps.type === 'number'
          ? {
              inputMode: 'numeric',
            }
          : {}),
      }}
      name={name}
      id={name}
      onFocus={onFocusInternal}
      onBlur={onBlurInner}
      sx={{
        ...(restOfProps.type === 'number' ? hideNumberSpinnersSx : {}),
      }}
      helperText={inputHelperText}
      role={'input'}
    />
  );
};
