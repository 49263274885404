import type { FC } from 'react';
import React, { useCallback, useMemo } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { useField } from 'formik';

const yesNoOptions = ['Yes', 'No'];
const yesNoStringToOptionalBoolean = (value?: string) => (value === 'Yes' ? true : value === 'No' ? false : undefined);
const optionalBooleanToYesNoString = (value?: boolean) => (value === true ? 'Yes' : value === false ? 'No' : undefined);

export const FormikYesNoQuestionPicker: FC<{ name: string; label: string; required?: boolean }> = ({ name, label, required }) => {
  const [{ value }, , { setValue: setFormikValue }] = useField<boolean | undefined>(name);

  const parsedValue = useMemo(() => optionalBooleanToYesNoString(value), [value]);

  const onChange = useCallback(
    async (_event: React.ChangeEvent<{}>, pickedValue: string | null) => {
      if (pickedValue !== null) {
        void setFormikValue(yesNoStringToOptionalBoolean(pickedValue));
      }
    },
    [setFormikValue],
  );

  const renderInput = useCallback((params: any) => <TextField {...params} required={required} label={label} />, [label, required]);

  return (
    <Autocomplete disableClearable options={yesNoOptions} value={parsedValue} renderInput={renderInput} onChange={onChange} fullWidth />
  );
};
