import type { Key } from 'rc-tree/lib/interface';
import type { DataNode } from './types';

export const getNodeAncestors = (node: DataNode, keyToNodeMap: Record<string, DataNode>): string[] => {
  const ancestors: string[] = [];
  let currentNode = keyToNodeMap[node.key as string];

  while (currentNode?.parent) {
    const parent = keyToNodeMap[currentNode.parent.key as string];
    if (parent) {
      ancestors.push(parent.key as string);
      currentNode = parent;
    } else {
      break;
    }
  }

  return ancestors;
};

export const getDescendantKeys = (key: Key, keyToNodeMap: Record<string, DataNode>): Key[] => {
  const descendants: Key[] = [];
  const node = keyToNodeMap[key as string];

  const traverse = (n: DataNode) => {
    if (n.children) {
      n.children.forEach((child) => {
        descendants.push(child.key);
        traverse(child as DataNode);
      });
    }
  };

  if (node) {
    traverse(node);
  }

  return descendants;
};
