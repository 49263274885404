import type { FC, RefObject } from 'react';
import React, { useCallback, useContext, useMemo } from 'react';
import { AccountDetails } from '@lama/app-components';
import type { PopoverOrigin } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { compact } from 'lodash-es';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useLogout } from '../../hooks/useLogout';
import { UserDetailsContext } from '../../shared/contexts/UserDetailsContext';

interface AccountMenuProps {
  topbarRef?: RefObject<HTMLDivElement>;
  popoverLocation: PopoverOrigin;
  showAccountSettings?: boolean;
}

export const AccountMenu: FC<AccountMenuProps> = ({ topbarRef, popoverLocation, showAccountSettings = true }) => {
  const user = useContext(UserDetailsContext);
  const { fibtDemo } = useFlags();
  const logout = useLogout();
  const navigate = useNavigate();

  const onMyApplications = useCallback(() => {
    navigate('/applications');
  }, [navigate]);

  const onClickSettings = useCallback(() => {
    navigate('/accountSettings');
  }, [navigate]);

  const accountDetailsActions = useMemo(
    () =>
      compact([
        !fibtDemo
          ? {
              label: 'My Applications',
              onClick: onMyApplications,
            }
          : null,
        !showAccountSettings
          ? {
              label: 'Account Settings',
              onClick: onClickSettings,
            }
          : null,
      ]),
    [fibtDemo, onMyApplications, showAccountSettings, onClickSettings],
  );

  if (!user) {
    return null;
  }

  const { email, firstName, lastName } = user;

  return (
    <AccountDetails
      logout={logout}
      email={email ?? ''}
      firstName={firstName ?? ''}
      lastName={lastName ?? ''}
      anchorRef={topbarRef}
      popoverLocation={popoverLocation}
      actions={accountDetailsActions}
    />
  );
};
