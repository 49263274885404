import { useAuth0 } from '@auth0/auth0-react';
import { useAsync } from 'react-use';

export const useFetchAuth0AccessToken = (setAccessToken: (t: string) => void) => {
  const auth0 = useAuth0();

  const { loading: isFetchingAccessToken } = useAsync(async () => {
    if (auth0.isAuthenticated) {
      const token = await auth0.getAccessTokenSilently();
      setAccessToken(token);
    }
  }, [auth0, setAccessToken]);

  return { isFetchingAccessToken };
};
