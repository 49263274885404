import React from 'react';
import { useTheme } from '@mui/material';

export const UploadSelfieIcon: React.FC = () => {
  const theme = useTheme();

  return (
    <svg width={'161'} height={'117'} viewBox={'0 0 161 117'} fill={'none'} xmlns={'http://www.w3.org/2000/svg'}>
      <g clipPath={'url(#clip0_9491_109962)'}>
        <path
          d={
            'M48.2769 97.424C48.6123 96.9953 49.0901 96.7142 49.6362 96.624C50.2186 96.5297 50.8088 96.6658 51.2951 97.01C51.7814 97.3543 52.1089 97.8638 52.2164 98.4472C52.3234 99.0266 52.1973 99.6118 51.8584 100.092C51.1683 101.083 49.7786 101.309 48.7723 100.598C48.286 100.253 47.9585 99.7439 47.8509 99.1604C47.7457 98.5788 47.8718 97.9936 48.2089 97.5156C48.2317 97.4864 48.2563 97.455 48.2792 97.4258L48.2769 97.424ZM51.1081 99.6425C51.1221 99.6246 51.1362 99.6066 51.1502 99.5886C51.356 99.2973 51.4339 98.9386 51.3688 98.5837C51.3036 98.2288 51.1048 97.9176 50.8084 97.7075C50.5121 97.4974 50.1503 97.4133 49.7949 97.4718C49.4395 97.5303 49.1322 97.7205 48.9247 98.014C48.7189 98.3053 48.641 98.6641 48.7061 99.019C48.7713 99.3739 48.9702 99.6851 49.2665 99.8952C49.8632 100.315 50.6743 100.197 51.1098 99.6403L51.1081 99.6425Z'
          }
          fill={theme.palette.primary.main}
        />
        <path
          d={
            'M6.76225 59.9212C6.80439 59.8673 6.85849 59.8264 6.92403 59.7945C7.14139 59.7004 7.39634 59.7985 7.49881 60.0162C12.0446 69.937 18.3716 79.0368 26.3038 87.0695C26.4719 87.2409 26.4693 87.5175 26.3037 87.6774C26.1346 87.8417 25.8613 87.8378 25.6931 87.6664C17.6879 79.5621 11.3043 70.3745 6.71337 60.3605C6.64418 60.2087 6.66918 60.0401 6.764 59.9189L6.76225 59.9212Z'
          }
          fill={theme.palette.primary.main}
        />
        <path
          d={
            'M29.3603 90.6705C29.3603 90.6705 29.3744 90.6525 29.3814 90.6435C29.5431 90.4698 29.8149 90.4616 29.9918 90.6218C31.0997 91.6368 32.2514 92.6428 33.4111 93.6151C33.5956 93.7703 33.6258 94.0473 33.4704 94.2224C33.3178 94.4033 33.0459 94.4258 32.8614 94.2706C31.6903 93.2893 30.5312 92.2739 29.4119 91.25C29.2418 91.0952 29.2228 90.8415 29.358 90.6687L29.3603 90.6705Z'
          }
          fill={theme.palette.primary.main}
        />
        <path
          d={
            'M14.2534 64.0701C14.2867 64.0275 14.331 63.9897 14.3827 63.9614C14.5893 63.848 14.8537 63.9246 14.9713 64.1324C21.5526 75.6963 30.7179 85.5874 42.2136 93.5314C42.4122 93.6687 42.4639 93.9371 42.3296 94.1323C42.1963 94.3356 41.9266 94.3743 41.728 94.237C30.1201 86.2197 20.8659 76.2301 14.2196 64.5467C14.1292 64.389 14.1498 64.2025 14.2516 64.0724L14.2534 64.0701Z'
          }
          fill={theme.palette.primary.main}
        />
      </g>
      <path
        d={
          'M33.0467 45.5324C36.23 21.1296 58.8314 1.34897 83.5273 1.34897C108.223 1.34897 125.662 21.1296 122.483 45.5324C119.3 69.9351 96.6988 89.7158 72.0029 89.7158C47.307 89.7158 29.8634 69.9351 33.0467 45.5324V45.5324Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M42.8945 77.6616C49.9301 85.1259 60.1212 89.7112 71.9963 89.7112C83.8714 89.7112 95.262 85.1259 104.244 77.6616C95.3497 74.8286 85.1493 73.2327 74.1462 73.2327C63.1431 73.2327 52.5275 74.8286 42.8945 77.6616V77.6616Z'
        }
        fill={theme.palette.primary.light}
      />
      <path
        d={
          'M73.4473 80.7016C69.5489 80.7016 66.7947 77.5788 67.2975 73.7294L71.9341 38.1692H86.0513L81.4147 73.7294C80.9119 77.5788 77.3457 80.7016 73.4473 80.7016V80.7016Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M104.626 51.1432C105.179 40.0962 111.011 33.2022 101.585 20.7249C91.828 7.80143 80.8756 16.4661 80.8756 16.4661C80.8756 16.4661 73.5679 9.75144 60.9915 23.7925C49.8915 36.1824 60.5902 47.2846 55.5015 55.0019C50.4129 62.7145 36.0234 66.9227 36.0234 66.9227C36.0234 66.9227 39.3175 75.6885 44.3138 79.5472H102.697C102.697 79.5472 116.455 66.5732 116.934 62.8939C116.934 62.8939 104.072 62.1902 104.626 51.1478V51.1432Z'
        }
        fill={theme.palette.primary.light}
      />
      <path
        d={
          'M42.9023 77.6616C49.9379 85.1259 60.129 89.7112 72.0041 89.7112C83.8792 89.7112 95.2699 85.1259 104.252 77.6616C95.3575 74.8286 85.1571 73.2327 74.154 73.2327C63.1509 73.2327 52.5353 74.8286 42.9023 77.6616V77.6616Z'
        }
        fill={theme.palette.primary.light}
      />
      <path
        d={
          'M72.0086 90.4056C60.3688 90.4056 49.8547 86.0503 42.3994 78.1353L41.6289 77.3166L42.7085 76.9993C52.7566 74.0421 63.3399 72.5428 74.1585 72.5428C84.9771 72.5428 95.1775 74.0421 104.469 77.0039L105.668 77.3856L104.7 78.1905C95.2282 86.0641 83.6161 90.401 72.0086 90.401V90.4056ZM44.2032 78.0065C51.3356 85.1213 61.1623 89.0259 72.0086 89.0259C82.8549 89.0259 93.7842 85.0983 102.822 77.9421C94.001 75.2792 84.3681 73.9271 74.1631 73.9271C63.9581 73.9271 53.7992 75.3022 44.2078 78.0111L44.2032 78.0065Z'
        }
        fill={theme.palette.primary.light}
      />
      <path
        d={
          'M81.1947 26.5979H80.7057L64.4801 40.8643L63.4605 48.6873C62.7777 53.9119 65.0429 58.465 68.9736 60.9347L67.3035 73.7248C66.8007 77.5742 69.5549 80.697 73.4533 80.697C77.3517 80.697 80.9179 77.5742 81.4208 73.7248L83.0585 61.1739C87.8519 58.7685 91.5058 54.0775 92.2117 48.6873L93.3005 40.3216C94.2877 32.7423 88.8715 26.5933 81.1993 26.5933L81.1947 26.5979Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M73.4461 81.3915C71.3562 81.3915 69.497 80.582 68.2006 79.1149C66.9088 77.6478 66.3414 75.7024 66.6136 73.642L68.2329 61.2383C68.2836 60.8611 68.6296 60.5944 69.0079 60.645C69.3862 60.6956 69.6538 61.0405 69.6031 61.4176L67.9838 73.8214C67.7669 75.4816 68.2144 77.0407 69.2386 78.2089C70.2674 79.3771 71.7622 80.0163 73.4461 80.0163C77.0031 80.0163 80.2694 77.1557 80.7262 73.642L82.3178 61.4544C82.3686 61.0773 82.7146 60.8105 83.0929 60.8611C83.4712 60.9117 83.7388 61.2567 83.688 61.6338L82.0964 73.8214C81.552 77.9973 77.672 81.3961 73.4415 81.3961L73.4461 81.3915Z'
        }
        fill={theme.palette.primary.light}
      />
      <path
        d={
          'M75.9702 63.5792C75.5873 63.5792 75.2782 63.271 75.2782 62.8893C75.2782 62.5076 75.5873 62.1994 75.9702 62.1994C83.5594 62.1994 90.5303 56.0965 91.5084 48.5999L92.6018 40.2342C93.0401 36.8631 92.1589 33.6713 90.1197 31.2522C88.0944 28.8469 85.1279 27.4441 81.7693 27.3016C81.6586 27.297 81.5525 27.2924 81.4418 27.2924L65.0916 41.4576L64.1367 48.7793C63.4492 54.0315 65.9451 58.8283 70.4848 60.9991C70.8308 61.1647 70.9738 61.574 70.8077 61.9189C70.6416 62.2638 70.2264 62.4064 69.885 62.2408C64.7918 59.8079 61.996 54.45 62.7618 48.5999L63.7491 41.016C63.7722 40.8505 63.8552 40.6941 63.9798 40.5837L80.7267 26.0782C80.8513 25.9678 81.0127 25.9081 81.1788 25.9081C81.3957 25.9081 81.6079 25.908 81.8201 25.9218C85.5708 26.0828 88.8925 27.6649 91.1716 30.3646C93.4645 33.0826 94.4564 36.6515 93.9674 40.4136L92.874 48.7793C91.8083 56.9427 84.2237 63.5838 75.961 63.5838L75.9702 63.5792Z'
        }
        fill={theme.palette.primary.light}
      />
      <path
        d={
          'M72.0061 90.4056C59.8542 90.4056 49.0356 85.7238 41.5479 77.2201C34.0649 68.7256 30.8032 57.4394 32.3671 45.445C33.0592 40.1606 34.6554 34.9867 37.1144 30.0656C37.2851 29.7253 37.7003 29.5873 38.0417 29.7575C38.3831 29.9277 38.5215 30.3416 38.3508 30.6819C35.961 35.4604 34.4109 40.4872 33.7373 45.6198C32.2241 57.2186 35.3659 68.1185 42.586 76.3094C49.8107 84.5096 60.2602 89.0259 72.0061 89.0259C96.3283 89.0259 118.667 69.4752 121.799 45.445C123.313 33.8461 120.171 22.9509 112.951 14.7553C105.726 6.55512 95.2764 2.03881 83.5305 2.03881C70.2945 2.03881 57.1184 7.84286 47.3748 17.9562C47.1072 18.2322 46.6735 18.2414 46.3967 17.9746C46.1199 17.7125 46.1107 17.2756 46.3783 16.9996C56.3803 6.6149 69.9208 0.659088 83.5305 0.659088C95.6824 0.659088 106.501 5.34096 113.989 13.8447C121.476 22.3392 124.733 33.6253 123.169 45.6198C119.949 70.3122 96.9973 90.401 72.0015 90.401L72.0061 90.4056Z'
        }
        fill={theme.palette.primary.light}
      />
      <g clipPath={'url(#clip1_9491_109962)'}>
        <path
          d={
            'M97.3464 83.2507C99.1599 69.3482 112.036 58.0791 126.105 58.0791C140.175 58.0791 150.11 69.3482 148.299 83.2507C146.486 97.1531 133.609 108.422 119.54 108.422C105.471 108.422 95.5328 97.1531 97.3464 83.2507V83.2507Z'
          }
          fill={'white'}
        />
        <path
          d={
            'M102.953 101.555C106.961 105.807 112.767 108.42 119.533 108.42C126.298 108.42 132.787 105.807 137.905 101.555C132.837 99.9409 127.026 99.0317 120.757 99.0317C114.489 99.0317 108.441 99.9409 102.953 101.555V101.555Z'
          }
          fill={theme.palette.primary.main}
        />
        <path
          d={
            'M120.362 103.287C118.141 103.287 116.571 101.508 116.858 99.3147L119.499 79.0558H127.542L124.901 99.3147C124.614 101.508 122.582 103.287 120.362 103.287V103.287Z'
          }
          fill={'white'}
        />
        <path
          d={
            'M138.122 86.4472C138.438 80.1537 141.76 76.2261 136.39 69.1177C130.831 61.7551 124.592 66.6914 124.592 66.6914C124.592 66.6914 120.428 62.866 113.264 70.8653C106.94 77.9239 113.035 84.2489 110.136 88.6455C107.237 93.0394 99.0391 95.4369 99.0391 95.4369C99.0391 95.4369 100.916 100.431 103.762 102.629H137.024C137.024 102.629 144.861 95.2377 145.135 93.1416C145.135 93.1416 137.807 92.7408 138.122 86.4498V86.4472Z'
          }
          fill={theme.palette.primary.main}
        />
        <path
          d={
            'M102.957 101.555C106.965 105.807 112.771 108.42 119.537 108.42C126.302 108.42 132.791 105.807 137.908 101.555C132.841 99.9409 127.03 99.0317 120.761 99.0317C114.493 99.0317 108.445 99.9409 102.957 101.555V101.555Z'
          }
          fill={theme.palette.primary.main}
        />
        <path
          d={
            'M119.538 108.815C112.907 108.815 106.917 106.334 102.669 101.825L102.23 101.358L102.845 101.178C108.57 99.4928 114.599 98.6386 120.763 98.6386C126.926 98.6386 132.737 99.4928 138.031 101.18L138.714 101.398L138.162 101.856C132.766 106.342 126.151 108.813 119.538 108.813V108.815ZM103.697 101.751C107.76 105.805 113.359 108.029 119.538 108.029C125.717 108.029 131.944 105.792 137.093 101.715C132.067 100.198 126.579 99.4273 120.765 99.4273C114.952 99.4273 109.164 100.211 103.7 101.754L103.697 101.751Z'
          }
          fill={theme.palette.primary.main}
        />
        <path
          d={
            'M124.774 72.4636H124.495L115.251 80.5912L114.67 85.048C114.281 88.0245 115.572 90.6184 117.811 92.0254L116.86 99.312C116.573 101.505 118.142 103.284 120.363 103.284C122.584 103.284 124.616 101.505 124.902 99.312L125.835 92.1617C128.566 90.7914 130.648 88.1188 131.05 85.048L131.67 80.282C132.233 75.9641 129.147 72.4609 124.776 72.4609L124.774 72.4636Z'
          }
          fill={'white'}
        />
        <path
          d={
            'M120.36 103.68C119.17 103.68 118.11 103.219 117.372 102.383C116.636 101.547 116.313 100.439 116.468 99.2649L117.39 92.1984C117.419 91.9835 117.616 91.8316 117.832 91.8604C118.047 91.8892 118.2 92.0857 118.171 92.3006L117.248 99.367C117.125 100.313 117.38 101.201 117.963 101.867C118.549 102.532 119.401 102.896 120.36 102.896C122.387 102.896 124.248 101.267 124.508 99.2649L125.414 92.3215C125.443 92.1067 125.641 91.9547 125.856 91.9835C126.072 92.0123 126.224 92.2089 126.195 92.4237L125.288 99.367C124.978 101.746 122.768 103.682 120.358 103.682L120.36 103.68Z'
          }
          fill={theme.palette.primary.main}
        />
        <path
          d={
            'M121.799 93.532C121.581 93.532 121.405 93.3565 121.405 93.139C121.405 92.9215 121.581 92.746 121.799 92.746C126.123 92.746 130.094 89.2691 130.651 84.9982L131.274 80.2322C131.524 78.3117 131.022 76.4933 129.86 75.1151C128.707 73.7448 127.017 72.9456 125.103 72.8644C125.04 72.8618 124.98 72.8592 124.916 72.8592L115.602 80.9292L115.058 85.1004C114.666 88.0926 116.088 90.8254 118.674 92.0621C118.871 92.1564 118.953 92.3896 118.858 92.5861C118.764 92.7827 118.527 92.8639 118.333 92.7695C115.431 91.3835 113.838 88.331 114.274 84.9982L114.837 80.6777C114.85 80.5833 114.897 80.4942 114.968 80.4314L124.509 72.1675C124.58 72.1046 124.672 72.0705 124.767 72.0705C124.89 72.0705 125.011 72.0705 125.132 72.0784C127.269 72.1701 129.161 73.0714 130.46 74.6094C131.766 76.1579 132.331 78.1911 132.052 80.3344L131.429 85.1004C130.822 89.7512 126.501 93.5346 121.794 93.5346L121.799 93.532Z'
          }
          fill={theme.palette.primary.main}
        />
        <path
          d={
            'M119.539 108.815C112.616 108.815 106.452 106.148 102.187 101.303C97.9236 96.4639 96.0653 90.0341 96.9563 83.2009C97.3506 80.1903 98.26 77.2427 99.6609 74.4391C99.7581 74.2453 99.9947 74.1666 100.189 74.2636C100.384 74.3605 100.463 74.5963 100.365 74.7902C99.0038 77.5126 98.1207 80.3764 97.737 83.3004C96.8749 89.9084 98.6648 96.1181 102.778 100.785C106.894 105.456 112.847 108.029 119.539 108.029C133.395 108.029 146.122 96.891 147.906 83.2009C148.769 76.5929 146.979 70.3858 142.865 65.7167C138.749 61.045 132.796 58.4721 126.104 58.4721C118.564 58.4721 111.057 61.7787 105.506 67.5403C105.354 67.6975 105.107 67.7028 104.949 67.5508C104.791 67.4015 104.786 67.1526 104.939 66.9954C110.637 61.0791 118.351 57.686 126.104 57.686C133.027 57.686 139.191 60.3533 143.457 65.1979C147.722 70.0373 149.578 76.4671 148.687 83.3004C146.852 97.3679 133.777 108.813 119.536 108.813L119.539 108.815Z'
          }
          fill={theme.palette.primary.main}
        />
        <path
          d={
            'M150.976 116.418C151.527 116.381 151.982 115.96 152.053 115.409L152.053 115.409L153.814 101.915L153.814 101.915C153.897 101.28 153.449 100.7 152.81 100.616C152.172 100.533 151.591 100.98 151.507 101.616L150.976 116.418ZM150.976 116.418H150.901H136.97C136.327 116.418 135.806 115.898 135.806 115.258C135.806 114.618 136.327 114.098 136.97 114.098H149.747H149.878L149.895 113.967L151.507 101.616L150.976 116.418Z'
          }
          fill={theme.palette.primary.main}
          stroke={'white'}
          strokeWidth={'0.3'}
        />
        <path
          d={
            'M157.327 67.6403L157.326 67.6392H157.264C157.213 67.6392 157.162 67.6368 157.113 67.6301L157.112 67.63C156.473 67.5464 156.025 66.9659 156.109 66.3311L156.109 66.3309L157.98 51.9831L158.002 51.8137H157.831H142.506C141.863 51.8137 141.341 51.2935 141.341 50.6536C141.341 50.0137 141.863 49.4935 142.506 49.4935H159.327C159.662 49.4935 159.981 49.6376 160.203 49.8882C160.424 50.1389 160.526 50.4728 160.482 50.8013L160.482 50.8019L158.419 66.63C158.419 66.63 158.419 66.63 158.419 66.6301M157.327 67.6403L158.419 66.6301M157.327 67.6403C157.876 67.6116 158.344 67.1941 158.419 66.6301M157.327 67.6403L158.419 66.6301'
          }
          fill={theme.palette.primary.main}
          stroke={'white'}
          strokeWidth={'0.3'}
        />
        <path
          d={
            'M92.0973 66.0829C92.6389 66.0464 93.0983 65.6317 93.1717 65.0737L92.0973 66.0829ZM92.0973 66.0829H92.022C91.9714 66.0829 91.9206 66.0805 91.8714 66.0738L91.8707 66.0737C91.2318 65.99 90.7834 65.4096 90.8671 64.7747L90.8671 64.7745L92.7254 50.5027L92.7254 50.5025C92.7998 49.9254 93.2955 49.4909 93.8803 49.4909H111.49C112.133 49.4909 112.654 50.0111 112.654 50.651C112.654 51.2908 112.133 51.811 111.49 51.811H95.0341H94.9024L94.8854 51.9416L93.1717 65.0736L92.0973 66.0829Z'
          }
          fill={theme.palette.primary.main}
          stroke={'white'}
          strokeWidth={'0.3'}
        />
        <path
          d={
            'M103.438 115.261C103.438 115.874 102.959 116.378 102.353 116.418H102.277H85.4557C85.1202 116.418 84.8011 116.274 84.5799 116.024C84.3587 115.773 84.2566 115.439 84.3007 115.11L84.3008 115.11L86.4665 98.5036C86.4665 98.5036 86.4665 98.5036 86.4665 98.5036C86.5503 97.8677 87.1322 97.4199 87.7702 97.5035C88.409 97.5872 88.8574 98.1676 88.7737 98.8024L88.7737 98.8026L86.7998 113.931L86.7777 114.101H86.9486H102.274C102.917 114.101 103.438 114.621 103.438 115.261Z'
          }
          fill={theme.palette.primary.main}
          stroke={'white'}
          strokeWidth={'0.3'}
        />
      </g>
      <defs>
        <clipPath id={'clip0_9491_109962'}>
          <rect width={'12.75'} height={'60.2727'} fill={'white'} transform={'translate(55.3281 95.6609) rotate(128.037)'} />
        </clipPath>
        <clipPath id={'clip1_9491_109962'}>
          <rect width={'76.5'} height={'67.2273'} fill={'white'} transform={'translate(84.1406 49.3409)'} />
        </clipPath>
      </defs>
    </svg>
  );
};
