import type { FC } from 'react';
import React from 'react';
import grey from '@mui/material/colors/grey.js';
import { InfoOutlined } from '@mui/icons-material';
import type { DismissedSourceReason } from '@lama/contracts';

import { Button, Flex, greyPalette, Text } from '@lama/design-system';
import styled from 'styled-components';
import { useAsyncFn } from 'react-use';
import { Tooltip } from '../Tooltip/Tooltip.js';
import { PaperClipIcon } from './assets/PaperClipIcon.js';
import { NoDocumentActionsMenu } from './NoDocumentActions.js';
import type { OnMetadataUpdate } from './types.js';
import { DismissedDocumentIcon } from './assets/DismissedDocumentIcon.js';

interface NoDocumentProps {
  description: string;
  onFileSelected: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onMetadataUpdate?: OnMetadataUpdate;
  onDismissSource?: (name: string) => Promise<void>;
  onUndismissSource?: (name: string) => Promise<void>;
  dismissed?: DismissedSourceReason;
  dismissible?: boolean;
}

export const StyledText = styled(Text)`
  white-space: pre;
`;

export const NoDocument: FC<NoDocumentProps> = ({
  description,
  onFileSelected,
  onDismissSource,
  onUndismissSource,
  onMetadataUpdate,
  dismissed,
  dismissible,
}) => {
  const [{ loading: dismissingDocument }, onUndismissSourceInternal] = useAsyncFn(async () => {
    await onUndismissSource?.(description);
  }, [description, onUndismissSource]);

  return (
    <Flex gap={4} alignItems={'center'} width={'100%'} minWidth={0}>
      <Flex
        flexDirection={'column'}
        alignItems={'center'}
        justifyContent={'center'}
        minWidth={'34px'}
        minHeight={'34px'}
        width={'34px'}
        height={'34px'}
        borderRadius={'4px'}
        color={grey[300]}
      >
        {dismissed ? <DismissedDocumentIcon color={greyPalette[400]} /> : <PaperClipIcon color={greyPalette[400]} />}
      </Flex>
      <Flex alignItems={'center'} minWidth={0}>
        <Text variant={'body2'} color={dismissed ? grey[500] : 'secondary'}>
          {description}
        </Text>
        {dismissed ? (
          <Flex gap={2} alignItems={'center'}>
            <StyledText variant={'body2'} color={grey[500]}>
              {' - Dismissed'}
            </StyledText>
            <Tooltip title={dismissed?.description} placement={'top'}>
              <InfoOutlined sx={{ color: grey[500], height: '16px', width: '16px' }} />
            </Tooltip>
          </Flex>
        ) : null}
      </Flex>
      <Flex justifyContent={'flex-end'} flex={1}>
        {dismissed ? (
          <Button variant={'tertiary'} color={'neutral'} size={'l'} onClick={onUndismissSourceInternal} loading={dismissingDocument}>
            {'Restore'}
          </Button>
        ) : (
          <NoDocumentActionsMenu
            description={description}
            onFileSelected={onFileSelected}
            onDismiss={onDismissSource}
            onUndismiss={onUndismissSource}
            dismissible={dismissible}
            dismissed={dismissed}
            onMetadataUpdate={onMetadataUpdate}
          />
        )}
      </Flex>
    </Flex>
  );
};
