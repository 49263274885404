import type { FC } from 'react';
import React from 'react';
import { Flex, Text } from '@lama/design-system';
import type { ClosingItem } from '@lama/contracts';
import { formatValue } from '@lama/data-formatters';

interface ClosingItemProps {
  item: ClosingItem;
}

export const ClosingItemItem: FC<ClosingItemProps> = ({ item: { type, amount } }) => (
  <Flex flexDirection={'column'}>
    <Text variant={'body1'} weight={700} color={'secondary'}>
      {type}
      {amount ? ` - ${formatValue(amount, 'currency')}` : ''}
    </Text>
  </Flex>
);
