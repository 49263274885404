import React from 'react';

interface EditIconProps {
  size: string;
}

export const EditIcon: React.FC<EditIconProps> = ({ size }) => (
  <svg width={size} height={size} viewBox={'0 0 13 13'} fill={'none'} xmlns={'http://www.w3.org/2000/svg'}>
    <path
      d={
        'M1.33333 11.6668H2.26667L8.01667 5.91683L7.08333 4.9835L1.33333 10.7335V11.6668ZM10.8667 4.95016L8.03333 2.15016L8.96667 1.21683C9.22222 0.961274 9.53622 0.833496 9.90867 0.833496C10.2807 0.833496 10.5944 0.961274 10.85 1.21683L11.7833 2.15016C12.0389 2.40572 12.1722 2.71416 12.1833 3.0755C12.1944 3.43638 12.0722 3.74461 11.8167 4.00016L10.8667 4.95016ZM9.9 5.9335L2.83333 13.0002H0V10.1668L7.06667 3.10016L9.9 5.9335Z'
      }
      fill={'#9E9E9E'}
    />
  </svg>
);
