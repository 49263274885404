import type { AppState } from '@auth0/auth0-react';
import { Auth0Provider } from '@auth0/auth0-react';
import type { FC } from 'react';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth0Audience, auth0ClientId, auth0Domain } from './framework/environment';

interface Props {
  children: React.ReactNode;
}

export const Auth0ProviderWithHistory: FC<Props> = ({ children }) => {
  const navigate = useNavigate();

  const onRedirectCallback = useCallback(
    (appState: AppState | undefined) => {
      navigate(appState?.returnTo ?? '/');
    },
    [navigate],
  );

  if (!(auth0Domain && auth0ClientId)) {
    console.error("missing auth0 domain and clientId, can't initialize auth0 provider");
    return null;
  }

  return (
    <Auth0Provider
      domain={auth0Domain}
      audience={auth0Audience}
      clientId={auth0ClientId}
      redirectUri={`${window.location.origin}/auth`}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};
