/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import type { EvaluatedApplicationRequirement } from '@lama/contracts';
import React, { useMemo } from 'react';
import { LoadingPage } from '@lama/app-components';
import { Flex } from '@lama/design-system';
import { useDocumentsQuery } from '../../../../hooks/react-query/useDocumentsQuery';
import { isDocumentRelatedToRequirement } from '../../../../shared/utils/isDocumentRelatedToRequirement';
import { RequirementDocuments } from './RequirementDocuments';
import { UploadArbitraryDocumentButton } from './UploadArbitraryDocumentButton';

interface DocumentRequirementProps {
  requirement: EvaluatedApplicationRequirement;
}

export const DocumentRequirement: React.FC<DocumentRequirementProps> = ({ requirement }) => {
  const { id: requirementId, entityId, entityType, referenceRequirementId, key, applicationId, sources } = requirement;
  const { data: documents, isPending: loadingDocuments } = useDocumentsQuery(applicationId);

  const requirementDocuments = useMemo(
    () => documents?.filter((document) => isDocumentRelatedToRequirement(document, requirement)) ?? [],
    [documents, requirement],
  );

  const requirementHasExtractableSources = useMemo(
    () => sources.uploadFilesSource?.some(({ extractable }) => extractable),
    [sources.uploadFilesSource],
  );

  if (loadingDocuments) {
    return <LoadingPage />;
  }

  return (
    <Flex flexDirection={'column'} gap={4} width={'100%'}>
      <RequirementDocuments requirement={requirement} requirementDocuments={requirementDocuments} />
      <UploadArbitraryDocumentButton
        applicationId={applicationId}
        entityId={entityId}
        entityType={entityType}
        requirementId={requirementId}
        sharedRequirementId={referenceRequirementId}
        requirementKey={key}
        extractable={requirementHasExtractableSources}
      />
    </Flex>
  );
};
