/* eslint-disable react/jsx-no-useless-fragment */
import type { PersonApiModel } from '@lama/clients';
import type { BusinessApiModel } from '@lama/business-service-client';

import React, { useCallback, useMemo, useState } from 'react';
import type { FC } from 'react';

import type { Entity } from '@lama/common-types';
import { Flex } from '@lama/design-system';
import { Toggle } from '../Toggle';
import { SelectOrCreateEntity } from './SelectOrCreateEntity';
import type { GenericAddRelationComponentProps } from './types';
import { EntityForm } from './EntityForm';
import { SearchExistingCustomer } from './SearchExistingCustomers';

type Step = 'form' | 'search' | 'selection';

interface EntityFormContainerProps extends GenericAddRelationComponentProps {
  step: Step;
  onBack: () => void;
}

const EntityFormContainer = (props: EntityFormContainerProps) => {
  const { relationName, showEntityTypeToggle, onToggleChange, entityType } = props;
  if (relationName === 'property') {
    return <EntityForm {...props} />;
  }

  return (
    <Flex minHeight={'100%'} fullWidth height={'100%'} gap={6} alignItems={'center'} flexDirection={'column'}>
      {showEntityTypeToggle && onToggleChange ? (
        <Toggle
          onChange={onToggleChange}
          optionA={{ value: true, text: 'Person' }}
          optionB={{ value: false, text: 'Business' }}
          value={entityType === 'person'}
        />
      ) : null}
      <EntityForm {...props} entityType={entityType} />
    </Flex>
  );
};

export const AddOrCreateRelatedEntity: FC<GenericAddRelationComponentProps> = ({
  editSelectedEntity = false,
  businessesSelectionOnly = false,
  ...props
}) => {
  const {
    application,
    existingEntityIds,
    submitHandler,
    isLoading,
    relationName,
    onClose,
    searchButtonVisible,
    customForm: CustomForm,
    onSearchResultConverted,
    onBack,
  } = props;

  const [step, setStep] = useState<Step>('selection');
  const onSearchSelected = useCallback(() => {
    setStep('search');
  }, []);

  const onBackInner = useCallback(() => {
    setStep('selection');

    if (onBack) {
      onBack();
    }
  }, [onBack]);

  const onEntitySelected = useCallback(
    async (selectedEntity?: BusinessApiModel | PersonApiModel, entityType?: Entity) => {
      const existingEntity = selectedEntity && entityType;

      if (!existingEntity) {
        setStep('form');

        return;
      }

      await submitHandler(selectedEntity, entityType);

      if (editSelectedEntity) {
        setStep('form');
      }
    },
    [submitHandler, editSelectedEntity],
  );

  const CurrentView = useMemo(() => {
    switch (step) {
      case 'form': {
        return CustomForm
          ? () => <CustomForm onBack={onBackInner} />
          : () => <EntityFormContainer {...props} onBack={onBackInner} step={step} />;
      }
      case 'search': {
        return () => <SearchExistingCustomer onSearchResultConverted={onSearchResultConverted} onClose={onClose} onBack={onBackInner} />;
      }
      default: {
        return () => (
          <SelectOrCreateEntity
            entityType={businessesSelectionOnly ? 'business' : undefined}
            onEntitySelected={onEntitySelected}
            excludedEntityIds={existingEntityIds}
            application={application}
            loading={!!isLoading}
            relationName={relationName}
            searchEnabled={searchButtonVisible}
            onSearchSelected={onSearchSelected}
          />
        );
      }
    }
  }, [
    step,
    CustomForm,
    props,
    onBackInner,
    onClose,
    onEntitySelected,
    existingEntityIds,
    application,
    isLoading,
    relationName,
    searchButtonVisible,
    onSearchSelected,
    onSearchResultConverted,
    businessesSelectionOnly,
  ]);

  return (
    <Flex height={'100%'} overflow={'auto'} fullWidth flexDirection={'column'}>
      <CurrentView />
    </Flex>
  );
};
