/* eslint-disable react/destructuring-assignment */
import type { FC } from 'react';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { isNil } from 'lodash-es';
import { applicationBorrowingRelatedBusinessesSelector, personFullName } from '@lama/data-formatters';
import { Button, Flex } from '@lama/design-system';
import { analyticsEvent } from '../../shared/utils/analytics';
import { ApplicationContext } from '../../shared/contexts/ApplicationContext';
import { useSubmitApplicationMutation } from '../../hooks/react-query/useSubmitApplicationMutation';
import { DocumentSignDemo } from '../shared/DocumentSignDemo/DocumentSignDemo';
import { BasicScreen } from '../shared/BasicScreen';
import { UserDetailsContext } from '../../shared/contexts/UserDetailsContext';

interface FibtSignAndSubmitProps {
  title?: string;
  subtitle?: string;
  pageUrls?: string[];
  signingPage?: number;
}

export const FibtSignAndSubmit: FC<FibtSignAndSubmitProps> = ({ title, subtitle, pageUrls, signingPage }) => {
  const { t } = useTranslation();
  const { application, requirements } = useContext(ApplicationContext);
  const navigate = useNavigate();
  const { mutateAsync: submitApplication } = useSubmitApplicationMutation(application.id);
  const userDetails = useContext(UserDetailsContext);
  const [signed, setSigned] = useState(false);

  const formData = useMemo(() => {
    const userName = userDetails?.firstName && userDetails.lastName ? personFullName(userDetails) : '';
    const tin = applicationBorrowingRelatedBusinessesSelector(application)[0]?.business.tin ?? '';

    return [
      { value: userName, left: '70px', top: '715px', handwriting: true },
      { value: userName, left: '70px', top: '770px' },
      { value: 'Owner', left: '70px', top: '800px' },
      { value: tin, left: '70px', top: '830px' },
    ];
  }, [application, userDetails]);

  const allRequirementsSubmitted = useMemo(
    () => requirements.length && requirements.every(({ submitted, isInvalidSubmission }) => submitted && !isInvalidSubmission),
    [requirements],
  );

  const onSign = useCallback(async () => {
    setSigned(true);
  }, []);

  const onContinue = useCallback(async () => {
    analyticsEvent({
      action: 'signed_and_submitted',
      category: 'conversion',
    });
    await submitApplication();
    navigate('../overview');
  }, [navigate, submitApplication]);

  useEffect(() => {
    if (allRequirementsSubmitted) {
      navigate('../overview');
    }
  }, [navigate, allRequirementsSubmitted]);

  if (!pageUrls?.length || isNil(signingPage)) {
    console.error('Missing required props for FibtSignAndSubmit');
    return null;
  }

  return (
    <BasicScreen flow={'management'} title={title || t('signAndSubmit.title')} subtitle={subtitle} nextVisible={false} backVisible={false}>
      <Flex flexDirection={'column'} alignItems={'center'} gap={12}>
        <DocumentSignDemo pageUrls={pageUrls} signingPage={signingPage} data={formData} onSign={onSign} />
        <Flex flexDirection={'column'} alignItems={'flex-end'}>
          <Button onClick={onContinue} disabled={!signed}>
            {'Continue'}
          </Button>
        </Flex>
      </Flex>
    </BasicScreen>
  );
};
