import React from 'react';
import type { FormikInputProps } from '@lama/app-components';
import { PropertyFormikInput } from '@lama/app-components';
import { capitalCase } from 'change-case';
import type { TextFieldProps } from '@mui/material';
import { useGetRequiredFields } from '../../hooks/useGetRequiredFields';

export const RequirementInput: React.FC<
  TextFieldProps & {
    name: string;
    label?: string;
    required?: boolean;
    submitted: boolean;
    Component?: React.FC<FormikInputProps>;
  }
> = ({ name, label, required, submitted, Component = PropertyFormikInput, ...textFieldProps }) => {
  const requiredFields = useGetRequiredFields();

  return (
    <Component
      {...textFieldProps}
      name={name}
      label={label ?? capitalCase(name)}
      fullWidth
      required={required ?? requiredFields.includes(name)}
      highlight={submitted}
    />
  );
};
