import React, { forwardRef } from 'react';
import { capitalCase } from 'change-case-all';
import ReactAvatar from 'react-avatar';
import type { AvatarProps } from './types';

export const getAvatarText = (firstName: string, lastName: string) =>
  `${capitalCase(firstName?.[0] ?? '')}${capitalCase(lastName?.[0] ?? '')}`;

export const Avatar = forwardRef<HTMLDivElement, AvatarProps>(
  (
    { size = '32px', round = true, textSizeRatio = 2, ...props },
    ref, // @ts-ignore
  ) => <ReactAvatar size={size} ref={ref} round={round} textSizeRatio={textSizeRatio} {...props} />,
);
