import { Box, Pagination, Typography } from '@mui/material';
import type { FC } from 'react';
import React, { useMemo, useCallback, useState } from 'react';
import { Button, Flex } from '@lama/design-system';
import { useAsyncFn } from 'react-use';
import EditIcon from '@mui/icons-material/Edit';

export const DocumentSignDemo: FC<{
  pageUrls: string[];
  signingPage: number;
  data: { value: string; left: string; top: string; handwriting?: boolean }[];
  onSign?: () => Promise<void>;
}> = ({ pageUrls, signingPage, data, onSign }) => {
  const [signed, setSigned] = useState(false);
  const [page, setPage] = useState(0);

  const [{ loading: isSigning }, onClick] = useAsyncFn(async () => {
    await new Promise((resolve) => setTimeout(resolve, 1000));
    setSigned(true);
    if (onSign) {
      await onSign();
    }
  }, [onSign]);

  const onPageChange = useCallback(
    (_event: React.ChangeEvent<unknown>, value: number) => {
      setPage(value - 1);
    },
    [setPage],
  );

  const imageUrl = useMemo(() => pageUrls[page], [page, pageUrls]);

  return (
    <Flex flexDirection={'column'} alignItems={'center'} gap={6}>
      <Flex flexDirection={'column'} alignItems={'center'} gap={4}>
        <Box sx={{ position: 'relative' }}>
          {signed && page === signingPage ? (
            <>
              {data.map(({ value, left, top, handwriting }) => (
                <Typography
                  key={value}
                  variant={handwriting ? 'signature' : 'body1'}
                  fontSize={handwriting ? '22px' : '12px'}
                  sx={{ position: 'absolute', left, top }}
                >
                  {value}
                </Typography>
              ))}
            </>
          ) : null}
          <Box sx={{ borderRadius: '4px', border: '1px solid #E0E0E0' }}>
            <img src={imageUrl} width={'700px'} />
          </Box>
        </Box>
      </Flex>
      <Flex gap={2} width={'100%'}>
        <Flex flex={1} />
        <Flex flex={1} justifyContent={'center'}>
          <Pagination count={pageUrls.length} page={page + 1} onChange={onPageChange} />
        </Flex>
        <Flex flex={1} justifyContent={'flex-end'}>
          <Button onClick={onClick} disabled={signed || signingPage !== page} loading={isSigning} endIcon={<EditIcon />} width={'100px'}>
            {'Sign'}
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};
