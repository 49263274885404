import tinycolor from 'tinycolor2';

export const getTextContrastColor = (backgroundColor: string): string => {
  const color = tinycolor(backgroundColor);
  const black = tinycolor('#000000');
  const white = tinycolor('#FFFFFF');

  const blackContrast = tinycolor.readability(color, black);
  const whiteContrast = tinycolor.readability(color, white);

  return whiteContrast > blackContrast ? white.toHexString() : black.toHexString();
};
