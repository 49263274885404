import React from 'react';

export const Progress25Icon = () => (
  <svg width={'8'} height={'8'} viewBox={'0 0 8 8'} fill={'none'} xmlns={'http://www.w3.org/2000/svg'}>
    <path
      d={
        'M8 8C8 6.94943 7.79307 5.90914 7.39104 4.93854C6.989 3.96793 6.39972 3.08602 5.65685 2.34315C4.91398 1.60028 4.03207 1.011 3.06147 0.608966C2.09086 0.206928 1.05057 1.53653e-06 -4.76837e-07 1.39876e-06L-1.39876e-06 8L8 8Z'
      }
      fill={'#9E9E9E'}
    />
  </svg>
);

export const Progress50Icon = () => (
  <svg width={'8'} height={'16'} viewBox={'0 0 8 16'} fill={'none'} xmlns={'http://www.w3.org/2000/svg'}>
    <path
      d={
        'M1.90798e-07 16C1.05057 16 2.09086 15.7931 3.06147 15.391C4.03207 14.989 4.91399 14.3997 5.65685 13.6569C6.39972 12.914 6.989 12.0321 7.39104 11.0615C7.79307 10.0909 8 9.05057 8 8C8 6.94942 7.79307 5.90914 7.39104 4.93853C6.989 3.96793 6.39972 3.08601 5.65685 2.34315C4.91399 1.60028 4.03207 1.011 3.06147 0.608964C2.09086 0.206926 1.05057 -8.97309e-09 -9.53674e-07 9.53991e-08L9.5399e-08 8L1.90798e-07 16Z'
      }
      fill={'#9E9E9E'}
    />
  </svg>
);

export const Progress75Icon = () => (
  <svg width={'16'} height={'16'} viewBox={'0 0 16 16'} fill={'none'} xmlns={'http://www.w3.org/2000/svg'}>
    <path
      d={
        'M6.99382e-07 8C5.61057e-07 9.58225 0.469192 11.129 1.34824 12.4446C2.22729 13.7602 3.47672 14.7855 4.93853 15.391C6.40034 15.9965 8.00887 16.155 9.56072 15.8463C11.1126 15.5376 12.538 14.7757 13.6569 13.6569C14.7757 12.538 15.5376 11.1126 15.8463 9.56072C16.155 8.00887 15.9965 6.40034 15.391 4.93853C14.7855 3.47672 13.7602 2.22729 12.4446 1.34824C11.129 0.469191 9.58225 -5.61057e-07 8 -6.99382e-07L8 8L6.99382e-07 8Z'
      }
      fill={'#9E9E9E'}
    />
  </svg>
);
