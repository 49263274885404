import type { FC } from 'react';
import React from 'react';
import { Flex, greyPalette, Spinner, Text } from '@lama/design-system';

export const DocumentUploadingState: FC = () => (
  <Flex gap={4} backgroundColor={greyPalette[50]} justifyContent={'center'} p={4} alignItems={'center'} width={'100%'} borderRadius={'8px'}>
    <Spinner size={'20px'} />
    <Text variant={'body2'} color={'secondary'}>
      {'Uploading file, this may take a few moments'}
    </Text>
  </Flex>
);
