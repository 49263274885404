import React from 'react';
import { InputAdornment } from '@mui/material';
import { Computer } from '@mui/icons-material';
import type { FormikInputProps } from './PropertyFormikInput';
import { PropertyFormikInput } from './PropertyFormikInput.js';

export const FormikWebsiteField: React.FC<FormikInputProps> = (props) => (
  <PropertyFormikInput
    InputProps={{
      startAdornment: (
        <InputAdornment position={'start'}>
          <Computer color={'primary'} />
        </InputAdornment>
      ),
    }}
    {...props}
  />
);
