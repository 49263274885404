/* eslint-disable @typescript-eslint/naming-convention */
import React, { useCallback, useMemo } from 'react';
import type { FC } from 'react';
import type { ProductPublicData } from '@lama/contracts';
import { Box, Divider, Typography } from '@mui/material';
import { Tooltip } from '@lama/app-components';
import { Flex } from '@lama/design-system';
import styled from 'styled-components';
import { MultilineTypography } from '../shared/MultilineTypography';
import { TermLoanLogo } from './productLogos/TermLoanLogo';
import { Sba7aLogo } from './productLogos/Sba7aLogo';
import { CreLogo } from './productLogos/CreLogo';
import { InformationStack } from './InformationStack';
import { Banner } from './Banner';
import { InvoiceFinancingLogo } from './productLogos/InvoiceFinancingLogo';
import { McaLogo } from './productLogos/McaLogo';
import { LineOfCreditLogo } from './productLogos/LineOfCreditLogo';
import { EquipmentFinancingLogo } from './productLogos/EquipmentFinancingLogo';
import { InventoryFinancingLogo } from './productLogos/InventoryFinancingLogo';

const productNameToLogo: Record<string, FC<{ selected: boolean }>> = {
  term: TermLoanLogo,
  sba7a: Sba7aLogo,
  cre: CreLogo,
  mca: McaLogo,
  lineOfCredit: LineOfCreditLogo,
  invoiceFinancing: InvoiceFinancingLogo,
  equipmentFinancing: EquipmentFinancingLogo,
  inventoryFinancing: InventoryFinancingLogo,
};

interface ProductCardProps {
  product: ProductPublicData;
  isSelected: boolean;
  onClick: (productId: string) => void;
  bannerText?: string;
}

const StyledCardContainer = styled(Flex)<{ selected?: boolean }>`
  width: 308px;
  height: 405px;
  border: 1px solid;
  border-radius: 10px;
  border-color: ${({ selected, theme }) => (selected ? theme.colors.primary.main : theme.colors.grey[300])};
  background-color: ${({ selected, theme }) => (selected ? theme.colors.primary.light : 'white')};
  &:hover {
    background-color: ${({ selected, theme }) => (selected ? theme.colors.primary.light : theme.colors.grey[100])};
  }
`;

export const ProductCard: FC<ProductCardProps> = ({ product, isSelected, onClick, bannerText }) => {
  const onClickHandler = useCallback(() => {
    onClick(product.id);
  }, [onClick, product.id]);

  const infoLines = useMemo(
    () => [
      {
        label: 'Speed of funds',
        value: product.terms?.ranges?.speedOfFunds
          ? `${product.terms.ranges.speedOfFunds.minDays} - ${product.terms.ranges.speedOfFunds.maxDays} days`
          : '-',
      },
      {
        label: 'Interest rate',
        value: product.terms?.ranges?.apr ? `${product.terms.ranges.apr.min}% - ${product.terms.ranges.apr.max}%` : '-',
      },
      {
        label: 'Term length',
        value: product.terms?.ranges?.termLength
          ? `${product.terms.ranges.termLength.minMonth} - ${product.terms.ranges.termLength.maxMonth} months`
          : '-',
      },
    ],
    [product.terms?.ranges?.apr, product.terms?.ranges?.speedOfFunds, product.terms?.ranges?.termLength],
  );

  const ProductLogo = useMemo(() => productNameToLogo[product.name] ?? TermLoanLogo, [product.name]);

  return (
    <Box position={'relative'}>
      {bannerText ? (
        <Box position={'absolute'} top={'32px'}>
          <Banner text={bannerText} color={'primary.main'} />
        </Box>
      ) : null}
      <StyledCardContainer flexDirection={'column'} selected={isSelected} onClick={onClickHandler} gap={4} px={8} py={4}>
        <Flex flexDirection={'column'} gap={4} flex={0.7}>
          <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={'115px'}>
            <ProductLogo selected={isSelected} />
          </Box>
          <Flex flexDirection={'column'} gap={4}>
            <Typography variant={'h5'}>{product.prettyName}</Typography>
            <Tooltip title={product.description}>
              <Box>
                <MultilineTypography variant={'body2'} maxLines={4}>
                  {product.description}
                </MultilineTypography>
              </Box>
            </Tooltip>
          </Flex>
        </Flex>
        <Flex flexDirection={'column'} mt={'auto'} gap={4}>
          <Divider />
          <Box flex={0.3}>
            <InformationStack infoLines={infoLines} />
          </Box>
        </Flex>
      </StyledCardContainer>
    </Box>
  );
};
