import { useTheme } from '@mui/material';
import type { FC } from 'react';
import React from 'react';

export const TermLoanLogo: FC<{ selected: boolean }> = ({ selected }) => {
  const theme = useTheme();

  return (
    <svg width={'84'} height={'116'} viewBox={'0 0 84 116'} fill={'none'} xmlns={'http://www.w3.org/2000/svg'}>
      <path
        d={
          'M9.39862 115.807L77.3314 115.379C79.4821 115.366 81.1791 113.554 81.0396 111.419L75.0289 18.8424C74.9001 16.8685 73.2446 15.3405 71.2563 15.362L3.24995 14.8057C1.72773 14.8011 -0.087286 16.4057 0.00315821 17.9183L5.64136 112.305C5.75786 114.28 7.40885 115.819 9.39862 115.807Z'
        }
        fill={theme.palette.primary.light}
      />
      <path
        d={
          'M11.5632 115.807L79.4959 115.376C81.6467 115.362 83.3437 113.542 83.2042 111.396L77.1935 18.3781C77.0647 16.395 75.4091 14.8594 73.4209 14.8808L5.63676 14.8594C3.70831 14.8594 2.17689 16.4808 2.29033 18.4057L7.80589 112.288C7.9224 114.273 9.57339 115.819 11.5632 115.807Z'
        }
        fill={'white'}
      />
      <path d={'M14.1906 109.341L76.0039 108.86L70.9528 22.5376L9.10277 22.887L14.1906 109.341Z'} fill={theme.palette.primary.light} />
      <path d={'M18.2514 38.7444L63.193 38.7199L62.7607 36.0532H18.0368L18.2514 38.7444Z'} fill={'white'} />
      <path d={'M18.5197 43.9275L51.6882 43.9689L51.4168 41.3022H18.3741L18.5197 43.9275Z'} fill={'white'} />
      <path d={'M19.0808 52.9848L63.7295 52.9235L63.2972 50.2568L18.8401 50.286L19.0808 52.9848Z'} fill={'white'} />
      <path d={'M19.4011 58.1955L52.867 58.1725L52.4347 55.5059L19.1865 55.5227L19.4011 58.1955Z'} fill={'white'} />
      <path d={'M19.9085 67.2531L64.7489 67.2868L64.3166 64.6202L19.7215 64.6079L19.9085 67.2531Z'} fill={'white'} />
      <path d={'M20.2289 72.5434L53.5722 72.5358L53.1399 69.8691H20.0971L20.2289 72.5434Z'} fill={'white'} />
      <path d={'M20.7379 81.4674L65.6059 81.4904L65.1736 78.8237L20.5509 78.8482L20.7379 81.4674Z'} fill={'white'} />
      <path d={'M21.0307 86.4359L54.2697 86.4191L53.8374 83.7524L20.8712 83.7647L21.0307 86.4359Z'} fill={'white'} />
      <path
        d={
          'M17.3025 116C17.1768 116 17.0741 115.897 17.0726 115.772C17.0726 115.644 17.1738 115.542 17.301 115.54L79.4944 115.146C80.4709 115.14 81.3753 114.743 82.0406 114.029C82.7059 113.315 83.0386 112.385 82.9757 111.411L76.9635 18.3933C76.8439 16.5328 75.2803 15.0922 73.4239 15.1106L5.6383 15.0892C4.77064 15.0892 3.96431 15.437 3.36799 16.0685C2.77167 16.6999 2.47122 17.5259 2.5218 18.3918L7.92699 111.954C7.93466 112.081 7.83808 112.19 7.71085 112.196C7.58668 112.202 7.47477 112.107 7.46864 111.98L2.06192 18.4194C2.00366 17.4248 2.34858 16.4776 3.03228 15.7528C3.71597 15.0279 4.64034 14.6294 5.63676 14.6294L73.4209 14.6509C73.4346 14.6509 73.45 14.6509 73.4638 14.6509C75.5517 14.6509 77.287 16.2738 77.4219 18.3642L83.4326 111.382C83.5031 112.484 83.129 113.536 82.3764 114.343C81.6237 115.151 80.6012 115.6 79.4975 115.606L17.3041 116H17.3025Z'
        }
        fill={'black'}
        fillOpacity={'0.6'}
      />
      <path
        d={
          'M59.356 26.1755L22.9101 25.9885C21.9888 25.9839 21.23 25.2974 21.1717 24.4161L20.4543 13.3848C20.3914 12.4178 21.1962 11.6009 22.208 11.604L58.6539 11.7435C59.5752 11.7465 60.3371 12.4347 60.3938 13.3159L61.1112 24.3947C61.1741 25.3632 60.3678 26.1801 59.3545 26.174L59.356 26.1755Z'
        }
        fill={'#666666'}
      />
      <path
        d={
          'M59.1859 26.096L23.8743 25.9075C22.9821 25.9029 22.2463 25.2071 22.1911 24.3167L21.4967 13.1582C21.4354 12.1789 22.2156 11.3528 23.1967 11.3574L58.5068 11.4984C59.4005 11.5015 60.1378 12.1973 60.193 13.0892L60.889 24.2952C60.9503 25.2745 60.1685 26.1006 59.1874 26.096H59.1859Z'
        }
        fill={selected ? theme.palette.primary.main : '#0C2007'}
      />
      <path
        d={
          'M59.1951 26.3257H59.1843L23.8728 26.1372C22.8641 26.1311 22.024 25.3387 21.9612 24.3319L21.2667 13.1734C21.233 12.6354 21.4185 12.1236 21.7879 11.7328C22.1574 11.3404 22.6571 11.1259 23.1967 11.1289L58.5068 11.2699C59.517 11.2745 60.3586 12.0669 60.4214 13.0768L61.1174 24.2828C61.1511 24.8207 60.9656 25.3326 60.5946 25.7249C60.2267 26.1142 59.7301 26.3272 59.1951 26.3272V26.3257ZM59.1874 25.8659H59.1951C59.6028 25.8659 59.9799 25.7035 60.2604 25.4077C60.5425 25.1089 60.6835 24.7196 60.6575 24.3104L59.9615 13.1044C59.914 12.3366 59.2732 11.7328 58.5037 11.7297L23.1937 11.5887H23.1875C22.7798 11.5887 22.4011 11.7512 22.1206 12.0485C21.8401 12.3473 21.699 12.7366 21.7236 13.1458L22.418 24.3043C22.4655 25.0705 23.1047 25.6744 23.8728 25.679L59.1843 25.8675L59.1874 25.8659Z'
        }
        fill={selected ? theme.palette.primary.main : '#0C2007'}
      />
      <path
        d={
          'M45.5502 6.73517C45.3479 3.46624 42.7051 0.909943 39.4889 0.870096L38.629 0.859369C34.7981 0.813392 31.7199 4.06393 31.907 7.96121L32.1308 12.6447C32.163 13.6546 32.3638 15.5259 33.4859 15.5259L46.1542 15.2853L45.5502 6.73517ZM39.2191 9.91827C38.1338 9.91827 37.2554 8.80411 37.2554 7.42788C37.2554 6.05165 38.1353 4.93749 39.2191 4.93749C40.3029 4.93749 41.1829 6.05165 41.1829 7.42788C41.1829 8.80411 40.3029 9.91827 39.2191 9.91827Z'
        }
        fill={'#666666'}
      />
      <path
        d={
          'M46.6739 6.789C46.4715 3.57984 43.8287 1.06799 40.6126 1.03121L39.7526 1.02048C35.9218 0.976034 32.8436 4.1668 33.0306 7.99358L33.443 13.8234C33.5135 14.8242 34.352 15.5966 35.3561 15.5858L45.3571 15.4694C46.3995 15.4571 47.2181 14.5698 47.1445 13.5307L46.6739 6.79053V6.789ZM40.0224 9.67479C38.9371 9.67479 38.0587 8.58055 38.0587 7.23037C38.0587 5.8802 38.9386 4.78595 40.0224 4.78595C41.1062 4.78595 41.9861 5.8802 41.9861 7.23037C41.9861 8.58055 41.1062 9.67479 40.0224 9.67479Z'
        }
        fill={selected ? theme.palette.primary.main : '#0C2007'}
      />
      <path
        d={
          'M35.3316 15.8156C34.2187 15.8156 33.2897 14.9528 33.2115 13.8402L32.7991 8.01033C32.7056 6.09158 33.3863 4.27704 34.7169 2.89621C36.049 1.51539 37.8364 0.769033 39.7541 0.790489L40.6141 0.801217C43.9314 0.841063 46.6938 3.46326 46.9023 6.77357L47.3729 13.5122C47.4128 14.0792 47.2227 14.6218 46.8364 15.0386C46.4516 15.457 45.9258 15.6899 45.3571 15.6961L35.3561 15.8125C35.3484 15.8125 35.3392 15.8125 35.3316 15.8125V15.8156ZM39.6698 1.25025C37.91 1.25025 36.2728 1.94603 35.0495 3.21498C33.8078 4.50233 33.1716 6.19579 33.259 7.98275L33.6699 13.808C33.7312 14.68 34.4593 15.3558 35.3316 15.3558C35.3377 15.3558 35.3454 15.3558 35.3515 15.3558L45.3525 15.2394C45.7924 15.2348 46.2002 15.0524 46.4991 14.729C46.7981 14.4057 46.9452 13.9857 46.9146 13.5459L46.4439 6.80575C46.4439 6.80575 46.4439 6.80575 46.4439 6.80422C46.2508 3.73299 43.6877 1.29776 40.6095 1.26098L39.7495 1.25025C39.7235 1.25025 39.6974 1.25025 39.6714 1.25025H39.6698ZM40.0209 9.90457C38.8114 9.90457 37.8272 8.70458 37.8272 7.23027C37.8272 5.75595 38.8114 4.55597 40.0209 4.55597C41.2304 4.55597 42.2145 5.75595 42.2145 7.23027C42.2145 8.70458 41.2304 9.90457 40.0209 9.90457ZM40.0209 5.01573C39.0643 5.01573 38.2871 6.00882 38.2871 7.23027C38.2871 8.45171 39.0659 9.4448 40.0209 9.4448C40.9759 9.4448 41.7546 8.45171 41.7546 7.23027C41.7546 6.00882 40.9759 5.01573 40.0209 5.01573V5.01573Z'
        }
        fill={selected ? theme.palette.primary.main : '#0C2007'}
      />
    </svg>
  );
};
