import React, { useContext, useMemo } from 'react';
import { Avatar } from '@lama/app-components';
import { Divider, Link } from '@mui/material';
import type { FC } from 'react';
import { useRecoilValue } from 'recoil';
import { Text, Flex, bluePalette, greyPalette } from '@lama/design-system';
import { personFullName } from '@lama/data-formatters';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { partnerState } from '../../../../state/appState';
import { ApplicationContext } from '../../../../shared/contexts/ApplicationContext';
import { useApplicationBankerQuery } from '../../../../hooks/react-query/useApplicationBankerQuery';

const defaultWorkHours = 'Mon-Fri, 9:00am - 5:00pm';

export const SideNavContactInfo: FC = () => {
  const { fibtDemo } = useFlags();
  const partner = useRecoilValue(partnerState);
  const {
    application: { id: applicationId },
  } = useContext(ApplicationContext);

  const { data: relationshipManager, isPending: fetchingRelationshipManager } = useApplicationBankerQuery(applicationId, {
    role: 'relationshipManager',
  });

  const contactInfoFullName = useMemo(() => (relationshipManager ? personFullName(relationshipManager) : ''), [relationshipManager]);

  if (fetchingRelationshipManager || !relationshipManager?.firstName || !relationshipManager?.lastName) {
    return null;
  }

  return (
    <Flex flexDirection={'column'} gap={4} px={8}>
      <Flex alignItems={'center'} flexDirection={'row'} gap={3}>
        <Avatar firstName={relationshipManager.firstName} lastName={relationshipManager.lastName} />
        <Flex flexDirection={'column'}>
          <Text variant={'body2'}>{contactInfoFullName}</Text>
          <Text variant={'body3'} color={greyPalette[500]}>
            {fibtDemo ? 'VP, National Sales Manager' : 'Your banker'}
          </Text>
        </Flex>
      </Flex>
      <Divider />
      <Flex flexDirection={'column'} gap={2}>
        <Text variant={'body3'}>{partner?.branding.communication.bankerWorkHours ?? defaultWorkHours}</Text>
        {relationshipManager.email ? (
          <Link href={`mailto:${relationshipManager.email}`} underline={'none'}>
            <Text variant={'body3'} color={bluePalette[700]}>
              {relationshipManager.email}
            </Text>
          </Link>
        ) : null}
        {relationshipManager.phoneNumber ? (
          <Link href={`tel:${relationshipManager.phoneNumber}`} underline={'none'}>
            <Text variant={'body3'} color={bluePalette[700]}>
              {relationshipManager.phoneNumber}
            </Text>
          </Link>
        ) : null}
      </Flex>
    </Flex>
  );
};
