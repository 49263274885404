import type { SourcedProperty } from '@lama/properties';

export const getPropertiesAsLarge = (properties: SourcedProperty[]): SourcedProperty[] =>
  properties.map((p) => ({
    ...p,
    size: 'large',
    ...(p.childProperties ? { childProperties: getPropertiesAsLarge(p.childProperties) } : {}),
  }));
export const getPropertiesAsOptional = (properties: SourcedProperty[]): SourcedProperty[] =>
  properties.map((p) => ({
    ...p,
    optional: true,
    ...(p.childProperties ? { childProperties: getPropertiesAsOptional(p.childProperties) } : {}),
  }));
