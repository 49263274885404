import { Flex, Spinner, Text } from '@lama/design-system';
import { MenuItem } from '@mui/material';
import React from 'react';
import type { FC } from 'react';
import { DismissedDocumentIcon } from '../assets/DismissedDocumentIcon';

interface DismissMenuItemProps {
  onClick: (e: React.MouseEvent) => void;
  disabled: boolean;
  loading: boolean;
}
export const DismissMenuItem: FC<DismissMenuItemProps> = ({ onClick: dismissDocument, disabled, loading: dismissing }) => (
  <MenuItem onClick={dismissDocument} disabled={disabled}>
    <Flex alignItems={'center'} gap={2}>
      {dismissing ? <Spinner size={'16px'} /> : <DismissedDocumentIcon size={'20px'} />}
      <Text variant={'body2'}>{'Dismiss'}</Text>
    </Flex>
  </MenuItem>
);
