import React, { useContext, useMemo } from 'react';
import type { FC } from 'react';
import { BaseGenericList, Indication } from '@lama/app-components';
import { useUpdateBusiness } from '../../hooks/react-query/useUpdateBusiness';
import { useUpdatePerson } from '../../hooks/react-query/useUpdatePerson';
import { useGetCurrentRequirement } from '../../hooks/useGetCurrentRequirement';
import { useUpdateApplication } from '../../hooks/react-query/useUpdateApplication';
import { ApplicationContext } from '../../shared/contexts/ApplicationContext';
import type { ScreenProps } from '../ScreenProps';
import { BasicScreen } from '../shared/BasicScreen';
import { ItemDocuments } from './ItemDocuments';

export const GenericListScreen: FC<ScreenProps> = ({ flow, ...props }) => {
  const { application, product } = useContext(ApplicationContext);
  const requirement = useGetCurrentRequirement();

  const { mutateAsync: updateApplication, isPending: updatingApplication } = useUpdateApplication(application.id);
  const { mutateAsync: updateBusiness, isPending: updatingBusiness } = useUpdateBusiness(application.id);
  const { mutateAsync: updatePerson, isPending: updatingPerson } = useUpdatePerson(application.id);

  const loading = useMemo(
    () => updatingApplication || updatingBusiness || updatingPerson,
    [updatingApplication, updatingBusiness, updatingPerson],
  );

  if (!requirement) {
    return null;
  }

  return (
    <BasicScreen {...props} nextVisible={flow === 'onboarding'} flow={flow} title={requirement.name} subtitle={requirement.description}>
      {flow === 'onboarding' ? null : <Indication screenName={requirement.screen} application={application} />}
      <BaseGenericList
        requirement={requirement}
        application={application}
        product={product}
        updatePerson={updatePerson}
        updateBusiness={updateBusiness}
        updateApplication={updateApplication}
        loading={loading}
        ItemDocumentsComponent={ItemDocuments}
      />
    </BasicScreen>
  );
};
