import type { FC } from 'react';
import React from 'react';
import { Fab } from '@mui/material';
import { Tooltip } from '@lama/app-components';
import { useRecoilValue } from 'recoil';
import { partnerState } from '../../../state/appState';
import { Headset } from './assets/Headset';

const subject = 'I have an issue with my application';

export const TechnicalSupportFab: FC = () => {
  const partner = useRecoilValue(partnerState);

  if (!partner?.branding.communication.supportEmail) {
    return null;
  }

  return (
    <Tooltip title={'Technical Support'}>
      <Fab
        href={`mailto:${partner.branding.communication.supportEmail}?subject=${subject}`}
        size={'medium'}
        sx={{ position: 'fixed', bottom: '24px', right: '24px', backgroundColor: 'white' }}
      >
        <Headset />
      </Fab>
    </Tooltip>
  );
};
