import React from 'react';
import type { TextFieldProps } from '@mui/material';
import { TextField, InputAdornment } from '@mui/material';
import { Public } from '@mui/icons-material';

export const DisabledCountryInput: React.FC<TextFieldProps> = (props) => (
  <TextField
    value={'United States'}
    label={'Country'}
    disabled
    InputProps={{
      startAdornment: (
        <InputAdornment position={'start'}>
          <Public color={'primary'} />
        </InputAdornment>
      ),
    }}
    {...props}
  />
);
