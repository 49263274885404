import type { FC, ReactElement } from 'react';
import React, { Suspense } from 'react';
import { createBrowserRouter, createRoutesFromElements, Navigate, Outlet, Route, ScrollRestoration } from 'react-router-dom';
import { LoadingPage } from '@lama/app-components';
import { LoginScreen } from './screens/loginScreen/LoginScreen';
import { OnboardingScreen } from './screens/shared/OnboardingScreen';
import { ApplicationsScreen } from './screens/applications/ApplicationsScreen';
import { ProductsScreen } from './screens/products/ProductsScreen';
import { ManagementScreen } from './screens/managementScreen/ManagementScreen';
import { useDemoAccess } from './hooks/useDemoAccess';
import { DemoLoginPage } from './screens/demoLogin/DemoLoginPage';
import { AuthCallbackScreen } from './screens/auth/AuthScreen';
import { PlaidAuthScreen } from './screens/plaidAuth/PlaidAuthScreen';
import { LenderLoginScreen } from './screens/lenderLoginScreen/LenderLoginScreen';
import { Auth0ProviderWithHistory } from './Auth0ProviderWithHistory';
import { UserDetailsProvider } from './shared/contexts/UserDetailsContext';
import { RedirectPage } from './RedirectPage';
import { AccountSettingsScreen } from './screens/accountSettingsScreen/AccountSettingsScreen';
import { useLaunchDarklyIdentify } from './hooks/useLaunchDarklyIdentify';
import { TechnicalSupportFab } from './screens/shared/TechnicalSupport/TechnicalSupportFab';

const LaunchDarklyIdentifier: FC<{ children: ReactElement }> = ({ children }) => {
  useLaunchDarklyIdentify();

  return children;
};

const LayoutWithProviders: FC = () => {
  const [access, setAccess] = useDemoAccess();

  if (!access && process.env.NODE_ENV === 'production') {
    return <DemoLoginPage setAccess={setAccess} />;
  }

  return (
    <>
      <Auth0ProviderWithHistory>
        <UserDetailsProvider>
          <LaunchDarklyIdentifier>
            <>
              <Suspense fallback={<LoadingPage />}>
                <Outlet />
              </Suspense>
              <TechnicalSupportFab />
            </>
          </LaunchDarklyIdentifier>
        </UserDetailsProvider>
      </Auth0ProviderWithHistory>
      <ScrollRestoration />
    </>
  );
};

export const routes = createRoutesFromElements([
  <Route path={'/'} element={<LayoutWithProviders />} key={'layout'}>
    <Route path={'products'}>
      <Route path={':productName'}>
        <Route path={'onboarding'}>
          <Route path={':step'} element={<OnboardingScreen />} />
          <Route index element={<Navigate to={{ pathname: 'get-started', search: window.location.search }} replace />} />
        </Route>
        <Route index element={<Navigate to={{ pathname: 'onboarding/get-started', search: window.location.search }} replace />} />
      </Route>
      <Route index element={<ProductsScreen />} />
    </Route>
    <Route path={'applications'}>
      <Route path={':applicationId'}>
        <Route path={':step'} element={<ManagementScreen />}>
          <Route path={':category'} element={<ManagementScreen />} />
        </Route>
        <Route index element={<Navigate to={'overview'} />} />
      </Route>
      <Route index element={<ApplicationsScreen />} />
    </Route>
    <Route path={'/accountSettings'} element={<AccountSettingsScreen />} />
    <Route path={'/login'} element={<LoginScreen />} />
    <Route path={'/auth'} element={<AuthCallbackScreen />} />
    <Route path={'/lender-login'} element={<LenderLoginScreen />} />
    <Route path={'/plaid-auth'} element={<PlaidAuthScreen />} />
    <Route path={'*'} element={<RedirectPage />} />
    <Route index element={<RedirectPage />} />
  </Route>,
]);

export const router = createBrowserRouter(routes);
