/* eslint-disable @typescript-eslint/naming-convention */
import type { JwtPayload } from 'jwt-decode';
import decode from 'jwt-decode';
import { useRecoilState, useRecoilValue } from 'recoil';
import { userServiceClient } from '../../shared/clients/userServiceClient';
import { partnerState } from '../../state/appState';
import type { UserCreationDetails } from '../../state/userState';
import { accessTokenState, userCreationDetailsState } from '../../state/userState';
import { useFetchAccessTokenFromUrl } from './useFetchAccessTokenFromUrl';
import { useFetchAuth0AccessToken } from './useFetchAuth0AccessToken';

const isExpired = (tokenPayload: JwtPayload) => {
  const currentDate = new Date();

  return !tokenPayload.exp || tokenPayload.exp * 1000 < currentDate.getTime();
};

type AuthenticationHookResult = { registerUser: RegisterUserFn } & (
  | {
      isAuthenticated: false;
      isPending: boolean;
      token: null;
      targetUrl: null;
      setAccessToken: (token: string) => void;
      creationDetails: UserCreationDetails | null;
    }
  | {
      isPending: boolean;
      isAuthenticated: true;
      token: string;
      targetUrl?: string;
      setAccessToken: (token: string) => void;
      creationDetails: null;
    }
);

type RegisterUserFn = ({
  id,
  email,
  firstName,
  lastName,
  phoneNumber,
  referrerId,
}: {
  id: string;
  email: string;
  firstName: string;
  middleName?: string;
  lastName: string;
  phoneNumber?: string;
  referrerId?: string;
}) => Promise<{ token: string }>;

export const useAuthentication = (): AuthenticationHookResult => {
  const [accessToken, setAccessToken] = useRecoilState(accessTokenState);
  const [creationDetails, setCreationDetails] = useRecoilState(userCreationDetailsState);

  const partner = useRecoilValue(partnerState);

  const { isFetchingAccessToken } = useFetchAuth0AccessToken(setAccessToken);
  const { targetUrl } = useFetchAccessTokenFromUrl(setAccessToken);

  const registerUser: RegisterUserFn = async ({
    id,
    email,
    firstName,
    middleName,
    lastName,
    phoneNumber,
    referrerId,
  }: {
    id: string;
    email: string;
    firstName: string;
    middleName?: string;
    lastName: string;
    phoneNumber?: string;
    referrerId?: string;
  }) => {
    if (!partner) {
      throw new Error('Partner not set');
    }

    const { tokenData } = await userServiceClient.registerApplicant({
      id,
      email,
      firstName,
      middleName,
      lastName,
      partner: partner.id,
      phoneNumber,
      referrerId,
    });

    setAccessToken(tokenData?.token ?? '');
    setCreationDetails({ id, email, firstName, lastName, partner: partner.id });

    return { token: tokenData?.token ?? '' };
  };

  if (!accessToken) {
    return {
      isAuthenticated: false,
      isPending: isFetchingAccessToken,
      registerUser,
      setAccessToken,
      token: null,
      targetUrl: null,
      creationDetails,
    };
  }

  const decodedToken = decode<JwtPayload>(accessToken);

  if (isExpired(decodedToken)) {
    return {
      isAuthenticated: false,
      isPending: false,
      registerUser,
      setAccessToken,
      token: null,
      targetUrl: null,
      creationDetails: null,
    };
  }

  return {
    isAuthenticated: true,
    isPending: false,
    registerUser,
    token: accessToken,
    setAccessToken,
    targetUrl,
    creationDetails: null,
  };
};
