import { useTheme } from '@mui/material';
import type { FC } from 'react';
import React from 'react';

export const TreasuryIcon: FC<{ color?: string }> = ({ color }) => {
  const theme = useTheme();
  return (
    <svg width={'20'} height={'20'} viewBox={'0 0 12 12'} fill={'none'} xmlns={'http://www.w3.org/2000/svg'}>
      <mask id={'mask0_23825_37311'} style={{ maskType: 'alpha' }} maskUnits={'userSpaceOnUse'} x={'0'} y={'0'} width={'20'} height={'20'}>
        <rect width={'20'} height={'20'} fill={'#D9D9D9'} />
      </mask>
      <g mask={'url(#mask0_23825_37311)'}>
        <path
          d={
            'M2.39374 10.375C2.29054 10.375 2.20432 10.3391 2.13509 10.2673C2.06586 10.1955 2.03125 10.1064 2.03125 10C2.03125 9.8936 2.06715 9.8045 2.13894 9.73271C2.21073 9.66092 2.29983 9.62503 2.40624 9.62503H7.41874C7.52194 9.62503 7.60815 9.66092 7.67739 9.73271C7.74661 9.8045 7.78123 9.8936 7.78123 10C7.78123 10.1064 7.74533 10.1955 7.67354 10.2673C7.60175 10.3391 7.51265 10.375 7.40624 10.375H2.39374ZM4.2928 7.15576L2.91395 5.77693C2.74152 5.60449 2.65322 5.39231 2.64905 5.14039C2.64488 4.88847 2.72902 4.67629 2.90145 4.50385L3.20626 4.19425L5.88316 6.84615L5.57356 7.15576C5.40112 7.3282 5.18766 7.41441 4.93318 7.41441C4.67869 7.41441 4.46523 7.3282 4.2928 7.15576ZM7.84854 4.88078L5.19664 2.20388L5.50624 1.89906C5.68188 1.72663 5.89486 1.6425 6.14518 1.64666C6.3955 1.65083 6.60688 1.73913 6.77931 1.91156L8.15815 3.29041C8.33058 3.46285 8.4168 3.6763 8.4168 3.93079C8.4168 4.18527 8.33058 4.39873 8.15815 4.57117L7.84854 4.88078ZM10.039 9.56346L3.95049 3.47501L4.4774 2.9481L10.5784 9.04906C10.6476 9.11829 10.6801 9.20402 10.676 9.30626C10.6718 9.4085 10.6351 9.49424 10.5659 9.56346C10.4934 9.6359 10.4056 9.67213 10.3024 9.67213C10.1992 9.67213 10.1114 9.6359 10.039 9.56346Z'
          }
          fill={color ?? theme.palette.primary.main}
        />
      </g>
    </svg>
  );
};
