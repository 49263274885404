import type { BoxProps } from '@mui/material';
import { Box } from '@mui/material';
import type { FC } from 'react';
import React from 'react';
import { CloseRounded } from '@mui/icons-material';

export const ChipDelete: FC<BoxProps> = (props) => (
  <Box {...props} pt={0.5}>
    <CloseRounded fontSize={'small'} />
  </Box>
);
