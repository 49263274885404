/* eslint-disable @typescript-eslint/naming-convention */
import * as React from 'react';
import { useMergeRefs } from '@floating-ui/react';
import { useCallback, isValidElement } from 'react';
import type { HTMLProps, ReactNode } from 'react';
import { usePopoverContext } from './PopoverContext';

interface PopoverTriggerProps {
  children: React.ReactNode;
}

export const PopoverTrigger = React.forwardRef<HTMLElement, PopoverTriggerProps & React.HTMLProps<HTMLElement>>(
  ({ children, ...props }, propRef) => {
    const context = usePopoverContext();
    const childrenRef = (children as any).ref as React.Ref<HTMLElement> | undefined;
    const ref = useMergeRefs([context.refs.setReference, propRef, childrenRef]);

    const isReactElementWithRef = useCallback((node: ReactNode): node is React.ReactElement => isValidElement(node) && 'ref' in node, []);

    if (isReactElementWithRef(children)) {
      const referencePropsParams: HTMLProps<Element> = {
        ref,
        ...props,
        ...children.props,
        'data-state': context.open ? 'open' : 'closed',
      };

      return React.cloneElement(children, context.getReferenceProps(referencePropsParams));
    }

    console.warn('PopoverTrigger expects a single child of type ReactElement, but found none or multiple children.');
    return null;
  },
);
