import React from 'react';
import { useTheme } from '@mui/material';

export const UploadIdCardIcon: React.FC = () => {
  const theme = useTheme();

  return (
    <svg width={'121'} height={'105'} viewBox={'0 0 121 105'} fill={'none'} xmlns={'http://www.w3.org/2000/svg'}>
      <path d={'M118.791 0.971898L107.613 13.4127L91.8581 8.8324L118.791 0.971898Z'} fill={'white'} stroke={theme.palette.primary.light} />
      <path
        d={
          'M97.0674 10.9576L106.039 7.29155L99.2893 11.1503L98.2658 11.7354L99.3978 12.0645L102.301 12.9086L99.0837 16.2312L97.0674 10.9576Z'
        }
        fill={'#E2F2EC'}
        stroke={theme.palette.primary.light}
      />
      <mask id={'path-3-inside-1_9474_109700'} fill={'white'}>
        <path
          d={
            'M99.3129 15.2857C99.2999 15.2857 99.2891 15.2857 99.2761 15.2836C99.1116 15.2641 98.9926 15.1126 99.0143 14.9481L99.3648 12.0541C99.3843 11.8896 99.5379 11.7684 99.7002 11.7922C99.8647 11.8117 99.9837 11.9632 99.962 12.1277L99.6115 15.0217C99.5942 15.1754 99.4622 15.2879 99.3129 15.2879V15.2857Z'
          }
        />
      </mask>
      <path
        d={
          'M99.3129 15.2857C99.2999 15.2857 99.2891 15.2857 99.2761 15.2836C99.1116 15.2641 98.9926 15.1126 99.0143 14.9481L99.3648 12.0541C99.3843 11.8896 99.5379 11.7684 99.7002 11.7922C99.8647 11.8117 99.9837 11.9632 99.962 12.1277L99.6115 15.0217C99.5942 15.1754 99.4622 15.2879 99.3129 15.2879V15.2857Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M99.3129 15.2857H100.313V14.2857H99.3129V15.2857ZM99.2761 15.2836L99.4405 14.2972L99.4172 14.2933L99.3937 14.2905L99.2761 15.2836ZM99.0143 14.9481L100.006 15.0785L100.007 15.0683L99.0143 14.9481ZM99.3648 12.0541L100.358 12.1744L100.358 12.1717L99.3648 12.0541ZM99.7002 11.7922L99.555 12.7816L99.5688 12.7837L99.5826 12.7853L99.7002 11.7922ZM99.962 12.1277L98.9705 11.9973L98.9693 12.0075L99.962 12.1277ZM99.6115 15.0217L98.6187 14.9014L98.6178 14.9097L99.6115 15.0217ZM99.3129 15.2879H98.3129V16.2879H99.3129V15.2879ZM99.3129 14.2857C99.294 14.2857 99.3611 14.284 99.4405 14.2972L99.1116 16.27C99.217 16.2875 99.3058 16.2857 99.3129 16.2857V14.2857ZM99.3937 14.2905C99.7778 14.336 100.057 14.6855 100.006 15.0785L98.0228 14.8177C97.9278 15.5396 98.4455 16.1922 99.1585 16.2766L99.3937 14.2905ZM100.007 15.0683L100.358 12.1744L98.3721 11.9339L98.0215 14.8278L100.007 15.0683ZM100.358 12.1717C100.314 12.5457 99.9705 12.8426 99.555 12.7816L99.8454 10.8028C99.1053 10.6943 98.455 11.2336 98.3717 11.9366L100.358 12.1717ZM99.5826 12.7853C99.1985 12.7398 98.9189 12.3903 98.9706 11.9973L100.953 12.2582C101.048 11.5362 100.531 10.8836 99.8178 10.7992L99.5826 12.7853ZM98.9693 12.0075L98.6187 14.9014L100.604 15.1419L100.955 12.248L98.9693 12.0075ZM98.6178 14.9097C98.659 14.5438 98.9673 14.2879 99.3129 14.2879V16.2879C99.957 16.2879 100.529 15.807 100.605 15.1336L98.6178 14.9097ZM100.313 15.2879V15.2857H98.3129V15.2879H100.313Z'
        }
        fill={theme.palette.primary.light}
        mask={'url(#path-3-inside-1_9474_109700)'}
      />
      <path
        d={
          'M14.3239 31.252C14.233 31.252 14.1443 31.2152 14.0815 31.1417C13.9625 31.0075 13.9755 30.802 14.1096 30.6829L14.9081 29.9754C15.0423 29.8563 15.2478 29.8693 15.3668 30.0035C15.4858 30.1376 15.4729 30.3432 15.3387 30.4622L14.5402 31.1698C14.4775 31.2239 14.4018 31.252 14.326 31.252H14.3239Z'
        }
        fill={'black'}
      />
      <path
        d={
          'M17.3395 28.5789C17.2508 28.5789 17.1599 28.5422 17.0971 28.4686C16.9781 28.3344 16.9911 28.1289 17.1253 28.0098L17.9259 27.3023C18.06 27.1833 18.2656 27.1962 18.3846 27.3304C18.5036 27.4646 18.4906 27.6701 18.3565 27.7891L17.5559 28.4967C17.4931 28.5508 17.4174 28.5789 17.3416 28.5789H17.3395Z'
        }
        fill={'black'}
      />
      <path
        d={
          'M17.9377 31.4708C17.849 31.4708 17.7581 31.434 17.6953 31.3604L16.9878 30.5598C16.8687 30.4256 16.8817 30.22 17.0159 30.101C17.15 29.982 17.3556 29.995 17.4746 30.1292L18.1822 30.9298C18.3012 31.0639 18.2882 31.2695 18.1541 31.3885C18.0913 31.4426 18.0156 31.4708 17.9398 31.4708H17.9377Z'
        }
        fill={'black'}
      />
      <path
        d={
          'M15.258 28.4512C15.1671 28.4512 15.0784 28.4144 15.0156 28.3409L14.3081 27.5402C14.189 27.4061 14.202 27.2005 14.3362 27.0815C14.4704 26.9625 14.6759 26.9755 14.7949 27.1096L15.5025 27.9103C15.6215 28.0444 15.6085 28.25 15.4744 28.369C15.4116 28.4231 15.3359 28.4512 15.2602 28.4512H15.258Z'
        }
        fill={'black'}
      />
      <path
        d={
          'M1.7134 34.4248C1.67777 34.4248 1.64437 34.4248 1.60873 34.4226C1.15218 34.3959 0.733494 34.1911 0.430614 33.8482C0.127733 33.5054 -0.0237068 33.0646 0.00301791 32.6082C0.0297427 32.1518 0.234633 31.7332 0.577601 31.4305C0.920569 31.1277 1.35707 30.9763 1.81808 31.003C2.76012 31.0609 3.48169 31.8735 3.42379 32.8174C3.36811 33.7258 2.61091 34.427 1.7134 34.427V34.4248ZM1.71118 31.6665C1.45506 31.6665 1.21232 31.7577 1.02079 31.9292C0.811444 32.1139 0.686728 32.37 0.668911 32.6482C0.633278 33.2226 1.07424 33.7213 1.64882 33.7547C2.2234 33.7903 2.72004 33.3495 2.75567 32.7751C2.7913 32.2008 2.35034 31.7043 1.77576 31.6687C1.75349 31.6687 1.73122 31.6687 1.71118 31.6687V31.6665Z'
        }
        fill={'black'}
      />
      <path
        d={
          'M82.2298 49.0232C84.8964 34.3565 83.4298 4.82315 56.2298 4.02315C29.0298 3.22315 31.2298 23.3565 35.7298 33.5231C42.3967 41.6898 63.7305 50.7232 95.7305 21.5232'
        }
        stroke={'black'}
        strokeWidth={'0.5'}
        strokeDasharray={'3 3'}
      />
      <path
        d={
          'M87.7456 104.409L6.8412 99.4621C5.44768 99.3774 4.32031 98.732 4.32031 98.0207V50.2991C4.32031 49.5888 5.44984 49.0815 6.8412 49.1663L87.7456 54.1129C89.1391 54.1976 90.2665 54.843 90.2665 55.5543V103.277C90.2665 103.987 89.1369 104.495 87.7456 104.41V104.409Z'
        }
        fill={'#FDFFFE'}
      />
      <path
        d={
          'M88.055 104.575C87.9403 104.575 87.8256 104.572 87.7087 104.565L6.80435 99.618C5.2572 99.5239 4 98.8074 4 98.0218V50.2991C4 49.8985 4.31592 49.5428 4.89151 49.2981C5.43247 49.0669 6.14222 48.9654 6.88875 49.0104L87.7931 53.9571C89.3403 54.0512 90.5975 54.7677 90.5975 55.5533V103.276C90.5975 103.677 90.2816 104.032 89.706 104.277C89.2494 104.472 88.6717 104.575 88.055 104.575ZM87.791 104.253C88.3493 104.287 88.8772 104.212 89.2775 104.042C89.7103 103.858 89.9483 103.586 89.9483 103.276V55.5543C89.9483 54.9215 88.9443 54.3441 87.7109 54.2688L6.80652 49.3221C6.24825 49.2876 5.72027 49.3629 5.31995 49.5334C4.88718 49.7175 4.64916 49.9895 4.64916 50.2991V98.0218C4.64916 98.6547 5.65318 99.2321 6.88658 99.3074L87.791 104.254V104.253Z'
        }
        fill={'black'}
      />
      <path
        d={
          'M33.8184 84.4969C38.7039 80.3436 38.865 72.5048 34.1782 66.9884C29.4914 61.4719 21.7315 60.3669 16.846 64.5202C11.9605 68.6734 11.7994 76.5122 16.4862 82.0287C21.173 87.5451 28.9329 88.6501 33.8184 84.4969Z'
        }
        fill={theme.palette.primary.main}
      />
      <path
        d={
          'M28.0793 76.266C29.7849 74.816 29.8413 72.0796 28.2053 70.1539C26.5692 68.2283 23.8603 67.8427 22.1547 69.2926C20.4491 70.7426 20.3927 73.4791 22.0287 75.4047C23.6648 77.3304 26.3737 77.716 28.0793 76.266Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M16.3516 81.8769C17.8165 80.4223 19.593 78.9981 21.4864 78.1453C21.943 77.9396 22.4861 77.9461 22.9881 78.1669C24.9139 79.0132 26.4135 78.918 28.0797 78.4937C28.4843 78.392 28.9322 78.4634 29.3195 78.7059C31.4012 80.0046 33.2015 81.6712 34.5734 83.7838C29.1638 89.2232 20.4975 87.2924 16.3559 81.8747L16.3516 81.8769Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M25.3344 87.4505C18.2889 86.5955 12.5547 80.0955 12.5547 72.9591C12.5547 65.8228 18.2867 60.7124 25.3344 61.5673C32.3799 62.4223 38.1141 68.9223 38.1141 76.0587C38.1141 83.1951 32.3821 88.3055 25.3344 87.4505ZM25.3344 62.6215C18.8623 61.8357 13.5977 66.5306 13.5977 73.0847C13.5977 79.6388 18.8623 85.6106 25.3344 86.3964C31.8065 87.1821 37.0712 82.4873 37.0712 75.9332C37.0712 69.379 31.8065 63.4072 25.3344 62.6215Z'
        }
        fill={'#FDFFFE'}
      />
      <rect
        x={'43.4023'}
        y={'66'}
        width={'40.3145'}
        height={'6'}
        rx={'0.5'}
        transform={'rotate(3.85233 43.4023 66)'}
        fill={theme.palette.primary.light}
      />
      <rect
        x={'43.4023'}
        y={'75'}
        width={'40.3145'}
        height={'3.4059'}
        rx={'0.5'}
        transform={'rotate(3.85233 43.4023 75)'}
        fill={theme.palette.primary.light}
      />
      <rect
        x={'43.125'}
        y={'82.5694'}
        width={'40.3145'}
        height={'1.83294'}
        rx={'0.5'}
        transform={'rotate(3.85233 43.125 82.5694)'}
        fill={theme.palette.primary.light}
      />
    </svg>
  );
};
