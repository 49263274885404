/* eslint-disable @typescript-eslint/naming-convention */
import type { FC, ReactNode } from 'react';
import React, { useCallback, useMemo, useState, useContext } from 'react';
import { Grid, TextField, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { sentenceCase } from 'change-case';
import { v4 as uuidv4 } from 'uuid';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Flex } from '@lama/design-system';
import type { CreateBusinessModelApi } from '@lama/business-service-client';
import { LoadingWrapper } from '@lama/app-components';
import { useAsyncFn } from 'react-use';
import { applicationBorrowingBusinessSelector } from '@lama/data-formatters';
import type { ScreenProps } from '../ScreenProps';
import { BasicScreen } from '../shared/BasicScreen';
import { ApplicationContext } from '../../shared/contexts/ApplicationContext';
import { useUpdateBusiness } from '../../hooks/react-query/useUpdateBusiness';
import { useAddApplicationRelation } from '../../hooks/react-query/useAddApplicationRelation';
import { useCreateBusinessMutation } from '../../hooks/react-query/useCreateBusinessMutation';
import { BusinessCard, EmptyCard } from './BusinessCard';
import { usePlacesApi } from './usePlacesApi';

const blacklistedPlaceTypes = new Set(['point_of_interest', 'establishment']);
const DEFAULT_CARD_ID = 'default_empty_card';

const GridItem: FC<{ children: ReactNode; isMobile: boolean }> = ({ children, isMobile }) => (
  <Grid item xs={12} md={6} paddingX={isMobile ? 0 : 2} paddingY={isMobile ? 1 : 2}>
    {children}
  </Grid>
);

export const SearchBusinessScreen: React.FC<ScreenProps> = ({ onNextClick = () => {}, onBackClick, ...stepsNavigationProps }) => {
  const [inputValue, setInputValue] = useState('');
  const { application } = useContext(ApplicationContext);
  const business = useMemo(() => applicationBorrowingBusinessSelector(application), [application]);

  const { placesResults, searchPlaces, didInitialSearch, clearResults } = usePlacesApi();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { t } = useTranslation();
  const showEmptyCard = useMemo(() => didInitialSearch && inputValue !== '', [didInitialSearch, inputValue]);
  const { useGooglePlaces } = useFlags();
  const { mutateAsync: createBusiness } = useCreateBusinessMutation();
  const { mutateAsync: updateBusiness } = useUpdateBusiness(application.id);
  const { mutateAsync: addApplicationRelation } = useAddApplicationRelation();

  const onInputChange = useCallback(
    ({ target: { value } }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setInputValue(value);
      if (navigator.userAgent.includes('DatadogSynthetics') || !useGooglePlaces) {
        return;
      }

      if (value !== '') {
        searchPlaces(value);
      } else {
        clearResults();
      }
    },
    [useGooglePlaces, searchPlaces, clearResults],
  );

  const upsertBusiness = useCallback(
    async (partialBusiness: Partial<CreateBusinessModelApi>) => {
      if (business) {
        await updateBusiness({ businessId: business.id, updateBusinessPayload: partialBusiness });
      } else {
        const businessId = uuidv4();
        await createBusiness({
          business: {
            id: businessId,
            partnerId: application.originatingPartner,
            ...partialBusiness,
            people: [],
            applicationId: application.id,
          },
          applicationId: application.id,
        });
        await addApplicationRelation({
          applicationId: application.id,
          relation: 'borrower',
          entityId: businessId,
          entityType: 'business',
        });
      }
    },
    [addApplicationRelation, application.id, application.originatingPartner, business, createBusiness, updateBusiness],
  );

  const [{ loading: cardClickLoading }, onCardClick] = useAsyncFn(
    async (selectedPlaceId: string) => {
      const selectedPlaceDetails = placesResults.find(({ place_id }) => place_id === selectedPlaceId);
      let partialBusiness: Partial<CreateBusinessModelApi> = {};
      if (!selectedPlaceDetails || selectedPlaceId === DEFAULT_CARD_ID) {
        partialBusiness = {
          legalName: inputValue,
        };
      } else {
        const address1 =
          selectedPlaceDetails.addressPartsByType.street_number?.shortName && selectedPlaceDetails.addressPartsByType.route?.shortName
            ? `${selectedPlaceDetails.addressPartsByType.street_number?.shortName} ${selectedPlaceDetails.addressPartsByType.route?.shortName}`
            : '';

        partialBusiness = {
          legalName: selectedPlaceDetails.name,
          addresses: [
            {
              city: selectedPlaceDetails?.addressPartsByType.locality?.shortName ?? '',
              state: selectedPlaceDetails.addressPartsByType.administrative_area_level_1?.longName ?? '',
              zip: selectedPlaceDetails.addressPartsByType.postal_code?.shortName ?? '',
              address1,
              country: 'US',
            },
          ],
          phoneNumber: selectedPlaceDetails.formatted_phone_number ?? '',
          website: selectedPlaceDetails.website?.split(/[#?]/)[0] ?? '',
          industryTypes: selectedPlaceDetails.types.filter((type) => !blacklistedPlaceTypes.has(type)),
          googlePlaceId: selectedPlaceDetails.place_id,
        };
      }

      await upsertBusiness(partialBusiness);

      onNextClick({ useOfFunds: application.useOfFunds, businessUnformed: business?.unformed });
    },
    [application.useOfFunds, business?.unformed, inputValue, onNextClick, placesResults, upsertBusiness],
  );

  const [{ loading: skipLoading }, onSkip] = useAsyncFn(async () => {
    await upsertBusiness({ legalName: inputValue });
    onNextClick({ useOfFunds: application.useOfFunds });
  }, [application.useOfFunds, inputValue, onNextClick, upsertBusiness]);

  const backClick = useCallback(() => {
    onBackClick?.({ useOfFunds: application.useOfFunds });
  }, [onBackClick, application.useOfFunds]);

  return (
    <BasicScreen
      {...stepsNavigationProps}
      title={t('searchBusiness.title')}
      subtitle={t('searchBusiness.subtitle')}
      onNextClick={onSkip}
      onBackClick={backClick}
      nextButtonText={'Skip'}
      nextLoading={skipLoading}
      nextEnabled={!cardClickLoading && !skipLoading}
    >
      <LoadingWrapper loading={cardClickLoading} disabled={skipLoading || cardClickLoading}>
        <Flex flexDirection={'column'} gap={4} alignItems={'center'}>
          <TextField
            type={'text'}
            variant={'outlined'}
            label={t('searchBusiness.input')}
            value={inputValue}
            onChange={onInputChange}
            sx={{ width: isMobile ? '100%' : '90%' }}
          />
          <Grid container>
            {placesResults.map(({ place_id, name, types, imageUrl, shortAddress }) => (
              <GridItem key={place_id} isMobile={isMobile}>
                <BusinessCard
                  key={place_id}
                  place_id={place_id}
                  name={name}
                  address={shortAddress}
                  tags={types
                    .filter((type) => !blacklistedPlaceTypes.has(type))
                    .slice(0, 1)
                    .map((type) => sentenceCase(type))}
                  image={imageUrl}
                  onClick={onCardClick}
                />
              </GridItem>
            ))}
            {showEmptyCard ? (
              <GridItem isMobile={isMobile}>
                <EmptyCard id={DEFAULT_CARD_ID} searchTerm={inputValue} onClick={onCardClick} />
              </GridItem>
            ) : null}
          </Grid>
        </Flex>
      </LoadingWrapper>
    </BasicScreen>
  );
};
