import React from 'react';

export const PaperClipIcon = ({ size = '24', color }: { size?: string; color: string }) => (
  <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill={'none'} xmlns={'http://www.w3.org/2000/svg'}>
    <g clipPath={'url(#clip0_36882_85)'}>
      <path
        d={
          'M21.44 11.5499L12.25 20.7399C11.1242 21.8658 9.59723 22.4983 8.00505 22.4983C6.41286 22.4983 4.88589 21.8658 3.76005 20.7399C2.6342 19.6141 2.00171 18.0871 2.00171 16.4949C2.00171 14.9027 2.6342 13.3758 3.76005 12.2499L12.95 3.05992C13.7006 2.30936 14.7186 1.8877 15.78 1.8877C16.8415 1.8877 17.8595 2.30936 18.61 3.05992C19.3606 3.81048 19.7823 4.82846 19.7823 5.88992C19.7823 6.95138 19.3606 7.96936 18.61 8.71992L9.41005 17.9099C9.03476 18.2852 8.52577 18.496 7.99505 18.496C7.46432 18.496 6.95533 18.2852 6.58005 17.9099C6.20476 17.5346 5.99393 17.0256 5.99393 16.4949C5.99393 15.9642 6.20476 15.4552 6.58005 15.0799L15.07 6.59992'
        }
        stroke={color}
        strokeWidth={'2'}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
      />
    </g>
    <defs>
      <clipPath id={'clip0_36882_85'}>
        <rect width={size} height={size} fill={'white'} transform={'translate(0 0.5)'} />
      </clipPath>
    </defs>
  </svg>
);
