import type { FC, ReactElement, SVGAttributes } from 'react';
import React, { useMemo, useContext } from 'react';
import { ThemeContext } from 'styled-components';
import type { ButtonVariant, ButtonSize, ButtonColors } from './Button.styles';
import { iconSize } from './Button.styles';

export interface ButtonIconProps {
  icon: FC<SVGAttributes<SVGElement>> | ReactElement;
  variant: ButtonVariant;
  size: ButtonSize;
  disabled?: boolean;
  color?: string;
  buttonColor: ButtonColors;
}

export const ButtonIcon: FC<ButtonIconProps> = ({ icon: Icon, variant, size, color, disabled, buttonColor }) => {
  const { button: buttonTheme } = useContext(ThemeContext);

  const iconColor = useMemo(() => {
    if (disabled) {
      return buttonTheme.variants[variant]?.disabled.iconColor;
    }
    return color ?? buttonTheme.variants[variant]?.colors[buttonColor]?.iconColor;
  }, [disabled, variant, buttonTheme.variants, buttonColor, color]);

  return typeof Icon === 'function' ? (
    <Icon color={iconColor} width={`${iconSize[size]}px`} height={`${iconSize[size]}px`} className={'buttonIcon'} />
  ) : (
    React.cloneElement(Icon, {
      sx: {
        color: iconColor,
        width: `${iconSize[size]}px`,
        height: `${iconSize[size]}px`,
      },
      color: iconColor,
      width: `${iconSize[size]}px`,
      height: `${iconSize[size]}px`,
    })
  );
};
