import React from 'react';
import { Box } from '@mui/material';
import type { ReactNode } from 'react';

export interface IconBorderBoxProps {
  color?: string;
  children: ReactNode;
}

const defaultColor = '#9E9E9E';

export const IconBorderBox: React.FC<IconBorderBoxProps> = ({ color, children }) => (
  <Box sx={{ width: 24, height: 24, borderWidth: '1px', borderColor: color ?? defaultColor, borderRadius: '50%', borderStyle: 'solid' }}>
    {children}
  </Box>
);
