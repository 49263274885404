import React, { useCallback } from 'react';
import { states } from '@lama/contracts';
import { useField } from 'formik';
import { FormikPicker } from './FormikPicker/FormikPicker.js';
import type { FormikInputProps } from './PropertyFormikInput.js';

type FormikStatePickerProps = FormikInputProps & {
  countyFieldName: string;
};

export const FormikStatePicker: React.FC<FormikStatePickerProps> = ({ countyFieldName, ...props }) => {
  const [, , { setValue }] = useField(countyFieldName);

  const onChangeCallback = useCallback(async () => {
    await setValue('');
  }, [setValue]);

  return (
    <FormikPicker values={states.map(({ name: stateName }) => stateName)} label={'State'} onChangeCallback={onChangeCallback} {...props} />
  );
};
