import type React from 'react';
import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { usePlaid } from '../../hooks/usePlaid';

export const PlaidAuthScreen: React.FC = () => {
  const navigate = useNavigate();
  const originPath = window.sessionStorage.getItem('originPath') ?? '/';

  const onPlaidConnected = useCallback(() => {
    navigate(originPath);
  }, [navigate, originPath]);

  const { open, ready } = usePlaid(onPlaidConnected);

  useEffect(() => {
    if (ready) {
      open();
    }
  }, [open, ready]);

  return null;
};
