import { Stack } from '@mui/material';
import type { FC } from 'react';
import React from 'react';
import { NoDebtImage } from './NoDebtImage';

export const DebtEmptyState: FC = () => (
  <Stack direction={'column'} alignItems={'center'} gap={2}>
    <NoDebtImage />
  </Stack>
);
