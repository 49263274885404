/* eslint-disable unicorn/no-keyword-prefix */
import React, { useCallback, useMemo } from 'react';
import { Divider, Grid } from '@mui/material';
import { Formik } from 'formik';
import * as yup from 'yup';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import {
  businessLegalNameValidation,
  businessTinValidation,
  ownershipPercentageValidation,
  zipCodeValidation,
} from '@lama/yup-validations';
import { Button, Flex } from '@lama/design-system';
import type { DialogMode } from '../BaseDialog';
import { PropertyFormikInput, FormikStatePicker } from '../FormikFields';
import { ModifyItemButton } from '../ModifyItemButton';
import { type BusinessWithOwnershipRequest } from './BasePrincipalsScreen';

export interface BusinessWithOwnership {
  businessValues: BusinessWithOwnershipRequest;
  ownershipPercentage: number;
}

export interface BusinessFormProps {
  handleClose: (values: BusinessWithOwnership | null) => void;
  business: Partial<BusinessWithOwnershipRequest> | null;
  isLoading?: boolean;
  dialogMode: DialogMode;
  showBackButton?: boolean;
  ownershipPercentage?: number;
  remainingOwnershipPercentage?: number;
  onBack?: () => void;
}

interface PrincipalBusinessFormValues {
  legalName: string;
  ownershipPercentage?: string;
  tin: string;
  address1?: string;
  city?: string;
  state?: string;
  zip?: string;
}

export const BusinessForm: React.FC<BusinessFormProps> = ({
  business,
  handleClose,
  isLoading,
  dialogMode,
  onBack,
  showBackButton = false,
  ownershipPercentage,
  remainingOwnershipPercentage,
}) => {
  const { t } = useTranslation();

  const validationSchema = yup.object({
    legalName: businessLegalNameValidation.required('Legal Name is required'),
    ownershipPercentage: ownershipPercentageValidation({
      maxValue: remainingOwnershipPercentage === undefined ? 100 : remainingOwnershipPercentage + (ownershipPercentage ?? 0),
      minValue: 1,
    }).required('% Ownership is required'),
    tin: businessTinValidation,
    zip: zipCodeValidation,
  });

  const onSubmitInternal = useCallback(
    (values: PrincipalBusinessFormValues) => {
      const newValues: BusinessWithOwnershipRequest = {
        id: business?.id ?? uuidv4(),
        legalName: values.legalName,
        tin: values.tin,
        addresses: [
          {
            address1: values.address1,
            city: values.city,
            state: values.state,
            zip: values.zip,
            country: 'US',
          },
        ],
      };

      handleClose({
        businessValues: newValues,
        ownershipPercentage: values.ownershipPercentage ? Number(values.ownershipPercentage) : 0,
      });
    },
    [business?.id, handleClose],
  );

  const initialValues: PrincipalBusinessFormValues = useMemo(
    () => ({
      legalName: business?.legalName ?? '',
      tin: business?.tin ?? '',
      ownershipPercentage: ownershipPercentage === 0 ? '' : ownershipPercentage?.toString() ?? '',
      ...(business?.addresses?.[0] ?? { state: '', country: '', city: '', zip: '', address1: '' }),
    }),
    [ownershipPercentage, business],
  );

  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues} onSubmit={onSubmitInternal}>
      {({ handleSubmit }) => (
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <PropertyFormikInput name={'legalName'} label={'Legal Name'} required fullWidth />
          </Grid>
          <Grid item md={6} xs={12}>
            <PropertyFormikInput name={'ownershipPercentage'} label={'% Ownership'} type={'number'} required fullWidth />
          </Grid>
          <Grid item md={6} xs={12}>
            <PropertyFormikInput name={'tin'} label={'Tax ID'} fullWidth />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <PropertyFormikInput name={'address1'} label={'Address Line'} fullWidth />
          </Grid>
          <Grid item xs={12} md={6}>
            <PropertyFormikInput name={'city'} label={'City'} fullWidth />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormikStatePicker name={'state'} countyFieldName={'county'} label={'State'} fullWidth />
          </Grid>
          <Grid item xs={12} md={6}>
            <PropertyFormikInput name={'zip'} label={'Zip Code'} fullWidth />
          </Grid>
          <Grid item xs={12} marginTop={2}>
            {showBackButton ? (
              <Flex marginTop={'auto'} fullWidth justifyContent={'space-between'}>
                <Button onClick={onBack} variant={'tertiary'}>
                  {'Back'}
                </Button>
                <ModifyItemButton
                  text={t(`principals.dialog.cta.${dialogMode}`)}
                  onClick={handleSubmit}
                  showIcon={false}
                  loading={isLoading}
                />
              </Flex>
            ) : (
              <Flex justifyContent={'center'}>
                <ModifyItemButton
                  onClick={handleSubmit}
                  text={t(`principals.dialog.cta.${dialogMode}`)}
                  showIcon={false}
                  loading={isLoading}
                />
              </Flex>
            )}
          </Grid>
        </Grid>
      )}
    </Formik>
  );
};
