import { useMergeRefs, FloatingPortal, FloatingFocusManager } from '@floating-ui/react';
import React from 'react';
import type { FlexProps } from '../Flex';
import { Flex } from '../Flex';
import { greyPalette } from '../../theme';
import { usePopoverContext } from './PopoverContext';

type PopoverContentProps = FlexProps & React.HTMLProps<HTMLDivElement>;

export const PopoverContent = React.forwardRef<HTMLDivElement, PopoverContentProps>(({ style, ...props }, propRef) => {
  const { context: floatingContext, ...context } = usePopoverContext();
  const ref = useMergeRefs([context.refs.setFloating, propRef]);

  if (!floatingContext.open) {
    return null;
  }

  return (
    <FloatingPortal>
      <FloatingFocusManager context={floatingContext} modal={context.modal}>
        <Flex
          bg={'white'}
          zIndex={1000}
          border={`1px solid ${greyPalette[400]}`}
          borderRadius={3}
          style={{ ...context.floatingStyles, ...style }}
          aria-labelledby={context.labelId}
          aria-describedby={context.descriptionId}
          {...props}
          ref={ref}
          {...context.getFloatingProps(props)}
        >
          {props.children}
        </Flex>
      </FloatingFocusManager>
    </FloatingPortal>
  );
});
