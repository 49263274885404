import { useContext, useMemo } from 'react';
import type { EvaluatedApplicationRequirement } from '@lama/contracts';
import type { ApplicationApiModel } from '@lama/clients';
import { ApplicationContext } from '../shared/contexts/ApplicationContext';
import { useQueryParams } from './useQueryParams';
import { useScreenNameFromUrl } from './useScreenNameFromUrl';

const getManagmentFlowRequirement = ({
  requirementId,
  requirements,
}: {
  requirementId: string;
  requirements: EvaluatedApplicationRequirement[];
}) => requirements.find((r) => r.id === requirementId);

const getOnboardingFlowRequirement = ({
  currentScreen,
  requirements,
  application,
}: {
  currentScreen: string;
  requirements: EvaluatedApplicationRequirement[];
  application: ApplicationApiModel;
}) => requirements.find((r) => r.screen === currentScreen && (r.entityType !== 'person' || r.entityId === application.initiator));

export const useGetCurrentRequirement = (): EvaluatedApplicationRequirement | undefined => {
  const { requirementId } = useQueryParams(['requirementId']);
  const currentScreen = useScreenNameFromUrl();
  const context = useContext(ApplicationContext);

  const currentRequirement = useMemo<EvaluatedApplicationRequirement | undefined>(() => {
    if (!context) {
      return;
    }

    const { application, requirements } = context;

    return requirementId
      ? getManagmentFlowRequirement({ requirementId, requirements })
      : getOnboardingFlowRequirement({
          currentScreen,
          requirements,
          application,
        });
  }, [context, currentScreen, requirementId]);

  return currentRequirement;
};
