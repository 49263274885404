import type { FC } from 'react';
import React from 'react';
import { Flex, greyPalette, Text } from '@lama/design-system';
import type { Fee } from '@lama/contracts';
import { formatValue } from '@lama/data-formatters';

interface FeeItemProps {
  item: Fee;
}

export const FeeItem: FC<FeeItemProps> = ({ item: { type, amount, comments } }) => (
  <Flex gap={4} flexDirection={'column'}>
    <Text variant={'body1'} weight={700} color={'secondary'}>
      {type}
      {amount ? ` - ${formatValue(amount, 'currency')}` : ''}
    </Text>
    {comments ? (
      <Flex flexDirection={'column'} gap={2} minWidth={0}>
        <Text variant={'body2'} color={greyPalette[500]}>
          {'Comments'}
        </Text>
        <Flex>
          <Text variant={'body2'}>{comments}</Text>
        </Flex>
      </Flex>
    ) : null}
  </Flex>
);
