import type { PersonUpdateApiModel } from '@lama/clients';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { displayToast } from '@lama/app-components';
import { useAuthentication } from '../authentication/useAuthentication';
import { peopleServiceClient } from '../../shared/clients/peopleService';

export const useUpdatePerson = (applicationId: string) => {
  const queryClient = useQueryClient();
  const { token, isAuthenticated } = useAuthentication();

  return useMutation({
    mutationFn: async ({ personId, updatePersonPayload }: { personId: string; updatePersonPayload: PersonUpdateApiModel }) => {
      if (isAuthenticated) {
        await peopleServiceClient.updatePerson(personId, updatePersonPayload, token);
      }
    },
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: ['applicationRequirements', applicationId],
        }),
        queryClient.invalidateQueries({
          queryKey: ['application', applicationId],
        }),
        queryClient.invalidateQueries({
          queryKey: ['applications'],
        }),
      ]);
    },
    onError: () => {
      displayToast('Something went wrong while updating the application. Please contact support.', 'error');
    },
  });
};
