import type { PropertySourceTypes } from '@lama/contracts';
import { useTheme } from '@mui/material';
import type { FC } from 'react';
import React, { useMemo } from 'react';
import { amber, grey } from '@mui/material/colors';
import { Text } from '@lama/design-system';
import styled from 'styled-components';
import { SourceIcon } from './SourceIcon';

const StyledText = styled(Text)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ theme: { space } }) => space[2]}px;
`;

interface FormikPickerHelperTextProps {
  source?: PropertySourceTypes;
  text: string;
  variant?: 'default' | 'diff' | 'error' | 'missing';
}

export const SourceHelperText: FC<FormikPickerHelperTextProps> = ({ source, text, variant = 'default' }) => {
  const theme = useTheme();

  const iconColor = useMemo(() => {
    switch (variant) {
      case 'missing': {
        return grey[500];
      }
      case 'diff': {
        return amber[700];
      }
      case 'error': {
        return theme.palette.error.light;
      }
      default: {
        return theme.palette.primary.main;
      }
    }
  }, [theme.palette.error.light, theme.palette.primary.main, variant]);

  return (
    <StyledText variant={'body3'} mt={1}>
      {source ? <SourceIcon source={source} color={iconColor} /> : null}
      {text}
    </StyledText>
  );
};
