import { Modal, Box, styled } from '@mui/material';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import type { FC } from 'react';
import React, { useCallback, useMemo } from 'react';
import { isNil } from 'lodash-es';
import { Flex, greyPalette } from '@lama/design-system';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { getFilePlugin } from '@react-pdf-viewer/get-file';
import type { TransformToolbarSlot, ToolbarSlot } from '@react-pdf-viewer/toolbar';
import { toolbarPlugin } from '@react-pdf-viewer/toolbar';
import type { DocumentWithIssues, LineItemBoundingBox } from '@lama/document-service-client';
import { highlightPlugin, type RenderHighlightsProps, Trigger } from '@react-pdf-viewer/highlight';
import { PDFLoadingState } from '../DocumentBox/PDFLoadingState';
import { imageFileExtensions, pdfFileExtensions } from '../DocumentBox/DocumentBox';
import { DocumentHighlights } from './DocumentHighlights';
import { ImageDocumentViewer } from './ImageDocumentViewer';

interface DocumentPreviewModalProps {
  document?: DocumentWithIssues;
  open: boolean;
  onClose: () => void;
  initialPage?: number;
  initialHighlight?: LineItemBoundingBox;
}

const ViewerContainer = styled(Flex)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 90%;
  outline: none;
`;

export type PDFHighlightedArea = LineItemBoundingBox & { pageIndex?: number };

export const getPDFHighlight = (highlightArea: PDFHighlightedArea | null) => {
  if (!highlightArea?.pageIndex) {
    return null;
  }

  const { top, left, width, height, pageIndex } = highlightArea;

  return {
    top: (top ?? 0) * 100,
    left: (left ?? 0) * 100,
    width: (width ?? 0) * 100,
    height: (height ?? 0) * 100,
    pageIndex: pageIndex - 1,
  };
};

const workerUrl = 'https://unpkg.com/pdfjs-dist@3.7.107/build/pdf.worker.min.js';

export const DocumentPreviewModal: FC<DocumentPreviewModalProps> = ({ document, open, onClose, initialPage, initialHighlight }) => {
  const getFilePluginInstance = getFilePlugin({
    fileNameGenerator: () => document?.filename ?? 'Copy of your file',
  });

  const { DownloadButton } = getFilePluginInstance;
  const toolbarPluginInstance = toolbarPlugin();
  const { renderDefaultToolbar, Toolbar } = toolbarPluginInstance;

  const documentType = useMemo(() => {
    const documentName = document?.filename;
    return pdfFileExtensions.some((pdfExtention) => documentName?.endsWith(pdfExtention))
      ? 'pdf'
      : imageFileExtensions.some((imageExtention) => documentName?.endsWith(imageExtention))
      ? 'image'
      : 'other';
  }, [document]);

  const transform: TransformToolbarSlot = (slot: ToolbarSlot) => ({
    ...slot,
    Download: DownloadButton,
  });

  const renderHighlights = useCallback(
    (highlightProps: RenderHighlightsProps) => {
      if (!initialHighlight) {
        return <div />;
      }

      const highlightArea = getPDFHighlight({
        ...initialHighlight,
        pageIndex: !isNil(initialPage) ? initialPage + 1 : undefined,
      });

      if (!highlightArea || highlightArea?.pageIndex !== highlightProps.pageIndex) {
        return <div />;
      }

      return <DocumentHighlights boundingBoxes={[highlightArea]} highlightProps={highlightProps} />;
    },
    [initialHighlight, initialPage],
  );

  const highlightPluginInstance = highlightPlugin({
    renderHighlights,
    trigger: Trigger.None,
  });

  const defaultLayoutPluginInstance = defaultLayoutPlugin({ renderToolbar: () => <Toolbar>{renderDefaultToolbar(transform)}</Toolbar> });

  return document?.previewUrl ? (
    <Modal open={open} onClose={onClose} sx={{ borderRadius: '8px' }}>
      <Box>
        <Worker workerUrl={workerUrl}>
          <ViewerContainer flexDirection={'column'} backgroundColor={greyPalette[200]}>
            {documentType === 'pdf' ? (
              <Viewer
                initialPage={initialPage}
                fileUrl={document?.previewUrl}
                plugins={[defaultLayoutPluginInstance, getFilePluginInstance, toolbarPluginInstance, highlightPluginInstance]}
                renderLoader={PDFLoadingState}
              />
            ) : documentType === 'image' && document.previewUrl ? (
              <ImageDocumentViewer documentUrl={document.previewUrl} />
            ) : null}
          </ViewerContainer>
        </Worker>
      </Box>
    </Modal>
  ) : null;
};
