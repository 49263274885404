import type { FC } from 'react';
import React, { useMemo, useCallback } from 'react';
import type { CheckboxProps } from '@mui/material';
import { FormControlLabel, Checkbox, FormHelperText } from '@mui/material';
import { useField } from 'formik';
import { useTranslation } from 'react-i18next';
import { Flex, Text, redPalette } from '@lama/design-system';
import { HelperText } from './HelperTexts/HelperText';

type CustomCheckboxProps = CheckboxProps & {
  name: string;
  label: string;
  required?: boolean;
  helperText?: string;
};

export const FormikCheckbox: FC<CustomCheckboxProps> = ({ name, label, required, helperText, ...props }) => {
  const { t } = useTranslation();
  const [{ value }, { error }, { setValue: setFormikValue }] = useField<boolean | string | undefined>(name);

  const onFieldChange = useCallback(
    async (e: React.ChangeEvent<any>) => {
      void setFormikValue(!!e.target.checked);
    },
    [setFormikValue],
  );

  const actualHelperText = useMemo(() => helperText ?? t(`global.helperText.${name}`, { defaultValue: '' }), [helperText, name, t]);

  const labelComponent = useMemo(
    () => (
      <Text variant={'body1'}>
        {label}
        {required ? <Text color={redPalette[400]}>{' *'}</Text> : null}
      </Text>
    ),
    [label, required],
  );

  return (
    <Flex flexDirection={'column'}>
      <Flex flexDirection={'column'}>
        <FormControlLabel name={name} label={labelComponent} control={<Checkbox checked={!!value} onChange={onFieldChange} {...props} />} />
        {error ? <FormHelperText error>{error}</FormHelperText> : null}
      </Flex>
      {actualHelperText ? <HelperText>{actualHelperText}</HelperText> : null}
    </Flex>
  );
};
