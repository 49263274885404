import React, { useMemo } from 'react';
import type { FC } from 'react';
import { sumBy } from 'lodash-es';
import { Text } from '@lama/design-system';
import { formatValue } from '@lama/data-formatters';
import RequirementIndicationIcon from './assets/RequirementIndication.svg';
import { IndicationContainer } from './IndicationContainer';
import type { CustomIndicationProps } from './Indication';

export const UseOfFundsBreakdownIndication: FC<CustomIndicationProps> = ({ application }) => {
  const { useOfFundsBreakdown, requestedAmount } = application;

  const remainingFunds = useMemo(() => {
    if (!requestedAmount) {
      return 0;
    }
    if (!useOfFundsBreakdown?.length) {
      return requestedAmount;
    }

    return requestedAmount - sumBy(useOfFundsBreakdown, (a) => a.amount ?? 0);
  }, [useOfFundsBreakdown, requestedAmount]);

  if (remainingFunds <= 0) {
    return null;
  }

  return (
    <IndicationContainer>
      <RequirementIndicationIcon />
      <Text variant={'body2'}> {`Use for ${formatValue(remainingFunds, 'currency')} has not yet been specified.`}</Text>
    </IndicationContainer>
  );
};
