import React, { useCallback } from 'react';
import { Box, useTheme } from '@mui/material';
import { paramCase } from 'change-case';
import { useNavigate } from 'react-router-dom';
import { Flex, Text } from '@lama/design-system';
import { RequirementIndication } from '../shared/RequirementIndication';
import type { RequirementCardProps } from './RequirementCardWrapper';

export const FulfilledRequirementCard: React.FC<RequirementCardProps> = ({ requirement }) => {
  const { id, name, screen } = requirement;
  const theme = useTheme();
  const navigate = useNavigate();

  const onClick = useCallback(() => {
    navigate(`../${paramCase(screen)}?requirementId=${id}`);
  }, [navigate, id, screen]);

  return (
    <Box
      border={'1px solid #E0E0E0'}
      borderRadius={'4px'}
      minHeight={'56px'}
      display={'flex'}
      alignItems={'center'}
      sx={{
        // eslint-disable-next-line @typescript-eslint/naming-convention
        '&:hover': {
          background: theme.colors.greyHover,
          cursor: 'pointer',
        },
        cursor: 'pointer',
      }}
      onClick={onClick}
      data-testid={'requirement'}
    >
      <Flex justifyContent={'space-between'} px={4} alignItems={'center'} width={'100%'} gap={4}>
        <Text variant={'body1'} color={'text.secondary'} ellipsis>
          {name}
        </Text>
        <RequirementIndication requirement={requirement} />
      </Flex>
    </Box>
  );
};
