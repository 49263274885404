/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-undef */

import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEffect, useState } from 'react';

/* eslint-disable @typescript-eslint/no-use-before-define */
export const loadDemoChat = () => {
  window.addEventListener('mouseover', initLandbot, { once: true });
  window.addEventListener('touchstart', initLandbot, { once: true });
  // @ts-ignore
  let myLandbot;
  function initLandbot() {
    // @ts-ignore
    if (!myLandbot) {
      const s = document.createElement('script');
      s.type = 'text/javascript';
      s.async = true;
      s.addEventListener('load', () => {
        // @ts-ignore
        const myLandbot = new Landbot.Livechat({
          configUrl: 'https://storage.googleapis.com/landbot.online/v3/H-2160094-SIYDBBORGFCABOFI/index.json',
        });
      });
      s.src = 'https://cdn.landbot.io/landbot-3/landbot-3.0.0.js';
      const x = document.querySelectorAll('script')[0];
      // @ts-ignore
      x.parentNode.insertBefore(s, x);
    }
  }
};

// i need a hook to call this function only once and mark that it has been called with a state and not call it again
export const useLoadDemoChat = () => {
  const [loaded, setLoaded] = useState(false);
  const { enableDemoChat } = useFlags();

  useEffect(() => {
    if (!loaded && enableDemoChat) {
      loadDemoChat();
      setLoaded(true);
    }
  }, [enableDemoChat, loaded]);
};
