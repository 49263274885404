import type { FC } from 'react';
import React from 'react';
import { Flex, Grid, Text } from '@lama/design-system';
import type { TransactionEstimate } from '@lama/business-service-client';
import { isNil } from 'lodash-es';
import { KeyValuePair } from '../../KeyValuePair';
import type { InfoLineProps } from '../../Information';

interface TransactionEstimateItemItemProps {
  item: TransactionEstimate;
}

export const TransactionEstimateItem: FC<TransactionEstimateItemItemProps> = ({
  item: { transactionType, estimateSingle, highestBatch, highestMoTotal, accountNumberLast4Digits },
}) => {
  const itemData: InfoLineProps[] = [
    ...(!isNil(estimateSingle) ? [{ label: 'Estimate Single', values: estimateSingle, type: 'currency' as const }] : []),
    ...(!isNil(highestBatch) ? [{ label: 'Highest Batch', values: highestBatch, type: 'currency' as const }] : []),
    ...(!isNil(highestMoTotal) ? [{ label: 'Highest Money Out Total', values: highestMoTotal, type: 'currency' as const }] : []),
    ...(!isNil(accountNumberLast4Digits) ? [{ label: 'Account No. Last 4 Digits', values: accountNumberLast4Digits }] : []),
  ];
  return (
    <Flex gap={4} flexDirection={'column'}>
      <Text variant={'body1'} weight={700} color={'secondary'}>
        {transactionType}
      </Text>
      <Grid columns={3}>
        {itemData.map((line) => (
          <KeyValuePair key={line.label} name={line.label} value={line.values} type={line.type} />
        ))}
      </Grid>
    </Flex>
  );
};
