import React from 'react';
import type { FC } from 'react';
import type { TooltipProps } from '@mui/material';
import { styled, Tooltip as MuiTooltip, tooltipClasses } from '@mui/material';
import grey from '@mui/material/colors/grey.js';

// eslint-disable-next-line unicorn/no-keyword-prefix
export const Tooltip: FC<TooltipProps> = styled(({ className, ...props }: TooltipProps) => (
  // eslint-disable-next-line unicorn/no-keyword-prefix
  <MuiTooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    color: 'rgba(0, 0, 0, 0.6)',
    boxShadow: '0px 11px 15px 0px #DBDBDB26',
    border: `1px solid ${grey[300]}`,
    fontSize: 10,
    fontFamily: 'Poppins',
    maxWidth: 500,
  },
}));
