/* eslint-disable @typescript-eslint/naming-convention */
import React, { useCallback, useState } from 'react';
import { Grid, ToggleButton, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import type { ScreenProps } from '../ScreenProps';
import { BasicScreen } from '../shared/BasicScreen';

const options = ['< 30 days', '30-90 days', '3-6 months', 'Unsure'];

export const RequestedFundingTimeScreen: React.FC<ScreenProps> = ({ onNextClick, onBackClick, ...stepsNavigationProps }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { t } = useTranslation();
  const [selectedTime, setSelectedTime] = useState('');

  const onClick = useCallback((_e: any, value: string) => {
    setSelectedTime(value);
  }, []);

  const onNextClickHandler = useCallback(() => {
    // TODO: save selection
    onNextClick();
  }, [onNextClick]);

  return (
    <BasicScreen
      {...stepsNavigationProps}
      title={t('requestedDisbursementTime.title')}
      onNextClick={onNextClickHandler}
      nextEnabled={!!selectedTime}
      onBackClick={onBackClick}
    >
      <Grid container spacing={isMobile ? 2 : 3}>
        {options.map((option) => (
          <Grid item xs={6} key={option}>
            <ToggleButton
              value={option}
              color={'primary'}
              onClick={onClick}
              selected={option === selectedTime}
              sx={{
                '&.Mui-selected': {
                  borderColor: 'primary.main',
                },
                height: `${isMobile ? '80px' : 'auto'}`,
              }}
              fullWidth
            >
              {option}
            </ToggleButton>
          </Grid>
        ))}
      </Grid>
    </BasicScreen>
  );
};
