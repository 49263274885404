import { useTheme } from '@mui/material';
import type { FC } from 'react';
import React from 'react';

export const Headset: FC = () => {
  const theme = useTheme();

  return (
    <svg width={'24'} height={'24'} viewBox={'0 0 24 24'} fill={'none'} xmlns={'http://www.w3.org/2000/svg'}>
      <mask id={'mask0_27694_633'} style={{ maskType: 'alpha' }} maskUnits={'userSpaceOnUse'} x={'0'} y={'0'} width={'24'} height={'24'}>
        <rect width={'24'} height={'24'} fill={'#D9D9D9'} />
      </mask>
      <g mask={'url(#mask0_27694_633)'}>
        <path
          d={
            'M5.3077 19.5C4.80257 19.5 4.375 19.325 4.025 18.975C3.675 18.625 3.5 18.1974 3.5 17.6923V11C3.5 9.83074 3.72308 8.73075 4.16925 7.7C4.6154 6.66923 5.22437 5.76795 5.99615 4.99615C6.76795 4.22437 7.66923 3.6154 8.7 3.16925C9.73075 2.72308 10.8307 2.5 12 2.5C13.1692 2.5 14.2692 2.72308 15.3 3.16925C16.3307 3.6154 17.232 4.22437 18.0038 4.99615C18.7756 5.76795 19.3846 6.66923 19.8307 7.7C20.2769 8.73075 20.5 9.83074 20.5 11V20.3846C20.5 20.8897 20.325 21.3172 19.975 21.6672C19.625 22.0172 19.1974 22.1922 18.6923 22.1922H12.75C12.5371 22.1922 12.3589 22.1204 12.2154 21.9769C12.0718 21.8333 12 21.6551 12 21.4422C12 21.2294 12.0718 21.0512 12.2154 20.9076C12.3589 20.764 12.5371 20.6922 12.75 20.6922H18.6923C18.782 20.6922 18.8557 20.6634 18.9134 20.6057C18.9711 20.548 19 20.4743 19 20.3845V19.4999H17.2692C16.7705 19.4999 16.3446 19.3233 15.9914 18.9701C15.6382 18.6169 15.4616 18.1909 15.4616 17.6922V14.2307C15.4616 13.732 15.6382 13.3061 15.9914 12.9529C16.3446 12.5997 16.7705 12.4231 17.2692 12.4231H19V11C19 9.06664 18.3166 7.41664 16.95 6.04998C15.5833 4.68331 13.9333 3.99998 12 3.99998C10.0666 3.99998 8.41664 4.68331 7.04998 6.04998C5.68331 7.41664 4.99997 9.06664 4.99997 11V12.4231H6.73073C7.22944 12.4231 7.6554 12.5997 8.0086 12.9529C8.3618 13.3061 8.5384 13.732 8.5384 14.2308V17.6923C8.5384 18.191 8.3618 18.6169 8.0086 18.9701C7.6554 19.3233 7.22944 19.5 6.73073 19.5H5.3077ZM5.3077 18H6.73073C6.82048 18 6.8942 17.9711 6.9519 17.9134C7.0096 17.8557 7.03845 17.782 7.03845 17.6923V14.2308C7.03845 14.141 7.0096 14.0673 6.9519 14.0096C6.8942 13.9519 6.82048 13.9231 6.73073 13.9231H4.99997V17.6923C4.99997 17.782 5.02883 17.8557 5.08653 17.9134C5.14423 17.9711 5.21795 18 5.3077 18ZM17.2692 18H19V13.9231H17.2692C17.1795 13.9231 17.1058 13.9519 17.0481 14.0096C16.9904 14.0673 16.9615 14.141 16.9615 14.2308V17.6923C16.9615 17.782 16.9904 17.8557 17.0481 17.9134C17.1058 17.9711 17.1795 18 17.2692 18ZM17.2692 13.9231H16.9615H19H17.2692Z'
          }
          fill={theme.palette.primary.main}
        />
      </g>
    </svg>
  );
};
