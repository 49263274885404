import React from 'react';
import type { FC } from 'react';
import { CollateralEmptyState } from './listEmptyStates/CollateralEmptyState';
import { DebtEmptyState } from './listEmptyStates/DebtEmptyState';
import { UseOfFundsBreakdownEmptyState } from './listEmptyStates/UseOfFundsBreakdownEmptyState';
import { UseOfFundsBreakdownItem } from './ListItems/UseOfFundsBreakdownItem';
import { CollateralItem } from './ListItems/CollateralItem';
import { OtherIncomeItem } from './ListItems/OtherIncomeItem';
import { FinancialReferenceItem } from './ListItems/FinancialReferenceItem';
import { ClosingItemItem } from './ListItems/ClosingItemItem';
import { FeeItem } from './ListItems/FeeItem';
import { DebtItem } from './ListItems/DebtItem';
import { TransactionEstimateItem } from './ListItems/TransactionEstimateItem';

interface RequirementListItemProps {
  item: any;
}

export const requirementToListItemComponent: Record<string, FC<RequirementListItemProps>> = {
  useOfFundsBreakdown: UseOfFundsBreakdownItem,
  debt: DebtItem,
  collateral: CollateralItem,
  transactionEstimates: TransactionEstimateItem,
  fees: FeeItem,
  closingItems: ClosingItemItem,
  otherIncomes: OtherIncomeItem,
  financialReferences: FinancialReferenceItem,
};

export const listRequirementToEmptyStateImage: Record<string, FC> = {
  collateral: () => <CollateralEmptyState />,
  debt: () => <DebtEmptyState />,
  useOfFundsBreakdown: () => <UseOfFundsBreakdownEmptyState />,
  transactionEstimates: () => <UseOfFundsBreakdownEmptyState />,
  fees: () => <UseOfFundsBreakdownEmptyState />,
  closingItems: () => <UseOfFundsBreakdownEmptyState />,
};
