import React from 'react';
import type { FC } from 'react';
import type { FormikValues } from 'formik';
import { useFormikContext } from 'formik';
import type { EvaluatedApplicationRequirement, EvaluatedOpportunityRequirement } from '@lama/contracts';
import type { ItemDocumentsProps } from './types';

export const FormikDocumentPlaceholders: FC<{
  requirement: EvaluatedApplicationRequirement | EvaluatedOpportunityRequirement;
  ItemDocumentsComponent: FC<ItemDocumentsProps>;
}> = ({ requirement, ItemDocumentsComponent }) => {
  const { values: formValues } = useFormikContext<FormikValues>();

  return <ItemDocumentsComponent itemId={formValues.id} requirement={requirement} formContext={formValues} />;
};
