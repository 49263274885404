/* eslint-disable @typescript-eslint/naming-convention */
import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { LoadingPage } from '@lama/app-components';
import { BasicScreen } from '../shared/BasicScreen';
import type { ScreenProps } from '../ScreenProps';
import { UserDetailsContext } from '../../shared/contexts/UserDetailsContext';
import { useGetUserQuery } from '../../hooks/react-query/useUserQuery';
import { OtpVerification } from '../shared/OtpVerification/OtpVerification';
import { useAuthentication } from '../../hooks/authentication/useAuthentication';
import VerifyOtpImage from './verifyOtp.svg';

export const VerificationScreen: FC<ScreenProps> = ({ flow, onNextClick, ...props }) => {
  const { t } = useTranslation();
  const userContext = useContext(UserDetailsContext);
  const { setAccessToken, creationDetails } = useAuthentication();
  const { data: currentUser, isPending: isFetchingUser } = useGetUserQuery(userContext?.id);
  const userVerified = useMemo(() => !!currentUser?.emailVerified || !!currentUser?.phoneNumberVerified, [currentUser]);

  useEffect(() => {
    if (userVerified && flow === 'onboarding') {
      onNextClick();
    }
  }, [flow, onNextClick, userVerified]);

  const onOtpVerified = useCallback(
    (token: string) => {
      setAccessToken(token);
      onNextClick();
    },
    [onNextClick, setAccessToken],
  );

  if (isFetchingUser) {
    return <LoadingPage />;
  }

  if (!currentUser?.email && !creationDetails?.email) {
    return (
      <BasicScreen
        {...props}
        flow={flow}
        preTitleComponent={<VerifyOtpImage />}
        title={t('verification.title')}
        subtitle={t('verification.subtitle.missingInfo')}
        nextVisible={false}
      />
    );
  }

  return (
    <BasicScreen {...props} flow={flow} preTitleComponent={<VerifyOtpImage />} title={`${t('verification.title')}`} nextVisible={false}>
      <OtpVerification
        initialEmail={currentUser?.email ?? creationDetails?.email}
        initialPhoneNumber={currentUser?.phoneNumber ?? creationDetails?.phoneNumber}
        onOtpVerified={onOtpVerified}
        autoSend
      />
    </BasicScreen>
  );
};
