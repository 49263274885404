import React from 'react';
import { Box } from '@mui/material';
import { Text, Flex, greyPalette } from '@lama/design-system';
import { RequirementIndication } from '../shared/RequirementIndication';
import type { RequirementCardProps } from './RequirementCardWrapper';

export const SubmittedRequirementCard: React.FC<RequirementCardProps> = ({ requirement }) => (
  <Box
    border={'1px solid #E0E0E0'}
    borderRadius={'4px'}
    minHeight={'56px'}
    display={'flex'}
    alignItems={'center'}
    data-testid={'requirement'}
  >
    <Flex px={4} alignItems={'center'} justifyContent={'space-between'} width={'100%'} gap={4}>
      <Text variant={'body1'} color={greyPalette[500]} ellipsis>
        {requirement.name}
      </Text>
      <RequirementIndication requirement={requirement} />
    </Flex>
  </Box>
);
