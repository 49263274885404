import { formatValue } from '@lama/data-formatters';
import { Flex, Text } from '@lama/design-system';
import type { FC } from 'react';
import React from 'react';
import { Tooltip } from '../Tooltip/Tooltip';

export interface ArrayInfoLineProps {
  values: any[];
  wrap?: boolean;
}

const maxItems = 2;

export const InformationArray: FC<ArrayInfoLineProps> = ({ values, wrap }) => {
  const formattedValues = values.map((value) => formatValue(value));
  return (
    <Flex gap={2} alignItems={'center'}>
      <Text variant={'body2'}>{formattedValues?.slice(0, wrap ? values.length : maxItems).join(', ')}</Text>
      {values?.length > maxItems && !wrap ? (
        <Tooltip title={formattedValues.join(', ')} placement={'top-end'}>
          <Text variant={'body3'}>{`+${values.length - maxItems} more`}</Text>
        </Tooltip>
      ) : null}
    </Flex>
  );
};
