import { useCallback } from 'react';
import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import type { Document } from '@lama/document-service-client';
import { createDocumentServiceClient } from '@lama/document-service-client';
import { documentServiceUrl } from '../../framework/environment';
import { useAuthentication } from '../authentication/useAuthentication';

export const useDocumentsQuery = (applicationId: string, options?: UseQueryOptions<Document[]>) => {
  const { token, isAuthenticated } = useAuthentication();

  const getDocumentsQuery = useCallback(async () => {
    if (!isAuthenticated) {
      return [];
    }

    return createDocumentServiceClient(documentServiceUrl as string).getDocuments({ applicationId, includeIssues: false }, token);
  }, [applicationId, isAuthenticated, token]);

  const query = useQuery<Document[]>({ queryKey: ['applicationDocuments', applicationId], queryFn: getDocumentsQuery, ...options });

  return query;
};
