import type { FC } from 'react';
import React, { useMemo, useCallback, useState } from 'react';

export interface ConfirmModalProps {
  title: string;
  message?: string;
  confirmText?: string;
  cancelText?: string;
  type?: 'confirm' | 'danger';
}

const modalDefaultText: ConfirmModalProps = { title: 'Confirm action', cancelText: 'Cancel', confirmText: 'Confirm', type: 'confirm' };

export interface ConfirmationModalContextProps {
  confirm: () => void;
  cancel: () => void;
  startConfirmation: () => Promise<boolean>;
  modalOpen: boolean;
  setModalProps: (modalText: ConfirmModalProps) => void;
  modalProps: ConfirmModalProps;
}

export const ConfirmationModalContext = React.createContext<ConfirmationModalContextProps>({
  confirm: () => null,
  cancel: () => null,
  startConfirmation: async () => false,
  modalOpen: false,
  setModalProps: () => null,
  modalProps: modalDefaultText,
});

interface ConfirmationModalProviderProps {
  children: React.ReactNode;
}

export const ConfirmationModalProvider: FC<ConfirmationModalProviderProps> = ({ children }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [resolveConfirmation, setResolveConfirmation] = useState<{ resolve: (value: boolean) => void }>();
  const [modalProps, setModalProps] = useState<ConfirmModalProps>(modalDefaultText);

  const startConfirmation = useCallback(() => {
    const promise = new Promise<boolean>((resolve) => {
      setResolveConfirmation({ resolve });
    });

    setModalOpen(true);

    return promise;
  }, []);

  const confirm = useCallback(() => {
    setModalOpen(false);

    resolveConfirmation?.resolve(true);
    setResolveConfirmation({ resolve: () => null });

    setTimeout(() => {
      // wait for next render cycle to close and reset modal
      setModalProps(modalDefaultText);
    }, 100);
  }, [resolveConfirmation, setModalProps]);

  const cancel = useCallback(() => {
    setModalOpen(false);

    resolveConfirmation?.resolve(false);
    setResolveConfirmation({ resolve: () => null });
  }, [resolveConfirmation]);

  const changeModalProps = useCallback(
    ({ title, cancelText, confirmText, message, type }: ConfirmModalProps) => {
      setModalProps({
        title: title || modalDefaultText.title,
        cancelText: cancelText ?? modalDefaultText.cancelText,
        confirmText: confirmText ?? modalDefaultText.confirmText,
        message: message ?? modalDefaultText.message,
        type,
      });
    },
    [setModalProps],
  );

  const value = useMemo(
    () => ({
      modalOpen,
      startConfirmation,
      confirm,
      cancel,
      setModalProps: changeModalProps,
      modalProps,
    }),
    [cancel, changeModalProps, confirm, modalOpen, modalProps, startConfirmation],
  );

  return <ConfirmationModalContext.Provider value={value}>{children}</ConfirmationModalContext.Provider>;
};
