import React, { useCallback, useState } from 'react';
import { Flex } from '@lama/design-system';
import type { ScreenProps } from '../ScreenProps';
import { BasicScreen } from '../shared/BasicScreen';
import type { Category } from '../shared/types/services';
import { SearchBar } from './SearchBar';
import { ServicesSearchResults } from './ServicesSearchResults';

export const ServicesSelectionScreen: React.FC<ScreenProps> = ({ onNextClick, onBackClick, ...stepsNavigationProps }) => {
  const [query, setQuery] = useState('');
  const [isExpandedCategory, setIsExpandedCategory] = useState(false);

  const handleCategoryExpanded = useCallback((categoryId: Category | null) => {
    setIsExpandedCategory(categoryId !== null);
    setQuery('');
  }, []);

  const onSearch = useCallback((queryString: string) => {
    setQuery(queryString);
  }, []);

  return (
    <BasicScreen
      {...stepsNavigationProps}
      title={'Select the services used by your business'}
      subtitle={'This can help speed up your application and improve your terms'}
      onNextClick={onNextClick}
      onBackClick={onBackClick}
      nextVisible={!query && !isExpandedCategory}
      backVisible={!query && !isExpandedCategory}
    >
      <Flex flexDirection={'column'} gap={12}>
        <SearchBar label={'Search'} query={query} onQueryChanged={onSearch} />
        <Flex flexDirection={'column'} gap={20} width={'100%'}>
          <ServicesSearchResults query={query} onExpanded={handleCategoryExpanded} />
        </Flex>
      </Flex>
    </BasicScreen>
  );
};
