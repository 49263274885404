import { Flex } from '@lama/design-system';
import React, { type FC, useMemo } from 'react';
import { RepullAndSubmitButton } from './RepullAndSubmitButton';

interface AlertActionsProps {
  applicationId: string;
  actions: string[];
}

export const AlertActions: FC<AlertActionsProps> = ({ applicationId, actions }) => {
  const alertActionToComponent: Record<string, React.FC<{ applicationId: string }>> = useMemo(
    () => ({
      repullAndSubmit: RepullAndSubmitButton,
    }),
    [],
  );

  return (
    <Flex gap={4}>
      {actions?.map((actionName) => {
        const Comp = alertActionToComponent[actionName];
        if (!Comp) {
          return null;
        }
        return <Comp key={actionName} applicationId={applicationId} />;
      })}
    </Flex>
  );
};
