import React, { useCallback } from 'react';
import { Card, IconButton } from '@mui/material';
import { DeleteOutlineRounded, EditOutlined } from '@mui/icons-material';
import { Flex } from '@lama/design-system';
import { useConfirmModal } from '../ConfirmationModal/useConfirmModal';
import { Tooltip } from '../Tooltip/Tooltip';

interface ItemCardProps {
  item: { id: string };
  ItemComponent?: React.FC<{ item: any }>;
  onEdit: (id: string) => void;
  onDelete: (id: string) => void;
}

export const ItemCard: React.FC<ItemCardProps> = ({ item, ItemComponent, onEdit, onDelete }) => {
  const { confirm } = useConfirmModal();

  const onClickDelete = useCallback(async () => {
    const confirmed = await confirm({ title: 'Are you sure you want to delete this item?', confirmText: 'Confirm' });
    if (confirmed) {
      onDelete(item.id);
    }
  }, [confirm, item.id, onDelete]);

  const onClickEdit = useCallback(() => {
    onEdit(item.id);
  }, [onEdit, item.id]);

  return (
    <Card variant={'outlined'} key={item.id} sx={{ width: '100%' }}>
      <Flex flexDirection={'row'} justifyContent={'space-between'} p={4} width={'100%'}>
        <Flex flexDirection={'column'} gap={1} maxWidth={'80%'} flex={1}>
          {ItemComponent ? <ItemComponent item={item} /> : null}
        </Flex>
        <Flex gap={2} flexDirection={'row'} alignItems={'center'}>
          <Tooltip title={'Delete Item'}>
            <IconButton id={item.id} onClick={onClickDelete} color={'primary'}>
              <DeleteOutlineRounded />
            </IconButton>
          </Tooltip>
          <Tooltip title={'Edit Item'}>
            <IconButton id={item.id} onClick={onClickEdit} color={'primary'}>
              <EditOutlined />
            </IconButton>
          </Tooltip>
        </Flex>
      </Flex>
    </Card>
  );
};
