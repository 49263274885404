import { useAsync } from 'react-use';
import { setUser } from '@sentry/react';
import type { User } from '@lama/user-service-client';

export const useSentryIdentification = (user: User | null, partner?: string) => {
  useAsync(async () => {
    if (user && process.env.NODE_ENV === 'production') {
      const { email, type, firstName, lastName } = user;
      const name = firstName && lastName ? `${firstName} ${lastName}` : email;

      if (name && partner && email && type) {
        setUser({
          name,
          email,
          partner,
          type,
        });
      }
    }
  }, [partner, user]);
};
