import type { FunctionComponent } from 'react';
import React from 'react';
import { ThemeProvider as SCThemeProvider } from 'styled-components';
import { getLightTheme } from './lightTheme.js';
import type { Theme } from './types.js';

interface ThemeProviderProps {
  theme?: Theme;
  children: React.ReactNode;
}
const lightTheme = getLightTheme();

export const ThemeProvider: FunctionComponent<ThemeProviderProps> = ({ children, theme = lightTheme }) => (
  <SCThemeProvider theme={theme}>{children}</SCThemeProvider>
);
