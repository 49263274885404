import { useCallback, useContext } from 'react';
import type { ConfirmModalProps } from './ConfirmationModalContext.js';
import { ConfirmationModalContext } from './ConfirmationModalContext.js';

export const useConfirmModal = () => {
  const { startConfirmation, setModalProps } = useContext(ConfirmationModalContext);

  const confirm = useCallback(
    (modalText: ConfirmModalProps) => {
      setModalProps(modalText);

      return startConfirmation();
    },
    [setModalProps, startConfirmation],
  );

  return {
    confirm,
  };
};
