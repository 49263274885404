import React, { useCallback, useEffect, useMemo } from 'react';
import { useRecoilState } from 'recoil';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { selectedServicesState } from '../../state/applicationState';
import type { Category } from '../shared/types/services';
import { services, categories } from '../shared/types/services';
import { CategoryServicesSelection } from './CategoryServicesSelection';

interface ServicesSearchResultsProps {
  query: string;
  onExpanded: (categoryId: Category | null) => void;
}

export const ServicesSearchResults: React.FC<ServicesSearchResultsProps> = ({ query, onExpanded }) => {
  const [selectedServices, setSelectedServices] = useRecoilState(selectedServicesState);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const expandedCategoryId = useMemo(() => searchParams.get('categoryId') as Category | null, [searchParams]);

  useEffect(() => {
    if (expandedCategoryId) {
      window.scrollTo(0, 0);
    }
  }, [expandedCategoryId]);

  const handleViewModeClick = useCallback(
    (categoryId: Category) => {
      if (expandedCategoryId === categoryId) {
        // Show Less
        history.back();
      } else {
        // Show More
        navigate(`?categoryId=${categoryId}`, { replace: false });
      }
    },
    [expandedCategoryId, navigate],
  );

  useEffect(() => {
    onExpanded(expandedCategoryId);
  }, [expandedCategoryId, onExpanded]);

  const handleSelectServiceClick = useCallback(
    (serviceName: string) => {
      if (selectedServices.includes(serviceName)) {
        setSelectedServices(selectedServices.filter((service) => service !== serviceName));
      } else {
        setSelectedServices([...selectedServices, serviceName]);
      }
    },
    [selectedServices, setSelectedServices],
  );

  const servicesWithSelection = services
    .map((s) => ({ ...s, selected: selectedServices.includes(s.name) }))
    .filter((s) => s.name.toLowerCase().includes(query?.toLowerCase() || ''));
  const servicesOfCategory = (groupId: Category) => servicesWithSelection.filter((s) => s.categories.includes(groupId));

  if (expandedCategoryId) {
    return (
      <CategoryServicesSelection
        categoryId={expandedCategoryId}
        services={servicesOfCategory(expandedCategoryId)}
        onViewModeClick={handleViewModeClick}
        onSelectClick={handleSelectServiceClick}
        viewMode={'expanded'}
      />
    );
  }

  if (query) {
    return <CategoryServicesSelection services={servicesWithSelection} onSelectClick={handleSelectServiceClick} />;
  }

  return (
    <>
      {Object.keys(categories).map((id) => (
        <CategoryServicesSelection
          categoryId={id as Category}
          services={servicesOfCategory(id as Category)}
          onViewModeClick={handleViewModeClick}
          onSelectClick={handleSelectServiceClick}
          viewMode={'compact'}
          key={id}
        />
      ))}
    </>
  );
};
