import React, { useCallback, useMemo, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useSessionStorage } from 'react-use';
import { Flex } from '@lama/design-system';
import { getRequirementsByCategory, LoadingPage } from '@lama/app-components';
import { ApplicationContext } from '../../shared/contexts/ApplicationContext';
import { getUnfulfilledRequirementsCount } from '../../shared/utils/requirementUtils';
import { useApplicationAlertsQuery } from '../../hooks/react-query/useApplicationAlertsQuery';
import { Requirements } from './Requirements';
import { OverviewHeader } from './OverviewHeader';
import { ApplicationAlerts } from './ApplicationAlerts';
import { AllRequirementsSubmittedScreen } from './AllRequirementsSubmittedScreen';

export const OverviewScreen: React.FC = () => {
  const { category: routeCategory } = useParams();
  const { requirements, application } = useContext(ApplicationContext);
  const [showAll, setShowAll] = useSessionStorage('showFulfilledRequirements', false);
  const { data: applicationAlerts, isPending: loadingAlerts } = useApplicationAlertsQuery(application.id);

  const progress = useMemo(
    () => Math.round(100 * ((requirements.length - getUnfulfilledRequirementsCount(requirements)) / requirements.length)),
    [requirements],
  );

  const handleToggleChange = useCallback(
    (event: React.MouseEvent<HTMLElement>, value: string | null) => {
      if (value) {
        setShowAll(value === 'true');
      }
    },
    [setShowAll],
  );

  const visibleRequirementByCategory = useMemo(() => {
    const visibleRequirements =
      routeCategory || progress === 100 || showAll ? requirements : requirements.filter(({ status }) => status === 'Pending');

    const requirementsByCategory = getRequirementsByCategory(visibleRequirements, application);

    return routeCategory ? requirementsByCategory.filter(({ categoryId }) => categoryId === routeCategory) : requirementsByCategory;
  }, [routeCategory, progress, showAll, requirements, application]);

  const allRequirementsSubmitted = useMemo(
    () => !requirements.filter(({ submitted, isInvalidSubmission }) => !submitted || isInvalidSubmission).length,
    [requirements],
  );

  if (loadingAlerts) {
    return <LoadingPage />;
  }

  if (allRequirementsSubmitted) {
    return <AllRequirementsSubmittedScreen alerts={applicationAlerts ?? []} />;
  }

  return (
    <Flex flexDirection={'column'} width={'100%'} gap={8}>
      {routeCategory ? null : (
        <>
          <OverviewHeader progress={progress} showFulfilled={showAll} toggleTaskView={handleToggleChange} />
          <ApplicationAlerts applicationId={application.id} alerts={applicationAlerts ?? []} />
        </>
      )}
      <Requirements requirementsByCategory={visibleRequirementByCategory} />
    </Flex>
  );
};
