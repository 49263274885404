import styled, { css } from 'styled-components';
import type { TextProps } from '../../Text';
import { Text } from '../../Text';
import type { InputSizes, StylingProps } from './Input.styles';

const labelSize: Record<InputSizes, string> = {
  l: '10px',
  m: '8px',
};

const focusedInputLabelStyles = ({ focused, disabled }: StylingProps) =>
  !disabled &&
  focused &&
  css`
    color: ${({ theme }) => theme.input.focused.labelColor};
  `;

const errorLabelStyles = ({ error, disabled }: StylingProps) =>
  !disabled &&
  error &&
  css`
    color: ${({ theme }) => theme.input.error.labelColor};
  `;

const disabledStyles = ({ disabled }: StylingProps) =>
  disabled &&
  css`
    color: ${({ theme }) => theme.input.disabled.labelColor};
  `;

export const StyledInputLabel = styled(Text)<StylingProps & TextProps>`
  position: absolute;
  top: -13px;
  left: 14px;
  max-width: 75%;
  font-family: 'Poppins';
  font-size: ${() => labelSize.l};
  padding: 2px 1px;
  font-weight: 400;
  color: ${({ theme }) => theme.input.labelColor};
  transition-property: color, box-shadow, border, background-color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  pointer-events: none;
  z-index: 4;

  ${focusedInputLabelStyles}
  ${errorLabelStyles}
  ${disabledStyles}
`;

export const StyledLabelCover = styled.div<StylingProps & { coverWidth: number }>`
  z-index: 3;
  height: 1px;
  position: absolute;
  top: -1px;
  left: 12px;
  background-color: white;
  width: ${({ coverWidth }) => coverWidth + 4}px;
`;
