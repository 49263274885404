export const isDocumentPasswordProtected = async (file: File): Promise<boolean> => {
  const arrayBuffer = await file.arrayBuffer();

  const pdfjsLib = await import('pdfjs-dist/legacy/build/pdf');
  pdfjsLib.GlobalWorkerOptions.workerSrc = '//cdnjs.cloudflare.com/ajax/libs/pdf.js/3.7.107/pdf.worker.js'; // Replace <version> with the version of PDF.js you're using

  try {
    await pdfjsLib.getDocument({ data: arrayBuffer }).promise;
    return false;
  } catch (error: any) {
    return error?.name === 'PasswordException';
  }
};
