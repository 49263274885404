import React, { useMemo } from 'react';
import { useField } from 'formik';
import { stateNameToAbbreviation, countiesNames, conutyNameByStateAbrreviation } from '@lama/contracts';
import { uniq } from 'lodash-es';
import { FormikPicker } from './FormikPicker/FormikPicker.js';
import type { FormikInputProps } from './PropertyFormikInput.js';

type FormikCountyPickerProps = FormikInputProps & {
  stateFieldName: string;
};

export const FormikCountyPicker: React.FC<FormikCountyPickerProps> = ({ stateFieldName, ...props }) => {
  const [, { value: state }] = useField(stateFieldName);

  const stateCounties = useMemo(() => {
    if (!state) {
      return uniq(countiesNames);
    }

    const stateAbbreviation: string = state.length > 2 ? stateNameToAbbreviation(state as string) : state;

    return conutyNameByStateAbrreviation[stateAbbreviation] ?? [];
  }, [state]);

  const isDisabled = useMemo(() => !state || props.disabled, [state, props.disabled]);

  return <FormikPicker values={stateCounties} label={'County'} disabled={isDisabled} {...props} />;
};
