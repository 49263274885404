import { Flex } from '@lama/design-system';
import type { FC, ReactNode } from 'react';
import React, { useCallback } from 'react';
import { MobileTopbar } from '../MobileTopbar';
import { SubmitBanner } from '../SubmitBanner';
import { MobileSidebar } from './SideNav/MobileSidebar';

export const MobileLayout: FC<{ children: ReactNode; logoUrl: string }> = ({ children, logoUrl }) => {
  const [sidebarOpen, setSidebarOpen] = React.useState(false);

  const openSidebar = useCallback(() => {
    setSidebarOpen(true);
  }, []);

  const closeSidebar = useCallback(() => {
    setSidebarOpen(false);
  }, []);

  return (
    <Flex flexDirection={'column'} width={'100%'} pb={16}>
      <MobileTopbar logoUrl={logoUrl} openSidebar={openSidebar} />
      <MobileSidebar logoUrl={logoUrl} open={sidebarOpen} onClose={closeSidebar} />
      <SubmitBanner sticky={false} />
      {children}
    </Flex>
  );
};
