import type { FinancialUpdateModel } from '@lama/clients';
import { createFinancialsServiceClient } from '@lama/clients';
import type { Entity } from '@lama/common-types';
import type { FinancialData } from '@lama/contracts';
import { financialsServiceBaseUrl } from '../../framework/environment';

const financialsServiceClient = createFinancialsServiceClient(financialsServiceBaseUrl as string);

export const createFinancials = (entityType: string, entityId: string, financials: FinancialData[], token: string) =>
  financialsServiceClient.createFinancials(entityType, entityId, financials, token);

export const updateFinancials = (entityType: Entity, entityId: string, financials: FinancialUpdateModel[], token: string) =>
  financialsServiceClient.updateFinancials(entityType, entityId, financials, token);

export const deleteFinancials = (entityType: Entity, entityId: string, financialIds: string[], token: string) =>
  financialsServiceClient.deleteFinancials(entityType, entityId, { financialIds }, token);
