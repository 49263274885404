import type { FunctionComponent } from 'react';
import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { get } from 'lodash-es';
import type { PixelSuffix } from '../../types';
import type { SpinnerSize } from './Spinner.styles';
import { StyledSpinner } from './Spinner.styles';

export interface SpinnerProps {
  size?: PixelSuffix | SpinnerSize;
  color?: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'data-testid'?: string;
}

export const Spinner: FunctionComponent<SpinnerProps> = ({ size = 'm', color, ...props }) => {
  const { colors, spinner } = useContext(ThemeContext);

  const spinnerColor = color ? get(colors, color) ?? color : spinner.color;

  return <StyledSpinner color={spinnerColor} data-testid={props['data-testid'] ?? 'spinner'} role={'progressbar'} size={size} {...props} />;
};

Spinner.displayName = 'Spinner';
