/* eslint-disable react/no-multi-comp */
import type { FC } from 'react';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Flex } from '../Flex/index.js';
import { greyPalette } from '../../theme/index.js';
import { ButtonGroupItemComponent } from './ButtonGroupItem.js';
import type { ButtonGroupItem } from './types.js';
import { ITEM_MAX_WIDTH } from './types.js';

export interface ButtonGroupProps {
  items: ButtonGroupItem[];
  value: string;
  onChange: (value: string) => void;
}

const StyledButtonGroupContainer = styled(Flex)<{ maxWidth: number }>`
  box-sizing: border-box;
  cursor: pointer;
  border: 1px solid ${greyPalette[300]};
  background-color: ${greyPalette[100]};
  border-radius: 8px;
  position: relative;
  width: 100%;
  max-width: ${({ maxWidth }) => maxWidth}px;
`;

const Indicator = styled(motion.div)<{ left: string; width: string }>`
  position: absolute;
  top: 4px;
  bottom: 4px;
  background-color: white;
  border-radius: 6px;
  z-index: 0;
  left: ${({ left }) => left};
  width: ${({ width }) => width};
`;

export const ButtonGroup: FC<ButtonGroupProps> = ({ items, onChange, value }) => {
  const { left, width, containerMaxWidth } = useMemo(() => {
    const selectedIndex = items.findIndex((item) => item.value === value);
    const itemWidth = 100 / items.length;
    const indicatorWidth = itemWidth * 0.9; // 90% of item width
    const indicatorLeft = selectedIndex * itemWidth + (itemWidth - indicatorWidth) / 2;

    return {
      left: `${indicatorLeft}%`,
      width: `${indicatorWidth}%`,
      containerMaxWidth: ITEM_MAX_WIDTH * items.length,
    };
  }, [items, value]);

  return (
    <StyledButtonGroupContainer flexDirection={'row'} backgroundColor={greyPalette[50]} maxWidth={containerMaxWidth}>
      <Indicator layout left={left} width={width} transition={{ type: 'spring', stiffness: 575, damping: 38 }} />
      {items.map((item) => (
        <ButtonGroupItemComponent
          key={item.value}
          label={item.label}
          value={item.value}
          onChange={onChange}
          selected={item.value === value}
        />
      ))}
    </StyledButtonGroupContainer>
  );
};

ButtonGroup.displayName = 'ButtonGroup';
