/* eslint-disable @typescript-eslint/naming-convention */
import { useCallback, useEffect, useState } from 'react';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { xor } from 'lodash-es';
import { googleApiKey } from '../../framework/environment';
import { getPlacesDetails } from './placesDetailsRetriever';
import type { PlaceDetails } from './placesDetailsRetriever';

const predictionsEqual = (a: { place_id: string }[], b: { place_id: string }[]) =>
  xor(
    a.map(({ place_id }) => place_id),
    b.map(({ place_id }) => place_id),
  ).length === 0;

export const usePlacesApi = () => {
  const { placesService, placePredictions, getPlacePredictions } = usePlacesService({
    apiKey: googleApiKey,
    sessionToken: true,
  });

  const [innerPlacePredictions, setInnerPlacePredictions] = useState<{ place_id: string }[]>([]);
  const [placesResults, setPlacesResults] = useState<PlaceDetails[]>([]);
  const [didInitialSearch, setDidInitialSearch] = useState(false);

  const clearResults = useCallback(() => {
    setPlacesResults([]);
  }, []);

  const searchPlaces = useCallback(
    (value: string) => {
      getPlacePredictions({ input: value, componentRestrictions: { country: 'us' }, types: ['establishment'] });
    },
    [getPlacePredictions],
  );

  useEffect(() => {
    if (innerPlacePredictions.length === 0) {
      clearResults();
    }

    getPlacesDetails(
      placesService,
      innerPlacePredictions.slice(0, 5).map(({ place_id }) => place_id),
    )
      .then((results) => {
        if (!didInitialSearch) {
          setDidInitialSearch(true);
        }

        setPlacesResults(results);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [innerPlacePredictions, didInitialSearch, placesService, clearResults]);

  useEffect(() => {
    if (!predictionsEqual(placePredictions as { place_id: string }[], innerPlacePredictions)) {
      setInnerPlacePredictions(placePredictions);
    }
  }, [placePredictions, setInnerPlacePredictions, innerPlacePredictions]);

  return {
    searchPlaces,
    clearResults,
    placesResults,
    didInitialSearch,
  };
};
