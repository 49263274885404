import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery, useTheme } from '@mui/material';
import { Check, InfoOutlined } from '@mui/icons-material';
import { Button, Flex, Text, greenPalette, greyPalette } from '@lama/design-system';
import { useAsyncFn } from 'react-use';
import { isNil } from 'lodash-es';
import { Avatar } from '../Avatar';
import { Tooltip } from '../Tooltip';
import { ItemActions } from '../ItemActions';

interface PrincipalItemProps {
  firstName: string;
  lastName: string;
  position?: string;
  ownershipPercentage?: number | null;
  deleteEnabled: boolean;
  onDelete?: (id: string) => void;
  onEdit: (id: string) => void;
  id: string;
  inviteAllowed?: boolean;
  hasBeenInvited?: boolean;
  onInvite?: (id: string) => Promise<void>;
  itemType?: string;
}

export const PrincipalItem: React.FC<PrincipalItemProps> = ({
  firstName,
  lastName,
  position,
  ownershipPercentage,
  deleteEnabled,
  onDelete,
  onEdit,
  id,
  inviteAllowed,
  onInvite,
  hasBeenInvited,
  itemType,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [{ loading }, onInviteClick] = useAsyncFn(async () => {
    if (!onInvite) {
      return;
    }

    await onInvite(id);
  }, [onInvite, id]);

  const fullName = `${firstName} ${lastName}`;

  return (
    <Flex
      gap={4}
      height={'100px'}
      px={isMobile ? 5 : 8}
      py={6}
      border={`1px solid ${greyPalette[300]}`}
      borderColor={greyPalette[300]}
      borderRadius={'4px'}
      width={'100%'}
      justifyContent={'space-between'}
    >
      <Flex alignItems={'center'} gap={4} minWidth={0}>
        <Avatar firstName={firstName} lastName={lastName} />
        <Flex flexDirection={'column'} minWidth={0}>
          <Tooltip title={fullName} placement={'top'}>
            <Text variant={'h6'}>{fullName}</Text>
          </Tooltip>
          <Text variant={'body2'}>
            {position ?? 'Owner'}
            {!isNil(ownershipPercentage) ? (
              <>
                {' • '}
                <Text variant={'body2'} color={theme.palette.primary.main}>
                  {`${ownershipPercentage}%`}
                </Text>
                {' Ownership'}
              </>
            ) : null}
          </Text>
        </Flex>
      </Flex>
      <Flex flexDirection={'row'} alignItems={'center'} gap={2}>
        {inviteAllowed && !isMobile ? (
          <Tooltip title={t('principals.invite.tooltip')} placement={'top'}>
            <Button
              endIcon={hasBeenInvited ? <Check /> : <InfoOutlined />}
              iconColor={greenPalette[500]}
              variant={'secondary'}
              color={'neutral'}
              onClick={onInviteClick}
              disabled={hasBeenInvited}
              loading={loading}
              size={'s'}
            >
              {hasBeenInvited ? t('principals.invite.invited') : t('principals.invite.invite')}
            </Button>
          </Tooltip>
        ) : null}
        <ItemActions
          item={{ id }}
          onDeleteClick={deleteEnabled ? onDelete : undefined}
          onEditClick={onEdit}
          itemType={itemType}
          onInviteClick={onInviteClick}
        />
      </Flex>
    </Flex>
  );
};
