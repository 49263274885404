import type { UpdateUserRequestBody } from '@lama/user-service-client';
import type { UseMutationOptions } from '@tanstack/react-query';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { userServiceClient } from '../../shared/clients/userServiceClient';
import { useAuthentication } from '../authentication/useAuthentication';

interface UpdateUserParams {
  userId: string;
}

export const useUpdateUserMutation = (
  params: Partial<UpdateUserParams>,
  { onSuccess, ...options }: UseMutationOptions<void, unknown, UpdateUserRequestBody> = {},
) => {
  const { token, isAuthenticated } = useAuthentication();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (body: UpdateUserRequestBody) => {
      if (!params.userId || !isAuthenticated) {
        return;
      }

      return userServiceClient.updateUser(params.userId, body, token);
    },
    onSuccess: async (...args) => {
      await queryClient.invalidateQueries({ queryKey: ['user', params.userId] });
      onSuccess?.(...args);
    },
    ...options,
  });
};
