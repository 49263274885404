import type { FC } from 'react';
import React, { useContext, useMemo } from 'react';
import { sortBy } from 'lodash-es';
import type { Alert } from '@lama/alerts-service-client';
import { formatAlertMessage } from '@lama/alerts-service-client';
import { ApplicationContext } from '../../shared/contexts/ApplicationContext';
import { ApplicationAlertView } from './ApplicationAlertView';

interface ApplicationAlertsProps {
  applicationId: string;
  alerts: Alert[];
}

export const ApplicationAlerts: FC<ApplicationAlertsProps> = ({ alerts }) => {
  const {
    application: { id: applicationId },
  } = useContext(ApplicationContext);

  const alertsWithMessages = useMemo(() => {
    const sorted = sortBy(
      alerts,
      (a) => !a.sticky,
      (a) => a.level === 'info',
    );

    return sorted.map((a) => ({ ...a, message: formatAlertMessage(a) }));
  }, [alerts]);

  const firstAlert = useMemo(() => alertsWithMessages[0], [alertsWithMessages]);

  if (!firstAlert) {
    return null;
  }

  return (
    <ApplicationAlertView
      message={firstAlert.message}
      actions={firstAlert.actions ?? []}
      level={firstAlert.level}
      applicationId={applicationId}
    />
  );
};
