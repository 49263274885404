import styled from 'styled-components';
import type { PixelSuffix } from '../../types';
import type { SpinnerProps } from './Spinner.component';

export type Size = 'l' | 'm' | 's' | 'xl' | 'xs';

export type SpinnerSize = PixelSuffix | Size;

const sizes: Record<SpinnerSize, string> = {
  xs: '10px',
  s: '20px',
  m: '30px',
  l: '45px',
  xl: '80px',
};

// eslint-disable-next-line radix
const getRadial = (size?: string) => `${Number.parseInt(size?.replace('px', '') ?? '10', 10) / 7.5}px`;

export const StyledSpinner = styled.div<SpinnerProps>`
  width: ${({ size }) => (size && sizes[size]) ?? size};
  height: ${({ size }) => (size && sizes[size]) ?? size};

  border-radius: 50%;
  background: radial-gradient(farthest-side, ${({ color, theme }) => color ?? theme.colors.primary.main} 94%, #0000) top/
      ${({ size }) => getRadial((size && sizes[size]) ?? size)} ${({ size }) => getRadial((size && sizes[size]) ?? size)} no-repeat,
    conic-gradient(#0000 30%, ${({ color, theme }) => color ?? theme.colors.primary.main});
  mask: radial-gradient(farthest-side, #0000 calc(100% - ${({ size }) => getRadial((size && sizes[size]) ?? size)}), #000 0);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - ${({ size }) => getRadial((size && sizes[size]) ?? size)}), #000 0);
  animation: spinner-c7wet2 1s infinite linear;

  @keyframes spinner-c7wet2 {
    100% {
      transform: rotate(1turn);
    }
  }
`;
