import type { FC } from 'react';
import React from 'react';
import { Flex, greyPalette, Grid, Text } from '@lama/design-system';
import type { Debt } from '@lama/contracts';
import { isNil } from 'lodash-es';
import type { InfoLineProps } from '../../Information';
import { KeyValuePair } from '../../KeyValuePair';

interface DebtItemProps {
  item: Debt;
}

export const DebtItem: FC<DebtItemProps> = ({ item: { type, amount, monthlyPayment, description, willBeRefinanced } }) => {
  const itemData: InfoLineProps[] = [
    ...(!isNil(amount) ? [{ label: 'Outstanding Amount', values: amount, type: 'currency' as const }] : []),
    ...(!isNil(monthlyPayment) ? [{ label: 'Monthly Amount', values: monthlyPayment, type: 'currency' as const }] : []),
    ...(!isNil(willBeRefinanced) ? [{ label: 'Refinanced', values: willBeRefinanced }] : []),
  ];

  return (
    <Flex gap={4} flexDirection={'column'}>
      <Text variant={'body1'} weight={700} color={'secondary'}>
        {type}
      </Text>
      <Grid columns={3}>
        {itemData.map((line) => (
          <KeyValuePair key={line.label} name={line.label} value={line.values} type={line.type} />
        ))}
      </Grid>
      {description ? (
        <Flex flexDirection={'column'} gap={2} minWidth={0}>
          <Text variant={'body2'} color={greyPalette[500]}>
            {'Description'}
          </Text>
          <Flex>
            <Text variant={'body2'}>{description}</Text>
          </Flex>
        </Flex>
      ) : null}
    </Flex>
  );
};
