import type { FC } from 'react';
import React from 'react';
import { Flex, greyPalette, Text } from '@lama/design-system';
import { camelCase } from 'lodash-es';
import type { InformationTypes } from '../Information/Information';
import { Information } from '../Information/Information';

interface KeyValuePairProps {
  name: string;
  value?: any;
  valueColor?: string;
  type?: InformationTypes;
}

export const KeyValuePair: FC<KeyValuePairProps> = ({ name, value, valueColor, type }) => {
  const id = camelCase(name);
  return (
    <Flex flexDirection={'column'} gap={2} minWidth={0}>
      <Flex id={id}>
        <Text variant={'body2'} color={greyPalette[500]}>
          {name}
        </Text>
      </Flex>
      <Flex aria-labelledby={id}>
        <Information value={value} type={type} color={valueColor} />
      </Flex>
    </Flex>
  );
};
