import { Text, greyPalette } from '@lama/design-system';
import styled from 'styled-components';

export const HelperText = styled(Text).attrs({ variant: 'body3' })`
  display: flex;
  flex-direction: column;
  gap: ${({ theme: { space } }) => space[2]}px;
  white-space: pre-wrap;
  color: ${greyPalette[500]};
`;
