/* eslint-disable @typescript-eslint/naming-convention */
import type { FC } from 'react';
import React from 'react';

import { industryTypes } from '@lama/contracts';
import type { FormikInputProps } from './PropertyFormikInput';
import { FormikMultiPicker } from './FormikMultiPicker.js';

export const FormikIndustryTypesPicker: FC<FormikInputProps> = ({ name, ...props }) => (
  <FormikMultiPicker name={name} options={industryTypes} {...props} />
);
