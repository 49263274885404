import { useCallback } from 'react';
import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import type { GetApplicationBankerQueryParams, GetApplicationBankerResponse } from '@lama/clients';
import { applicationServiceClient } from '../../shared/clients/applicationService';
import { useAuthentication } from '../authentication/useAuthentication';

export const useApplicationBankerQuery = (
  applicationId: string | null | undefined,
  queryParams: GetApplicationBankerQueryParams,
  options?: UseQueryOptions<GetApplicationBankerResponse | null>,
) => {
  const { token, isAuthenticated } = useAuthentication();

  const getApplicationQuery = useCallback(async () => {
    if (!applicationId || !isAuthenticated) {
      return null;
    }

    return applicationServiceClient.getApplicationBanker(applicationId, queryParams, token);
  }, [applicationId, isAuthenticated, queryParams, token]);

  const query = useQuery<GetApplicationBankerResponse | null>({
    queryKey: ['applicationBanker', applicationId, queryParams.role],
    queryFn: getApplicationQuery,
    ...options,
  });

  return query;
};
