import type { FC } from 'react';
import React from 'react';
import type { UseOfFundsBreakdown } from '@lama/contracts';
import { formatValue } from '@lama/data-formatters';
import { Flex, Text } from '@lama/design-system';

interface UseOfFundsBreakdownItemProps {
  item: UseOfFundsBreakdown;
}

export const UseOfFundsBreakdownItem: FC<UseOfFundsBreakdownItemProps> = ({ item: { type, amount, description } }) => (
  <Flex flexDirection={'column'} gap={2}>
    <Text variant={'h6'} noWrap>
      {`${type ?? 'Other'}${amount ? ` - ${formatValue(amount, 'currency')}` : ''}`}
    </Text>
    {description ? (
      <Text variant={'body2'} color={'text.secondary'}>
        {description}
      </Text>
    ) : null}
  </Flex>
);
