import React from 'react';
import type { FC } from 'react';
import { Box, Typography } from '@mui/material';

export const Banner: FC<{ color: string; text: string }> = ({ color, text }) => (
  <Box bgcolor={color} borderRadius={'0px 4px 4px 0px'} px={1.5} py={0.5}>
    <Typography variant={'banner'} color={'white'}>
      {text}
    </Typography>
  </Box>
);
