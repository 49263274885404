import { useCallback } from 'react';
import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import type { EvaluatedApplicationRequirement } from '@lama/contracts';
import { getApplicationRequirements } from '../../shared/clients/applicationService';
import { useAuthentication } from '../authentication/useAuthentication';

export const useApplicationRequirementsQuery = (
  applicationId?: string,
  options?: UseQueryOptions<EvaluatedApplicationRequirement[] | null>,
) => {
  const { token, isAuthenticated } = useAuthentication();

  const getRequirementsQuery = useCallback(async () => {
    if (!applicationId || !isAuthenticated) {
      return null;
    }

    return getApplicationRequirements(applicationId, token);
  }, [applicationId, isAuthenticated, token]);

  return useQuery<EvaluatedApplicationRequirement[] | null>({
    queryKey: ['applicationRequirements', applicationId],
    queryFn: getRequirementsQuery,
    ...options,
  });
};
