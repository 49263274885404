import type { FC } from 'react';
import React from 'react';
import type { EvaluatedApplicationRequirement } from '@lama/contracts';
import { amberPalette, colors, greenPalette, greyPalette } from '@lama/design-system';
import { StyledChip } from './StyledChip';

const requirementToIndicationProps = (
  requirement: EvaluatedApplicationRequirement,
): { text: string; color: string; bgcolor: string } | null => {
  if (requirement.submitted) {
    return requirement.isInvalidSubmission
      ? { text: 'Reopened', color: amberPalette[700], bgcolor: amberPalette[50] }
      : { text: 'Submitted', color: greyPalette[500], bgcolor: greyPalette[100] };
  }

  switch (requirement.status) {
    case 'Pending': {
      return { text: 'Incomplete', color: amberPalette[700], bgcolor: amberPalette[50] };
    }
    case 'Complete': {
      return { text: 'Ready', color: colors.success.main, bgcolor: greenPalette[50] };
    }
    case 'Dismissed': {
      return { text: 'Dismissed', color: greyPalette[500], bgcolor: greyPalette[100] };
    }
    default: {
      return null;
    }
  }
};

export const RequirementIndication: FC<{ requirement: EvaluatedApplicationRequirement }> = ({ requirement }) => {
  const chipStyle = requirementToIndicationProps(requirement);
  return chipStyle ? <StyledChip text={chipStyle.text} color={chipStyle.color} bgcolor={chipStyle.bgcolor} /> : null;
};
