import React, { useMemo } from 'react';
import { businessName } from '@lama/data-formatters';
import type { BusinessApiModel } from '@lama/business-service-client';
import { OwnedBusinessCard } from './OwnedBusinessCard';
import type { Owner } from './ownerType';

interface OwnedBusinessListItemProps {
  owner: Owner;
  ownedBusiness: BusinessApiModel;
  onDelete: (id: string) => void;
  onEdit: (id: string) => void;
}

export const OwnedBusinessListItem: React.FC<OwnedBusinessListItemProps> = ({ owner, ownedBusiness, onDelete, onEdit }) => {
  const ownershipPercentage = useMemo(() => {
    switch (owner.entityType) {
      case 'person': {
        return ownedBusiness.people.find(({ id }) => id === owner.entity.id)?.ownershipPercentage ?? null;
      }
      case 'business': {
        return ownedBusiness.owningBusinesses?.find(({ id }) => id === owner.entity.id)?.ownershipPercentage ?? null;
      }
      default: {
        return null;
      }
    }
  }, [owner, ownedBusiness]);

  return (
    <OwnedBusinessCard
      id={ownedBusiness.id}
      ownedBusinessName={businessName(ownedBusiness) ?? ''}
      ownershipPercentage={ownershipPercentage}
      onDelete={onDelete}
      onEdit={onEdit}
    />
  );
};
