import React from 'react';
import type { FC } from 'react';
import { useTheme } from '@mui/material';

export const BusinessIcon: FC<{ size?: number }> = ({ size = 72 }) => {
  const theme = useTheme();

  return (
    <svg width={size} height={size} viewBox={'0 0 72 72'} fill={'none'} xmlns={'http://www.w3.org/2000/svg'}>
      <circle cx={'36'} cy={'36'} r={'36'} fill={theme.palette.primary.light} />
      <mask
        id={'mask0_11584_148664'}
        style={{ maskType: 'alpha' }}
        maskUnits={'userSpaceOnUse'}
        x={'16'}
        y={'16'}
        width={'40'}
        height={'40'}
      >
        <rect x={'16'} y={'16'} width={'40'} height={'40'} fill={'#D9D9D9'} />
      </mask>
      <g mask={'url(#mask0_11584_148664)'}>
        <path
          d={
            'M24.2506 25.5833C23.9173 25.5833 23.6256 25.465 23.3756 25.2283C23.1256 24.9928 23.0006 24.6944 23.0006 24.3333C23.0006 23.9722 23.1256 23.6733 23.3756 23.4367C23.6256 23.2011 23.9173 23.0833 24.2506 23.0833H47.7506C48.084 23.0833 48.3756 23.2011 48.6256 23.4367C48.8756 23.6733 49.0006 23.9722 49.0006 24.3333C49.0006 24.6944 48.8756 24.9928 48.6256 25.2283C48.3756 25.465 48.084 25.5833 47.7506 25.5833H24.2506ZM24.584 48.9167C24.1673 48.9167 23.8134 48.7711 23.5223 48.48C23.2301 48.1878 23.084 47.8333 23.084 47.4167V38.9167H22.584C22.1117 38.9167 21.7162 38.7289 21.3973 38.3533C21.0773 37.9789 20.9729 37.5556 21.084 37.0833L22.7506 29.2917C22.834 28.9306 23.0145 28.6389 23.2923 28.4167C23.5701 28.1944 23.8895 28.0833 24.2506 28.0833H47.7506C48.1117 28.0833 48.4312 28.1944 48.709 28.4167C48.9867 28.6389 49.1673 28.9306 49.2506 29.2917L50.9173 37.0833C51.0284 37.5556 50.9245 37.9789 50.6056 38.3533C50.2856 38.7289 49.8895 38.9167 49.4173 38.9167H48.9173V47.6667C48.9173 48.0278 48.7995 48.3267 48.564 48.5633C48.3273 48.7989 48.0284 48.9167 47.6673 48.9167C47.3062 48.9167 47.0073 48.7989 46.7706 48.5633C46.5351 48.3267 46.4173 48.0278 46.4173 47.6667V38.9167H38.9173V47.4167C38.9173 47.8333 38.7717 48.1878 38.4806 48.48C38.1884 48.7711 37.834 48.9167 37.4173 48.9167H24.584ZM25.584 46.4167H36.4173V38.9167H25.584V46.4167ZM23.7923 36.4167H48.209L46.959 30.5833H25.0423L23.7923 36.4167Z'
          }
          fill={theme.palette.primary.main}
        />
      </g>
    </svg>
  );
};
