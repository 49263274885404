const parseHash = (hash: string) => new URLSearchParams(hash.slice(1));

export const getParamsFromRedirectUrl = (hash: string, pathname: string) => {
  let targetUrl: string | undefined;
  let token: string | null | undefined;
  const parsedHash = parseHash(hash);

  if (pathname === '/auth' && parsedHash.has('access_token')) {
    token = parsedHash.get('access_token');

    const state = parsedHash.get('state');
    if (state?.startsWith('targetUrl')) {
      [, targetUrl] = decodeURIComponent(state).split(':');
      targetUrl = targetUrl.startsWith('/') ? targetUrl : `/${targetUrl}`;
    }
  }

  return { targetUrl, token };
};
