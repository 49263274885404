/* eslint-disable @typescript-eslint/naming-convention */
import type { FC, HTMLAttributes } from 'react';
import React, { useCallback, useMemo } from 'react';
import type { AutocompleteRenderGetTagProps, AutocompleteRenderInputParams, FilterOptionsState } from '@mui/material';
import { createFilterOptions, Autocomplete, TextField, Chip, Typography } from '@mui/material';
import type { LabeledValue } from '@lama/contracts';
import { ChipDelete } from './ChipDelete.js';

const defaultRenderInput = (params: AutocompleteRenderInputParams) => <TextField {...params} />;

const defaultRenderOptions = (props: HTMLAttributes<HTMLLIElement>, option: LabeledValue) => (
  <Typography {...props}>{option.label}</Typography>
);

const defaultRenderTags = (tagValue: LabeledValue[], getTagProps: AutocompleteRenderGetTagProps) =>
  tagValue.map((option, index) => (
    <Chip
      color={'primary'}
      variant={'outlined'}
      label={option.label}
      {...getTagProps({ index })}
      deleteIcon={<ChipDelete />}
      key={option.value}
    />
  ));

const filter = createFilterOptions<LabeledValue>({
  stringify: (option) => option.label,
});

export interface AutocompleteMultiPickerProps {
  value?: string[];
  options: LabeledValue[] | string[] | readonly string[];
  onChange: (values: string[]) => void;
  renderInput?: (params: AutocompleteRenderInputParams) => React.ReactNode;
  maxItems?: number;
  allowFreeformOptions?: boolean;
}

export const AutocompleteMultiPicker: FC<AutocompleteMultiPickerProps> = ({
  value,
  onChange,
  options,
  renderInput,
  maxItems = Number.POSITIVE_INFINITY,
  allowFreeformOptions = false,
}) => {
  const handleChange = useCallback(
    (_: any, values: (LabeledValue | string)[]) => {
      onChange(values.map((v) => (typeof v === 'string' ? v : v.value)));
    },
    [onChange],
  );

  const innerOptions = useMemo(() => options.map((o) => (typeof o === 'string' ? { value: o, label: o } : o)), [options]);

  const innerValue: LabeledValue[] | undefined = useMemo(
    () => value?.map((v) => innerOptions.find((o) => o.value === v) ?? { value: v, label: v }),
    [innerOptions, value],
  );

  const checkDisable = useCallback(
    (option: LabeledValue) => (value?.length ?? 0) >= maxItems && !value?.includes(option.value),
    [maxItems, value],
  );

  const filterOptions = useCallback(
    (opts: LabeledValue[], params: FilterOptionsState<LabeledValue>) => {
      const filtered = filter(opts, params);

      const { inputValue } = params;

      const optionExists = opts.some((option) => inputValue === option.label);
      if (allowFreeformOptions && inputValue && !optionExists) {
        filtered.push({
          value: inputValue,
          label: `Add "${inputValue}"`,
        });
      }

      return filtered;
    },
    [allowFreeformOptions],
  );

  return (
    <Autocomplete
      getOptionDisabled={checkDisable}
      fullWidth
      multiple
      freeSolo={allowFreeformOptions}
      clearOnEscape
      autoComplete
      selectOnFocus
      handleHomeEndKeys
      value={innerValue}
      renderTags={defaultRenderTags}
      renderInput={renderInput ?? defaultRenderInput}
      options={innerOptions}
      renderOption={defaultRenderOptions}
      onChange={handleChange}
      filterOptions={filterOptions}
      ListboxProps={{
        style: {
          maxHeight: '160px',
        },
      }}
      limitTags={1}
      sx={{
        '.MuiAutocomplete-inputRoot': {
          flexWrap: 'nowrap !important',
        },
        '.Mui-focused': {
          flexWrap: 'wrap !important',
        },
      }}
    />
  );
};
