/* eslint-disable react/no-multi-comp */
import React from 'react';
import { InputAdornment } from '@mui/material';
import type { FormikInputProps } from './PropertyFormikInput.js';
import { PropertyFormikInput } from './PropertyFormikInput.js';
import { CustomNumericFormat } from './customNumericFormat.js';

export const FormikMoneyInputField: React.FC<FormikInputProps> = ({ InputProps, ...props }) => (
  <PropertyFormikInput
    {...props}
    InputProps={{
      ...InputProps,
      startAdornment: <InputAdornment position={'start'}>{'$'}</InputAdornment>,
      inputComponent: CustomNumericFormat,
    }}
  />
);
