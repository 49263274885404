import React from 'react';
import { useTheme, Typography } from '@mui/material';
import type { FC } from 'react';
import { Flex } from '@lama/design-system';

const InfoLine: FC<{ label: string; value: string }> = ({ label, value }) => {
  const theme = useTheme();

  return (
    <Flex justifyContent={'space-between'} flexDirection={'row'} width={'100%'}>
      <Typography variant={'helperText'} color={theme.palette.text.secondary} flex={0.5}>
        {label}
      </Typography>
      <Typography variant={'body2'} color={theme.palette.text.primary} flex={0.5}>
        {value}
      </Typography>
    </Flex>
  );
};

export const InformationStack: FC<{ infoLines: { label: string; value: string }[] }> = ({ infoLines }) => (
  <Flex flexDirection={'column'} gap={2}>
    {infoLines.map(({ label, value }) => (
      <InfoLine key={label} label={label} value={value} />
    ))}
  </Flex>
);
