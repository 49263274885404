import React from 'react';
import { ProgressBar } from '@react-pdf-viewer/core';
import { Flex, Text } from '@lama/design-system';
import styled from 'styled-components';

const MiddleOfScreenContainer = styled(Flex)`
  position: absolute;
`;

export const PDFLoadingState = (percentages: number) => (
  <Flex width={'100%'} height={'100%'} justifyContent={'center'} alignItems={'center'} position={'relative'} backgroundColor={'grey.100'}>
    <MiddleOfScreenContainer flexDirection={'column'} gap={2} height={'100%'} justifyContent={'center'}>
      <Flex flexDirection={'column'} alignItems={'center'} width={'100%'}>
        <Text variant={'body1'}>{'Getting your document...'}</Text>
      </Flex>
      <ProgressBar progress={Math.round(percentages)} />
    </MiddleOfScreenContainer>
  </Flex>
);
