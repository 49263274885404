import type { FC } from 'react';
import React, { useCallback, useState } from 'react';
import { Flex } from '@lama/design-system';
import { LoadingPage } from '../LoadingPage';

export const ImageDocumentViewer: FC<{ documentUrl: string }> = ({ documentUrl }) => {
  const [imageLoading, setImageLoading] = useState(true);

  const onLoad = useCallback(() => {
    setImageLoading(false);
  }, []);

  return (
    <Flex alignItems={'center'} justifyContent={'center'} height={'100%'} width={'100%'}>
      {imageLoading ? <LoadingPage /> : null}
      <img style={{ maxHeight: '100%', maxWidth: '100%' }} src={documentUrl} onLoad={onLoad} hidden={imageLoading} />
    </Flex>
  );
};
