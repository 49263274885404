/* eslint-disable react/no-multi-comp */
// eslint-disable-next-line import/no-extraneous-dependencies
import RCTree from 'rc-tree';
import React, { useCallback, useMemo, useState } from 'react';
import type { FC, PropsWithChildren } from 'react';
import type { TreeProps as RcTreeProps, TreeNodeProps } from 'rc-tree';
import './tree.css';
import Fuse from 'fuse.js';
import type { Key } from 'rc-tree/lib/interface';
import { compact, flatMap, isNil } from 'lodash-es';
import type { NodeMouseEventHandler } from 'rc-tree/lib/contextTypes';
import styled from 'styled-components';
import { Text } from '../Text/Text.component';
import { Flex } from '../Flex';
import { getDescendantKeys, getNodeAncestors } from './treeUtils';
import type { DataNode } from './types';
import { ArrowDownIcon, ArrowRightIcon } from './TreeIcons';

export const DefaultTreeItem = styled(Flex)<{ nodeData: DataNode }>`
  cursor: ${({ nodeData }) => (nodeData.children ? 'pointer' : 'default')};
  user-select: none;
  display: inline-flex;
  width: 100%;
`;

export const DefaultRenderItem: FC<DataNode> = (nodeData) => (
  <DefaultTreeItem pr={4} pl={2} py={1} my={2} alignItems={'center'} nodeData={nodeData}>
    <Text variant={'body1'}>{nodeData?.title?.toString()}</Text>
  </DefaultTreeItem>
);

export interface CustomTreeProps extends Omit<RcTreeProps, 'onClick' | 'prefixCls' | 'treeData'> {
  disableArrow?: boolean;
  renderItem?: (node: DataNode) => React.ReactNode;
  filterValue?: string;
  treeData?: DataNode[];
  onClickItem?: (nodeData: DataNode) => void;
}

const flattenTreeData = (nodes: DataNode[]): DataNode[] => {
  const flatList: DataNode[] = [];

  const traverse = (nodeList: DataNode[], parent?: DataNode) => {
    nodeList.forEach((node) => {
      const nodeWithParent = { ...node, parent };
      flatList.push(nodeWithParent);
      if (node.children) {
        traverse(node.children as DataNode[], nodeWithParent);
      }
    });
  };

  traverse(nodes);

  return flatList;
};

export const Tree: FC<PropsWithChildren<CustomTreeProps>> = ({
  disableArrow,
  renderItem,
  filterValue,
  children,
  motion = true,
  treeData = [],
  autoExpandParent = true,
  onClickItem,
  ...props
}) => {
  const [userExpandedKeys, setUserExpandedKeys] = useState<string[]>([]);

  const onClickInner: NodeMouseEventHandler<DataNode> = useCallback(
    (_event, node) => {
      if (node.children?.length) {
        const updatedExpandedKeys = userExpandedKeys.includes(node.key.toString())
          ? userExpandedKeys.filter((key) => key !== node.key.toString())
          : [...userExpandedKeys, node.key.toString()];

        setUserExpandedKeys(updatedExpandedKeys);

        return;
      }
      onClickItem?.(node);
    },
    [onClickItem, userExpandedKeys],
  );

  const switcherIcon = useCallback(
    ({ expanded, isLeaf, title }: TreeNodeProps) => {
      if (disableArrow ?? isLeaf) {
        return null;
      }
      const testId = `${title?.toString()}-expand`;

      return expanded ? <ArrowDownIcon data-testid={testId} /> : <ArrowRightIcon data-testid={testId} />;
    },
    [disableArrow],
  );

  const itemRenderer = useCallback((nodeData: DataNode) => (renderItem ?? DefaultRenderItem)(nodeData), [renderItem]);

  const flatData = useMemo(() => flattenTreeData(treeData), [treeData]);

  const keyToNodeMap: Record<string, DataNode> = useMemo(() => Object.fromEntries(flatData.map((node) => [node.key, node])), [flatData]);

  const fuse = useMemo(
    () =>
      new Fuse(flatData, {
        keys: ['title'],
        threshold: 0.3,
      }),
    [flatData],
  );

  const { filteredData, filterExpandedKeys } = useMemo(() => {
    if (!filterValue) {
      return { filteredData: treeData, filterExpandedKeys: [] };
    }

    const results = fuse.search(filterValue).map((result) => result.item);
    const resultKeys = new Set(results.map((node) => node.key));
    const expandedKeySet = new Set<string>(flatMap(results, (node) => getNodeAncestors(node, keyToNodeMap)));

    const filterNodes = (nodes: DataNode[]): DataNode[] =>
      compact(
        nodes.map((node) => {
          if (resultKeys.has(node.key)) {
            return node;
          }

          if (node.children) {
            const filteredChildren = filterNodes(node.children as DataNode[]);
            if (filteredChildren.length > 0) {
              return { ...node, children: filteredChildren };
            }
          }

          return null;
        }),
      );

    const filteredNodes = filterNodes(treeData);

    return {
      filteredData: filteredNodes,
      filterExpandedKeys: [...expandedKeySet],
    };
  }, [filterValue, fuse, treeData, keyToNodeMap]);

  const expandedKeys = useMemo(() => {
    if (!filterValue) {
      return userExpandedKeys;
    }
    return [...new Set([...filterExpandedKeys, ...userExpandedKeys])];
  }, [filterValue, filterExpandedKeys, userExpandedKeys]);

  const innerAutoExpandParent = useMemo(
    () => (!isNil(autoExpandParent) ? autoExpandParent : !!filterValue),
    [autoExpandParent, filterValue],
  );

  const onExpand = useCallback(
    (keys: Key[], { expanded, node }: { expanded: boolean; node: DataNode }) => {
      if (!expanded) {
        const collapsedKey = node.key;
        const descendantKeys = getDescendantKeys(collapsedKey, keyToNodeMap);
        const updatedExpandedKeys = keys.filter((key) => !descendantKeys.includes(key));

        setUserExpandedKeys(updatedExpandedKeys as string[]);
      } else {
        setUserExpandedKeys(keys as string[]);
      }
    },
    [keyToNodeMap],
  );

  return (
    <RCTree
      switcherIcon={switcherIcon}
      titleRender={itemRenderer}
      expandedKeys={expandedKeys}
      motion={motion}
      onExpand={onExpand}
      autoExpandParent={innerAutoExpandParent}
      {...props}
      style={{ userSelect: 'none' }}
      treeData={filteredData}
      onClick={onClickInner}
    >
      {children}
    </RCTree>
  );
};

Tree.displayName = 'Tree';
