import React from 'react';
import type { FC } from 'react';
import { ModifyItemButton } from '@lama/app-components';
import { Flex } from '@lama/design-system';
import { ConnectedAccountCard } from './ConnectedAccountCard';

export interface Account {
  id: string;
  title: string;
  name: string[];
  logo?: string;
}
interface ConnectedAccountsViewProps {
  accounts: Account[];
  deleteAccount?: (accountId: string) => void;
  addAccount?: () => void;
  addAccountText?: string;
}

export const ConnectedAccountsView: FC<ConnectedAccountsViewProps> = ({ accounts, deleteAccount, addAccount, addAccountText }) => (
  <Flex flexDirection={'column'} gap={8} alignItems={'center'}>
    <Flex flexDirection={'column'} gap={4} width={'90%'}>
      {accounts.map((account) => (
        <ConnectedAccountCard
          key={account.id}
          accountId={account.id}
          accountTitle={account.title}
          deleteEnabled={!!deleteAccount}
          serviceFirstName={account.name[0]}
          serviceLastName={account.name[1]}
          serviceLogo={account.logo}
          deleteAccount={deleteAccount}
        />
      ))}
    </Flex>
    {addAccount && addAccountText ? <ModifyItemButton text={addAccountText} onClick={addAccount} /> : null}
  </Flex>
);
