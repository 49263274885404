import { useMutation } from '@tanstack/react-query';
import { displayToast } from '@lama/app-components';
import type { CreateBusinessModelApi , DataProviderCredentials } from '@lama/business-service-client';
import { useAuthentication } from '../authentication/useAuthentication';
import { businessServiceClient } from '../../shared/clients/businessService';

export const useCreateBusinessMutation = () => {
  const { token, isAuthenticated } = useAuthentication();

  return useMutation({
    mutationFn: async ({
      business,
      dataProvidersCredentials,
      applicationId,
    }: {
      business: CreateBusinessModelApi;
      dataProvidersCredentials?: DataProviderCredentials[];
      applicationId: string;
    }) => {
      if (isAuthenticated) {
        await businessServiceClient.createBusiness({ business, dataProvidersCredentials, applicationId, token });
      }
    },
    onError: () => {
      displayToast('Something went wrong while creating the business. Please contact support.', 'error');
    },
  });
};
