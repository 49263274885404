import type { UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import type { ProductPublicData } from '@lama/contracts';
import { productServiceClient } from '../../shared/clients/productService';

export const useProductsByPartnerQuery = (partner: string | undefined): UseQueryResult<ProductPublicData[] | null> => {
  const getProductsQuery = async () => {
    if (!partner) {
      return null;
    }

    const products = await productServiceClient.getPublicProductsData(partner);

    return products;
  };

  const query = useQuery({ queryKey: ['products', partner], queryFn: getProductsQuery });

  return query;
};
