import SearchIcon from '@mui/icons-material/Search';
import { TextField, InputAdornment } from '@mui/material';
import { Button, Flex, Spinner, Text, colors } from '@lama/design-system';
import type { FC } from 'react';
import React, { useState, useCallback, useContext, useMemo } from 'react';
import styled from 'styled-components';
import { SearchResult } from './RelationsListOptionButtons';
import { SearchExistingCustomersContext } from './searchExistingCustomersContext';
import { EmptySearchResultsImage } from './assets/EmptySearchResultsImage';

interface SearchResultsListProps {
  onEntitySelected?: (selector: string) => void;
}
const SearchResultsListContainer = styled(Flex)`
  max-height: 400px;
  flex-direction: column;
  position: relative;
  &:after {
    content: '';
    display: block;
    min-height: 50px;
    top: 90%;
    background: linear-gradient(rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%);
    position: absolute;
    width: 100%;
  }
`;
const SearchResultsList = ({ onEntitySelected }: SearchResultsListProps) => {
  const { searchResults, isSaving, searchTerm } = useContext(SearchExistingCustomersContext);
  const people = useMemo(() => searchResults?.filter((s) => s.entityType === 'person'), [searchResults]);
  const businesses = useMemo(() => searchResults?.filter((s) => s.entityType === 'business'), [searchResults]);
  const [selectedEntityId, setSelectedEntityId] = useState<string | null>(null);
  const onClickCreator = useCallback(
    (id: string) => () => {
      if (selectedEntityId === id) {
        onEntitySelected?.('');
        setSelectedEntityId(null);
        return;
      }
      onEntitySelected?.(id);
      setSelectedEntityId(id);
    },
    [setSelectedEntityId, selectedEntityId, onEntitySelected],
  );

  if (!people?.length && !businesses?.length) {
    if (!searchTerm) {
      return null;
    }
    return (
      <Flex gap={3} justifyContent={'center'} alignItems={'center'} flexDirection={'column'}>
        <EmptySearchResultsImage />
        {searchTerm ? <Text variant={'body3'} color={'secondary'}>{`No search results for ${searchTerm}`}</Text> : null}
      </Flex>
    );
  }
  if (isSaving) {
    return <Spinner />;
  }
  return (
    <SearchResultsListContainer>
      <Flex pb={8} fullWidth overflow={'scroll'} flexDirection={'column'} gap={4}>
        {people?.length ? (
          <Flex flexDirection={'column'} gap={2}>
            <Text color={colors.grey['600']} variant={'body3'}>
              {'People'}
            </Text>
            {people.map((person) => (
              <SearchResult
                key={person.taxId}
                entityType={'person'}
                name={person.name}
                taxId={person.taxId}
                extraDetail={person.extraDetail}
                onClick={onClickCreator(person.taxId)}
                isSelected={selectedEntityId === person.taxId}
              />
            ))}
          </Flex>
        ) : null}
        {businesses?.length ? (
          <Flex flexDirection={'column'} gap={2}>
            <Text color={colors.grey['600']} variant={'body3'}>
              {'Businesses'}
            </Text>
            {businesses.map((business) => (
              <SearchResult
                key={business.taxId}
                entityType={'business'}
                name={business.name}
                taxId={business.taxId}
                extraDetail={business.extraDetail}
                onClick={onClickCreator(business.taxId)}
                isSelected={selectedEntityId === business.taxId}
              />
            ))}
          </Flex>
        ) : null}
      </Flex>
    </SearchResultsListContainer>
  );
};

interface SearchExistingCustomerProps {
  onBack: () => void;
  onClose: () => void;
  onSearchResultConverted?: (entity: any) => void;
}

export const SearchExistingCustomer: FC<SearchExistingCustomerProps> = ({
  onBack,
  onClose,
  onSearchResultConverted,
}: SearchExistingCustomerProps) => {
  const { onSearchTermChange, onSearchResultSelect, searchTerm, isSaving } = useContext(SearchExistingCustomersContext);
  const [selectedEntity, setSelectedEntity] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const onEntitySelected = useCallback((entity: string) => {
    if (!entity?.length) {
      setSelectedEntity(null);
      return;
    }
    setSelectedEntity(entity);
  }, []);
  const onAddClick = useCallback(async () => {
    if (!selectedEntity || !onSearchResultSelect) {
      return;
    }
    const entity = await onSearchResultSelect(selectedEntity);
    if (entity) {
      onSearchResultConverted?.(entity);
      return;
    }

    onClose?.();
  }, [selectedEntity, onSearchResultSelect, onClose, onSearchResultConverted]);
  const searchTermChangedHandler = useCallback(
    (currentSearchTerm: string) => {
      if (!onSearchTermChange) {
        return;
      }
      setIsLoading(true);
      onSearchTermChange(currentSearchTerm);
      setIsLoading(false);
    },
    [onSearchTermChange],
  );

  const onTextFieldChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      searchTermChangedHandler(event.target.value);
    },
    [searchTermChangedHandler],
  );
  return (
    <Flex fullWidth minHeight={'100%'} gap={6} justifyContent={'space-between'} flexDirection={'column'}>
      <Flex flexDirection={'column'}>
        <TextField
          InputProps={{
            startAdornment: (
              <InputAdornment position={'start'}>
                <SearchIcon sx={{ height: '20px', width: '20px' }} />
              </InputAdornment>
            ),
          }}
          sx={{
            // eslint-disable-next-line @typescript-eslint/naming-convention
            '& fieldset': { borderColor: 'rgba(0, 0, 0, 0.23) !important', borderWidth: '1px !important' },
          }}
          autoFocus
          value={searchTerm}
          fullWidth
          onChange={onTextFieldChange}
          placeholder={'Search for existing customer'}
          variant={'outlined'}
          autoComplete={'off'}
        />
        <Text variant={'body3'} color={'secondary'}>
          {'Note: Customer data syncs once a day.'}
        </Text>
      </Flex>
      {isLoading || isSaving ? (
        <Flex alignSelf={'center'}>
          <Spinner />
        </Flex>
      ) : (
        <SearchResultsList onEntitySelected={onEntitySelected} />
      )}
      <Flex marginTop={'auto'} pb={4} pt={8} fullWidth justifyContent={'space-between'}>
        <Button onClick={onBack} variant={'tertiary'}>
          {'Back'}
        </Button>
        <Button loading={isSaving} disabled={!selectedEntity} onClick={onAddClick} variant={'primary'}>
          {'Add'}
        </Button>
      </Flex>
    </Flex>
  );
};
