import type { FC } from 'react';
import React, { useCallback, useState } from 'react';
import type { FormikHelpers, FormikProps, FormikValues } from 'formik';
import { Formik } from 'formik';
import { ConfirmLeave } from '@lama/app-components';

interface BasicFormProps {
  initialValues: FormikValues;
  validationSchema?: any;
  submitCallback: (values: any) => void;
  children: (props: FormikProps<any> & { submitted: boolean }) => React.ReactNode;
}

export const BasicForm: FC<BasicFormProps> = ({ initialValues, validationSchema, submitCallback, children }) => {
  const [submitted, setSubmitted] = useState(false);

  const onSubmit = useCallback(
    (values: any, { resetForm }: FormikHelpers<any>) => {
      submitCallback(values);
      setSubmitted(true);
      resetForm({ values });
    },
    [submitCallback],
  );

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {(formikProps) => <ConfirmLeave shouldBlock={formikProps.dirty}>{children({ submitted, ...formikProps })}</ConfirmLeave>}
    </Formik>
  );
};
