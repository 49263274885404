/* eslint-disable @typescript-eslint/naming-convention */
import type { FC } from 'react';
import React, { useCallback, useMemo } from 'react';
import { useField, useFormikContext } from 'formik';
import { TextField } from '@mui/material';
import type { LabeledValue } from '@lama/contracts';
import type { FormikInputProps } from './PropertyFormikInput';
import { AutocompleteMultiPicker } from './AutocompleteMultiPicker';
import { FormikMultiSourceHelperText } from './HelperTexts/FormikMultiSourceHelperText';
import { HelperText } from './HelperTexts/HelperText';
import { useSelectedPropertyValue } from './hooks/useSelectedValueSource';

export const FormikMultiPicker: FC<
  FormikInputProps & { options: LabeledValue[] | string[] | readonly string[]; maxItems?: number; allowFreeformOptions?: boolean }
> = ({ name, options, maxItems, allowFreeformOptions, sourcesValues, helperText, ...props }) => {
  const { submitCount } = useFormikContext();
  const [{ value: fieldValue }, { error, touched }, { setValue: setFieldValue }] = useField(name);

  const selectedPropertyValue = useSelectedPropertyValue(sourcesValues, fieldValue);

  const inputError = useMemo(() => (!!touched || submitCount > 0) && Boolean(error), [error, submitCount, touched]);
  const inputHelperText = useMemo(() => {
    if (inputError) {
      return error;
    }

    return (
      <HelperText>
        <FormikMultiSourceHelperText selectedPropertyValue={selectedPropertyValue} sourcesValues={sourcesValues} />
        {helperText}
      </HelperText>
    );
  }, [error, helperText, inputError, selectedPropertyValue, sourcesValues]);

  const handleChange = useCallback(
    async (values: string[]) => {
      void setFieldValue(values);
    },
    [setFieldValue],
  );

  const customRenderInput = useCallback(
    (params: any) => <TextField {...params} {...props} helperText={inputHelperText} error={inputError} />,
    [inputError, inputHelperText, props],
  );

  return (
    <AutocompleteMultiPicker
      value={fieldValue}
      options={options}
      onChange={handleChange}
      renderInput={customRenderInput}
      maxItems={maxItems}
      allowFreeformOptions={allowFreeformOptions}
    />
  );
};
