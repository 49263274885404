import React, { useCallback } from 'react';
import { ArbitraryDocumentUploadButton } from '@lama/app-components';
import type { FC } from 'react';
import type { Entity } from '@lama/common-types';
import { useUploadDocumentMutation } from '../../../../hooks/react-query/useUploadDocumentMutation';

interface UploadArbitraryDocumentButtonProps {
  applicationId: string;
  entityId: string;
  entityType: Entity;
  requirementId?: string;
  sharedRequirementId?: string;
  requirementKey?: string;
  extractable?: boolean;
  relatedItemId?: string;
  relatedItemType?: string;
}

export const UploadArbitraryDocumentButton: FC<UploadArbitraryDocumentButtonProps> = ({
  applicationId,
  entityId,
  entityType,
  requirementId,
  sharedRequirementId,
  requirementKey,
  extractable,
  relatedItemId,
  relatedItemType,
}) => {
  const { mutateAsync: uploadDocument, isPending: uploadingDocument } = useUploadDocumentMutation(applicationId);

  const uploadDocumentsCallback = useCallback(
    async (files: FileList) => {
      await Promise.all(
        [...files].map((file) =>
          uploadDocument({
            applicationId,
            description: '',
            entityId,
            entityType,
            file,
            topic: null,
            requirementId,
            sharedRequirementId,
            requirementKey,
            extractable,
            relatedItemId,
            relatedItemType,
          }),
        ),
      );
    },
    [
      uploadDocument,
      applicationId,
      entityId,
      entityType,
      requirementId,
      sharedRequirementId,
      requirementKey,
      extractable,
      relatedItemId,
      relatedItemType,
    ],
  );

  return <ArbitraryDocumentUploadButton onFilesSelected={uploadDocumentsCallback} uploading={uploadingDocument} />;
};
