import type { FC } from 'react';
import React, { useContext, useMemo } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useRecoilValue } from 'recoil';
import { Text, Flex } from '@lama/design-system';
import type { DecisionReason, ProductPublicData } from '@lama/contracts';
import { creditProviderToContactData } from '@lama/credit-report-retriever-client';
import type { ApplicationApiModel } from '@lama/clients';
import { personFullName, formatValue } from '@lama/data-formatters';
import { partnerState } from '../../state/appState';
import { UserDetailsContext } from '../../shared/contexts/UserDetailsContext';
import { SimpleHeader } from '../shared/SimpleHeader';
import { useGetApplicationRejectionDecisionQuery } from './hooks/useGetApplicationRejectionDecisionQuery';
import { customRejectionScreens } from './customRejectionScreens';

export interface ApplicationRejectedScreenProps {
  application: ApplicationApiModel;
  product: ProductPublicData;
}

export const ApplicationRejectedScreen: FC<ApplicationRejectedScreenProps> = ({ application, product }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const partner = useRecoilValue(partnerState);
  const { data: rejectionDecision } = useGetApplicationRejectionDecisionQuery(application.id);
  const userContext = useContext(UserDetailsContext);

  const isCreditRejection = useMemo(() => rejectionDecision?.reasons?.some((r) => r.type === 'Credit'), [rejectionDecision]);

  const personName = useMemo(
    () =>
      userContext?.firstName && userContext?.lastName
        ? personFullName(userContext as { firstName: string; middleName?: string; lastName: string })
        : 'applicant',
    [userContext],
  );

  const creditProviderContactData = useMemo(
    () => (partner?.creditProvider ? creditProviderToContactData[partner.creditProvider] : null),
    [partner],
  );

  const shouldShowCreditProviderContactData = useMemo(
    () => isCreditRejection && partner?.creditProvider && creditProviderContactData,
    [isCreditRejection, partner, creditProviderContactData],
  );
  const CustomRejectionScreen = useMemo(
    () => (partner?.customRejectionScreen ? customRejectionScreens[partner?.customRejectionScreen] : null),
    [partner],
  );
  if (CustomRejectionScreen) {
    return <CustomRejectionScreen application={application} product={product} />;
  }
  return (
    <Flex width={'100%'} height={'100%'} flexDirection={'column'}>
      <SimpleHeader showAccountSettings />
      <Flex
        flexDirection={'column'}
        gap={10}
        alignItems={'center'}
        flex={isMobile ? 1 : 0.7}
        color={grey[500]}
        py={20}
        px={isMobile ? 0 : 4}
        justifyContent={'center'}
      >
        <Flex flexDirection={'column'} gap={8} alignItems={'center'} justifyContent={'center'}>
          <Text variant={'h4'} color={'black'} textAlign={'center'}>
            {"You don't meet the loan requirements"}
          </Text>
          <Flex
            flexDirection={'column'}
            gap={4}
            justifyContent={'center'}
            borderRadius={'8px'}
            border={isMobile ? 'none' : '1px solid #E0E0E0'}
            p={isMobile ? 0 : 8}
            width={isMobile ? '85%' : '75%'}
          >
            <Text variant={'body2'} color={'secondary'} textAlign={isMobile ? 'center' : 'inherit'}>
              {`Dear ${personName},`}
            </Text>
            <Text variant={'body2'} color={'secondary'} textAlign={isMobile ? 'center' : 'inherit'}>
              {`Thank you for applying for a ${product.prettyName}${product.prettyName.includes('Loan') ? '' : ' Loan'} for ${formatValue(
                application.requestedAmount ?? 0,
                'currencyCompact',
              )}. However, we regret to advise you that we cannot grant your request at this time. Below are the specific reasons why your application was denied:`}
            </Text>
            <Text variant={'body2'} color={'secondary'} textAlign={isMobile ? 'center' : 'inherit'}>
              {rejectionDecision?.reasons?.map((reason: DecisionReason) => <li key={reason.id}>{reason.text}</li>) ?? []}
            </Text>
            {shouldShowCreditProviderContactData ? (
              <Flex gap={1} flexDirection={'column'}>
                <Text variant={'body2'} color={'secondary'}>
                  {`If you have any questions regarding your credit report or credit score, you should contact ${partner?.creditProvider} at:`}
                </Text>
                <Text variant={'body2'} color={'secondary'}>
                  {creditProviderContactData?.address.address1}
                </Text>
                <Text variant={'body2'} color={'secondary'}>
                  {`${creditProviderContactData?.address.city ?? ''}, ${creditProviderContactData?.address.state ?? ''} ${
                    creditProviderContactData?.address.zip ?? ''
                  }`}
                </Text>
                <Text variant={'body2'} color={'secondary'}>
                  {creditProviderContactData?.phone}
                </Text>
                <Text variant={'body2'} color={'secondary'}>
                  {creditProviderContactData?.website}
                </Text>
              </Flex>
            ) : null}
            <Text variant={'body2'} color={'secondary'} textAlign={isMobile ? 'center' : 'inherit'}>
              {`Sincerely, ${partner?.displayName}`}
            </Text>
            <Text variant={'body2'} color={'grey.500'} textAlign={isMobile ? 'center' : 'inherit'}>
              {partner?.branding.communication.rejectionEmailFooter}
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
