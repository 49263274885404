import type { UseMutationOptions } from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import type { VerifyOtpRequestBody, VerifyOtpResponse } from '@lama/user-service-client';
import { userServiceClient } from '../../shared/clients/userServiceClient';

interface VerifyOtpParams {
  verifyOtpRequest: VerifyOtpRequestBody;
}

export const useVerifyOtpMutation = (options?: UseMutationOptions<VerifyOtpResponse, unknown, VerifyOtpParams>) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ verifyOtpRequest }: VerifyOtpParams) => userServiceClient.verifyOtp(verifyOtpRequest),
    ...options,
    onSuccess: async (data, variables, context) => {
      await Promise.all([
        options?.onSuccess?.(data, variables, context),
        queryClient.invalidateQueries({
          queryKey: ['applicationRequirements'],
        }),
        queryClient.invalidateQueries({
          queryKey: ['user'],
        }),
      ]);
    },
  });
};
