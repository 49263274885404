import { displayToast } from '@lama/app-components';
import type { UpdateApplicationRelationParams } from '@lama/clients';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { applicationServiceClient } from '../../shared/clients/applicationService';
import { useAuthentication } from '../authentication/useAuthentication';

export const useAddApplicationRelation = () => {
  const queryClient = useQueryClient();
  const { token, isAuthenticated } = useAuthentication();

  return useMutation({
    mutationFn: async ({ applicationId, relation, entityId, entityType }: UpdateApplicationRelationParams) => {
      if (isAuthenticated) {
        await applicationServiceClient.addApplicationRelatedEntity({ applicationId, relation, entityId, entityType }, token);
      }
      return { applicationId };
    },
    onSuccess: async (params) => {
      if (params?.applicationId) {
        await Promise.all([
          queryClient.invalidateQueries({
            queryKey: ['application', params.applicationId],
          }),
          queryClient.invalidateQueries({
            queryKey: ['applicationRequirements', params.applicationId],
          }),
        ]);
      }
    },
    onError: () => {
      displayToast('Something went wrong while updating the application. Please contact support.', 'error');
    },
  });
};
