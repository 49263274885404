import React, { useMemo } from 'react';
import type { EvaluatedApplicationRequirement } from '@lama/contracts';
import { DismissedRequirementCard } from './DismissedRequirementCard';
import { FulfilledRequirementCard } from './FulfilledRequirementCard';
import { UnfulfilledRequirementCard } from './UnfulfilledRequirementCard';
import { SubmittedRequirementCard } from './SubmittedRequirementCard';

export interface RequirementCardWrapperProps {
  requirement: EvaluatedApplicationRequirement;
}

export interface RequirementCardProps {
  requirement: EvaluatedApplicationRequirement;
}

const getRequirementCard = (requirement: EvaluatedApplicationRequirement): React.FC<RequirementCardProps> | null => {
  if (requirement.submitted) {
    return requirement.isInvalidSubmission ? FulfilledRequirementCard : SubmittedRequirementCard;
  }

  switch (requirement.status) {
    case 'Pending': {
      return UnfulfilledRequirementCard;
    }
    case 'Complete': {
      return FulfilledRequirementCard;
    }
    case 'Dismissed': {
      return DismissedRequirementCard;
    }
    default: {
      return null;
    }
  }
};

export const RequirementCardWrapper: React.FC<RequirementCardWrapperProps> = ({ requirement }) => {
  const Component = useMemo(() => getRequirementCard(requirement), [requirement]);

  return Component && <Component requirement={requirement} />;
};
