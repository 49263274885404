import React from 'react';
import { useMediaQuery, useTheme } from '@mui/material';

export const MultiPeopleIcon: React.FC<{ selected: boolean }> = ({ selected }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const height = isMobile ? 78 : 88;
  const width = isMobile ? 82 : 90;

  return (
    <svg width={width} height={height} viewBox={'0 0 90 88'} fill={'none'} xmlns={'http://www.w3.org/2000/svg'}>
      <g clipPath={'url(#clip0_9574_16835)'}>
        <path
          d={
            'M71.4551 30.6325H18.2356C12.3659 30.6325 1.01758 33.7005 1.01758 39.899V58.8075C1.01758 62.4546 3.63159 65.1313 7.2787 65.1313L8.84397 65.0686V86.9826H80.8468V65.0686L82.4121 65.1313C86.0592 65.1313 88.6732 62.4546 88.6732 58.8075V39.899C88.6732 33.7005 77.3249 30.6325 71.4551 30.6325Z'
          }
          fill={'white'}
        />
        <path
          d={
            'M44.845 24.3714C38.9752 24.3714 27.627 27.5646 27.627 33.7631V52.6716C27.627 56.3188 30.241 58.8075 33.8881 58.8075H35.4533V86.9826H54.2367V58.8075H55.802C59.4491 58.8075 62.0631 56.3188 62.0631 52.6716V33.7631C62.0631 27.5646 50.7148 24.3714 44.845 24.3714Z'
          }
          fill={selected ? theme.palette.primary.main : 'white'}
        />
        <path
          d={
            'M55.802 58.9328C59.4491 58.9328 62.0631 56.3187 62.0631 52.6716V33.7631C62.0631 27.5646 50.7148 24.3714 44.845 24.3714C38.9752 24.3714 27.627 27.5646 27.627 33.7631V52.6716C27.627 56.3187 30.241 58.9328 33.8881 58.9328'
          }
          stroke={'black'}
          strokeWidth={'1.3'}
          strokeLinecap={'round'}
          strokeLinejoin={'round'}
        />
        <path
          d={
            'M44.845 19.6599C49.2121 19.6599 52.6713 16.0911 52.6713 11.677V9.01601C52.6713 4.60192 49.2121 1.03308 44.845 1.03308C40.4778 1.03308 37.0186 4.60192 37.0186 9.01601V11.677C37.0186 16.0911 40.4778 19.6599 44.845 19.6599Z'
          }
          fill={'white'}
          stroke={'black'}
          strokeWidth={'1.3'}
          strokeMiterlimit={'10'}
        />
        <path
          d={
            'M82.4124 65.1313C86.0595 65.1313 88.6735 62.4546 88.6735 58.8075V39.899C88.6735 33.7005 77.3253 30.6325 71.4555 30.6325H65.1943'
          }
          stroke={'black'}
          strokeWidth={'1.3'}
          strokeLinecap={'round'}
          strokeLinejoin={'round'}
        />
        <path d={'M80.8467 86.9826V46.2853'} stroke={'black'} strokeWidth={'1.3'} strokeLinecap={'round'} strokeLinejoin={'round'} />
        <path
          d={
            'M71.4553 25.7801C75.8224 25.7801 79.2817 22.2113 79.2817 17.7972V15.1362C79.2817 10.7222 75.8224 7.15332 71.4553 7.15332C67.0882 7.15332 63.6289 10.7222 63.6289 15.1362V17.7972C63.6289 22.2113 67.0882 25.7801 71.4553 25.7801Z'
          }
          fill={'white'}
          stroke={'black'}
          strokeWidth={'1.3'}
          strokeMiterlimit={'10'}
        />
        <path
          d={
            'M7.2787 65.1313C3.63159 65.1313 1.01758 62.4546 1.01758 58.8075V39.899C1.01758 33.7005 12.3659 30.6325 18.2356 30.6325H24.4968'
          }
          stroke={'black'}
          strokeWidth={'1.3'}
          strokeLinecap={'round'}
          strokeLinejoin={'round'}
        />
        <path d={'M8.84375 86.9826V46.2853'} stroke={'black'} strokeWidth={'1.3'} strokeLinecap={'round'} strokeLinejoin={'round'} />
        <path
          d={
            'M18.2356 25.7801C13.8684 25.7801 10.4092 22.2113 10.4092 17.7972V15.1362C10.4092 10.7222 13.8684 7.15332 18.2356 7.15332C22.6027 7.15332 26.062 10.7222 26.062 15.1362V17.7972C26.062 22.2113 22.6027 25.7801 18.2356 25.7801Z'
          }
          fill={'white'}
          stroke={'black'}
          strokeWidth={'1.3'}
          strokeMiterlimit={'10'}
        />
        <path d={'M54.2373 86.9826V40.0242'} stroke={'black'} strokeWidth={'1.3'} strokeLinecap={'round'} strokeLinejoin={'round'} />
        <path d={'M35.4531 40.0242V86.9826'} stroke={'black'} strokeWidth={'1.3'} strokeLinecap={'round'} strokeLinejoin={'round'} />
        <path d={'M44.8457 57.2423V86.9826'} stroke={'black'} strokeWidth={'1.3'} strokeLinecap={'round'} strokeLinejoin={'round'} />
      </g>
      <defs>
        <clipPath id={'clip0_9574_16835'}>
          <rect width={'89.6905'} height={'88'} fill={'white'} />
        </clipPath>
      </defs>
    </svg>
  );
};
