import type { EvaluatedApplicationRequirement } from '@lama/contracts';
import type { Document } from '@lama/document-service-client';

export const isDocumentRelatedToRequirement = (document: Document, requirement: EvaluatedApplicationRequirement) =>
  document.requirementId === requirement.id ||
  !!(
    ((document.sharedRequirementId && document.sharedRequirementId === requirement.referenceRequirementId) ||
      (document.requirementKey && document.requirementKey === requirement.key) ||
      requirement.sources.uploadFilesSource?.some((file) => file.topic === document.topic)) &&
    document.relatedEntityId === requirement.entityId
  );
