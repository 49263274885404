import styled from 'styled-components';
import { Flex } from '../Flex';
import type { RichInputProps } from './types';

export const StyledEditorContainer = styled(Flex)<Partial<RichInputProps>>`
  min-height: ${(props) => props.minHeight};
  gap: ${({ gap }) => gap ?? 6};
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  color: ${({ color, theme }) => color ?? theme.text.colors.primary};
`;
