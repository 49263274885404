import React, { useCallback, useMemo, useState, useContext } from 'react';
import { InputAdornment, Slider, TextField, Typography } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { grey } from '@mui/material/colors';
import { addCommasToNumber, formatValue, sanitizeStringToNumber } from '@lama/data-formatters';
import { clamp } from 'lodash-es';
import type { ScreenProps } from '../ScreenProps';
import { BasicScreen } from '../shared/BasicScreen';
import { analyticsEvent } from '../../shared/utils/analytics';
import { partnerState } from '../../state/appState';
import { ApplicationContext } from '../../shared/contexts/ApplicationContext';
import { useUpdateApplication } from '../../hooks/react-query/useUpdateApplication';
import { useGetCurrentRequirement } from '../../hooks/useGetCurrentRequirement';

interface RequestedAmountScreenProps extends ScreenProps {
  min?: number;
  max?: number;
  step?: number;
  initialValue?: number;
  title?: string;
}

export const RequestedAmountScreen: React.FC<RequestedAmountScreenProps> = ({
  onNextClick,
  min: minAmount = 50_000,
  max: maxAmount = 25_000_000,
  initialValue = 50_000,
  step = 50_000,
  title = 'Requested Loan Amount',
  flow,
  nextLoading,
  ...stepsNavigationProps
}) => {
  const { application } = useContext(ApplicationContext);
  const [requestedAmount, setRequestedAmount] = useState(
    application.requestedAmount ?? (initialValue && initialValue >= minAmount && initialValue <= maxAmount ? initialValue : minAmount),
  );
  const partner = useRecoilValue(partnerState);
  const requirement = useGetCurrentRequirement();

  const { mutateAsync: updateApplication, isPending: updatingApplication } = useUpdateApplication(application.id);

  const handleNextClick = useCallback(async () => {
    if (flow === 'onboarding') {
      analyticsEvent({
        action: 'requested_amount',
        category: 'conversion',
        value: requestedAmount,
        params: {
          partnerId: partner?.id ?? '',
          partnerStatus: partner?.status ?? '',
        },
      });
    }

    await updateApplication({ updateApplicationPayload: { requestedAmount } });
    onNextClick();
  }, [flow, onNextClick, partner?.id, partner?.status, requestedAmount, updateApplication]);

  const marks = useMemo(
    () => [
      { value: minAmount, label: formatValue(minAmount, 'currency') },
      { value: maxAmount, label: formatValue(maxAmount, 'currency') },
    ],
    [maxAmount, minAmount],
  );

  const onTextFieldChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const numberWithoutCommas = sanitizeStringToNumber(event.target.value);
      setRequestedAmount(Number(numberWithoutCommas));
    },
    [setRequestedAmount],
  );

  const onTextFieldBlur = useCallback(() => {
    setRequestedAmount(clamp(requestedAmount, minAmount, maxAmount));
  }, [maxAmount, minAmount, requestedAmount, setRequestedAmount]);

  const onSliderChange = useCallback(
    (_event: Event, value: number[] | number) => {
      setRequestedAmount(Number(value));
    },
    [setRequestedAmount],
  );

  return (
    <BasicScreen
      {...stepsNavigationProps}
      flow={flow}
      title={requirement?.name ?? title}
      onNextClick={handleNextClick}
      backVisible={false}
      nextLoading={updatingApplication || nextLoading}
    >
      <TextField
        value={requestedAmount !== null ? addCommasToNumber(requestedAmount ?? minAmount) : minAmount}
        onChange={onTextFieldChange}
        onBlur={onTextFieldBlur}
        size={'medium'}
        inputProps={{
          style: {
            fontSize: '42px',
            paddingTop: '10px',
            paddingBottom: '10px',
            color: grey[700],
          },
        }}
        InputProps={{
          style: { fontSize: '42px' },
          startAdornment: (
            <InputAdornment position={'start'}>
              <Typography fontSize={'48px'}>{'$'}</Typography>
            </InputAdornment>
          ),
        }}
        fullWidth
      />
      <Slider step={step} min={minAmount} max={maxAmount} value={requestedAmount} onChange={onSliderChange} marks={marks} />
    </BasicScreen>
  );
};
