import React, { forwardRef } from 'react';
import styled from 'styled-components';
import type { FontSizeProps, FontWeightProps, TextAlignProps, SpaceProps, LetterSpacingProps } from 'styled-system';
import { color, fontWeight, fontSize, space, textAlign, letterSpacing, variant as variantAccessor } from 'styled-system';
import {
  italicsStyle,
  strikeThroughStyle,
  codeStyle,
  underlineStyle,
  upperCaseStyle,
  paragraphStyle,
  ellipsisStyle,
  capitalizeStyle,
  lineClampStyle,
  noWrapStyle,
} from './Text.styles';

export type TextVariant = 'body1' | 'body2' | 'body3' | 'body4' | 'body5' | 'h1' | 'h4' | 'h5' | 'h6';

export interface TextProps extends TextAlignProps, SpaceProps, LetterSpacingProps {
  onClick?: React.MouseEventHandler<HTMLSpanElement>;
  variant?: TextVariant;
  weight?: 300 | 400 | 500 | 700;
  size?: 4 | 8 | 10 | 12 | 16 | 20 | 24 | 28 | 32 | 36 | 40 | 44 | 48 | 52 | 56 | 60 | 64 | 68 | 72 | 76 | 80 | 84 | 88 | 92 | 96 | 100;
  color?: string;
  italic?: boolean;
  paragraph?: boolean;
  underline?: boolean;
  strikethrough?: boolean;
  upperCase?: boolean;
  capitalize?: boolean;
  code?: boolean;
  ellipsis?: boolean;
  maxLines?: number;
  title?: string;
  children?: React.ReactNode;
  noWrap?: boolean;
  maxWidth?: string;
}

const StyledText = styled.span<FontSizeProps & FontWeightProps & LetterSpacingProps & TextAlignProps & TextProps>`
  font-family: ${({ theme }) => theme.fonts.text};
  -webkit-font-smoothing: antialiased;

  color: ${({ theme, color: colorProp }) =>
    // @ts-ignore
    (colorProp && theme.text?.colors?.[colorProp]) ?? colorProp};
  ${({ italic }) => italic && italicsStyle}
  ${({ underline }) => underline && underlineStyle}
  ${({ strikethrough }) => strikethrough && strikeThroughStyle}
  ${({ upperCase }) => upperCase && upperCaseStyle}
  ${({ capitalize }) => capitalize && capitalizeStyle}
  ${({ paragraph }) => paragraph && paragraphStyle}
  ${({ code }) => code && codeStyle}
  ${({ ellipsis }) => ellipsis && ellipsisStyle}
  ${({ maxLines }) => maxLines && lineClampStyle(maxLines)}
  ${({ noWrap }) => noWrap && noWrapStyle}



  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth};`}
  ${space}
  ${fontSize}
  ${textAlign}
  
  ${({ theme }) => variantAccessor({ variants: theme.text.variants as Record<string, any> })}

  ${letterSpacing}
  ${color}
  ${fontWeight}
`;

export const Text = forwardRef<HTMLSpanElement, TextProps>(({ children, weight = 300, size = 14, ...props }, ref) => (
  <StyledText fontWeight={weight} fontSize={`${size}px`} ref={ref} {...props}>
    {children}
  </StyledText>
));

Text.displayName = 'Text';
