import type { UseMutationOptions } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';
import { lenderUserAlreadyExistMessageError, type SendOtpRequestBody } from '@lama/user-service-client';
import { useCallback } from 'react';
import { displayToast } from '@lama/app-components';
import type { AxiosError } from 'axios';
import axios from 'axios';
import { userServiceClient } from '../../shared/clients/userServiceClient';

interface SendOtpParams {
  sendOtpRequest: SendOtpRequestBody;
}

export const useSendOtpMutation = (options?: UseMutationOptions<void, unknown, SendOtpParams>) => {
  const sendOtpMutation = useCallback(async ({ sendOtpRequest }: SendOtpParams) => userServiceClient.sendOtp(sendOtpRequest), []);

  return useMutation({
    mutationFn: sendOtpMutation,
    onError: (error: AxiosError) => {
      const tryingToGetOTPForLenderUser =
        axios.isAxiosError(error) &&
        error.response?.status === 409 &&
        (error.response?.data as any)?.message === lenderUserAlreadyExistMessageError;

      const message = tryingToGetOTPForLenderUser
        ? 'This email is registered to a bank employee. To create a new application, use a different email address, or log into https://app.lama.ai.'
        : 'Something went wrong while sending OTP. Please contact support.';

      displayToast(message, 'error');
    },
    ...options,
  });
};
