import type { MutationOptions } from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { displayToast } from '@lama/app-components';
import type { PersonCreateApiModel } from '@lama/clients';
import { useAuthentication } from '../authentication/useAuthentication';
import { peopleServiceClient } from '../../shared/clients/peopleService';

interface MutationParams {
  person: Omit<PersonCreateApiModel, 'createdAt' | 'updatedAt'>;
}

interface CreatePersonParams {
  applicationId?: string;
  options?: MutationOptions<string | undefined, Error, MutationParams>;
}

export const useCreatePerson = (params?: CreatePersonParams) => {
  const queryClient = useQueryClient();
  const { token, isAuthenticated } = useAuthentication();

  return useMutation<string | undefined, Error, MutationParams>({
    mutationFn: async ({ person }: MutationParams) => {
      if (isAuthenticated) {
        const result = await peopleServiceClient.createPerson(person, token);
        return result;
      }
      return;
    },
    onSuccess: async () => {
      if (params?.applicationId) {
        await Promise.all([
          queryClient.invalidateQueries({
            queryKey: ['applicationRequirements', params.applicationId],
          }),
          queryClient.invalidateQueries({
            queryKey: ['application', params.applicationId],
          }),
          queryClient.invalidateQueries({
            queryKey: ['applications'],
          }),
        ]);
      }
    },
    onError: () => {
      displayToast('Something went wrong while updating the application. Please contact support.', 'error');
    },
    ...params?.options,
  });
};
