import React from 'react';
import type { TypographyProps } from '@mui/material';
import { Typography } from '@mui/material';
import type { FlexProps } from '@lama/design-system';
import { Flex, Text } from '@lama/design-system';
import styled from 'styled-components';
import { MultilineTypography } from '../shared/MultilineTypography';

interface SummaryCardProps {
  Icon: React.FC;
  text1: string;
  text2: string;
  isMobile: boolean;
  onClick?: () => void;
}

const SummaryCardContainer = styled(Flex)`
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  cursor: default;
`;

export const SummaryCard: React.FC<SummaryCardProps> = ({ Icon, text1, text2, isMobile, onClick }) => {
  const flexProps: FlexProps = isMobile
    ? { height: '148px', width: '100%', px: 2, py: 2 }
    : { height: '212px', width: '212px', px: 8, py: 6 };

  const text1Props: TypographyProps = isMobile
    ? { textAlign: 'center', variant: 'h6', noWrap: true, maxWidth: '80%' }
    : { textAlign: 'center', variant: 'h6' };

  return (
    <SummaryCardContainer flexDirection={'column'} alignItems={'center'} justifyContent={'center'} {...flexProps} gap={2} onClick={onClick}>
      <Icon />
      {isMobile ? <Typography {...text1Props}>{text1}</Typography> : <MultilineTypography {...text1Props}>{text1}</MultilineTypography>}
      <Text textAlign={'center'} variant={'body2'}>
        {text2}
      </Text>
    </SummaryCardContainer>
  );
};
