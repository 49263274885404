import React from 'react';
import { useMediaQuery, useTheme } from '@mui/material';

export const OnBehalfOfOwnersIcon: React.FC<{ selected: boolean }> = ({ selected }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const height = isMobile ? 78 : 88;
  const width = isMobile ? 79 : 87;

  return (
    <svg width={width} height={height} viewBox={'0 0 87 88'} fill={'none'} xmlns={'http://www.w3.org/2000/svg'}>
      <g clipPath={'url(#clip0_9574_18283)'}>
        <path
          d={
            'M79.0093 1.03436H7.39994C4.75835 1.03436 2.62598 3.13491 2.62598 5.7765V37.6029C2.62598 40.2445 4.75835 42.3769 7.39994 42.3769L13.7652 42.4087L22.0719 53.1501C23.0108 54.3277 24.9045 53.6593 24.9045 52.1635V42.4246H79.0093C81.6509 42.4246 83.7833 40.2445 83.7833 37.6188V5.7765C83.7833 3.13491 81.6509 1.03436 79.0093 1.03436Z'
          }
          fill={'white'}
        />
        <path
          d={
            'M29.6784 42.3769H24.9045V52.1635C24.9045 53.6752 23.0108 54.3277 22.0719 53.1501L13.4629 42.3769L7.39994 42.4087C4.75835 42.4087 2.62598 40.2763 2.62598 37.6347V5.80832C2.62598 3.16673 4.75835 1.03436 7.39994 1.03436H79.0093C81.6509 1.03436 83.7833 3.16673 83.7833 5.80832V37.6347C83.7833 40.2763 81.6509 42.4087 79.0093 42.4087H55.1395'
          }
          stroke={'black'}
          strokeWidth={'1.3'}
          strokeLinecap={'round'}
          strokeLinejoin={'round'}
        />
        <path d={'M32.8604 20.0984H37.6343'} stroke={'black'} strokeWidth={'1.3'} strokeLinecap={'round'} strokeLinejoin={'round'} />
        <path d={'M40.8174 20.0984H45.5913'} stroke={'black'} strokeWidth={'1.3'} strokeLinecap={'round'} strokeLinejoin={'round'} />
        <path d={'M48.7744 20.0984H53.5484'} stroke={'black'} strokeWidth={'1.3'} strokeLinecap={'round'} strokeLinejoin={'round'} />
        <path
          d={
            'M42.4087 53.3569C46.8485 53.3569 50.3653 49.7287 50.3653 45.2412V42.536C50.3653 38.0484 46.8485 34.4202 42.4087 34.4202C37.969 34.4202 34.4521 38.0484 34.4521 42.536V45.2412C34.4521 49.7287 37.969 53.3569 42.4087 53.3569Z'
          }
          fill={'white'}
          stroke={'black'}
          strokeWidth={'1.3'}
          strokeMiterlimit={'10'}
        />
        <path
          d={
            'M42.4088 58.1627C36.4414 58.1627 24.9043 59.8177 24.9043 66.1193V86.9656H59.9133V66.1193C59.9133 59.8177 48.3763 58.1627 42.4088 58.1627Z'
          }
          fill={selected ? theme.palette.primary.main : 'white'}
        />
        <path
          d={
            'M59.9133 86.9338V66.1193C59.9133 59.8177 48.3763 58.1627 42.4088 58.1627C36.4414 58.1627 24.9043 59.8177 24.9043 66.1193V86.9338'
          }
          stroke={'black'}
          strokeWidth={'1.3'}
          strokeLinecap={'round'}
          strokeLinejoin={'round'}
        />
        <path d={'M51.957 86.9657V72.6438'} stroke={'black'} strokeWidth={'1.3'} strokeLinecap={'round'} strokeLinejoin={'round'} />
        <path d={'M32.8604 72.6438V86.9339'} stroke={'black'} strokeWidth={'1.3'} strokeLinecap={'round'} strokeLinejoin={'round'} />
        <path d={'M13.7646 86.9338H79.0088'} stroke={'black'} strokeWidth={'1.3'} strokeLinecap={'round'} strokeLinejoin={'round'} />
        <path d={'M10.5821 86.9338H7.39941'} stroke={'black'} strokeWidth={'1.3'} strokeLinecap={'round'} strokeLinejoin={'round'} />
        <path d={'M4.21682 86.9338H1.03418'} stroke={'black'} strokeWidth={'1.3'} strokeLinecap={'round'} strokeLinejoin={'round'} />
        <path d={'M85.374 86.9338H82.1914'} stroke={'black'} strokeWidth={'1.3'} strokeLinecap={'round'} strokeLinejoin={'round'} />
      </g>
      <defs>
        <clipPath id={'clip0_9574_18283'}>
          <rect width={'86.4087'} height={'88'} fill={'white'} />
        </clipPath>
      </defs>
    </svg>
  );
};
