import React from 'react';
import { Box, Typography } from '@mui/material';

export const BadgeCounter: React.FC<{ count: number }> = ({ count }) => (
  <Box
    sx={{
      bgcolor: 'primary.main',
      color: 'white',
      height: '20px',
      width: '20px',
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexShrink: 0,
    }}
  >
    <Typography variant={'badge'}>{count}</Typography>
  </Box>
);
