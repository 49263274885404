/* eslint-disable react/no-multi-comp */
import React from 'react';

export const ArrowRightIcon = (props: any) => (
  <svg width={'1em'} height={'1em'} viewBox={'0 0 16 16'} {...props}>
    <path d={'M6 12l4-4-4-4'} stroke={'currentColor'} fill={'none'} />
  </svg>
);

export const ArrowDownIcon = (props: any) => (
  <svg width={'1em'} height={'1em'} viewBox={'0 0 16 16'} {...props}>
    <path d={'M4 6l4 4 4-4'} stroke={'currentColor'} fill={'none'} />
  </svg>
);
