import React, { useContext, useMemo } from 'react';
import type { FC } from 'react';
import { Flex } from '@lama/design-system';
import { getEnabledFileUploadSources } from '@lama/evaluations';
import type { ItemDocumentsProps } from '@lama/app-components';
import { LoadingPage } from '@lama/app-components';
import { ApplicationContext } from '../../shared/contexts/ApplicationContext';
import { useDocumentsQuery } from '../../hooks/react-query/useDocumentsQuery';
import { DocumentBox } from '../shared/genericRequirements/documentRequirement/documentBox/DocumentBox';
import { UploadArbitraryDocumentButton } from '../shared/genericRequirements/documentRequirement/UploadArbitraryDocumentButton';

export const ItemDocuments: FC<ItemDocumentsProps> = ({ requirement, itemId, formContext }) => {
  const { id: requirementId, sources, entityId, entityType, referenceRequirementId, key: requirementKey, properties } = requirement;
  const { application } = useContext(ApplicationContext);
  const { data: documents, isPending: loadingDocuments } = useDocumentsQuery(application.id);

  const itemType = useMemo(() => properties.find((p) => p.type === 'table')?.fieldName ?? '', [properties]);

  const itemDocuments = useMemo(
    () => documents?.filter((document) => document.relatedItemId && document.relatedItemId === itemId) ?? [],
    [documents, itemId],
  );

  const enabledSources = useMemo(
    () =>
      getEnabledFileUploadSources({
        sources: sources.uploadFilesSource ?? [],
        entityId,
        application,
        entityContext: formContext,
      }),
    [sources.uploadFilesSource, entityId, application, formContext],
  );

  const requirementHasExtractableSources = enabledSources.some(({ extractable }) => extractable);

  const documentBoxes = useMemo(() => {
    const predefinedDocumentsComponents =
      enabledSources.map(({ name: documentName, topic, dismissDataByEntity, extractable }) => (
        <DocumentBox
          key={documentName}
          requirement={requirement}
          description={documentName}
          topic={topic}
          document={itemDocuments.find((d) => d.topic === topic)}
          sharedRequirementId={referenceRequirementId}
          requirementKey={requirementKey}
          entityId={entityId}
          entityType={entityType}
          dismissible
          dismissed={dismissDataByEntity?.[entityId]}
          extractable={extractable}
          relatedItemId={itemId}
          relatedItemType={itemType}
        />
      )) ?? [];

    const topics = new Set(enabledSources.map((file) => file.topic));
    const arbitraryDocumentsComponents = itemDocuments
      .filter((r) => !r.topic || !topics.has(r.topic))
      .map((document) => (
        <DocumentBox
          requirement={requirement}
          key={document.topic}
          description={document.filename}
          topic={document.topic}
          document={document}
          sharedRequirementId={referenceRequirementId}
          requirementKey={requirementKey}
          entityId={entityId}
          entityType={entityType}
          relatedItemId={itemId}
          relatedItemType={itemType}
        />
      ));

    return [...predefinedDocumentsComponents, ...arbitraryDocumentsComponents];
  }, [enabledSources, itemDocuments, requirement, referenceRequirementId, requirementKey, entityId, entityType, itemId, itemType]);

  if (loadingDocuments) {
    return <LoadingPage />;
  }

  return (
    <Flex flexDirection={'column'} gap={4}>
      {documentBoxes}
      <Flex justifyContent={'center'}>
        <UploadArbitraryDocumentButton
          applicationId={application.id}
          entityId={entityId}
          entityType={entityType}
          requirementId={requirementId}
          sharedRequirementId={referenceRequirementId}
          requirementKey={requirementKey}
          extractable={requirementHasExtractableSources}
          relatedItemId={itemId}
          relatedItemType={itemType}
        />
      </Flex>
    </Flex>
  );
};
