import { useCallback } from 'react';
import { auth0Domain, auth0ClientId } from '../framework/environment';
import { resetStorage } from '../shared/utils/resetStorage';

export const useLogout = () => {
  const logout = useCallback(() => {
    resetStorage();
    const params = window.location.href.split('?').at(1);
    window.location.href = `https://${auth0Domain}/v2/logout?client_id=${auth0ClientId}&returnTo=${encodeURIComponent(
      `${window.location.origin}${params ? `?${params}` : ''}`,
    )}`;
  }, []);

  return logout;
};
