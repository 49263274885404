import type { FC, ReactNode } from 'react';
import React, { useMemo } from 'react';
import type { HierarchicalOption, LabeledValue } from '@lama/contracts';
import { customComponents as baseCustomComponents } from './customComponentsMap.js';
import { sourceToValues as baseSourceToValues, sourceToHeirarchicalValues } from './valuesSources.js';
import type { PropertyComponentProps } from './propertyTypeToComponentMap.js';

export const GenericPropertiesContext = React.createContext<{
  customComponents: Record<string, FC<PropertyComponentProps>>;
  sourceToValues: Record<string, LabeledValue[] | string[] | readonly string[]>;
  sourceToHierarchicalValues: Record<string, HierarchicalOption[]>;
}>(null!);

interface GenericPropertiesProviderProps {
  customComponents: Record<string, FC<PropertyComponentProps>>;
  customSourceToValues: Record<string, LabeledValue[] | string[] | readonly string[]>;
  children: ReactNode;
}

export const GenericPropertiesProvider: FC<GenericPropertiesProviderProps> = ({ customComponents, customSourceToValues, children }) => {
  const value = useMemo(
    () => ({
      customComponents: { ...baseCustomComponents, ...customComponents },
      sourceToValues: { ...baseSourceToValues, ...customSourceToValues },
      sourceToHierarchicalValues: sourceToHeirarchicalValues,
    }),
    [customComponents, customSourceToValues],
  );

  return <GenericPropertiesContext.Provider value={value}>{children}</GenericPropertiesContext.Provider>;
};
