import type { ReactElement } from 'react';
import React from 'react';
import { Flex } from '@lama/design-system';
import styled from 'styled-components';
import { Tooltip } from '../Tooltip/Tooltip';
import type { InformationTypes } from './Information';
import { Information } from './Information';
import { InfoType } from './InfoLabel';

const StyledFlex = styled(Flex)`
  cursor: default;
`;

export interface InfoLineProps {
  label: string;
  values?: any;
  type?: InformationTypes;
  icon?: ReactElement;
  tip?: string;
  color?: string;
  wrap?: boolean;
}

export const InfoLine: React.FC<InfoLineProps> = ({ values, ...restProps }) => (
  <Flex justifyContent={'space-between'} width={'100%'} gap={6} flex={1}>
    <InfoType label={restProps.label} />
    <Flex flex={0.55} gap={2} alignItems={'center'} minWidth={0}>
      {restProps.icon ? (
        <Tooltip title={restProps.tip} placement={'bottom'}>
          <StyledFlex>{restProps.icon}</StyledFlex>
        </Tooltip>
      ) : null}
      <Information value={values} {...restProps} />
    </Flex>
  </Flex>
);
