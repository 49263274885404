import { Flex, Spinner, Text } from '@lama/design-system';
import { MenuItem, Tooltip } from '@mui/material';
import React from 'react';
import type { FC } from 'react';
import { MoveToFolderIcon } from '../assets/MoveToFolderIcon';

interface DocumentMoveToMenuItemsProps {
  onClick?: (e: React.MouseEvent) => void;
  loading: boolean;
}

export const DocumentMoveToMenuItem: FC<DocumentMoveToMenuItemsProps> = ({ onClick: openMoveTo, loading }) => (
  <MenuItem onClick={openMoveTo}>
    <Tooltip enterDelay={500} title={'Move file to a different placeholder'} placement={'left'}>
      <Flex alignItems={'center'} gap={2} alignContent={'center'}>
        {loading ? <Spinner size={'16px'} /> : <MoveToFolderIcon size={'20px'} />}
        <Text variant={'body2'}>{'Move To'}</Text>
      </Flex>
    </Tooltip>
  </MenuItem>
);
