import type { FC } from 'react';
import React from 'react';
import styled from 'styled-components';

const StyledPrintWrapper = styled.div`
  @media print {
    display: none;
  }
`;

export const HideOnPrintWrapper: FC<{ children: React.ReactNode }> = ({ children }) => <StyledPrintWrapper>{children}</StyledPrintWrapper>;
