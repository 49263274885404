import type { FC } from 'react';
import React from 'react';
import type { TypographyProps } from '@mui/material';
import { styled, Typography } from '@mui/material';

const MultiLine = styled(Typography)({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
});

interface MultilineTypographyProps extends TypographyProps {
  maxLines?: number;
}

export const MultilineTypography: FC<MultilineTypographyProps> = ({ maxLines = 2, children, ...muiProps }) => (
  <MultiLine sx={{ WebkitLineClamp: maxLines }} {...muiProps}>
    {children}
  </MultiLine>
);
