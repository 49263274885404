import type { FC } from 'react';
import React, { useCallback, useMemo, useState } from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import { get, isEmpty, merge } from 'lodash-es';
import type { EvaluatedApplicationRequirement, EvaluatedOpportunityRequirement, ProductApiModel, ProductPublicData } from '@lama/contracts';
import { useToggle } from 'react-use';
import { v4 as uuidv4 } from 'uuid';
import { getApplicationEntityByType, getSourcedProperty } from '@lama/properties';
import { Flex } from '@lama/design-system';
import type { ApplicationApiModel, ApplicationUpdateApiModel, OpportunityApiModel, PersonUpdateApiModel } from '@lama/clients';
import type { UpdateBusinessRequest } from '@lama/business-service-client';
import { GenericPropertiesProvider } from '../GenericProperties/GenericPropertiesContext';
import { customComponents } from '../GenericProperties/customComponentsMap';
import { createUpdatePayload, getBusinessIdByEntity, getInitialFormValues } from '../GenericProperties/genericFormUtils';
import { ModifyItemButton } from '../ModifyItemButton/ModifyItemButton';
import { GenericAddOrEditDialog } from './GenericAddOrEditDialog';
import { listRequirementToEmptyStateImage, requirementToListItemComponent } from './genericListUtils';
import { ItemCard } from './ItemCard';
import type { ItemDocumentsProps } from './types';

export interface BaseGenericListProps {
  requirement: EvaluatedApplicationRequirement | EvaluatedOpportunityRequirement;
  application: ApplicationApiModel;
  opportunity?: OpportunityApiModel;
  product: ProductApiModel | ProductPublicData;
  updatePerson: ({ personId, updatePersonPayload }: { personId: string; updatePersonPayload: PersonUpdateApiModel }) => Promise<void>;
  updateBusiness: ({
    businessId,
    updateBusinessPayload,
  }: {
    businessId: string;
    updateBusinessPayload: UpdateBusinessRequest;
  }) => Promise<void>;
  updateApplication: (payload: { updateApplicationPayload: ApplicationUpdateApiModel }) => Promise<void>;
  loading: boolean;
  ItemDocumentsComponent: FC<ItemDocumentsProps>;
}

export const BaseGenericList: FC<BaseGenericListProps> = ({
  requirement,
  application,
  opportunity,
  product,
  updatePerson,
  updateBusiness,
  updateApplication,
  loading,
  ItemDocumentsComponent,
}) => {
  const { entityId, entityType, entityGroups, properties } = requirement;

  const [dialogMode, setDialogMode] = useState<'add' | 'edit'>('add');
  const tableProperty = useMemo(() => properties.find((p) => p.type === 'table'), [properties]);
  const tablePropertyFieldName = useMemo(() => tableProperty?.fieldName ?? '', [tableProperty]);
  const tablePropertyNoItemsFieldName = useMemo(() => tableProperty?.noItemsFieldName ?? '', [tableProperty]);

  const itemComponent = useMemo(() => requirementToListItemComponent[tablePropertyFieldName], [tablePropertyFieldName]);
  const EmptyState = useMemo(() => listRequirementToEmptyStateImage[tablePropertyFieldName], [tablePropertyFieldName]);

  const requirementEntity = useMemo(() => {
    const entities = getApplicationEntityByType(application, entityType, entityGroups);
    return entities.find(({ id }) => id === entityId);
  }, [application, entityGroups, entityId, entityType]);

  const listItems = useMemo<any[]>(() => get(requirementEntity, tablePropertyFieldName) ?? [], [requirementEntity, tablePropertyFieldName]);

  const [openDialog, toggleDialog] = useToggle(false);
  const [dialogInitialValues, setDialogInitialValues] = useState<Record<string, any> | null>();
  const markedNoItems = useMemo(
    () => (tablePropertyNoItemsFieldName ? Boolean(get(requirementEntity, tablePropertyNoItemsFieldName)) : false),
    [requirementEntity, tablePropertyNoItemsFieldName],
  );

  const onClose = useCallback(() => {
    setDialogInitialValues(null);
    toggleDialog();
  }, [toggleDialog]);

  const onSubmit = useCallback(
    async (values: any) => {
      const updatedListItems = [...listItems];
      if (dialogMode === 'edit') {
        const originalUpdatedItem = listItems.find((listItem: { id: string }) => listItem.id === values.id);
        updatedListItems[updatedListItems.findIndex((item: { id: any }) => item.id === values.id)] = merge({}, originalUpdatedItem, values);
      } else {
        updatedListItems.push(values);
      }
      const payload = createUpdatePayload(application, entityType, '', { [tablePropertyFieldName]: updatedListItems });

      if (!isEmpty(payload.updateApplicationPayload)) {
        await updateApplication({ updateApplicationPayload: payload.updateApplicationPayload });
      }

      if (!isEmpty(payload.updateBusinessPayload)) {
        const businessEntityId = getBusinessIdByEntity(application, entityType, entityId);

        if (businessEntityId) {
          await updateBusiness({ businessId: businessEntityId, updateBusinessPayload: payload.updateBusinessPayload });
        }
      }

      if (!isEmpty(payload.updatePersonPayload)) {
        await updatePerson({ personId: entityId, updatePersonPayload: payload.updatePersonPayload });
      }
      onClose();
    },
    [
      listItems,
      dialogMode,
      application,
      entityType,
      tablePropertyFieldName,
      onClose,
      updateApplication,
      entityId,
      updateBusiness,
      updatePerson,
    ],
  );

  const onDelete = useCallback(
    async (id: string) => {
      const updatedList = listItems.filter((listItem: { id: string }) => listItem.id !== id);
      const payload = createUpdatePayload(application, entityType, '', { [tablePropertyFieldName]: updatedList });

      if (!isEmpty(payload.updateApplicationPayload)) {
        await updateApplication({ updateApplicationPayload: payload.updateApplicationPayload });
      }

      if (!isEmpty(payload.updateBusinessPayload)) {
        const businessEntityId = getBusinessIdByEntity(application, entityType, entityId);

        if (businessEntityId) {
          await updateBusiness({ businessId: businessEntityId, updateBusinessPayload: payload.updateBusinessPayload });
        }
      }
      if (!isEmpty(payload.updatePersonPayload)) {
        await updatePerson({ personId: entityId, updatePersonPayload: payload.updatePersonPayload });
      }
    },
    [application, entityId, entityType, tablePropertyFieldName, listItems, updateApplication, updateBusiness, updatePerson],
  );

  const updateDialogInitialValues = useCallback(
    (listItem: any) => {
      const yearsBack = new Date().getUTCFullYear() - application.leadingReferenceYear;

      const propertiesWithDecidedSource = tableProperty?.childProperties?.map((p) => getSourcedProperty(p, listItem, yearsBack)) ?? [];
      const initialValues = getInitialFormValues(propertiesWithDecidedSource, listItem);
      setDialogInitialValues(initialValues);
      toggleDialog();
    },
    [application.leadingReferenceYear, tableProperty?.childProperties, toggleDialog],
  );

  const onClickEdit = useCallback(
    (id: string) => {
      const listItem = listItems.find((item: { id: string }) => item.id === id);
      updateDialogInitialValues(listItem);
      setDialogMode('edit');
    },
    [listItems, updateDialogInitialValues],
  );

  const onNoItemsClick = useCallback(async () => {
    if (tablePropertyNoItemsFieldName) {
      const payload = createUpdatePayload(application, entityType, '', { [tablePropertyNoItemsFieldName]: !markedNoItems });

      if (!isEmpty(payload.updateApplicationPayload)) {
        await updateApplication({ updateApplicationPayload: payload.updateApplicationPayload });
      }

      if (!isEmpty(payload.updateBusinessPayload)) {
        const businessEntityId = getBusinessIdByEntity(application, entityType, entityId);

        if (businessEntityId) {
          await updateBusiness({ businessId: businessEntityId, updateBusinessPayload: payload.updateBusinessPayload });
        }
      }

      if (!isEmpty(payload.updatePersonPayload)) {
        await updatePerson({ personId: entityId, updatePersonPayload: payload.updatePersonPayload });
      }
    }
  }, [application, entityId, entityType, markedNoItems, tablePropertyNoItemsFieldName, updateApplication, updateBusiness, updatePerson]);

  const onClickAddItem = useCallback(() => {
    updateDialogInitialValues({ id: uuidv4() });
    setDialogMode('add');
  }, [updateDialogInitialValues]);

  if (!tableProperty) {
    return null;
  }

  return (
    <GenericPropertiesProvider customComponents={customComponents} customSourceToValues={product.customOptionsLists ?? {}}>
      <Flex flexDirection={'column'} flex={1} alignItems={'center'} gap={8}>
        {listItems.length ? (
          listItems.map((item: { id: string }) => (
            <ItemCard key={item.id} onDelete={onDelete} onEdit={onClickEdit} item={item} ItemComponent={itemComponent} />
          ))
        ) : EmptyState ? (
          <EmptyState />
        ) : null}
        {!listItems.length && tablePropertyNoItemsFieldName ? (
          <FormControlLabel
            label={'No items to add'}
            control={<Checkbox checked={markedNoItems} onChange={onNoItemsClick} />}
            sx={{ marginRight: 0 }}
          />
        ) : null}
        <ModifyItemButton onClick={onClickAddItem} text={'Add Item'} variant={'text'} disabled={markedNoItems || loading} />
        <GenericAddOrEditDialog
          open={openDialog}
          application={application}
          opportunity={opportunity}
          initialValues={dialogInitialValues ?? undefined}
          onClose={onClose}
          onSubmit={onSubmit}
          itemProperties={tableProperty.childProperties}
          parentFieldName={tablePropertyFieldName}
          parentDisplayName={tableProperty.displayName}
          entity={requirementEntity}
          isLoading={loading}
          requirement={requirement}
          ItemDocumentsComponent={ItemDocumentsComponent}
          mode={dialogMode}
        />
      </Flex>
    </GenericPropertiesProvider>
  );
};
