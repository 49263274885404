/* eslint-disable react/jsx-boolean-value */
import { ToggleButton, styled, Typography, ToggleButtonGroup, useMediaQuery, useTheme } from '@mui/material';
import React, { useCallback } from 'react';

interface Option {
  value: boolean;
  text: string;
}
interface ToggleProps {
  value: boolean;
  optionA: Option;
  optionB: Option;
  onChange: (value: boolean) => void;
  width?: string;
}

const TaskToggleButton = styled(ToggleButton)({
  height: '24px',
  backgroundColor: '#EEEEEE',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  '&.hover': {
    backgroundColor: '#EEEEEE',
  },
  // eslint-disable-next-line @typescript-eslint/naming-convention
  '&.Mui-selected, &.Mui-selected:hover': {
    backgroundColor: 'white',
    zIndex: 2,
  },
  // eslint-disable-next-line @typescript-eslint/naming-convention
  '&.MuiToggleButtonGroup-grouped': {
    borderRadius: '20px !important',
    border: '1px solid rgb(0, 0, 0, 0.12) !important',
  },
});

export const Toggle: React.FC<ToggleProps> = ({ value: selectedValue, onChange, optionA, optionB, width }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const internalOnChange = useCallback(
    (_event: React.MouseEvent<HTMLElement>, value: boolean) => {
      onChange(value);
    },
    [onChange],
  );

  return (
    <ToggleButtonGroup value={selectedValue} onChange={internalOnChange} exclusive sx={{ width: isMobile ? '100%' : undefined }}>
      <TaskToggleButton
        disableRipple
        value={optionA.value}
        sx={{ marginRight: '-26px', width: isMobile ? '100%' : width ?? '130px', height: '32px' }}
      >
        <Typography variant={'subtitle2'}>{optionA.text}</Typography>
      </TaskToggleButton>
      <TaskToggleButton disableRipple value={optionB.value} sx={{ width: isMobile ? '100%' : width ?? '130px', height: '32px' }}>
        <Typography variant={'subtitle2'}>{optionB.text}</Typography>
      </TaskToggleButton>
    </ToggleButtonGroup>
  );
};
