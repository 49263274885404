import type { FC } from 'react';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { ApplicationApiModel, ApplicationUpdateApiModel, PersonCreateApiModel, PersonUpdateApiModel } from '@lama/clients';
import type { CreateBusinessModelApi, BusinessApiModel, RelatedPersonApiModel, UpdateBusinessRequest } from '@lama/business-service-client';
import { Flex, Text, bluePalette } from '@lama/design-system';
import axios from 'axios';
import {
  applicationHasBusinessSelector,
  applicationHasPersonSelector,
  businessOwnershipSumSelector,
  owningBusinessesSelectorV2,
} from '@lama/data-formatters';
import type { RequirementProperty } from '@lama/contracts';
import type { FeatureConfigurations } from '@lama/partner-service-client';
import { displayToast } from '../../utils/displayToast';
import type { DialogMode } from '../BaseDialog';
import { ModifyItemButton } from '../ModifyItemButton';
import { EmptyRelationsListImage } from '../RelationsList/EmptyRelationsListImage';
import { PrincipalItem } from './PrincipalItem';
import Info from './assets/Info.svg';
import { AddOrEditPrincipalDialog } from './AddOrEditPrincipalDialog';

const TotalOwnershipPercentageWarning: FC<{ minRequiredOwnershipPercentage?: number }> = ({ minRequiredOwnershipPercentage }) => (
  <Flex backgroundColor={bluePalette[50]} p={3} borderRadius={'4px'} alignItems={'center'} gap={2}>
    <Info />
    <Text variant={'body2'}>{`Listed ownership does not add up to ${minRequiredOwnershipPercentage}%`}</Text>
  </Flex>
);

interface BasePrincipalsScreenProps {
  business: BusinessApiModel;
  application: ApplicationApiModel;
  requirementProperties: RequirementProperty[];
  createPerson: ({ person }: { person: PersonCreateApiModel }) => Promise<string | undefined>;
  updatePerson: ({ personId, updatePersonPayload }: { personId: string; updatePersonPayload: PersonUpdateApiModel }) => Promise<void>;
  createBusiness: ({ business, applicationId }: { business: CreateBusinessModelApi; applicationId: string }) => Promise<void>;
  updateBusiness: ({
    businessId,
    updateBusinessPayload,
  }: {
    businessId: string;
    updateBusinessPayload: UpdateBusinessRequest;
  }) => Promise<void>;
  updateApplication: ({ updateApplicationPayload }: { updateApplicationPayload: ApplicationUpdateApiModel }) => Promise<void>;
  inviteToApplication: ({
    personId,
    skipInviteEmail,
  }: {
    personId: string;
    skipInviteEmail?: boolean;
  }) => Promise<{ skipInviteEmail?: boolean }>;
  loading?: boolean;
  partnerFeatureConfigurations: FeatureConfigurations;
  disableEmailUpdate?: boolean;
  minRequiredOwnershipPercentage?: number;
}

export type ExistingPrincipalPerson = Partial<RelatedPersonApiModel> &
  Pick<RelatedPersonApiModel, 'applicationId' | 'firstName' | 'id' | 'lastName' | 'partnerId'> & {
    inviteToApplication?: boolean;
    isPrimaryContact?: boolean;
  };

interface DeletePrincipalBusinessRequest {
  id: string;
  relatedBusinesses: string[];
}
type UpdateBusinessWithOwnershipRequest = UpdateBusinessRequest & { ownershipPercentage?: number | null; id: string };
type CreateBusinessWithOwnershipRequest = CreateBusinessModelApi & { ownershipPercentage?: number | null };

export type BusinessWithOwnershipRequest = CreateBusinessWithOwnershipRequest | UpdateBusinessWithOwnershipRequest;

export type ExistingPrincipal = BusinessWithOwnershipRequest | ExistingPrincipalPerson;

export const BasePrincipalsScreen: FC<BasePrincipalsScreenProps> = ({
  business,
  application,
  requirementProperties,
  createPerson,
  updatePerson,
  createBusiness,
  updateBusiness,
  updateApplication,
  inviteToApplication,
  loading,
  partnerFeatureConfigurations,
  disableEmailUpdate,
  minRequiredOwnershipPercentage = 100,
}) => {
  const { t } = useTranslation();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogMode, setDialogMode] = useState<DialogMode>('add');
  const [dialogType, setDialogType] = useState<'business' | 'person'>('person');
  const [existingPrincipal, setExistingPrincipal] = useState<ExistingPrincipal | null>(null);

  const owningBusinesses = useMemo(() => owningBusinessesSelectorV2({ application, business }), [application, business]);

  const hasBeenInvitedByPersonId = useMemo(
    () => Object.fromEntries(business.people.map(({ id, email }) => [id, !!email && application.invitedUsers?.includes(email)])),
    [application, business],
  );

  const collectedOwnershipPercentage = useMemo(() => businessOwnershipSumSelector(business), [business]);
  const remainingOwnershipPercentage = useMemo(() => 100 - collectedOwnershipPercentage, [collectedOwnershipPercentage]);

  const existingEntityIds = useMemo(() => {
    const relatedPeopleIds = business.people.map(({ id }) => id);
    const ownersBusinessesIds = owningBusinesses.map(({ owner: { id } }) => id);

    return [...relatedPeopleIds, ...ownersBusinessesIds, business.id];
  }, [business, owningBusinesses]);

  const createBorrower = useCallback(
    async (person: ExistingPrincipalPerson) => {
      if (person.email) {
        try {
          await inviteToApplication({ personId: person.id, skipInviteEmail: true });
        } catch (error) {
          if (axios.isAxiosError(error) && error.response?.status !== 409) {
            throw error;
          }
        }
      }
    },
    [inviteToApplication],
  );

  const onAddPrincipalClick = useCallback(() => {
    setDialogMode('add');
    setDialogType('person');
    setDialogOpen(true);
  }, []);

  const addPerson = useCallback(
    async (person: ExistingPrincipalPerson) => {
      if (!applicationHasPersonSelector(application, person.id)) {
        await createPerson({ person: { ...person, applicationId: application.id, partnerId: application.leadingPartnerId } });
      }

      const updatedPeople = [...business.people, person];
      await updateBusiness({ businessId: business.id, updateBusinessPayload: { people: updatedPeople } });

      // Creating a borrower user must happen after he has a person entity
      if (person.isPrimaryContact) {
        await createBorrower(person);
        await updateApplication({ updateApplicationPayload: { primaryContactPersonId: person.id } });
      }

      return person.id;
    },
    [application, business, createBorrower, createPerson, updateApplication, updateBusiness],
  );

  const editPerson = useCallback(
    async (updatedPerson: ExistingPrincipalPerson) => {
      await updatePerson({ personId: updatedPerson.id, updatePersonPayload: updatedPerson });

      const updatedPeople = business.people.map((person) => (person.id === updatedPerson.id ? updatedPerson : person));
      await updateBusiness({ businessId: business.id, updateBusinessPayload: { people: updatedPeople } });

      if (updatedPerson.isPrimaryContact) {
        await createBorrower(updatedPerson);
        await updateApplication({ updateApplicationPayload: { primaryContactPersonId: updatedPerson.id } });
      }
    },
    [business, createBorrower, updateApplication, updateBusiness, updatePerson],
  );

  // To be deleted after fully removing affiliates
  const assignOwnershipOnOwningBusiness = useCallback(
    async ({ owningBusiness, ownedBusiness }: { owningBusiness: CreateBusinessWithOwnershipRequest; ownedBusiness: BusinessApiModel }) => {
      await updateBusiness({
        businessId: owningBusiness.id,
        updateBusinessPayload: {
          relatedBusinesses: [...(owningBusiness.relatedBusinesses ?? []).filter((id) => id !== ownedBusiness.id), ownedBusiness.id],
        },
      });
    },
    [updateBusiness],
  );

  const assignOwnershipOnOwnedBusiness = useCallback(
    async ({ owningBusiness, ownedBusiness }: { owningBusiness: CreateBusinessWithOwnershipRequest; ownedBusiness: BusinessApiModel }) => {
      await updateBusiness({
        businessId: ownedBusiness.id,
        updateBusinessPayload: {
          owningBusinesses: [
            ...(ownedBusiness.owningBusinesses ?? []).filter(({ id }) => id !== owningBusiness.id),
            { id: owningBusiness.id, ownershipPercentage: owningBusiness.ownershipPercentage },
          ],
          relatedBusinesses: [...(ownedBusiness.relatedBusinesses ?? []).filter((id) => id !== owningBusiness.id), owningBusiness.id],
        },
      });
    },
    [updateBusiness],
  );

  const assignOwnership = useCallback(
    async ({ owningBusiness, ownedBusiness }: { owningBusiness: CreateBusinessWithOwnershipRequest; ownedBusiness: BusinessApiModel }) => {
      await assignOwnershipOnOwningBusiness({ owningBusiness, ownedBusiness });
      await assignOwnershipOnOwnedBusiness({ owningBusiness, ownedBusiness });
    },
    [assignOwnershipOnOwningBusiness, assignOwnershipOnOwnedBusiness],
  );

  // To be deleted after fully removing affiliates
  const removeOwnershipFromOwningBusiness = useCallback(
    async ({ owningBusiness, ownedBusiness }: { owningBusiness: DeletePrincipalBusinessRequest; ownedBusiness: BusinessApiModel }) => {
      await updateBusiness({
        businessId: owningBusiness.id,
        updateBusinessPayload: {
          relatedBusinesses: (owningBusiness.relatedBusinesses ?? []).filter((id) => id !== ownedBusiness.id),
        },
      });
    },
    [updateBusiness],
  );

  const removeOwnershipFromOwnedBusiness = useCallback(
    async ({ owningBusiness, ownedBusiness }: { owningBusiness: DeletePrincipalBusinessRequest; ownedBusiness: BusinessApiModel }) => {
      await updateBusiness({
        businessId: ownedBusiness.id,
        updateBusinessPayload: {
          owningBusinesses: (ownedBusiness.owningBusinesses ?? []).filter(({ id }) => id !== owningBusiness.id),
          relatedBusinesses: (ownedBusiness.relatedBusinesses ?? []).filter((id) => id !== owningBusiness.id),
        },
      });
    },
    [updateBusiness],
  );

  const removeOwnership = useCallback(
    async ({ owningBusiness, ownedBusiness }: { owningBusiness: DeletePrincipalBusinessRequest; ownedBusiness: BusinessApiModel }) => {
      await removeOwnershipFromOwningBusiness({ owningBusiness, ownedBusiness });
      await removeOwnershipFromOwnedBusiness({ owningBusiness, ownedBusiness });
    },
    [removeOwnershipFromOwnedBusiness, removeOwnershipFromOwningBusiness],
  );

  const editOwningBusiness = useCallback(
    async (owningBusinessPayload: UpdateBusinessWithOwnershipRequest) => {
      await updateBusiness({ businessId: owningBusinessPayload.id, updateBusinessPayload: owningBusinessPayload });
    },
    [updateBusiness],
  );

  const editOwnedBusiness = useCallback(
    async ({ ownedBusiness, owner }: { ownedBusiness: BusinessApiModel; owner: { id: string; ownershipPercentage: number } }) => {
      await updateBusiness({
        businessId: ownedBusiness.id,
        updateBusinessPayload: {
          owningBusinesses: [...(ownedBusiness.owningBusinesses ?? []).filter(({ id }) => id !== owner.id), owner],
        },
      });
    },
    [updateBusiness],
  );

  const addBusiness = useCallback(
    async (owningBusiness: CreateBusinessWithOwnershipRequest) => {
      const businessAlreadyExists = applicationHasBusinessSelector(application, owningBusiness.id);
      await (businessAlreadyExists
        ? updateBusiness({ businessId: owningBusiness.id, updateBusinessPayload: owningBusiness })
        : createBusiness({
            business: { ...owningBusiness, people: [], applicationId: application.id, partnerId: application.leadingPartnerId },
            applicationId: application?.id,
          }));

      await assignOwnership({ owningBusiness, ownedBusiness: business });
    },
    [application, business, assignOwnership, createBusiness, updateBusiness],
  );

  const editBusiness = useCallback(
    async (updatedOwningBusiness: UpdateBusinessWithOwnershipRequest) => {
      const { ownershipPercentage, ...businessPayload } = updatedOwningBusiness;

      await editOwningBusiness(businessPayload);
      await editOwnedBusiness({
        ownedBusiness: business,
        owner: { id: updatedOwningBusiness.id, ownershipPercentage: ownershipPercentage ?? 0 },
      });
    },
    [business, editOwningBusiness, editOwnedBusiness],
  );

  const onEditPersonCard = useCallback(
    (id: string) => {
      const personToEdit = business.people.find((person) => person.id === id);
      setExistingPrincipal(personToEdit ? { ...personToEdit, isPrimaryContact: application.primaryContactPersonId === id } : null);
      setDialogMode('edit');
      setDialogType('person');
      setDialogOpen(true);
    },
    [application.primaryContactPersonId, business],
  );

  const onDeletePersonCard = useCallback(
    async (id: string) => {
      const updatedPeople = business.people.filter((person) => id !== person.id);
      await updateBusiness({ businessId: business.id, updateBusinessPayload: { people: updatedPeople } });
    },
    [business, updateBusiness],
  );

  const onEditBusinessCard = useCallback(
    (principalId: string) => {
      setExistingPrincipal(
        owningBusinesses
          .map(({ owner, ownershipPercentage }) => ({ ...owner, ownershipPercentage }))
          .find(({ id }) => id === principalId) ?? null,
      );
      setDialogMode('edit');
      setDialogType('business');
      setDialogOpen(true);
    },
    [owningBusinesses],
  );

  const onDeleteOwningBusinessCard = useCallback(
    async (ownerId: string) => {
      const owner = owningBusinesses.find((ownerBusiness) => ownerBusiness.owner.id === ownerId)?.owner;
      if (!owner) {
        return;
      }

      await removeOwnership({
        owningBusiness: { id: owner.id, relatedBusinesses: owner.relatedBusinesses ?? [] },
        ownedBusiness: business,
      });
    },
    [business, owningBusinesses, removeOwnership],
  );

  const onInvitePerson = useCallback(
    async (id: string) => {
      const person = business.people.find((s) => s.id === id);
      if (person) {
        await inviteToApplication({ personId: person.id });
      }
    },
    [business, inviteToApplication],
  );

  const handleClose: (principal?: ExistingPrincipal | null | undefined) => Promise<void> = useCallback(
    async (principal: ExistingPrincipal | null | undefined) => {
      setExistingPrincipal(null);

      if (!principal) {
        setDialogOpen(false);
        return;
      }

      let success = true;

      if (Object.keys(principal).includes('legalName')) {
        await (owningBusinesses.some(({ owner }) => owner.id === principal.id)
          ? editBusiness(principal as UpdateBusinessWithOwnershipRequest)
          : addBusiness(principal as CreateBusinessWithOwnershipRequest));
      } else if (business.people.some((s) => s.id === principal.id)) {
        await editPerson(principal as ExistingPrincipalPerson);
      } else {
        const relatedPerson = principal as ExistingPrincipalPerson;

        if (relatedPerson.email && business.people.some((p) => p.email?.toLowerCase() === relatedPerson.email!.toLowerCase())) {
          success = false;
          displayToast('This email address is already in use by another person in this application', 'error');
        } else {
          const personId = await addPerson(relatedPerson);

          if ((principal as ExistingPrincipalPerson).inviteToApplication && personId) {
            await inviteToApplication({ personId });
          }
        }
      }

      if (success) {
        setDialogOpen(false);
      }
    },
    [addBusiness, editBusiness, addPerson, editPerson, business, inviteToApplication, owningBusinesses],
  );

  return (
    <>
      <Flex flexDirection={'column'} flex={1} gap={4}>
        {collectedOwnershipPercentage < minRequiredOwnershipPercentage ? (
          <TotalOwnershipPercentageWarning minRequiredOwnershipPercentage={minRequiredOwnershipPercentage} />
        ) : null}
        {business.people.map((person) => (
          <PrincipalItem
            key={person.id}
            id={person.id}
            firstName={person.firstName}
            lastName={person.lastName}
            position={person.position}
            ownershipPercentage={person.ownershipPercentage}
            deleteEnabled
            onDelete={onDeletePersonCard}
            onEdit={onEditPersonCard}
            inviteAllowed
            hasBeenInvited={hasBeenInvitedByPersonId[person.id]}
            onInvite={onInvitePerson}
            itemType={'person'}
          />
        ))}
        {owningBusinesses.map(({ owner, ownershipPercentage }) => (
          <PrincipalItem
            key={owner.id}
            id={owner.id}
            firstName={owner.legalName ?? ''}
            lastName={''}
            ownershipPercentage={ownershipPercentage}
            deleteEnabled
            onDelete={onDeleteOwningBusinessCard}
            onEdit={onEditBusinessCard}
            itemType={'business'}
          />
        ))}
        {!business.people.length && !owningBusinesses.length ? (
          <Flex flexDirection={'column'} alignItems={'center'} gap={4}>
            <EmptyRelationsListImage />
            <Text variant={'body1'}>{'No principals were added yet'}</Text>
          </Flex>
        ) : null}
        <ModifyItemButton onClick={onAddPrincipalClick} text={t('principals.cta.add')} variant={'text'} />
      </Flex>
      {dialogOpen ? (
        <AddOrEditPrincipalDialog
          open={dialogOpen}
          handleClose={handleClose}
          remainingOwnershipPercentage={remainingOwnershipPercentage}
          existingPrincipal={existingPrincipal}
          requirementProperties={requirementProperties}
          isLoading={loading}
          dialogMode={dialogMode}
          dialogType={dialogType}
          existingEntityIds={existingEntityIds}
          application={application}
          partnerFeatureConfigurations={partnerFeatureConfigurations}
          disableEmailUpdate={disableEmailUpdate}
        />
      ) : null}
    </>
  );
};
