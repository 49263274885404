import { useCallback } from 'react';
import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import type { ApplicationApiModel } from '@lama/clients';
import { getApplication } from '../../shared/clients/applicationService';
import { useAuthentication } from '../authentication/useAuthentication';

export const useApplicationQuery = (applicationId: string | null | undefined, options?: UseQueryOptions<ApplicationApiModel | null>) => {
  const { token, isAuthenticated } = useAuthentication();

  const getApplicationQuery = useCallback(async () => {
    if (!applicationId || !isAuthenticated) {
      return null;
    }

    return getApplication(applicationId, token);
  }, [applicationId, isAuthenticated, token]);

  return useQuery<ApplicationApiModel | null>({
    queryKey: ['application', applicationId],
    queryFn: getApplicationQuery,
    ...options,
  });
};
