import React, { useMemo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { Flex, ProgressBar } from '@lama/design-system';
import type { ScreenProps } from '../ScreenProps';
import { BasicScreen } from '../shared/BasicScreen';
import { currentOnboardingApplicationIdState, partnerState } from '../../state/appState';
import { ConclusionImage } from './ConclusionImage';
import { CloudDataCollectionImage } from './CloudDataCollectionImage';
import { ConclusionImageV2 } from './ConclusionImageV2';

const conclusionImageByName: Record<string, React.FC> = {
  default: ConclusionImageV2,
  cloud: CloudDataCollectionImage,
  old: ConclusionImage,
};

interface ConclusionScreenProps extends ScreenProps {
  title?: string;
  subtitle?: string;
  imageName?: string;
}

const useProgress = (duration: number, steps = 100): number => {
  const [completed, setCompleted] = useState(0);

  useEffect(() => {
    const intervalTime = duration / steps;
    const increment = 100 / steps;

    let currentCompleted = 0;
    const interval = setInterval(() => {
      currentCompleted += increment;
      if (currentCompleted >= 100) {
        currentCompleted = 100;
        clearInterval(interval);
      }
      setCompleted(currentCompleted);
    }, intervalTime);

    return () => {
      clearInterval(interval);
    };
  }, [duration, steps]);

  return completed;
};

export default useProgress;

export const ConclusionScreen: React.FC<ConclusionScreenProps> = ({ title, subtitle, imageName = 'default', ...restProps }) => {
  const { t } = useTranslation();
  const Image = useMemo(() => conclusionImageByName[imageName], [imageName]);

  const navigate = useNavigate();
  const currentOnboardingApplicationId = useRecoilValue(currentOnboardingApplicationIdState);
  const partner = useRecoilValue(partnerState);
  const completed = useProgress(8000, 100);

  useEffect(() => {
    if (partner?.featureConfigurations?.navigateToManagementAfterOnboarding && currentOnboardingApplicationId && completed === 100) {
      navigate(`/applications/${currentOnboardingApplicationId}/overview`);
    }
  }, [currentOnboardingApplicationId, navigate, completed, partner?.featureConfigurations?.navigateToManagementAfterOnboarding]);

  return (
    <BasicScreen {...restProps} title={title ?? t('conclusion.title')} subtitle={subtitle} nextVisible={false} backVisible={false}>
      <Flex flexDirection={'column'} gap={6} justifyContent={'center'} alignItems={'center'} mt={4} width={'100%'}>
        <Image />
      </Flex>
      <ProgressBar completed={completed} />
    </BasicScreen>
  );
};
