import { useTheme } from '@mui/material';
import type { FC } from 'react';
import React from 'react';

export const CollateralEmptyState: FC = () => {
  const theme = useTheme();

  return (
    <svg width={'282'} height={'269'} viewBox={'0 0 282 269'} fill={'none'} xmlns={'http://www.w3.org/2000/svg'}>
      <path
        d={
          'M169.168 0.0655242C160.455 0.0526031 80.9507 1.26396 37.5058 66.6999C14.3797 101.532 -1.13709 158.489 21.5325 189.525C28.2256 198.689 31.7356 196.674 67.5193 218.365C116.637 248.142 116.407 255.807 136.739 262.62C163.207 271.49 202.319 271.471 231.614 249.692C264.856 224.981 269.771 183.911 274.696 142.78C279.527 102.414 284.423 61.5153 258.723 31.3929C232.074 0.165664 189.251 0.0978271 169.168 0.0655242Z'
        }
        fill={'url(#paint0_linear_12120_151428)'}
      />
      <path
        d={
          'M270.832 75.9282C270.599 75.9282 270.371 75.8337 270.209 75.6446C269.904 75.2998 269.937 74.7715 270.282 74.4656L272.334 72.6471C272.679 72.3412 273.207 72.3746 273.513 72.7194C273.819 73.0642 273.785 73.5925 273.441 73.8984L271.388 75.7169C271.227 75.8559 271.033 75.9282 270.838 75.9282H270.832Z'
        }
        fill={'black'}
      />
      <path
        d={
          'M278.594 69.0581C278.366 69.0581 278.132 68.9636 277.971 68.7745C277.665 68.4297 277.699 67.9014 278.043 67.5955L280.101 65.777C280.446 65.4711 280.974 65.5045 281.28 65.8493C281.586 66.1941 281.553 66.7224 281.208 67.0282L279.15 68.8468C278.989 68.9858 278.794 69.0581 278.6 69.0581H278.594Z'
        }
        fill={'black'}
      />
      <path
        d={
          'M280.086 76.49C279.858 76.49 279.625 76.3954 279.464 76.2064L277.645 74.1487C277.339 73.8039 277.373 73.2756 277.717 72.9697C278.062 72.6638 278.59 72.6972 278.896 73.042L280.715 75.0997C281.021 75.4445 280.987 75.9728 280.643 76.2787C280.481 76.4177 280.287 76.49 280.092 76.49H280.086Z'
        }
        fill={'black'}
      />
      <path
        d={
          'M273.217 68.7297C272.984 68.7297 272.756 68.6352 272.594 68.4461L270.776 66.3884C270.47 66.0436 270.503 65.5153 270.848 65.2095C271.193 64.9036 271.721 64.937 272.027 65.2818L273.846 67.3394C274.152 67.6842 274.118 68.2125 273.773 68.5184C273.612 68.6574 273.417 68.7297 273.223 68.7297H273.217Z'
        }
        fill={'black'}
      />
      <path
        d={
          'M0.832323 145.928C0.59875 145.928 0.370733 145.834 0.209457 145.645C-0.0964114 145.3 -0.0630412 144.771 0.281756 144.466L2.33386 142.647C2.67866 142.341 3.20697 142.375 3.51284 142.719C3.81871 143.064 3.78535 143.592 3.44055 143.898L1.38845 145.717C1.22717 145.856 1.03252 145.928 0.837881 145.928H0.832323Z'
        }
        fill={'black'}
      />
      <path
        d={
          'M8.59403 139.058C8.36602 139.058 8.13245 138.964 7.97118 138.774C7.66531 138.43 7.69868 137.901 8.04348 137.595L10.1011 135.777C10.4459 135.471 10.9742 135.504 11.2801 135.849C11.586 136.194 11.5526 136.722 11.2078 137.028L9.15016 138.847C8.98888 138.986 8.79424 139.058 8.5996 139.058H8.59403Z'
        }
        fill={'black'}
      />
      <path
        d={
          'M10.0864 146.49C9.85838 146.49 9.62481 146.395 9.46353 146.206L7.64501 144.149C7.33914 143.804 7.3725 143.276 7.7173 142.97C8.0621 142.664 8.59042 142.697 8.89629 143.042L10.7148 145.1C11.0207 145.444 10.9873 145.973 10.6425 146.279C10.4812 146.418 10.2866 146.49 10.092 146.49H10.0864Z'
        }
        fill={'black'}
      />
      <path
        d={
          'M3.21725 138.73C2.98368 138.73 2.75567 138.635 2.59439 138.446L0.775864 136.388C0.469995 136.044 0.503365 135.515 0.848163 135.209C1.19296 134.904 1.72128 134.937 2.02714 135.282L3.84567 137.339C4.15154 137.684 4.11817 138.213 3.77338 138.518C3.6121 138.657 3.41745 138.73 3.22281 138.73H3.21725Z'
        }
        fill={'black'}
      />
      <path
        d={
          'M242.535 48.0715C242.462 48.0715 242.393 48.0715 242.32 48.0669C241.377 48.0117 240.514 47.5888 239.889 46.8809C239.264 46.173 238.951 45.2628 239.006 44.3205C239.061 43.3782 239.484 42.514 240.192 41.8888C240.9 41.2636 241.8 40.9511 242.751 41.0062C244.695 41.1257 246.184 42.8036 246.065 44.7526C245.95 46.6281 244.387 48.0761 242.535 48.0761V48.0715ZM242.531 42.3761C242.002 42.3761 241.502 42.5645 241.106 42.9185C240.674 43.3 240.417 43.8286 240.38 44.4032C240.307 45.5892 241.217 46.6189 242.402 46.6878C243.588 46.7614 244.613 45.8512 244.686 44.6653C244.76 43.4793 243.85 42.4542 242.664 42.3807C242.618 42.3807 242.572 42.3807 242.531 42.3807V42.3761Z'
        }
        fill={'black'}
      />
      <g clipPath={'url(#clip0_12120_151428)'}>
        <path
          d={
            'M251.283 218.801H251.076L251.073 218.804H46.346C44.5094 218.804 43.0176 217.311 43.0176 215.473V112.023C43.0176 108.676 45.7292 105.963 49.0734 105.963H247.276C250.686 105.963 253.454 108.73 253.454 112.145V216.628C253.454 217.828 252.481 218.801 251.283 218.801Z'
          }
          fill={theme.palette.primary.light}
          stroke={'black'}
        />
        <path
          d={
            'M227.882 165.377H217.097C216.574 165.377 216.15 165.801 216.15 166.324V182.88C216.15 183.403 216.574 183.827 217.097 183.827H227.882C228.405 183.827 228.829 183.403 228.829 182.88V166.324C228.829 165.801 228.405 165.377 227.882 165.377Z'
          }
          fill={'white'}
        />
        <path
          d={
            'M242.662 165.377H232.067C231.492 165.377 231.025 165.843 231.025 166.419V182.785C231.025 183.361 231.492 183.827 232.067 183.827H242.662C243.237 183.827 243.704 183.361 243.704 182.785V166.419C243.704 165.843 243.237 165.377 242.662 165.377Z'
          }
          fill={'white'}
        />
        <path
          d={
            'M228.152 185.88H216.827C216.453 185.88 216.15 186.183 216.15 186.557V203.653C216.15 204.027 216.453 204.33 216.827 204.33H228.152C228.526 204.33 228.829 204.027 228.829 203.653V186.557C228.829 186.183 228.526 185.88 228.152 185.88Z'
          }
          fill={'white'}
        />
        <path
          d={
            'M242.635 185.88H232.094C231.504 185.88 231.025 186.358 231.025 186.949V203.261C231.025 203.852 231.504 204.33 232.094 204.33H242.635C243.225 204.33 243.704 203.852 243.704 203.261V186.949C243.704 186.358 243.225 185.88 242.635 185.88Z'
          }
          fill={'white'}
        />
        <path
          d={
            'M136.239 69.5161L126.095 111.04L34.7526 110.582L34.7524 110.582C33.8504 110.578 33.2495 109.655 33.5999 108.831L60.5278 72.1061L60.528 72.1058C61.0259 71.4258 61.8216 71.0309 62.6606 71.0482L62.6611 71.0483C68.075 71.154 86.6423 70.7659 103.837 70.3522C112.439 70.1452 120.703 69.9317 126.817 69.7699C129.874 69.689 132.393 69.621 134.148 69.5732C135.026 69.5494 135.712 69.5306 136.179 69.5177L136.239 69.5161Z'
          }
          fill={theme.palette.primary.main}
          stroke={'black'}
        />
        <path
          d={
            'M263.247 110.267L263.247 110.267L171.904 110.725L161.76 69.2012L161.82 69.2029C162.287 69.2157 162.974 69.2346 163.851 69.2584C165.606 69.3062 168.126 69.3741 171.182 69.455C177.296 69.6168 185.56 69.8304 194.162 70.0374C211.357 70.4511 229.924 70.8391 235.338 70.7334L235.339 70.7334C236.178 70.716 236.973 71.111 237.471 71.791L237.472 71.7913L264.399 108.516C264.75 109.34 264.149 110.263 263.247 110.267Z'
          }
          fill={theme.palette.primary.main}
          stroke={'black'}
        />
        <path
          d={
            'M76.6711 165.062H65.8862C65.3633 165.062 64.9395 165.486 64.9395 166.009V182.565C64.9395 183.089 65.3633 183.513 65.8862 183.513H76.6711C77.1939 183.513 77.6178 183.089 77.6178 182.565V166.009C77.6178 165.486 77.1939 165.062 76.6711 165.062Z'
          }
          fill={'white'}
        />
        <path
          d={
            'M61.7011 165.062H51.1061C50.5308 165.062 50.0645 165.529 50.0645 166.104V182.47C50.0645 183.046 50.5308 183.513 51.1061 183.513H61.7011C62.2764 183.513 62.7428 183.046 62.7428 182.47V166.104C62.7428 165.529 62.2764 165.062 61.7011 165.062Z'
          }
          fill={'white'}
        />
        <path
          d={
            'M76.9411 185.565H65.6161C65.2424 185.565 64.9395 185.868 64.9395 186.242V203.338C64.9395 203.712 65.2424 204.016 65.6161 204.016H76.9411C77.3148 204.016 77.6178 203.712 77.6178 203.338V186.242C77.6178 185.868 77.3148 185.565 76.9411 185.565Z'
          }
          fill={'white'}
        />
        <path
          d={
            'M61.6744 185.565H51.1329C50.5428 185.565 50.0645 186.043 50.0645 186.634V202.946C50.0645 203.537 50.5428 204.016 51.1329 204.016H61.6744C62.2645 204.016 62.7428 203.537 62.7428 202.946V186.634C62.7428 186.043 62.2645 185.565 61.6744 185.565Z'
          }
          fill={'white'}
        />
        <path
          d={
            'M227.585 120.537H216.8C216.277 120.537 215.854 120.961 215.854 121.485V138.041C215.854 138.564 216.277 138.988 216.8 138.988H227.585C228.108 138.988 228.532 138.564 228.532 138.041V121.485C228.532 120.961 228.108 120.537 227.585 120.537Z'
          }
          fill={'white'}
        />
        <path
          d={
            'M242.365 120.537H231.77C231.195 120.537 230.729 121.004 230.729 121.58V137.946C230.729 138.521 231.195 138.988 231.77 138.988H242.365C242.94 138.988 243.407 138.521 243.407 137.946V121.58C243.407 121.004 242.94 120.537 242.365 120.537Z'
          }
          fill={'white'}
        />
        <path
          d={
            'M227.855 141.037H216.53C216.156 141.037 215.854 141.34 215.854 141.714V158.811C215.854 159.185 216.156 159.488 216.53 159.488H227.855C228.229 159.488 228.532 159.185 228.532 158.811V141.714C228.532 141.34 228.229 141.037 227.855 141.037Z'
          }
          fill={'white'}
        />
        <path
          d={
            'M242.338 141.037H231.797C231.207 141.037 230.729 141.516 230.729 142.106V158.419C230.729 159.009 231.207 159.488 231.797 159.488H242.338C242.929 159.488 243.407 159.009 243.407 158.419V142.106C243.407 141.516 242.929 141.037 242.338 141.037Z'
          }
          fill={'white'}
        />
        <path
          d={
            'M76.3742 120.223H65.5893C65.0664 120.223 64.6426 120.647 64.6426 121.17V137.726C64.6426 138.249 65.0664 138.673 65.5893 138.673H76.3742C76.8971 138.673 77.3209 138.249 77.3209 137.726V121.17C77.3209 120.647 76.8971 120.223 76.3742 120.223Z'
          }
          fill={'white'}
        />
        <path
          d={
            'M61.4042 120.223H50.8093C50.234 120.223 49.7676 120.689 49.7676 121.265V137.631C49.7676 138.207 50.234 138.673 50.8093 138.673H61.4042C61.9795 138.673 62.4459 138.207 62.4459 137.631V121.265C62.4459 120.689 61.9795 120.223 61.4042 120.223Z'
          }
          fill={'white'}
        />
        <path
          d={
            'M76.6443 140.722H65.3192C64.9455 140.722 64.6426 141.026 64.6426 141.4V158.496C64.6426 158.87 64.9455 159.173 65.3192 159.173H76.6443C77.018 159.173 77.3209 158.87 77.3209 158.496V141.4C77.3209 141.026 77.018 140.722 76.6443 140.722Z'
          }
          fill={'white'}
        />
        <path
          d={
            'M61.3775 140.722H50.836C50.2459 140.722 49.7676 141.201 49.7676 141.792V158.104C49.7676 158.694 50.2459 159.173 50.836 159.173H61.3775C61.9676 159.173 62.4459 158.694 62.4459 158.104V141.792C62.4459 141.201 61.9676 140.722 61.3775 140.722Z'
          }
          fill={'white'}
        />
        <path
          d={
            'M86.2592 215.728L85.6211 99.7405L147.232 68.3359L207.609 100.854L208.075 218.074C208.039 219.66 203.688 220.396 202.228 220.382L89.5267 219.312C87.7193 219.295 86.2592 217.697 86.2592 215.728Z'
          }
          fill={'white'}
        />
        <path
          d={
            'M198.421 163.925H187.636C187.113 163.925 186.689 164.349 186.689 164.872V181.401C186.689 181.924 187.113 182.349 187.636 182.349H198.421C198.944 182.349 199.368 181.924 199.368 181.401V164.872C199.368 164.349 198.944 163.925 198.421 163.925Z'
          }
          fill={theme.palette.primary.light}
        />
        <path
          d={
            'M183.453 163.925H172.858C172.283 163.925 171.816 164.391 171.816 164.967V181.306C171.816 181.882 172.283 182.349 172.858 182.349H183.453C184.028 182.349 184.495 181.882 184.495 181.306V164.967C184.495 164.391 184.028 163.925 183.453 163.925Z'
          }
          fill={theme.palette.primary.light}
        />
        <path
          d={
            'M198.691 184.395H187.366C186.992 184.395 186.689 184.698 186.689 185.072V202.142C186.689 202.516 186.992 202.819 187.366 202.819H198.691C199.065 202.819 199.368 202.516 199.368 202.142V185.072C199.368 184.698 199.065 184.395 198.691 184.395Z'
          }
          fill={theme.palette.primary.light}
        />
        <path
          d={
            'M183.422 184.395H172.881C172.291 184.395 171.812 184.873 171.812 185.464V201.75C171.812 202.34 172.291 202.819 172.881 202.819H183.422C184.013 202.819 184.491 202.34 184.491 201.75V185.464C184.491 184.873 184.013 184.395 183.422 184.395Z'
          }
          fill={theme.palette.primary.light}
        />
        <path
          d={
            'M159.097 93.5338H148.312C147.789 93.5338 147.365 93.9579 147.365 94.4811V107.494C147.365 108.018 147.789 108.442 148.312 108.442H159.097C159.62 108.442 160.044 108.018 160.044 107.494V94.4811C160.044 93.9579 159.62 93.5338 159.097 93.5338Z'
          }
          fill={theme.palette.primary.main}
        />
        <path
          d={
            'M144.129 93.5338H133.534C132.959 93.5338 132.492 94.0005 132.492 94.5762V107.399C132.492 107.975 132.959 108.442 133.534 108.442H144.129C144.704 108.442 145.171 107.975 145.171 107.399V94.5762C145.171 94.0005 144.704 93.5338 144.129 93.5338Z'
          }
          fill={theme.palette.primary.main}
        />
        <path
          d={
            'M159.367 110.099H148.042C147.668 110.099 147.365 110.402 147.365 110.776V124.33C147.365 124.703 147.668 125.007 148.042 125.007H159.367C159.741 125.007 160.044 124.703 160.044 124.33V110.776C160.044 110.402 159.741 110.099 159.367 110.099Z'
          }
          fill={theme.palette.primary.main}
        />
        <path
          d={
            'M144.098 110.099H133.557C132.967 110.099 132.488 110.577 132.488 111.168V123.938C132.488 124.528 132.967 125.007 133.557 125.007H144.098C144.688 125.007 145.167 124.528 145.167 123.938V111.168C145.167 110.577 144.688 110.099 144.098 110.099Z'
          }
          fill={theme.palette.primary.main}
        />
        <path
          d={
            'M120.446 163.865H109.662C109.139 163.865 108.715 164.289 108.715 164.812V181.369C108.715 181.892 109.139 182.316 109.662 182.316H120.446C120.969 182.316 121.393 181.892 121.393 181.369V164.812C121.393 164.289 120.969 163.865 120.446 163.865Z'
          }
          fill={theme.palette.primary.light}
        />
        <path
          d={
            'M105.476 163.865H94.8815C94.3062 163.865 93.8398 164.332 93.8398 164.907V181.273C93.8398 181.849 94.3062 182.316 94.8815 182.316H105.476C106.052 182.316 106.518 181.849 106.518 181.273V164.907C106.518 164.332 106.052 163.865 105.476 163.865Z'
          }
          fill={theme.palette.primary.light}
        />
        <path
          d={
            'M120.717 184.365H109.391C109.018 184.365 108.715 184.668 108.715 185.042V202.139C108.715 202.513 109.018 202.816 109.391 202.816H120.717C121.09 202.816 121.393 202.513 121.393 202.139V185.042C121.393 184.668 121.09 184.365 120.717 184.365Z'
          }
          fill={theme.palette.primary.light}
        />
        <path
          d={
            'M105.45 184.365H94.9082C94.3182 184.365 93.8398 184.844 93.8398 185.434V201.747C93.8398 202.337 94.3182 202.816 94.9082 202.816H105.45C106.04 202.816 106.518 202.337 106.518 201.747V185.434C106.518 184.844 106.04 184.365 105.45 184.365Z'
          }
          fill={theme.palette.primary.light}
        />
        <path
          d={
            'M148.076 60.7127L212.785 95.2711C214.156 96.0046 214.417 97.4924 213.346 98.4813L207.906 103.5L147.604 72.5262C147.224 72.3302 146.734 72.3302 146.351 72.5232L84.8708 103.565L81.351 99.3693C79.9028 97.6439 80.4993 95.3364 82.6925 94.1753L145.879 60.7068C146.541 60.3564 147.414 60.3564 148.076 60.7098V60.7127Z'
          }
          fill={theme.palette.primary.main}
        />
        <path
          d={
            'M84.8708 104.011C84.7432 104.011 84.6156 103.954 84.5295 103.851L81.0097 99.6544C80.2678 98.7694 79.974 97.7033 80.1788 96.652C80.4073 95.4819 81.2472 94.4366 82.4818 93.7803L145.672 60.3148C146.467 59.896 147.491 59.896 148.283 60.3177L212.993 94.8761C213.809 95.3097 214.331 96.0135 214.432 96.8005C214.524 97.5281 214.239 98.2616 213.643 98.808L208.203 103.827C208.066 103.954 207.864 103.981 207.698 103.895L147.399 72.9211C147.147 72.7905 146.805 72.7905 146.55 72.9211L85.0667 103.963C85.0044 103.996 84.9331 104.011 84.8678 104.011H84.8708ZM146.975 60.8909C146.663 60.8909 146.354 60.9622 146.087 61.1017L82.9002 94.5673C81.903 95.0959 81.2293 95.9155 81.0543 96.8213C80.9029 97.6053 81.1284 98.4071 81.6923 99.0812L84.9866 103.007L146.149 72.1223C146.66 71.8639 147.295 71.8639 147.805 72.1282L207.832 102.96L213.043 98.1517C213.429 97.7953 213.607 97.3558 213.551 96.9133C213.488 96.4144 213.132 95.9601 212.574 95.6631L147.865 61.1047C147.598 60.9622 147.286 60.8909 146.975 60.8909Z'
          }
          fill={'black'}
        />
        <path
          d={
            'M207.885 205.396C207.641 205.396 207.442 205.2 207.439 204.954L206.84 122.851C206.84 122.604 207.036 122.405 207.282 122.402H207.285C207.528 122.402 207.727 122.598 207.73 122.845L208.33 204.948C208.33 205.194 208.134 205.393 207.887 205.396H207.885Z'
          }
          fill={'#0C2007'}
        />
        <path
          d={
            'M88.6639 142.97C88.4176 142.97 88.2188 142.771 88.2188 142.525C88.2188 142.278 88.4176 142.079 88.6639 142.079L204.582 141.889C204.829 141.889 205.027 142.088 205.027 142.335C205.027 142.581 204.829 142.78 204.582 142.78L88.6639 142.97Z'
          }
          fill={theme.palette.primary.light}
        />
        <path
          d={
            'M129.922 219.862H129.917L92.8045 219.44C92.5582 219.44 92.3623 219.235 92.3653 218.989C92.3653 218.745 92.5671 218.549 92.8104 218.549H92.8164L129.928 218.971C130.175 218.971 130.371 219.176 130.368 219.422C130.368 219.666 130.166 219.862 129.922 219.862Z'
          }
          fill={theme.palette.primary.light}
        />
        <path
          d={
            'M194.483 220.361L166.437 220.331C166.191 220.331 165.992 220.132 165.992 219.886C165.992 219.639 166.191 219.44 166.437 219.44L194.483 219.47C194.729 219.47 194.928 219.669 194.928 219.915C194.928 220.162 194.729 220.361 194.483 220.361Z'
          }
          fill={theme.palette.primary.light}
        />
        <path
          d={
            'M161.681 150.519H131.46C130.477 150.519 129.68 151.317 129.68 152.301V218.439C129.68 219.423 130.477 220.221 131.46 220.221H161.681C162.665 220.221 163.462 219.423 163.462 218.439V152.301C163.462 151.317 162.665 150.519 161.681 150.519Z'
          }
          fill={'white'}
        />
        <path
          d={
            'M160.419 152.461H132.588C131.932 152.461 131.4 152.993 131.4 153.649V216.571C131.4 217.227 131.932 217.759 132.588 217.759H160.419C161.075 217.759 161.606 217.227 161.606 216.571V153.649C161.606 152.993 161.075 152.461 160.419 152.461Z'
          }
          fill={theme.palette.primary.light}
        />
        <path
          d={
            'M167.527 215.475H125.465C125.001 215.475 124.625 215.852 124.625 216.316V220.714C124.625 221.178 125.001 221.554 125.465 221.554H167.527C167.991 221.554 168.367 221.178 168.367 220.714V216.316C168.367 215.852 167.991 215.475 167.527 215.475Z'
          }
          fill={theme.palette.primary.light}
        />
        <path
          d={
            'M125.432 222C124.741 222 124.18 221.442 124.18 220.753V216.28C124.18 215.591 124.738 215.033 125.426 215.033L152.569 215.078C152.816 215.078 153.015 215.276 153.015 215.523C153.015 215.769 152.816 215.968 152.569 215.968L125.426 215.924C125.23 215.924 125.07 216.084 125.07 216.28V220.753C125.07 220.949 125.23 221.109 125.426 221.109L164.461 220.744C164.711 220.756 164.909 220.94 164.909 221.186C164.909 221.433 164.714 221.635 164.467 221.635L125.429 222H125.432Z'
          }
          fill={'#0C2007'}
        />
        <path
          d={
            'M131.757 210.157C131.51 210.157 131.312 209.955 131.314 209.708L131.457 179.955C131.457 179.708 131.656 179.512 131.902 179.512C132.148 179.512 132.347 179.714 132.344 179.961L132.202 209.714C132.202 209.961 132.003 210.157 131.757 210.157Z'
          }
          fill={'#0C2007'}
        />
        <path
          d={
            'M137.445 173.552C137.3 173.552 137.154 173.481 137.071 173.347C136.938 173.14 136.997 172.866 137.205 172.733L156.792 160.135C157 160.002 157.276 160.061 157.407 160.269C157.54 160.477 157.481 160.75 157.273 160.884L137.686 173.481C137.611 173.529 137.528 173.552 137.445 173.552Z'
          }
          fill={'white'}
        />
        <path
          d={
            'M136.65 181.96C136.505 181.96 136.359 181.888 136.276 181.755C136.143 181.547 136.202 181.274 136.41 181.14L154.629 169.419C154.837 169.285 155.11 169.344 155.243 169.552C155.377 169.76 155.318 170.033 155.11 170.167L136.891 181.888C136.817 181.936 136.733 181.96 136.65 181.96Z'
          }
          fill={'white'}
        />
      </g>
      <defs>
        <linearGradient
          id={'paint0_linear_12120_151428'}
          x1={'145'}
          y1={'-4.91597e-06'}
          x2={'144.314'}
          y2={'267.998'}
          gradientUnits={'userSpaceOnUse'}
        >
          <stop offset={'0.0677083'} stopColor={'#E9E9E9'} />
          <stop offset={'1'} stopColor={'#FCFCFC'} stopOpacity={'0.6'} />
        </linearGradient>
        <clipPath id={'clip0_12120_151428'}>
          <rect width={'232'} height={'162'} fill={'white'} transform={'translate(33 60)'} />
        </clipPath>
      </defs>
    </svg>
  );
};
