import React from 'react';
import type { FC, ReactNode } from 'react';
import { Lock, ElectricBolt } from '@mui/icons-material';
import { Box, Avatar, Typography, useTheme, useMediaQuery } from '@mui/material';
import { grey } from '@mui/material/colors';
import { Flex } from '@lama/design-system';

interface EmptySystemLogoProps {
  logo: ReactNode;
}

export const EmptySystemLogo: FC<EmptySystemLogoProps> = ({ logo }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const smallAvatarSize = isMobile ? '32px' : '40px';
  const smallBoxWidth = isMobile ? '88px' : '124px';
  const smallBoxHeight = isMobile ? '96px' : '132px';

  const mainAvatarSize = isMobile ? '48px' : '72px';
  const mainBoxWidth = isMobile ? '140px' : '200px';
  const mainBoxHeight = isMobile ? '116px' : '164px';

  const fontSize = isMobile ? 'small' : 'medium';
  const textVariant = isMobile ? 'subtitle3' : 'subtitle2';

  return (
    <Flex flexDirection={'row'} alignItems={'center'} gap={-2}>
      <Box
        width={smallBoxWidth}
        height={smallBoxHeight}
        border={1}
        borderColor={grey[300]}
        borderRadius={1}
        justifyContent={'center'}
        display={'flex'}
        alignItems={'center'}
      >
        <Flex flexDirection={'column'} alignItems={'center'} gap={2}>
          <Avatar sx={{ bgcolor: 'success.light', width: smallAvatarSize, height: smallAvatarSize }}>
            <Lock color={'success'} fontSize={fontSize} />
          </Avatar>
          <Typography variant={textVariant} maxWidth={'80%'} textAlign={'center'}>
            {'Bank Level Security'}
          </Typography>
        </Flex>
      </Box>
      <Box
        width={mainBoxWidth}
        height={mainBoxHeight}
        border={1}
        borderColor={grey[300]}
        borderRadius={1}
        justifyContent={'center'}
        display={'flex'}
        alignItems={'center'}
        bgcolor={'white'}
        zIndex={1}
      >
        <Flex flexDirection={'column'} alignItems={'center'} gap={2} width={'100%'}>
          <Avatar sx={{ bgcolor: 'success.light', width: mainAvatarSize, height: mainAvatarSize }}>{logo}</Avatar>
          <Typography variant={textVariant} maxWidth={'80%'} textAlign={'center'}>
            {'One-click Integration'}
          </Typography>
        </Flex>
      </Box>
      <Box
        width={smallBoxWidth}
        height={smallBoxHeight}
        border={1}
        borderColor={grey[300]}
        borderRadius={1}
        justifyContent={'center'}
        display={'flex'}
        alignItems={'center'}
      >
        <Flex flexDirection={'column'} alignItems={'center'} gap={2} width={'100%'}>
          <Avatar sx={{ bgcolor: 'success.light', width: smallAvatarSize, height: smallAvatarSize }}>
            <ElectricBolt color={'success'} fontSize={fontSize} />
          </Avatar>
          <Typography variant={textVariant} maxWidth={'80%'} textAlign={'center'}>
            {isMobile ? 'Higher Approval' : 'Improve Approval Rates'}
          </Typography>
        </Flex>
      </Box>
    </Flex>
  );
};
