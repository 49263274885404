import React from 'react';
import ReactDOM from 'react-dom/client';
import { RecoilRoot } from 'recoil';
import setupLocatorUI from '@locator/runtime';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { Replay, init as initSentry, BrowserTracing } from '@sentry/react';
import { ldClientSideId } from './framework/environment';
import App from './App';
import { initGA } from './shared/utils/analytics';

if (process.env.NODE_ENV === 'development') {
  setupLocatorUI();
}

if (process.env.NODE_ENV === 'production' && !navigator.userAgent.includes('DatadogSynthetics')) {
  initSentry({
    dsn: 'https://4b26aee3d49846a595abca908c48b75f@o4505183063506944.ingest.sentry.io/4505183181799424',
    integrations: [new BrowserTracing(), new Replay({ networkDetailAllowUrls: ['api.lama.ai'], blockAllMedia: false })],
    replaysSessionSampleRate: 1,
    replaysOnErrorSampleRate: 1,
  });
  initGA();
}

await (async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: ldClientSideId,
    reactOptions: {
      useCamelCaseFlagKeys: false,
    },
  });
  ReactDOM.createRoot(document.querySelector('#root')!).render(
    <React.StrictMode>
      <RecoilRoot>
        <LDProvider>
          <App />
        </LDProvider>
      </RecoilRoot>
    </React.StrictMode>,
  );
})();
