import { css } from 'styled-components';

export const italicsStyle = css`
  font-style: italic;
`;
export const underlineStyle = css`
  text-decoration: underline;
`;
export const strikeThroughStyle = css`
  text-decoration: line-through;
`;
export const upperCaseStyle = css`
  text-transform: uppercase;
`;

export const capitalizeStyle = css`
  text-transform: capitalize;
`;

export const paragraphStyle = css`
  line-height: 1.5;
`;

export const ellipsisStyle = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const lineClampStyle = (clampSize: number) => css`
  display: -webkit-box;
  -webkit-line-clamp: ${clampSize};
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const codeStyle = css`
  background-color: ${({ theme }) => theme.text.code.backgroundColor};
  border: ${({ theme }) => theme.text.code.borderColor};
  border-radius: 2px;
  font-family: ${({ theme }) => theme.fonts.code};
`;

export const noWrapStyle = css`
  white-space: nowrap;
`;
