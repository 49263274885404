import { QueryClient } from '@tanstack/react-query';
import axios from 'axios';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 120_000,
      refetchOnWindowFocus: true,
      retry: (failureCount: number, error: any): boolean => {
        if (error?.response?.status === 404) {
          return false;
        }
        if (axios.isAxiosError(error) && error.response?.status !== 500) {
          return false;
        }
        return failureCount < 3;
      },
    },
  },
});
