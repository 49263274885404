import React from 'react';
import { useTheme } from '@mui/material';

export const LoanTypeIcon: React.FC = () => {
  const theme = useTheme();

  return (
    <svg width={'72'} height={'72'} viewBox={'0 0 72 72'} fill={'none'} xmlns={'http://www.w3.org/2000/svg'}>
      <circle cx={'36'} cy={'36'} r={'36'} fill={theme.palette.primary.light} />
      <mask
        id={'mask0_11584_148665'}
        style={{ maskType: 'alpha' }}
        maskUnits={'userSpaceOnUse'}
        x={'16'}
        y={'16'}
        width={'40'}
        height={'40'}
      >
        <rect x={'16'} y={'16'} width={'40'} height={'40'} fill={'#D9D9D9'} />
      </mask>
      <g mask={'url(#mask0_11584_148665)'}>
        <path
          d={
            'M37.541 34.4167L31.041 27.9167L32.8327 26.1667L37.541 30.875L46.9993 21.4583L48.7493 23.2083L37.541 34.4167ZM39.041 52.3333L26.8743 48.8333V51.125H18.541V35.1667H30.4577L40.666 38.9167C41.416 39.2222 42.041 39.7289 42.541 40.4367C43.041 41.1455 43.291 41.9722 43.291 42.9167H47.4577C48.6521 42.9167 49.6316 43.3261 50.396 44.145C51.1594 44.965 51.541 46.0278 51.541 47.3333V48.4167L39.041 52.3333ZM21.041 48.625H24.3743V37.6667H21.041V48.625ZM38.9577 49.6667L48.916 46.5833C48.8605 46.2222 48.701 45.9372 48.4377 45.7283C48.1732 45.5206 47.8466 45.4167 47.4577 45.4167H39.5827C38.7771 45.4167 38.0132 45.3678 37.291 45.27C36.5688 45.1733 35.8605 45 35.166 44.75L32.0827 43.7083L32.8743 41.2917L36.1243 42.375C36.6521 42.5694 37.2566 42.7083 37.9377 42.7917C38.6177 42.875 39.5688 42.9167 40.791 42.9167C40.791 42.5 40.701 42.1455 40.521 41.8533C40.3399 41.5622 40.0966 41.3611 39.791 41.25L30.041 37.6667H26.8743V46.25L38.9577 49.6667Z'
          }
          fill={theme.palette.primary.main}
        />
      </g>
    </svg>
  );
};
