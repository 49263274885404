import { useMutation, useQueryClient } from '@tanstack/react-query';
import type { ApplicationRequirementUpdateApiModel } from '@lama/clients';

import { displayToast } from '@lama/app-components';
import { updateApplicationRequirement } from '../../shared/clients/applicationService';
import { useAuthentication } from '../authentication/useAuthentication';

export const useUpdateRequirementMutation = (applicationId?: string, requirementId?: string) => {
  const queryClient = useQueryClient();
  const { token, isAuthenticated } = useAuthentication();

  const updateRequirementMutation = useMutation({
    mutationFn: async ({ updateRequirementPayload }: { updateRequirementPayload: ApplicationRequirementUpdateApiModel }) => {
      if (!isAuthenticated || !applicationId || !requirementId) {
        return null;
      }

      await updateApplicationRequirement(applicationId, requirementId, updateRequirementPayload, token);
    },
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: ['applicationRequirements', applicationId],
        }),
      ]);
    },
    onError: () => {
      displayToast('Something went wrong while updating the requirement. Please contact support.', 'error');
    },
  });

  return updateRequirementMutation;
};
