import ReactGA from 'react-ga4';

export const initGA = () => {
  ReactGA.initialize([{ trackingId: 'G-JFQ9Z4SFCT' }, { trackingId: 'AW-11126437242' }]);
};

export const analyticsEvent = ({
  category,
  action,
  label,
  value,
  params,
}: {
  category: string;
  action: string;
  label?: string;
  value?: number;
  params?: Record<string, string>;
}) => {
  if (ReactGA.isInitialized) {
    ReactGA.event(
      {
        category,
        action,
        label,
        value,
      },
      params,
    );
  }
};
