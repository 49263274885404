import type {
  ApplicationApiModel,
  ApplicationCreateApiModel,
  ApplicationRequirementUpdateApiModel,
  ApplicationUpdateApiModel,
} from '@lama/clients';
import { createApplicationServiceClient } from '@lama/clients';
import type { EvaluatedApplicationRequirement, UnderwritingDecision } from '@lama/contracts';
import type { Alert } from '@lama/alerts-service-client';
import { applicationServiceBaseUrl } from '../../framework/environment';

export const applicationServiceClient = createApplicationServiceClient(applicationServiceBaseUrl as string);

export const createApplication = (application: ApplicationCreateApiModel, token: string) =>
  applicationServiceClient.createApplication({ application, token });

export const updateApplication = (applicationId: string, applicationUpdate: ApplicationUpdateApiModel, token: string) =>
  applicationServiceClient.updateApplication({ applicationId, application: applicationUpdate, token });

export const getApplicationsByPerson = async (personId: string, token: string): Promise<ApplicationApiModel[]> =>
  applicationServiceClient.getApplicationsByPerson(personId, token);

export const getApplicationsByUser = async (userId: string, partner: string, token: string): Promise<ApplicationApiModel[]> =>
  applicationServiceClient.getApplicationsByUser(userId, partner, token);

export const getApplication = async (applicationId: string, token: string): Promise<ApplicationApiModel> =>
  applicationServiceClient.getApplication(applicationId, token);

export const getApplicationRejectionDecision = async (applicationId: string, token: string): Promise<UnderwritingDecision> =>
  applicationServiceClient.getApplicationRejectionDecision(applicationId, token);

export const updateApplicationRequirement = (
  applicationId: string,
  requirementId: string,
  requirementUpdate: ApplicationRequirementUpdateApiModel,
  token: string,
) => applicationServiceClient.updateApplicationRequirement(applicationId, requirementId, requirementUpdate, token);

export const getApplicationRequirements = async (applicationId: string, token: string): Promise<EvaluatedApplicationRequirement[]> => {
  const requirements = await applicationServiceClient.getApplicationRequirements(applicationId, token);
  return requirements;
};

export const getApplicationAlerts = async (applicationId: string, token: string): Promise<Alert[]> => {
  const { alerts } = await applicationServiceClient.getApplicationAlerts(applicationId, token);
  return alerts;
};
