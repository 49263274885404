import type { DocumentWithIssues } from '@lama/document-service-client';
import { Flex, greyPalette } from '@lama/design-system';
import { isNil } from 'lodash-es';
import styled from 'styled-components';

const activeBorderStyles = {
  borderWidth: '2px',
  borderColor: '#0000001F',
  borderStyle: 'solid',
};

const disabledBorderStyles = {
  borderWidth: '2px',
  borderColor: greyPalette[300],
  borderStyle: 'solid',
};

const dashedBoxBorderStyle = {
  backgroundImage:
    'repeating-linear-gradient(-21deg, #eee 0px, #eee 5px, transparent 5px, transparent 10px),  repeating-linear-gradient(69deg, #eee 0px, #eee 5px, transparent 5px, transparent 10px), repeating-linear-gradient(159deg, #eee 0px, #eee 5px, transparent 5px, transparent 10px), repeating-linear-gradient(249deg, #eee 0px, #eee 5px, transparent 5px, transparent 10px)',
  backgroundSize: '2px 100%, 100% 2px, 2px 100%, 100% 2px',
  backgroundPosition: '0 0, 0 0, 100% 0, 0 100%',
  backgroundRepeat: 'no-repeat',
};

interface DocumentCardContainerProps {
  isDragActive: boolean;
  document?: DocumentWithIssues;
  documentDeleted?: boolean;
  uploading?: boolean;
}

export const DocumentCardContainer = styled(Flex)<DocumentCardContainerProps>`
  align-items: center;
  user-select: none;
  padding: 12px 16px;
  border-radius: 8px;
  ${({ uploading }) => (uploading ? 'cursor: not-allowed; padding:0px;' : 'cursor: pointer;')};
  ${({ document, uploading }) => {
    if (document?.status === 'Deleted') {
      return disabledBorderStyles;
    }
    return !isNil(document) || uploading ? activeBorderStyles : dashedBoxBorderStyle;
  }};

  ${({ document }) => isNil(document) && 'border: none;'}
  background-color: ${({ document }) => (!isNil(document) ? 'white' : greyPalette[50])};
  cursor: ${({ document, documentDeleted }) => (document && !documentDeleted ? 'pointer !important' : 'default !important')};
`;
