import type { FC } from 'react';
import React, { useCallback, useContext, useMemo } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useMediaQuery, useTheme } from '@mui/material';
import { loanDisplayNameSelector } from '@lama/data-formatters';
import { keyBy } from 'lodash-es';
import { Flex, Text } from '@lama/design-system';
import { useEffectOnce } from 'react-use';
import { displayToast, LoadingPage } from '@lama/app-components';
import { LoadingButton } from '@mui/lab';
import { useAuthentication } from '../../hooks/authentication/useAuthentication';
import { currentOnboardingApplicationIdState, partnerState } from '../../state/appState';
import { UserDetailsContext } from '../../shared/contexts/UserDetailsContext';
import { useProductsByPartnerQuery } from '../../hooks/react-query/useProductsByPartnerQuery';
import { useApplicationsByUserIdQuery } from '../../hooks/react-query/useApplicationsByUserIdQuery';
import { SimpleHeader } from '../shared/SimpleHeader';
import { useCreateApplicationAndRelatedEntities } from '../shared/PreflowScreen/useCreateApplicationAndRelatedEntities';
import { ApplicationCard } from './ApplicationCard';

export const ApplicationsScreen: FC = () => {
  const partner = useRecoilValue(partnerState);
  const user = useContext(UserDetailsContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();

  const { data: applications, isPending: isLoadingApplications } = useApplicationsByUserIdQuery(partner?.id, user?.id);
  const { data: products, isPending: isLoadingProducts } = useProductsByPartnerQuery(partner?.id);

  const { isAuthenticated, isPending: isAuthenticating, token } = useAuthentication();
  const setCurrentOnboardingApplicationId = useSetRecoilState(currentOnboardingApplicationIdState);

  const personApplications = useMemo(
    () => applications?.filter(({ originatingPartner }) => originatingPartner === partner?.id),
    [applications, partner?.id],
  );

  useEffectOnce(() => {
    setCurrentOnboardingApplicationId(null);
  });

  const productsById = useMemo(() => keyBy(products ?? [], (p) => p.id), [products]);

  const defaultProduct = useMemo(
    () => products?.find((p) => p.id === partner?.defaultProductId || p.key === partner?.defaultProductKey) ?? products?.[0],
    [partner?.defaultProductId, partner?.defaultProductKey, products],
  );

  const { createApplicationAndRelatedEntities, creatingApplicationAndRelatedEntities } = useCreateApplicationAndRelatedEntities();

  const onNewApplicationClicked = useCallback(async () => {
    if (!defaultProduct || !user?.firstName || !user?.lastName || !user?.email || !partner || !token) {
      displayToast('Something went wrong. Please contact support.', 'error');
      return;
    }

    const applicationId = await createApplicationAndRelatedEntities({
      partnerId: partner?.id ?? '',
      product: defaultProduct,
      userDetails: user,
    });

    setCurrentOnboardingApplicationId(applicationId);

    navigate(`/products/${defaultProduct.name}/onboarding/${defaultProduct.screensConfiguration.flowSteps[0][0]}`);
  }, [createApplicationAndRelatedEntities, defaultProduct, navigate, partner, setCurrentOnboardingApplicationId, token, user]);

  if (isLoadingApplications || isAuthenticating || isLoadingProducts) {
    return <LoadingPage />;
  }

  if (!isAuthenticated) {
    return <Navigate to={{ pathname: '/products', search: window.location.search }} replace />;
  }

  return (
    <Flex flexDirection={'column'} px={6} gap={12}>
      <SimpleHeader showAccountSettings={false} />
      <Flex flexDirection={'column'} flex={1} alignItems={'center'}>
        <Flex flexDirection={'column'} gap={8} justifyContent={'flex-start'} width={isMobile ? '100%' : '960px'}>
          <Flex flexDirection={'row'} justifyContent={'space-between'} alignItems={'flex-end'}>
            <Text variant={'h4'}>{'My Applications'}</Text>
            <LoadingButton variant={'contained'} onClick={onNewApplicationClicked} loading={creatingApplicationAndRelatedEntities}>
              {isMobile ? 'New' : 'New Application'}
            </LoadingButton>
          </Flex>
          <Flex flexDirection={'row'} gap={4} flexWrap={'wrap'} justifyContent={'space-between'} width={'100%'}>
            {personApplications?.map((application) => (
              <ApplicationCard
                key={application.id}
                applicationId={application.id}
                requestedAmount={application.requestedAmount}
                productName={productsById[application.originalProductId]?.prettyName ?? ''}
                loanName={loanDisplayNameSelector(application)}
                applicationStatus={application.status}
              />
            ))}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
