import type { PartnerPublicDataApiModel } from '@lama/partner-service-client';

import { atom } from 'recoil';
import { persistAtom } from './localStorageEffect';

const appDataAtom = <T>(name: string, defaultValue: T) =>
  atom<T>({
    key: name,
    default: defaultValue,
  });

export const currentOnboardingApplicationIdState = atom<string | null>({
  key: 'currentOnboardingApplicationIdState',
  default: null,
  effects: [persistAtom('currentOnboardingApplicationIdState')],
});

export const partnerState = appDataAtom<PartnerPublicDataApiModel | null>('partner', null);
