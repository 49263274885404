import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { useTranslation } from 'react-i18next';
import { useAsyncFn } from 'react-use';
import { Flex } from '@lama/design-system';

const dashedBorderStyle = {
  backgroundImage:
    'repeating-linear-gradient(0deg, #bdbdbd, #bdbdbd 4px, transparent 4px, transparent 9px, #bdbdbd 9px), repeating-linear-gradient(90deg, #bdbdbd, #bdbdbd 4px, transparent 4px, transparent 9px, #bdbdbd 9px), repeating-linear-gradient(180deg, #bdbdbd, #bdbdbd 4px, transparent 4px, transparent 9px, #bdbdbd 9px), repeating-linear-gradient(270deg, #bdbdbd, #bdbdbd 4px, transparent 4px, transparent 9px, #bdbdbd 9px)',
  backgroundSize: '0px 100%, 100% 0px, 0px 100% , 100% 1px',
  backgroundPosition: '0 0, 0 0, 100% 0, 0 100%',
  backgroundRepeat: 'no-repeat',
};

const DashedBorder: React.FC = () => <Box width={'100%'} height={'1px'} style={{ ...dashedBorderStyle }} zIndex={'-1'} />;

export interface SignatureProps {
  fullName: string;
  isSigned: boolean;
  sign: () => void;
}

const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const Signature: React.FC<SignatureProps> = ({ fullName, isSigned, sign }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const [{ loading }, delayedSign] = useAsyncFn(async () => {
    await sleep(500);

    sign();
  }, [sign]);

  return (
    <Flex flexDirection={'column'} gap={4} width={'100%'}>
      {isSigned ? (
        <Typography variant={'signature'} whiteSpace={'pre-wrap'} marginBottom={-4}>
          {` ${fullName}`}
        </Typography>
      ) : (
        <LoadingButton
          sx={{
            width: '40px',
            minWidth: '40px',
            height: '40px',
            backgroundColor: theme.palette.primary.light,
            borderRadius: '4px',
          }}
          loading={loading}
          onClick={delayedSign}
        >
          <FileDownloadOutlinedIcon />
        </LoadingButton>
      )}
      <DashedBorder />
      <Typography variant={'subtitle1'} color={'#9E9E9E'} fontSize={'12px'}>
        {t('signature.underline')}
      </Typography>
    </Flex>
  );
};
