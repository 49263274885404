import { useMemo } from 'react';
import { useGetCurrentRequirement } from './useGetCurrentRequirement';

export const useGetRequiredFields = () => {
  const currentRequirement = useGetCurrentRequirement();
  const requiredFields = useMemo(
    () => currentRequirement?.properties.filter((p) => !p.optional).map((p) => p.fieldName) ?? [],
    [currentRequirement?.properties],
  );

  return requiredFields;
};
