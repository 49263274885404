import React, { useCallback, useRef } from 'react';
import type { Editor } from '@tiptap/react';
import type { FC } from 'react';
import styled from 'styled-components';
import { Flex } from '../Flex';
import { greyPalette } from '../../theme';
import { DotLoader } from '../DotLoader';
import { FormatBoldIcon } from './assets/FormatBoldIcon.js';
import { FormatItalicIcon } from './assets/FormatItalicIcon.js';
import { FormatListNumberedIcon } from './assets/FormatListNumberedIcon.js';
import { FormatListBulletedIcon } from './assets/FormatListBulletedIcon.js';
import { UploadImage } from './assets/UploadImage';

interface MenuItemProps {
  isActive: boolean;
  disabled?: boolean;
}

const MenuItem = styled(Flex)<MenuItemProps>`
  justify-content: center;
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  &:hover {
    background-color: ${greyPalette[100]};
  }
  border-radius: 4px;
  width: 28px;
  height: 28px;
  background-color: ${({ isActive }) => (isActive ? greyPalette[100] : 'transparent')};
`;

interface MenuBarProps {
  editor: Editor;
  onImageSelected?: (fileContents: File) => Promise<{ src: string }>;
}

export const MenuBar: FC<MenuBarProps> = ({ editor, onImageSelected }) => {
  const [uploadingImage, setUploadingImage] = React.useState(false);
  const uploadImageInputRef = useRef<HTMLInputElement>(null);
  const onBoldClick = useCallback(() => editor.chain().focus().toggleBold().run(), [editor]);
  const onItalicClick = useCallback(() => editor.chain().focus().toggleItalic().run(), [editor]);
  const onBulletListClick = useCallback(() => editor.chain().focus().toggleBulletList().run(), [editor]);
  const onOrderedListClick = useCallback(() => editor.chain().focus().toggleOrderedList().run(), [editor]);
  const addImage = useCallback(
    (event: React.MouseEvent) => {
      if (!editor || uploadingImage) {
        return;
      }
      event.stopPropagation();
      uploadImageInputRef?.current?.click();
    },
    [editor, uploadingImage],
  );
  const cancelInputPropagation = useCallback((event: React.MouseEvent) => {
    event.stopPropagation();
  }, []);
  const onFileSelected = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (!file || !onImageSelected) {
        return;
      }
      setUploadingImage(true);
      const { src } = await onImageSelected(file);
      editor.chain().focus().setImage({ src }).run();
      setUploadingImage(false);
    },
    [editor, onImageSelected],
  );
  return (
    <Flex gap={2} flexWrap={'wrap'}>
      <MenuItem onClick={onBoldClick} isActive={editor.isActive('bold')}>
        <FormatBoldIcon />
      </MenuItem>
      <MenuItem onClick={onItalicClick} isActive={editor.isActive('italic')}>
        <FormatItalicIcon />
      </MenuItem>
      <MenuItem onClick={onBulletListClick} isActive={editor.isActive('bulletList')}>
        <FormatListBulletedIcon />
      </MenuItem>
      <MenuItem onClick={onOrderedListClick} isActive={editor.isActive('orderedList')}>
        <FormatListNumberedIcon />
      </MenuItem>
      {onImageSelected ? (
        <MenuItem onClick={addImage} disabled={uploadingImage} isActive={uploadingImage}>
          {uploadingImage ? <DotLoader /> : <UploadImage />}
          <input
            role={'textbox'}
            type={'file'}
            hidden
            onChange={onFileSelected}
            ref={uploadImageInputRef}
            onClick={cancelInputPropagation}
            accept={'.png,.jpg,.jpeg,.PNG,.JPG,.JPEG'}
          />
        </MenuItem>
      ) : null}
    </Flex>
  );
};
