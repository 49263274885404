import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { personFullName } from '@lama/data-formatters';
import { BasicScreen } from '../shared/BasicScreen';
import type { ScreenProps } from '../ScreenProps';
import { DocumentSignDemo } from '../shared/DocumentSignDemo/DocumentSignDemo';
import { UserDetailsContext } from '../../shared/contexts/UserDetailsContext';

const w9ImageUrls = Array.from({ length: 4 }, (_, i) => `https://lama-ai-assets.s3.amazonaws.com/w9/w9-${i + 1}.jpg`);

export const W9FormSignScreen: React.FC<ScreenProps> = ({ flow, ...props }) => {
  const { t } = useTranslation();
  const userDetails = useContext(UserDetailsContext);
  const fullName = useMemo(() => {
    if (!userDetails?.firstName || !userDetails?.lastName) {
      return '';
    }

    const { firstName, lastName } = userDetails;

    return personFullName({ firstName, lastName });
  }, [userDetails]);

  const formData = useMemo(
    () => [
      { value: fullName, left: '150px', top: '600px', handwriting: true },
      { value: new Date().toLocaleDateString(), left: '500px', top: '600px', handwriting: true },
    ],
    [fullName],
  );

  return (
    <BasicScreen
      {...props}
      flow={flow}
      title={t('w9FormSign.title')}
      subtitle={t('w9FormSign.subtitle')}
      nextVisible={flow === 'onboarding'}
      disableIndication
    >
      <DocumentSignDemo pageUrls={w9ImageUrls} signingPage={0} data={formData} />
    </BasicScreen>
  );
};
