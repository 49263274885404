import { useMutation, useQueryClient } from '@tanstack/react-query';
import type { FinancialData } from '@lama/contracts';
import { displayToast } from '@lama/app-components';
import { useAuthentication } from '../authentication/useAuthentication';

import { updateFinancials } from '../../shared/clients/financialsService';

export const useUpdateBusinessFinancialsMutation = (applicationId: string) => {
  const queryClient = useQueryClient();
  const { token, isAuthenticated } = useAuthentication();

  return useMutation({
    mutationFn: async ({ businessId, businessFinancialsUpdate }: { businessId: string; businessFinancialsUpdate: FinancialData[] }) => {
      if (!isAuthenticated) {
        return null;
      }

      await updateFinancials('business', businessId, businessFinancialsUpdate, token);
    },
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: ['application', applicationId],
        }),
        queryClient.invalidateQueries({
          queryKey: ['applicationRequirements', applicationId],
        }),
      ]);
    },
    onError: () => {
      displayToast('There was a problem processing the request. Please try again.', 'error');
    },
  });
};
