import { useCallback } from 'react';
import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import type { ApplicationApiModel } from '@lama/clients';
import { getApplicationsByUser } from '../../shared/clients/applicationService';
import { useAuthentication } from '../authentication/useAuthentication';

export const useApplicationsByUserIdQuery = (
  partner?: string,
  userId?: string,
  options?: UseQueryOptions<ApplicationApiModel[] | null>,
) => {
  const { token, isAuthenticated } = useAuthentication();

  const getApplicationsQuery = useCallback(async () => {
    if (!isAuthenticated || !userId || !partner) {
      return null;
    }

    return getApplicationsByUser(userId, partner, token);
  }, [isAuthenticated, partner, token, userId]);

  const query = useQuery<ApplicationApiModel[] | null>({
    queryKey: ['applications', userId, partner],
    queryFn: getApplicationsQuery,
    ...options,
  });

  return query;
};
