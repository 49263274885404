import type { ButtonHTMLAttributes, FC, PropsWithChildren, ReactElement, SVGAttributes } from 'react';
import React, { useContext, useCallback, forwardRef, useMemo } from 'react';
import type { HeightProps, PaddingProps, SpaceProps, WidthProps } from 'styled-system';
import styled, { ThemeContext } from 'styled-components';
import { Spinner } from '../Spinner';
import { Flex } from '../Flex';
import { Text } from '../Text';
import type { ButtonColors, ButtonSize, ButtonVariant } from './Button.styles';
import { spinnerSizes, StyledButton, textVariants } from './Button.styles';
import { ButtonIcon } from './ButtonIcon.component';

type MouseEventHandler = (event: React.MouseEvent<HTMLButtonElement>) => void;
type FormEventHandler = (event: React.FormEvent<HTMLFormElement>) => void;

type OnClickHandler = FormEventHandler | MouseEventHandler;
export interface ButtonProps
  extends PaddingProps,
    SpaceProps,
    WidthProps,
    HeightProps,
    Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'onClick'> {
  variant?: ButtonVariant;
  color?: ButtonColors;
  size?: ButtonSize;
  disabled?: boolean;
  loading?: boolean;
  onClick?: OnClickHandler;
  value?: number | string;
  selected?: boolean;
  iconColor?: string;
  startIcon?: FC<SVGAttributes<SVGElement>> | ReactElement;
  endIcon?: FC<SVGAttributes<SVGElement>> | ReactElement;
}

const NoWrapButtonText = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
`;

export const Button = forwardRef<HTMLButtonElement, PropsWithChildren<ButtonProps>>(
  (
    {
      children,
      variant = 'primary',
      color = 'primary',
      size = 'm',
      disabled = false,
      loading = false,
      startIcon: StartIcon,
      endIcon: EndIcon,
      onClick,
      iconColor,
      ...props
    },
    ref,
  ) => {
    const { button: buttonTheme } = useContext(ThemeContext);

    const onClickInner = useCallback(
      (event: React.MouseEvent<HTMLButtonElement>) => {
        if (disabled || loading) {
          event?.stopPropagation();
          event?.preventDefault();
        } else if (onClick && event.type === 'click') {
          (onClick as MouseEventHandler)(event);
        } else if (onClick && event.type === 'submit') {
          (onClick as FormEventHandler)(event as unknown as React.FormEvent<HTMLFormElement>);
        }
      },
      [disabled, loading, onClick],
    );

    const spinnerColor = useMemo(
      () =>
        disabled ? buttonTheme.variants[variant].disabled.iconColor : iconColor ?? buttonTheme.variants[variant].colors[color].iconColor,
      [disabled, iconColor, buttonTheme, variant, color],
    );

    return (
      <StyledButton variant={variant} size={size} disabled={disabled || loading} onClick={onClickInner} ref={ref} color={color} {...props}>
        <Flex justifyContent={'center'} gap={2} alignItems={'center'}>
          {loading ? (
            <Spinner color={spinnerColor} size={spinnerSizes[size]} />
          ) : StartIcon ? (
            <ButtonIcon buttonColor={color} icon={StartIcon} variant={variant} size={size} color={iconColor} disabled={disabled} />
          ) : null}
          <NoWrapButtonText variant={textVariants[size]}>{children}</NoWrapButtonText>
          {EndIcon && !loading ? (
            <ButtonIcon buttonColor={color} icon={EndIcon} variant={variant} size={size} color={iconColor} disabled={disabled} />
          ) : null}
        </Flex>
      </StyledButton>
    );
  },
);

Button.displayName = 'Button';
