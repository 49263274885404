import { Flex } from '@lama/design-system';
import { MobileStepper, useMediaQuery, useTheme } from '@mui/material';
import React, { useCallback, useMemo } from 'react';

export const MultiStepper: React.FC<{ currentStep: string; groupedSteps: string[][] }> = ({ currentStep, groupedSteps }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const activeGroupIndex = useMemo(() => groupedSteps.findIndex((group) => group.includes(currentStep)), [currentStep, groupedSteps]);
  const activeStepInGroupIndex = useMemo(
    () => groupedSteps[activeGroupIndex].indexOf(currentStep),
    [groupedSteps, activeGroupIndex, currentStep],
  );

  const activeStepByGroupIndex = useCallback(
    (groupIndex: number) => {
      if (groupIndex === activeGroupIndex) {
        return activeStepInGroupIndex;
      }
      if (groupIndex < activeGroupIndex) {
        return groupedSteps[groupIndex].length;
      }
      return 0;
    },
    [activeGroupIndex, activeStepInGroupIndex, groupedSteps],
  );

  return (
    <Flex flexDirection={'row'} width={'100%'}>
      {groupedSteps.map((_group, index) => (
        <MobileStepper
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          variant={'progress'}
          position={'static'}
          sx={{ flex: 1, ...(isMobile ? { marginX: '-4px' } : {}) }}
          LinearProgressProps={{
            sx: { width: '100%', borderRadius: '32px' },
            color: 'primary',
          }}
          steps={groupedSteps[index].length + 1} // this is a hack to allow incremental progress between steppers
          activeStep={activeStepByGroupIndex(index)}
          nextButton={null}
          backButton={null}
        />
      ))}
    </Flex>
  );
};
