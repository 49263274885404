import type { FC } from 'react';
import React, { useCallback, useMemo } from 'react';
import type { DismissedSourceReason } from '@lama/contracts';
import type { DocumentWithIssues } from '@lama/document-service-client';
import { useDropzone } from 'react-dropzone';
import { useAsyncFn, useToggle } from 'react-use';
import { isNil } from 'lodash-es';
import type { DataNode } from '@lama/design-system';
import { DocumentPreviewModal } from '../DocumentPreviewModal/DocumentPreviewModal.js';
import { displayToast } from '../../utils/displayToast.js';
import { DocumentCardContent } from './DocumentCard.js';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { DocumentCardContainer } from './DocumentBox.styles.js';
import { isFileValid } from './services/isFileValid.js';
import { DocumentUploadingState } from './DocumentUploadingState.js';
import type { OnMetadataUpdate } from './types.js';

export const allowedFileTypes = [
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'image/png',
  'image/jpeg',
  'image/jpg',
  'image/tiff',
];

export const pdfFileExtensions = ['pdf', 'PDF'];
export const imageFileExtensions = ['png', 'jpg', 'jpeg', 'PNG', 'JPG', 'JPEG'];
export const allSupportedExtensions = [...pdfFileExtensions, ...imageFileExtensions];

export interface DocumentBoxProps {
  description?: string;
  document?: DocumentWithIssues;
  onDismissSource?: (name: string) => Promise<void>;
  onUndismissSource?: (name: string) => Promise<void>;
  onMoveToClick?: (moveToNode: DataNode) => Promise<void>;
  dismissed?: DismissedSourceReason;
  dismissible?: boolean;
  onDocumentUpload?: (file: File) => Promise<void>;
  onDocumentRemoved?: () => Promise<void>;
  onMetadataUpdate?: OnMetadataUpdate;
  downloadEnabled?: boolean;
  moveToTreeData?: DataNode[];
}

export const DocumentBox: FC<DocumentBoxProps> = ({
  description,
  document,
  onDismissSource,
  onUndismissSource,
  dismissed,
  dismissible,
  onDocumentUpload,
  onMetadataUpdate,
  onDocumentRemoved,
  downloadEnabled = true,
  moveToTreeData,
  onMoveToClick,
}) => {
  const [{ loading: uploadingDocument }, onDrop] = useAsyncFn(
    async (acceptedFiles: File[]) => {
      const [fileToUpload] = acceptedFiles;

      if (isNil(fileToUpload)) {
        return;
      }

      const validationResult = await isFileValid(fileToUpload);

      if (!validationResult.result) {
        const { message } = validationResult;
        if (message) {
          displayToast(message, 'warning');
        }
        return;
      }

      await onDocumentUpload?.(fileToUpload);
    },
    [onDocumentUpload],
  );

  const [pdfDialogOpen, togglePdfDialog] = useToggle(false);

  const onFileSelectedInner = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.files?.[0]) {
        await onDrop([e.target.files[0]]);
      }
    },
    [onDrop],
  );

  const { getRootProps, isDragActive } = useDropzone({ onDrop, maxFiles: 1, noClick: true });

  const openDocument = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();

      if (!document?.previewUrl) {
        return;
      }

      const extension = document.filename.split('.').at(-1);
      if (!extension || !allSupportedExtensions.includes(extension)) {
        open(document?.previewUrl);
        return;
      }

      togglePdfDialog();
    },
    [document?.filename, document?.previewUrl, togglePdfDialog],
  );
  const downloadDocumentClickHandler = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      if (!document?.downloadUrl) {
        return;
      }
      const { document: domDocument } = window;
      const link = domDocument.createElement('a');
      link.href = document.downloadUrl;
      link.download = document.filename;
      domDocument.body.append(link);
      link.click();
      link.remove();
    },
    [document],
  );

  const documentDeleted = useMemo(() => document?.status === 'Deleted', [document]);

  return (
    <>
      <DocumentCardContainer
        onClick={document && !documentDeleted ? openDocument : undefined}
        document={document}
        documentDeleted={documentDeleted}
        data-testid={'document-box'}
        uploading={uploadingDocument}
        {...(document ? ({} as any) : getRootProps())}
      >
        {uploadingDocument ? (
          <DocumentUploadingState />
        ) : (
          <DocumentCardContent
            onDocumentMetadataUpdate={onMetadataUpdate}
            onOpen={openDocument}
            document={document}
            description={description ?? document?.filename}
            onDelete={onDocumentRemoved}
            onFileSelected={onFileSelectedInner}
            onDismissSource={onDismissSource}
            onUndismissSource={onUndismissSource}
            dismissed={dismissed}
            dismissible={dismissible}
            onDownloadDocument={downloadEnabled ? downloadDocumentClickHandler : undefined}
            isDragActive={isDragActive}
            documentTreeData={moveToTreeData}
            onMoveToClick={onMoveToClick}
          />
        )}
      </DocumentCardContainer>
      <DocumentPreviewModal document={document} open={pdfDialogOpen} onClose={togglePdfDialog} />
    </>
  );
};
