import { grayPalette, redPalette } from '../../../theme/colors.js';
import type { ColorOverrides } from '../../../theme/types.js';

export const getInputLightTheme = (colors: ColorOverrides) => ({
  textColor: grayPalette[700],
  labelColor: grayPalette[700],
  borderColor: grayPalette[400],
  helperTextColor: grayPalette[700],
  iconColor: grayPalette[700],

  disabled: {
    backgroundColor: grayPalette[50],
    borderColor: grayPalette[300],
    iconColor: grayPalette[300],
    labelColor: '#C5C5C5',
    textColor: '#C5C5C5',
  },

  focused: {
    labelColor: colors.primary.main,
    borderColor: colors.primary.main,
  },
  error: {
    labelColor: redPalette[700],
    borderColor: redPalette[700],
  },
});
