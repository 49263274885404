import { Text } from '@lama/design-system';
import { Container } from '@mui/material';
import type { FC } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const ErrorScreen: FC = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Text variant={'h4'}>{t('errorScreen.text')}</Text>
    </Container>
  );
};
