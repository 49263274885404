import type { FC } from 'react';
import React, { useCallback } from 'react';
import { Button } from '@lama/design-system';
import { useSubmitApplicationMutation } from '../../../hooks/react-query/useSubmitApplicationMutation';

interface RepullAndSubmitProps {
  applicationId: string;
}

export const RepullAndSubmitButton: FC<RepullAndSubmitProps> = ({ applicationId }) => {
  const { mutate: submitApplication, isPending: submittingApplication } = useSubmitApplicationMutation(applicationId);

  const onClick = useCallback(async () => {
    if (!submittingApplication) {
      submitApplication();
    }
  }, [submitApplication, submittingApplication]);

  return (
    <Button variant={'secondary'} color={'neutral'} onClick={onClick} loading={submittingApplication}>
      {'Mark as complete'}
    </Button>
  );
};
