import type { FC } from 'react';
import React from 'react';
import type { ApplicationApiModel } from '@lama/clients';
import { UseOfFundsBreakdownIndication } from './UseOfFundsBreakdownIndication';

const requirementToIndicationComponent: Record<string, FC<CustomIndicationProps>> = {
  useOfFundsBreakdown: UseOfFundsBreakdownIndication,
};

export interface CustomIndicationProps {
  application: ApplicationApiModel;
}

export const Indication: FC<CustomIndicationProps & { screenName: string }> = ({ screenName, ...props }) => {
  const IndicationComponent = requirementToIndicationComponent[screenName];
  return IndicationComponent ? <IndicationComponent {...props} /> : null;
};
