import React from 'react';
import { alpha, MobileStepper, useTheme } from '@mui/material';

export const GradientProgress: React.FC<{ progress: number }> = ({ progress }) => {
  const theme = useTheme();

  return (
    <MobileStepper
      variant={'progress'}
      position={'static'}
      sx={{
        height: '10px',
        borderRadius: '10px',
      }}
      style={{ width: '100%', paddingLeft: 0 }}
      LinearProgressProps={{
        sx: {
          width: '100%',
          height: '10px',
          borderRadius: '8px',
          border: '1px solid',
          borderColor: 'rgb(0, 0, 0, 0.12)',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          '.MuiLinearProgress-bar': {
            background: `linear-gradient(269.99deg, ${theme.palette.primary.main} -2.56%, ${alpha(
              theme.palette.primary.light,
              0.4,
            )} 126.55%)`,
            borderRadius: '300px',
          },
          bgcolor: 'white',
        },
      }}
      steps={101}
      activeStep={progress}
      nextButton={null}
      backButton={null}
    />
  );
};
