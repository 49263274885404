import type { FC } from 'react';
import React, { useCallback } from 'react';
import styled from 'styled-components';
import type { SuggestionOption } from '../types';
import { Flex } from '../../Flex';
import { Text } from '../../Text';
import { greyPalette } from '../../../theme';
import { Avatar } from '../../Avatar';

interface SuggestionItemProps {
  item: SuggestionOption;
  onClick: (item: SuggestionOption) => void;
  selected: boolean;
}

const StyledSuggestionContainer = styled(Flex)<{ selected: boolean }>`
  cursor: pointer;
  background-color: ${({ selected }) => (selected ? greyPalette[100] : 'white')};
  &:hover {
    background-color: ${greyPalette[100]};
  }
`;
export const SuggestionItem: FC<SuggestionItemProps> = ({ item, onClick, selected = false }) => {
  const onClickItem = useCallback(() => {
    onClick(item);
  }, [item, onClick]);

  return (
    <StyledSuggestionContainer alignItems={'center'} gap={1} pl={2} pr={6} py={1} selected={selected} onClick={onClickItem}>
      <Avatar size={'20px'} name={item.label} />
      <Text variant={'body2'} weight={400}>
        {item.label}
      </Text>
    </StyledSuggestionContainer>
  );
};
