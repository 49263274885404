import React, { forwardRef } from 'react';

import type { StylingProps } from './Styles/Input.styles';
import { StyledInput, StyledNumberInput } from './Styles/Input.styles';

export const InputByType = forwardRef<HTMLInputElement, StylingProps>(({ type, onValueChange, $size, ...props }, ref) => {
  if (type === 'number') {
    return <StyledNumberInput getInputRef={ref} $size={$size} onValueChange={onValueChange} {...props} />;
  }
  return <StyledInput ref={ref} $size={$size} type={type} {...props} />;
});
