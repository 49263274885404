export const applicationServiceBaseUrl = import.meta.env.VITE_APPLICATION_SERVICE_URL;
export const userServiceBaseUrl = import.meta.env.VITE_USER_SERVICE_URL;
export const documentServiceUrl = import.meta.env.VITE_DOCUMENT_SERVICE_URL;
export const productServiceBaseUrl = import.meta.env.VITE_PRODUCT_SERVICE_URL;
export const partnerServiceBaseUrl = import.meta.env.VITE_PARTNER_SERVICE_URL;
export const bankServiceBaseUrl = import.meta.env.VITE_BANK_SERVICE_URL;
export const accountingsServiceBaseUrl = import.meta.env.VITE_ACCOUNTING_SERVICE_URL;
export const businessServiceBaseUrl = import.meta.env.VITE_BUSINESS_SERVICE_URL;
export const peopleServiceBaseUrl = import.meta.env.VITE_PEOPLE_SERVICE_URL;
export const financialsServiceBaseUrl = import.meta.env.VITE_FINANCIALS_SERVICE_URL;

export const googleApiKey = import.meta.env.VITE_GOOGLE_API_KEY;
export const railzWidgetId = import.meta.env.VITE_RAILZ_WIDGET_ID;

export const ldClientSideId = import.meta.env.VITE_LAUNCH_DARKLY_CLIENT_SIDE_ID;

export const mode = import.meta.env.MODE;

export const auth0ClientId = import.meta.env.VITE_AUTH0_CLIENT_ID;
export const auth0Domain = import.meta.env.VITE_AUTH0_DOMAIN;
export const auth0Audience = import.meta.env.VITE_AUTH0_AUDIENCE;
export const localPartner = import.meta.env.VITE_LOCAL_PARTNER;
