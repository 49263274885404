import { Stack } from '@mui/material';
import type { FC } from 'react';
import React from 'react';
import { CoinStackLogo } from './CoinStackLogo';

export const UseOfFundsBreakdownEmptyState: FC = () => (
  <Stack direction={'column'} alignItems={'center'} gap={2}>
    <CoinStackLogo />
  </Stack>
);
