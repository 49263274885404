import { Clear } from '@mui/icons-material';
import { TextField, IconButton } from '@mui/material';
import React, { useCallback } from 'react';

interface SearchBarProps {
  onQueryChanged: (query: string) => void;
  label: string;
  query: string;
}

export const SearchBar: React.FC<SearchBarProps> = ({ label, query, onQueryChanged }) => {
  const onClearSearch = useCallback(() => {
    onQueryChanged('');
  }, [onQueryChanged]);

  const onTextChanged = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onQueryChanged(e.target.value);
    },
    [onQueryChanged],
  );

  return (
    <TextField
      value={query}
      label={label}
      onChange={onTextChanged}
      fullWidth
      InputProps={{
        endAdornment: query ? (
          <IconButton color={'primary'} onClick={onClearSearch}>
            <Clear />
          </IconButton>
        ) : null,
      }}
    />
  );
};
