import React, { useCallback } from 'react';
import styled from 'styled-components';
import type { FC } from 'react';
import { Flex } from '../Flex';
import { Text } from '../Text';
import { ITEM_MAX_WIDTH } from './types';
import type { ButtonGroupItem } from './types';

interface ButtonGroupItemProps extends ButtonGroupItem {
  onChange: (value: string) => void;
  selected: boolean;
}

const StyledText = styled(Text)<{ selected: boolean }>`
  user-select: none;
  color: ${({ selected }) => (selected ? 'black' : 'inherit')};
  padding: 4px;
`;

const StyledButtonGroupItem = styled(Flex)`
  box-sizing: border-box;
  background-color: transparent;
  border-radius: 6px;
  align-items: center;
  justify-content: center;
  flex: 1 1 0;
  max-width: ${ITEM_MAX_WIDTH}px;
  z-index: 1;
  padding: 4px;
`;

export const ButtonGroupItemComponent: FC<ButtonGroupItemProps> = ({ value, label, onChange, selected }) => {
  const onClick = useCallback(() => {
    onChange(value);
  }, [onChange, value]);

  return (
    <StyledButtonGroupItem onClick={onClick} flexDirection={'row'} role={'button'}>
      <StyledText variant={'body2'} selected={selected}>
        {label}
      </StyledText>
    </StyledButtonGroupItem>
  );
};
