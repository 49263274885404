import { ElectricBolt, Verified } from '@mui/icons-material';
import { Button, Box, Avatar, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { Flex } from '@lama/design-system';
import { selectedServicesState } from '../../state/applicationState';
import type { ScreenProps } from '../ScreenProps';
import { BasicScreen } from '../shared/BasicScreen';
import { services } from '../shared/types/services';

export const ServicesConnectionScreen: React.FC<ScreenProps> = ({ onBackClick, onNextClick = () => {}, ...stepsNavigationProps }) => {
  const selectedServices = useRecoilValue(selectedServicesState);
  const [currentServiceIndex, setCurrentServiceIndex] = useState(0);

  const { state } = useLocation() as { state?: { service?: string } };

  useEffect(() => {
    let index = 0;
    if (state?.service && selectedServices.includes(state.service)) {
      index = selectedServices.indexOf(state?.service);
    }
    setCurrentServiceIndex(index);
  }, [state, selectedServices]);

  const handleConnectServiceClick = useCallback(() => {}, []);

  const handleNext = useCallback(() => {
    if (currentServiceIndex < selectedServices.length - 1) {
      setCurrentServiceIndex(currentServiceIndex + 1);
    } else {
      onNextClick();
    }
  }, [currentServiceIndex, onNextClick, selectedServices.length]);

  const handleBack = useCallback(() => {
    if (currentServiceIndex > 0) {
      setCurrentServiceIndex(currentServiceIndex - 1);
    } else {
      onBackClick?.();
    }
  }, [currentServiceIndex, onBackClick]);

  const logo = useMemo(
    () => services.find((s) => s.name === selectedServices[currentServiceIndex])?.logo,
    [currentServiceIndex, selectedServices],
  );

  return (
    <BasicScreen
      {...stepsNavigationProps}
      title={`Connect to ${selectedServices[currentServiceIndex]}`}
      onNextClick={handleNext}
      nextButtonText={'Skip'}
      onBackClick={handleBack}
    >
      <Flex flexDirection={'column'} gap={10} alignItems={'center'} maxWidth={'600px'} width={'80%'} alignSelf={'center'}>
        <Flex flexDirection={'row'} alignItems={'center'} gap={-2}>
          <Box
            width={'124px'}
            height={'132px'}
            border={1}
            borderColor={grey[300]}
            borderRadius={1}
            justifyContent={'center'}
            display={'flex'}
            alignItems={'center'}
          >
            <Flex flexDirection={'column'} alignItems={'center'} gap={2}>
              <Avatar sx={{ bgcolor: 'primary.light', width: 40, height: 40 }}>
                <Verified color={'primary'} />
              </Avatar>
              <Typography variant={'subtitle2'} maxWidth={'80%'} textAlign={'center'}>
                {'Attractive Rates'}
              </Typography>
            </Flex>
          </Box>
          <Box
            width={'200px'}
            height={'164px'}
            border={1}
            borderColor={grey[300]}
            borderRadius={1}
            justifyContent={'center'}
            display={'flex'}
            alignItems={'center'}
            bgcolor={'white'}
            zIndex={1}
          >
            <img src={`/logos/${logo}`} alt={selectedServices[currentServiceIndex]} height={'80px'} />
          </Box>
          <Box
            width={'124px'}
            height={'132px'}
            border={1}
            borderColor={grey[300]}
            borderRadius={1}
            justifyContent={'center'}
            display={'flex'}
            alignItems={'center'}
          >
            <Flex flexDirection={'column'} alignItems={'center'} gap={2}>
              <Avatar sx={{ bgcolor: 'primary.light', width: 40, height: 40 }}>
                <ElectricBolt color={'primary'} />
              </Avatar>
              <Typography variant={'subtitle2'} maxWidth={'80%'} textAlign={'center'}>
                {'Expedite Your Loan'}
              </Typography>
            </Flex>
          </Box>
        </Flex>
        <Button size={'large'} variant={'outlined'} color={'primary'} fullWidth onClick={handleConnectServiceClick}>
          {`Connect ${selectedServices[currentServiceIndex]}`}
        </Button>
      </Flex>
    </BasicScreen>
  );
};
