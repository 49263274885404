import { IconButton } from '@mui/material';
import type { FC } from 'react';
import React from 'react';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

interface CloseButtonProps {
  onClose: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export const CloseButton: FC<CloseButtonProps> = ({ onClose }) => (
  <IconButton sx={{ position: 'absolute', right: 8, top: 8 }} onClick={onClose}>
    <CloseRoundedIcon fontSize={'large'} />
  </IconButton>
);
