import { useCallback } from 'react';
import type { UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import type { ProductPublicData } from '@lama/contracts';
import { productServiceClient } from '../../shared/clients/productService';

export const useProductQuery = (
  productId: string | undefined,
  options?: UseQueryOptions<ProductPublicData | null>,
): UseQueryResult<ProductPublicData | null> => {
  const getProductQuery = useCallback(async () => {
    if (!productId) {
      return null;
    }

    const product = await productServiceClient.getPublicProductData(productId);

    return product;
  }, [productId]);

  const query = useQuery({ queryKey: ['product', productId], queryFn: getProductQuery, ...options });

  return query;
};
