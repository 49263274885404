/* eslint-disable @typescript-eslint/naming-convention */
export type Category = keyof typeof categories;

export const categories = {
  accounting: 'Accounting',
  payments: 'Payments',
  payroll: 'Payroll',
  subscription: 'Subscriptions',
  'e-commerce': 'eCommerce',
  marketing: 'Marketing',
  analytics: 'Analytics',
  crm: 'CRM',
  social: 'Social Media',
};

export const services: { name: string; categories: Category[]; logo: string }[] = [
  { name: 'Quickbooks', categories: ['accounting'], logo: 'quickbooks.svg' },
  { name: 'ZOHO', categories: ['accounting', 'crm'], logo: 'zoho.svg' },
  { name: 'FreshBooks', categories: ['accounting'], logo: 'freshbooks.svg' },
  { name: 'Bexio', categories: ['accounting'], logo: 'bexio.svg' },
  { name: 'Expensify', categories: ['accounting'], logo: 'expensify.svg' },
  { name: 'Free Agent', categories: ['accounting'], logo: 'free-agent.svg' },
  { name: 'Sage Business Cloud', categories: ['accounting'], logo: 'sage-business-cloud.svg' },
  { name: 'Xero', categories: ['accounting'], logo: 'xero.svg' },
  { name: 'Wave', categories: ['accounting'], logo: 'wave.svg' },
  { name: 'Kashoo', categories: ['accounting'], logo: 'kakshoo.svg' },
  { name: 'Invoiced', categories: ['accounting'], logo: 'invoiced.svg' },

  { name: 'Hubspot', categories: ['crm'], logo: 'hubspot.svg' },
  { name: 'Pipedrive', categories: ['crm'], logo: 'pipedrive.svg' },
  { name: 'Salesforce', categories: ['crm'], logo: 'salesforce.svg' },
  { name: 'Copper', categories: ['crm'], logo: 'copper.svg' },
  { name: 'AgileCRM', categories: ['crm'], logo: 'agile-crm.svg' },
  { name: 'Follow Up Boss', categories: ['crm'], logo: 'follow-up-boss.svg' },
  { name: 'Insightly', categories: ['crm'], logo: 'insightly.svg' },
  { name: 'Keap', categories: ['crm'], logo: 'keap.svg' },
  { name: 'Streak', categories: ['crm'], logo: 'streak.svg' },
  { name: 'vcita', categories: ['crm'], logo: 'vcita.svg' },
  { name: 'Retail CRM', categories: ['crm'], logo: 'retail-crm.svg' },
  { name: 'Freshsales', categories: ['crm'], logo: 'fresh-sales.svg' },

  { name: 'Square', categories: ['payments', 'payroll'], logo: 'square.svg' },
  { name: 'Stripe', categories: ['payments'], logo: 'stripe.svg' },
  { name: 'Paypal', categories: ['payments'], logo: 'paypal.svg' },
  { name: 'Hyperwallet', categories: ['payments'], logo: 'hyper-wallet.svg' },
  { name: 'Qiwi', categories: ['payments'], logo: 'qiwi.svg' },
  { name: 'LiqPay', categories: ['payments'], logo: 'liq-pay.svg' },
  { name: 'Payoneer', categories: ['payments'], logo: 'payoneer.svg' },
  { name: 'Brex', categories: ['payments'], logo: 'brex.svg' },
  { name: 'Sum Up', categories: ['payments'], logo: 'sum-up.svg' },
  { name: 'Clover', categories: ['payments'], logo: 'clover.svg' },
  { name: 'Vend', categories: ['payments'], logo: 'vend.svg' },
  { name: 'Lightspeed Retail', categories: ['payments'], logo: 'lightspeed-retail.svg' },
  { name: 'Erply', categories: ['payments'], logo: 'erply.svg' },
  { name: 'ePOS Now', categories: ['payments'], logo: 'epos-now.svg' },
  { name: 'Shopify', categories: ['payments', 'e-commerce'], logo: 'shopify.svg' },

  { name: 'Amazon', categories: ['e-commerce'], logo: 'amazon.svg' },
  { name: 'eBay', categories: ['e-commerce'], logo: 'ebay.svg' },
  { name: 'Woo Commerce', categories: ['e-commerce'], logo: 'woo-commerce.svg' },
  { name: 'Big Commerce', categories: ['e-commerce'], logo: 'big-commerce.svg' },
  { name: 'Big Cartel', categories: ['e-commerce'], logo: 'big-cartel.svg' },
  { name: 'Allegro', categories: ['e-commerce'], logo: 'allegro.svg' },
  { name: 'Squarespace', categories: ['e-commerce'], logo: 'square-space.svg' },
  { name: 'ecwid', categories: ['e-commerce'], logo: 'ecwid.svg' },
  { name: 'Gumroad', categories: ['e-commerce'], logo: 'gumroad.svg' },
  { name: '3d Cart', categories: ['e-commerce'], logo: '3d-cart.svg' },
  { name: 'Mercado Libre', categories: ['e-commerce'], logo: 'mercado-libre.svg' },
  { name: 'Etsy', categories: ['e-commerce'], logo: 'etsy.svg' },
  { name: 'Lazada', categories: ['e-commerce'], logo: 'lazada.svg' },
  { name: 'Magento', categories: ['e-commerce'], logo: 'magento.svg' },

  { name: 'Gusto', categories: ['payroll'], logo: 'gusto.svg' },
  { name: 'Paychex', categories: ['payroll'], logo: 'paychex.svg' },
  { name: 'ADP', categories: ['payroll'], logo: 'adp.svg' },
  { name: 'Zenefits', categories: ['payroll'], logo: 'zenefits.svg' },

  { name: 'Data Axle', categories: ['marketing'], logo: 'data-axle.svg' },
  { name: 'Mail Chimp', categories: ['marketing'], logo: 'mail-chimp.svg' },
  { name: 'Facebook', categories: ['marketing', 'social'], logo: 'facebook-business.svg' },
  { name: 'Instagram', categories: ['marketing', 'social'], logo: 'instagram-business.svg' },
  { name: 'LinkedIn', categories: ['marketing', 'social'], logo: 'linkedin.svg' },
  { name: 'Google Ads', categories: ['marketing'], logo: 'google-ads.svg' },
  { name: 'X', categories: ['marketing', 'social'], logo: 'twitterx.svg' },
  { name: 'YouTube', categories: ['marketing', 'social'], logo: 'youtube.svg' },
  { name: 'Active Campaign', categories: ['marketing'], logo: 'active-campaign.svg' },
  { name: 'Klaviyo', categories: ['marketing'], logo: 'klaviyo.svg' },
  { name: 'Maileon', categories: ['marketing'], logo: 'maileon.svg' },
  { name: 'Criteo', categories: ['marketing'], logo: 'criteo.svg' },
  { name: 'Customer.io', categories: ['marketing'], logo: 'customer-io.svg' },
  { name: 'Marketo', categories: ['marketing'], logo: 'marketo.svg' },
  { name: 'MediaMath', categories: ['marketing'], logo: 'media-math.svg' },
  { name: 'Infusionsoft', categories: ['marketing'], logo: 'infusion-soft.svg' },
  { name: 'Send Pulse', categories: ['marketing'], logo: 'send-pulse.svg' },
  { name: 'Omni Send', categories: ['marketing'], logo: 'omni-send.svg' },
  { name: 'Perfect Audience', categories: ['marketing'], logo: 'perfect-audience.svg' },

  { name: 'Google Analytics', categories: ['analytics'], logo: 'google-analytics.svg' },
  { name: 'Matomo', categories: ['analytics'], logo: 'matomo.svg' },
  { name: 'Adobe Analytics', categories: ['analytics'], logo: 'adobe-analytics.svg' },
  { name: 'Mixpanel', categories: ['analytics'], logo: 'mixpanel.svg' },
  { name: 'Kissmetrics', categories: ['analytics'], logo: 'kissmetrics.svg' },

  { name: 'Recurly', categories: ['subscription'], logo: 'recurly.svg' },
  { name: 'Chargebee', categories: ['subscription'], logo: 'chargebee.svg' },
  { name: 'SaaS Optics', categories: ['subscription'], logo: 'saas-optics.svg' },
  { name: 'Zuora', categories: ['subscription'], logo: 'zuora.svg' },
  { name: 'Braintree', categories: ['subscription'], logo: 'braintree.svg' },
  { name: 'Chargify', categories: ['subscription'], logo: 'chargify.svg' },
  { name: 'Paddle', categories: ['subscription'], logo: 'paddle.svg' },
  { name: 'FastSpring', categories: ['subscription'], logo: 'fast-spring.svg' },
];
