import type { FC } from 'react';
import React, { useMemo } from 'react';
import type { DocumentWithIssues } from '@lama/document-service-client';
import { Flex, Text, greyPalette } from '@lama/design-system';
import { formatValue } from '@lama/data-formatters';
import { Tooltip } from '@mui/material';
import { DocumentActions } from './DocumentActions.js';
import { DeletePermanently } from './assets/DeletePermanently.js';

interface DeletedDocumentProps {
  document: DocumentWithIssues;
  onDownloadDocument?: (e: React.MouseEvent) => void;
}

export const DeletedDocument: FC<DeletedDocumentProps> = ({ document, onDownloadDocument }) => {
  const deletedAt = useMemo(() => formatValue(document.updatedAt, 'date'), [document.updatedAt]);
  const fullDeletedAt = useMemo(() => formatValue(document.updatedAt, 'datetime'), [document.updatedAt]);

  return (
    <Flex gap={2} alignItems={'center'} width={'100%'} minWidth={0}>
      <Flex
        flexDirection={'column'}
        alignItems={'center'}
        justifyContent={'center'}
        minWidth={'34px'}
        minHeight={'34px'}
        width={'34px'}
        height={'34px'}
        borderRadius={'4px'}
      >
        <DeletePermanently size={'28px'} color={greyPalette[400]} />
      </Flex>
      <Flex flexDirection={'column'} gap={1} minWidth={0}>
        <Flex gap={1} alignItems={'center'}>
          {document.description ? (
            <Text variant={'body1'} color={greyPalette[500]} ellipsis>
              {document.description}
            </Text>
          ) : null}
          {document.filename ? (
            <>
              {document.description ? (
                <Text variant={'body1'} color={greyPalette[500]}>
                  {'•'}
                </Text>
              ) : null}
              <Text variant={'body1'} color={greyPalette[500]} ellipsis>
                {document.filename}
              </Text>
            </>
          ) : null}
        </Flex>
        <Flex>
          <Tooltip title={fullDeletedAt} placement={'bottom'}>
            <Text variant={'body4'} color={greyPalette[500]} ellipsis>
              {`Deleted at: ${deletedAt}`}
            </Text>
          </Tooltip>
        </Flex>
      </Flex>
      <Flex justifyContent={'flex-end'} flex={1}>
        <DocumentActions
          onDownloadDocument={onDownloadDocument}
          placeholder={document.description}
          fileName={document.filename}
          documentDeleted
        />
      </Flex>
    </Flex>
  );
};
