import { useCallback } from 'react';
import type { UseMutationOptions } from '@tanstack/react-query';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { createDocumentServiceClient } from '@lama/document-service-client';
import { displayToast } from '@lama/app-components';
import { documentServiceUrl } from '../../framework/environment';
import { useAuthentication } from '../authentication/useAuthentication';

interface DeleteFileParams {
  documentId: string;
}

export const useDeleteDocumentMutation = (applicationId: string, options?: UseMutationOptions<void, unknown, DeleteFileParams>) => {
  const { token, isAuthenticated } = useAuthentication();
  const queryClient = useQueryClient();

  const deleteDocumentMutation = useCallback(
    async ({ documentId }: DeleteFileParams) => {
      if (!isAuthenticated) {
        return;
      }

      return createDocumentServiceClient(documentServiceUrl as string).deleteDocument(documentId, token);
    },
    [isAuthenticated, token],
  );

  return useMutation({
    mutationFn: deleteDocumentMutation,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['applicationDocuments', applicationId] });
      await queryClient.invalidateQueries({ queryKey: ['applicationRequirements', applicationId] });
    },
    onError: () => {
      displayToast('There was a problem processing the request. Please try again.', 'error');
    },
    ...options,
  });
};
