import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { useFloating, autoUpdate } from '@floating-ui/react';
import type { MentionNodeAttrs } from '@tiptap/extension-mention';
import type { SuggestionProps } from '@tiptap/suggestion';
import styled from 'styled-components';
import type { SuggestionOption as SuggestionItemType } from '../types';
import { Flex } from '../../Flex';
import { Text } from '../../Text';
import { greyPalette } from '../../../theme';
import type { MentionSuggestionsHandle } from '../useRichEditor';
import { SuggestionItem } from './SuggestionItem';

interface StyledMentionSuggestionsContainerProps {
  x: number;
  y: number;
  position: 'absolute' | 'fixed';
}

const StyledMentionSuggestionsContainer = styled(Flex)<StyledMentionSuggestionsContainerProps>`
  position: ${({ position }) => position};
  top: ${({ y }) => y}px;
  left: ${({ x }) => x}px;
  background-color: white;
  border-radius: 4px;
  padding: 8px;
  border: 1px solid ${greyPalette[300]};
`;

const MentionSuggestionList: React.ForwardRefRenderFunction<
  MentionSuggestionsHandle,
  SuggestionProps<SuggestionItemType, MentionNodeAttrs>
> = ({ items, command, decorationNode }, ref) => {
  const [selectedIndex, setSelectedIndex] = useState(-1);

  const { x, y, refs, strategy, update } = useFloating({
    strategy: 'absolute',
    placement: 'bottom-start',
    whileElementsMounted: autoUpdate,
  });

  const itemsToShow = useMemo(() => items.slice(0, 5), [items]);

  useEffect(() => {
    refs.setReference(decorationNode);
    update();
  }, [decorationNode, refs, update]);

  const onItemClick = useCallback(
    (item: SuggestionItemType) => {
      setSelectedIndex(0);
      command(item);
    },
    [command],
  );

  const upHandler = useCallback(
    (event: KeyboardEvent) => {
      event.preventDefault();
      event.stopPropagation();
      setSelectedIndex((selectedIndex + itemsToShow.length - 1) % itemsToShow.length);
    },
    [selectedIndex, itemsToShow.length],
  );

  const downHandler = useCallback(
    (event: KeyboardEvent) => {
      event.preventDefault();
      event.stopPropagation();
      setSelectedIndex((selectedIndex + 1) % itemsToShow.length);
    },
    [selectedIndex, itemsToShow.length],
  );

  const enterHandler = useCallback(
    (event: KeyboardEvent) => {
      event.preventDefault();
      event.stopPropagation();
      const selectedItem = itemsToShow[selectedIndex];
      if (selectedItem) {
        command(selectedItem);
      }
    },
    [selectedIndex, itemsToShow, command],
  );

  useEffect(() => {
    setSelectedIndex(0);
  }, [itemsToShow]);

  useImperativeHandle(ref, () => ({
    onKeyDown: (event: KeyboardEvent) => {
      if (event.key === 'ArrowUp') {
        upHandler(event);
        return true;
      }

      if (event.key === 'ArrowDown') {
        downHandler(event);
        return true;
      }

      if (event.key === 'Enter') {
        enterHandler(event);
        return true;
      }

      return false;
    },
  }));

  return (
    // @ts-ignore
    <StyledMentionSuggestionsContainer
      zIndex={1000}
      flexDirection={'column'}
      bg={'white'}
      x={x}
      y={y}
      position={strategy}
      ref={refs.floating as any}
    >
      {itemsToShow.length > 0 ? (
        itemsToShow.map((item, index) => (
          <SuggestionItem key={item.id} item={item} onClick={onItemClick} selected={index === selectedIndex} />
        ))
      ) : (
        <Text variant={'body2'}>{'No result'}</Text>
      )}
    </StyledMentionSuggestionsContainer>
  );
};

export const MentionSuggestions = forwardRef(MentionSuggestionList);
