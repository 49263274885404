import { Flex, Spinner } from '@lama/design-system';
import type { SpinnerSize } from '@lama/design-system';
import type { FC } from 'react';
import React from 'react';

interface LoadingPageProps {
  spinnerSize?: SpinnerSize;
}

export const LoadingPage: FC<LoadingPageProps> = ({ spinnerSize = 'xl' }) => (
  <Flex width={'100%'} height={'100%'} alignItems={'center'} justifyContent={'center'}>
    <Spinner size={spinnerSize} />
  </Flex>
);
