import type { AtomEffect } from 'recoil';
import type { PersistStorage } from 'recoil-persist';
import { recoilPersist } from 'recoil-persist';

const persisters: Record<string, AtomEffect<any>> = {};

export const persistAtom = (key: string, storage: PersistStorage = localStorage): AtomEffect<any> => {
  if (!persisters[key]) {
    persisters[key] = recoilPersist({ key, storage }).persistAtom;
  }
  return persisters[key];
};
