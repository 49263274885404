import { Flex, Text } from '@lama/design-system';
import type { ToggleButtonProps } from '@mui/material';
import { MenuItem, TextField, ToggleButton, useMediaQuery, useTheme } from '@mui/material';
import type { FC } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useToggle } from 'react-use';
import type { ScreenProps } from '../ScreenProps';
import { BasicScreen } from '../shared/BasicScreen';

const accountTypes = ['Checking', 'Savings', 'Money Market', 'Certificate of Deposit'];

const categories: { category: string; options: string[] }[] = [
  {
    category: 'Digital Banking',
    options: ['Online Banking', 'Mobile Banking', 'Remote Deposit Capture'],
  },
  {
    category: 'Funds Transfer & Payments',
    options: ['ACH', 'Bill Pay', 'Wire'],
  },
  {
    category: 'Physical Services',
    options: ['Check Services', 'Cash Services', 'Lockbox'],
  },
  {
    category: 'Merchant Services',
    options: ['Credit Card', 'Debit Card', 'Point of Sale'],
  },
  {
    category: 'Liquidity Management',
    options: ['Account Reconciliation', 'Controlled Disbursement', 'Sweep Accounts'],
  },
];

const BasicToggleButton: FC<ToggleButtonProps> = ({ children, ...rest }) => {
  const [selected, toggleSelected] = useToggle(false);

  return (
    <ToggleButton {...rest} selected={selected} onClick={toggleSelected}>
      {children}
    </ToggleButton>
  );
};

const ServiceSelection: FC<{ category: string; options: string[] }> = ({ category, options }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Flex flexDirection={'column'} gap={4}>
      <Text variant={'body2'}>{category}</Text>
      <Flex gap={6} flexDirection={isMobile ? 'column' : 'row'}>
        {options.map((option) => (
          <BasicToggleButton
            key={option}
            value={option}
            color={'primary'}
            // selected={useOfFunds.includes(option)}
            sx={{
              // eslint-disable-next-line @typescript-eslint/naming-convention
              '&.Mui-selected': {
                borderColor: 'primary.main',
              },
              height: '56px',
            }}
            fullWidth
          >
            {option}
          </BasicToggleButton>
        ))}
      </Flex>
    </Flex>
  );
};

export const AccountServicesSelectionScreen: FC<ScreenProps> = ({ onNextClick, onBackClick, ...stepsNavigationProps }) => {
  const { t } = useTranslation();

  return (
    <BasicScreen
      {...stepsNavigationProps}
      title={t('accountServicesSelection.title')}
      subtitle={t('accountServicesSelection.subtitle')}
      onNextClick={onNextClick}
      onBackClick={onBackClick}
    >
      <Flex flexDirection={'column'} gap={10}>
        <TextField select placeholder={'Select'} label={'Account Type'}>
          {accountTypes.map((value) => (
            <MenuItem key={value} value={value}>
              {value}
            </MenuItem>
          ))}
        </TextField>
        <Flex flexDirection={'column'} gap={8}>
          {categories.map((category) => (
            <ServiceSelection key={category.category} {...category} />
          ))}
        </Flex>
      </Flex>
    </BasicScreen>
  );
};
