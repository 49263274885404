import React, { useMemo } from 'react';
import type { EvaluatedApplicationRequirement } from '@lama/contracts';
import { sortBy } from 'lodash-es';
import { grey } from '@mui/material/colors';
import { Flex, Text } from '@lama/design-system';
import type { RequirementsByCategory } from '@lama/app-components';
import { RequirementCardWrapper } from './RequirementCardWrapper';

export interface RequirementsProps {
  requirementsByCategory: RequirementsByCategory<EvaluatedApplicationRequirement>[];
}

const RequirementCards = ({ requirements }: { requirements: EvaluatedApplicationRequirement[] }) => {
  const sorted = useMemo(() => sortBy(requirements, (x) => x.viewIndex ?? 10_000), [requirements]);

  return (
    <Flex flexDirection={'column'} gap={4} pb={4}>
      {sorted.map((requirement) => (
        <RequirementCardWrapper key={requirement.id} requirement={requirement} />
      ))}
    </Flex>
  );
};

export const Requirements: React.FC<RequirementsProps> = ({ requirementsByCategory }) => (
  <Flex flexDirection={'column'} gap={10}>
    {requirementsByCategory.map(
      ({ categoryLongName, requirements }) =>
        requirements.length && (
          <Flex flexDirection={'column'} key={categoryLongName} gap={6}>
            <Text variant={'h6'} color={grey[500]}>
              {categoryLongName}
            </Text>
            <RequirementCards requirements={requirements} />
          </Flex>
        ),
    )}
  </Flex>
);
