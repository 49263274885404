import React from 'react';
import type { FC } from 'react';
import { Text, Flex } from '@lama/design-system';

export const StyledChip: FC<{ text: string; color: string; bgcolor: string }> = ({ text, color, bgcolor }) => (
  <Flex>
    <Flex alignItems={'center'} backgroundColor={bgcolor} borderRadius={'4px'} px={2} py={1} maxHeight={'28px'}>
      <Text variant={'body2'} color={color} textAlign={'center'}>
        {text}
      </Text>
    </Flex>
  </Flex>
);
