import type { ApplicationUpdateApiModel } from '@lama/clients';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { displayToast } from '@lama/app-components';
import { updateApplication } from '../../shared/clients/applicationService';
import { useAuthentication } from '../authentication/useAuthentication';

export const useUpdateApplication = (applicationId: string) => {
  const queryClient = useQueryClient();
  const { token, isAuthenticated } = useAuthentication();

  return useMutation({
    mutationFn: async ({ updateApplicationPayload }: { updateApplicationPayload: ApplicationUpdateApiModel }) => {
      if (isAuthenticated) {
        await updateApplication(applicationId, updateApplicationPayload, token);
      }
    },
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: ['applicationRequirements', applicationId],
        }),
        queryClient.invalidateQueries({
          queryKey: ['application', applicationId],
        }),
        queryClient.invalidateQueries({
          queryKey: ['applications'],
        }),
      ]);
    },
    onError: () => {
      displayToast('Something went wrong while updating the application. Please contact support.', 'error');
    },
  });
};
