import React, { useMemo } from 'react';
import { useTheme, Box } from '@mui/material';
import type { HighlightArea, RenderHighlightsProps } from '@react-pdf-viewer/highlight';
import { compact, isNil } from 'lodash-es';
import type { FC } from 'react';
import type { PDFHighlightedArea } from './DocumentPreviewModal';

interface DocumentHighlightsProps {
  boundingBoxes: PDFHighlightedArea[];
  highlightProps: RenderHighlightsProps;
}

export const DocumentHighlights: FC<DocumentHighlightsProps> = React.memo(({ boundingBoxes, highlightProps }) => {
  const theme = useTheme();

  const highlightAreas = useMemo<HighlightArea[]>(() => {
    const areas = compact(
      boundingBoxes.map((box) => {
        const { top, left, width, height, pageIndex } = box;
        if (box && [left, top, width, height, pageIndex].some(isNil)) {
          return null;
        }

        return {
          top: top!,
          left: left!,
          width: width!,
          height: height!,
          pageIndex: pageIndex!,
        };
      }),
    );
    return areas;
  }, [boundingBoxes]);

  return (
    <>
      {highlightAreas.map((area) => (
        <Box
          key={`${area.pageIndex}-${area.top}-${area.left}-${area.width}-${area.height}`}
          className={'highlight-area'}
          style={{
            background: theme.palette.primary.main,
            opacity: 0.3,
            ...highlightProps.getCssProperties(area, highlightProps.rotation),
          }}
        />
      ))}
    </>
  );
});
