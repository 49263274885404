import { IconButton, useTheme, useMediaQuery, Menu, MenuItem, Typography } from '@mui/material';
import React, { useCallback, useRef, useState } from 'react';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DeleteOutlineRounded, EditOutlined } from '@mui/icons-material';
import { grey } from '@mui/material/colors';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Flex } from '@lama/design-system';
import { Tooltip } from '../Tooltip';
import { useConfirmModal } from '../ConfirmationModal';

interface ItemActionsProps {
  item: { id: string };
  onEditClick: (id: string) => void;
  onDeleteClick?: (id: string) => void;
  onInviteClick?: (id: string) => void;
  hasBeenInvited?: boolean;
  inviteAllowed?: boolean;
  deleteEnabled?: boolean;
  itemType?: string;
}

export const menuProps = {
  anchorOrigin: {
    vertical: 'bottom' as const,
    horizontal: 'right' as const,
  },
  transformOrigin: {
    vertical: 'top' as const,
    horizontal: 'right' as const,
  },
  PaperProps: {
    elevation: 0,
    sx: {
      width: '246px',
      maxWidth: '100%',
      border: 0,
      py: 0,
      mt: 1,
      borderWidth: '1px',
      borderColor: grey[300],
      boxShadow: '0px 11px 15px 0px #DBDBDB26',
    },
  },
};

interface ApplicationActionsMenuItemProps {
  text: string;
  icon?: React.ReactNode;
  onClick: () => void;
  color?: string;
  disabled?: boolean;
}

const ApplicationActionMenuItem: React.FC<ApplicationActionsMenuItemProps> = ({ text, onClick, icon, color, disabled }) => (
  // eslint-disable-next-line @typescript-eslint/naming-convention
  <MenuItem onClick={onClick} sx={{ '&:hover': { bgcolor: 'primary.light' }, borderRadius: '4px' }} disabled={disabled}>
    <Flex gap={2} alignItems={'center'}>
      <Typography variant={'body1'} color={color ?? 'text.secondary'}>
        {text}
      </Typography>
      <Typography variant={'body1'} color={color ?? 'text.secondary'}>
        {icon}
      </Typography>
    </Flex>
  </MenuItem>
);

const MobileItemActions: FC<{
  onDeleteClick: () => void;
  onEditClick: () => void;
  onInviteClick: () => void;
  hasBeenInvited?: boolean;
  inviteAllowed?: boolean;
  deleteEnabled?: boolean;
}> = ({ onDeleteClick, onEditClick, onInviteClick, hasBeenInvited, inviteAllowed = true, deleteEnabled = true }) => {
  const menuRef = useRef(null);
  const [open, setOpen] = useState(false);

  const onIconClick = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const onInvite = useCallback(() => {
    onInviteClick();
    handleClose();
  }, [handleClose, onInviteClick]);

  return (
    <>
      <IconButton
        onClick={onIconClick}
        // eslint-disable-next-line @typescript-eslint/naming-convention
        sx={{ borderRadius: '8px', '&:hover': { bgcolor: 'primary.light' }, bgcolor: open ? 'primary.light' : undefined }}
        ref={menuRef}
        role={'button'}
        aria-label={'item-actions'}
        disableRipple
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        key={'application-actions-menu'}
        anchorEl={menuRef.current}
        open={open}
        onClose={handleClose}
        {...menuProps}
        MenuListProps={{ sx: { py: 0.5, px: 0.5 } }}
      >
        <ApplicationActionMenuItem text={'Edit'} onClick={onEditClick} />
        {inviteAllowed ? <ApplicationActionMenuItem text={'Invite'} onClick={onInvite} disabled={hasBeenInvited} /> : null}
        {deleteEnabled ? <ApplicationActionMenuItem text={'Delete'} onClick={onDeleteClick} /> : null}
      </Menu>
    </>
  );
};

export const ItemActions: FC<ItemActionsProps> = ({
  item,
  onDeleteClick,
  onEditClick,
  onInviteClick,
  hasBeenInvited,
  deleteEnabled = true,
  inviteAllowed,
  itemType = 'item',
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { t } = useTranslation();
  const { confirm } = useConfirmModal();

  const onEdit = useCallback(() => {
    onEditClick(item.id);
  }, [item.id, onEditClick]);

  const onDelete = useCallback(async () => {
    const result = await confirm({
      title: t('itemActions.deleteConfirmation.title', { itemType }),
      message: t('itemActions.deleteConfirmation.message', { itemType }),
      cancelText: t('itemActions.deleteConfirmation.cancelText'),
      confirmText: t('itemActions.deleteConfirmation.confirmText'),
    });

    if (result) {
      onDeleteClick?.(item.id);
    }
  }, [confirm, item.id, itemType, onDeleteClick, t]);

  const onInvite = useCallback(() => {
    onInviteClick?.(item.id);
  }, [item.id, onInviteClick]);

  if (isMobile) {
    return (
      <MobileItemActions
        onDeleteClick={onDelete}
        onEditClick={onEdit}
        onInviteClick={onInvite}
        hasBeenInvited={hasBeenInvited}
        deleteEnabled={deleteEnabled}
        inviteAllowed={inviteAllowed}
      />
    );
  }

  return (
    <Flex gap={isMobile ? 0 : 2} flexDirection={'row'} alignItems={'center'}>
      {deleteEnabled ? (
        <Tooltip title={t('itemActions.delete')}>
          <IconButton onClick={onDelete} color={'primary'}>
            <DeleteOutlineRounded />
          </IconButton>
        </Tooltip>
      ) : null}
      <Tooltip title={t('itemActions.edit')}>
        <IconButton onClick={onEdit} color={'primary'}>
          <EditOutlined />
        </IconButton>
      </Tooltip>
    </Flex>
  );
};
