import { useTheme } from '@mui/material';
import type { FC } from 'react';
import React from 'react';

export const InventoryFinancingLogo: FC<{ selected: boolean }> = ({ selected }) => {
  const theme = useTheme();

  return (
    <svg width={'179'} height={'108'} viewBox={'0 0 179 108'} fill={'none'} xmlns={'http://www.w3.org/2000/svg'}>
      <path
        d={
          'M96.4484 7.75093L137.188 14.8673L177.603 7.79101C177.662 7.78008 177.662 7.69991 177.603 7.68898L136.729 0.536133L96.452 7.74728L96.4484 7.75093Z'
        }
        fill={'white'}
      />
      <path d={'M152.068 3.36426L112.455 10.5098L121.349 12.0621L160.987 4.90196L152.068 3.36426Z'} fill={'white'} />
      <path d={'M137.181 75.1915V14.8095L96.2807 7.72949V67.9439L137.181 75.1915Z'} fill={'white'} />
      <path d={'M121.185 18.7006V11.9886L112.277 10.4473V17.1228L121.185 18.7006Z'} fill={theme.palette.primary.light} />
      <path
        d={
          'M137.181 75.738C137.148 75.738 137.119 75.738 137.086 75.7307L96.1858 68.4831C95.9233 68.4357 95.7337 68.2098 95.7337 67.9438V7.72942C95.7337 7.56909 95.8066 7.41605 95.927 7.31037C96.0509 7.20835 96.215 7.16098 96.3718 7.19013L137.272 14.2737C137.534 14.3175 137.724 14.547 137.724 14.813V75.1914C137.724 75.3517 137.651 75.5047 137.527 75.6104C137.429 75.6942 137.305 75.738 137.177 75.738H137.181ZM96.8276 67.4847L136.634 74.5391V15.2722L96.8276 8.37802V67.4847Z'
        }
        fill={'#666666'}
      />
      <path
        d={
          'M129.819 52.8837C129.786 52.8837 129.753 52.8837 129.724 52.8764L103.289 48.143C103.03 48.0957 102.837 47.8698 102.837 47.6038V18.8466C102.837 18.6863 102.91 18.5332 103.034 18.4276C103.157 18.3255 103.322 18.2782 103.478 18.3073L129.914 22.9313C130.176 22.9787 130.366 23.2046 130.366 23.4706V52.3371C130.366 52.4974 130.293 52.6541 130.169 52.7561C130.071 52.8399 129.947 52.8837 129.819 52.8837ZM103.93 47.1483L129.272 51.6848V23.9298L103.93 19.4989V47.1483Z'
        }
        fill={theme.palette.primary.light}
      />
      <path
        d={
          'M113.655 46.4708C113.655 46.4708 113.633 46.4708 113.622 46.4708C112.714 46.449 111.868 46.0664 111.227 45.385C110.57 44.689 110.209 43.7671 110.209 42.7869V42.1747C110.209 41.6828 110.6 41.2784 111.077 41.2784C111.314 41.2784 111.533 41.3731 111.697 41.548C111.857 41.7156 111.945 41.9415 111.945 42.1747V42.8161C111.945 43.3153 112.127 43.7853 112.459 44.1388C112.787 44.4849 113.221 44.6781 113.681 44.6781C113.681 44.6781 113.688 44.6781 113.692 44.6781C114.144 44.6744 114.57 44.4813 114.895 44.1424C115.227 43.789 115.409 43.3225 115.409 42.8197L115.416 24.8447C115.416 24.5859 115.522 24.3418 115.712 24.1706C115.89 24.0066 116.131 23.9264 116.368 23.9519C116.805 23.9956 117.148 24.4001 117.148 24.8665V42.7942C117.148 43.7744 116.787 44.6963 116.131 45.3886C115.486 46.07 114.636 46.4526 113.724 46.4745C113.717 46.4745 113.713 46.4745 113.706 46.4745H113.684C113.684 46.4745 113.662 46.4745 113.651 46.4745L113.655 46.4708Z'
        }
        fill={theme.palette.primary.light}
      />
      <path
        d={
          'M108.175 33.9984C107.635 33.9073 107.285 33.3643 107.427 32.8323C108.656 28.1281 112.798 26.6706 117.21 27.4358C121.458 28.1719 124.67 31.4805 125.742 35.6527C125.914 36.3158 125.356 36.9426 124.681 36.826L108.175 33.9984Z'
        }
        fill={theme.palette.primary.light}
      />
      <path d={'M137.181 75.1917V14.8097L177.727 7.71875V67.9769L137.181 75.1917Z'} fill={theme.palette.primary.light} />
      <path
        d={
          'M137.181 75.7385C137.053 75.7385 136.929 75.6948 136.831 75.611C136.707 75.5053 136.634 75.3522 136.634 75.1919V14.8099C136.634 14.5439 136.823 14.318 137.086 14.2706L177.632 7.17973C177.793 7.15058 177.953 7.19431 178.077 7.29998C178.201 7.40201 178.274 7.55869 178.274 7.71902V67.9771C178.274 68.2431 178.085 68.469 177.822 68.5164L137.276 75.7312C137.243 75.7385 137.214 75.7385 137.181 75.7385ZM137.728 15.269V74.5397L177.18 67.5216V8.37127L137.728 15.2727V15.269Z'
        }
        fill={'#666666'}
      />
      <path d={'M167.977 40.3271V44.7653L147.974 48.1723V43.8289L167.977 40.3271Z'} fill={'#BDBDBD'} />
      <path
        d={'M148.936 30.6021L153.068 24.6699L157.173 29.1555L155.066 29.5235V40.0469L151.069 40.7247V30.2231L148.936 30.6021Z'}
        fill={'#BDBDBD'}
      />
      <path
        d={'M158.778 28.9478L162.909 23.0156L167.015 27.4975L164.903 27.8656V38.389L160.911 39.0704V28.5652L158.778 28.9478Z'}
        fill={'#BDBDBD'}
      />
      <path
        d={
          'M144.758 52.884C144.63 52.884 144.506 52.8403 144.408 52.7565C144.284 52.6545 144.211 52.4978 144.211 52.3374V23.471C144.211 23.205 144.4 22.9791 144.663 22.9317L171.098 18.3077C171.259 18.2785 171.419 18.3222 171.543 18.4279C171.667 18.5299 171.74 18.6866 171.74 18.847V47.6041C171.74 47.8701 171.55 48.096 171.288 48.1434L144.853 52.8767C144.82 52.884 144.787 52.884 144.758 52.884ZM145.305 23.9301V51.6852L170.646 47.1486V19.4992L145.305 23.9301Z'
        }
        fill={'#BDBDBD'}
      />
      <path
        d={
          'M137.188 15.418C137.155 15.418 137.126 15.418 137.093 15.4107L96.3536 8.29428C96.0911 8.24691 95.9015 8.02099 95.9015 7.75864C95.9015 7.49264 96.0911 7.26672 96.3536 7.21935L136.63 0.00819863C136.692 -0.00273288 136.758 -0.00273288 136.82 0.00819863L177.694 7.16105C177.982 7.21206 178.19 7.45984 178.19 7.75135C178.19 8.04286 177.982 8.29064 177.694 8.34165L137.283 15.418C137.25 15.4216 137.221 15.4253 137.188 15.4253V15.418ZM99.5878 7.7477L137.188 14.3175L174.723 7.7477L136.725 1.09771L99.5842 7.7477H99.5878Z'
        }
        fill={'#666666'}
      />
      <path d={'M106.942 34.0669L62.3191 41.8136L17.7362 34.0596L62.8259 26.2217L106.942 34.0669Z'} fill={'white'} />
      <path d={'M62.3191 41.8132L17.7362 34.0591L62.3884 26.6221L62.3191 41.8132Z'} fill={theme.palette.primary.light} />
      <path d={'M62.33 107.454V41.7487L107.128 34.042V99.5654L62.33 107.454Z'} fill={theme.palette.primary.light} />
      <path
        d={
          'M62.33 108C62.2024 108 62.0784 107.957 61.98 107.873C61.856 107.771 61.7831 107.614 61.7831 107.454V41.7482C61.7831 41.4822 61.9763 41.2563 62.2389 41.2089L107.037 33.5022C107.197 33.4767 107.358 33.5205 107.482 33.6225C107.606 33.7282 107.675 33.8775 107.675 34.0415V99.565C107.675 99.831 107.485 100.057 107.223 100.104L62.4248 107.993C62.392 108 62.3628 108 62.33 108ZM62.877 42.211V106.802L106.581 99.1058V34.6901L62.877 42.211Z'
        }
        fill={'#666666'}
      />
      <path
        d={
          'M70.3919 90.6086C70.2643 90.6086 70.1403 90.5649 70.0419 90.4811C69.9179 90.3754 69.845 90.2224 69.845 90.062V58.6522C69.845 58.3862 70.0346 58.1603 70.2971 58.1129L99.2521 53.0808C99.4125 53.0516 99.573 53.099 99.6969 53.201C99.8209 53.303 99.8902 53.4597 99.8902 53.62V84.9133C99.8902 85.1793 99.7006 85.4052 99.438 85.4526L70.4831 90.6013C70.4503 90.6086 70.4211 90.6086 70.3883 90.6086H70.3919ZM70.9388 59.1113V89.4098L98.7999 84.4542V54.2686L70.9388 59.1113Z'
        }
        fill={selected ? theme.palette.primary.main : '#0C2007'}
      />
      <path
        d={
          'M82.1183 84.2098C82.1183 84.2098 82.0964 84.2098 82.0854 84.2098C81.0937 84.1843 80.1639 83.7689 79.4601 83.0292C78.7418 82.2749 78.348 81.2692 78.348 80.2016V79.5347C78.348 78.9991 78.7746 78.5582 79.2997 78.5582C79.5586 78.5582 79.7992 78.6639 79.9779 78.8534C80.1529 79.0355 80.2477 79.2797 80.2477 79.5384V80.2344C80.2477 80.7773 80.4483 81.2874 80.8129 81.6737C81.1702 82.0526 81.6479 82.2603 82.1511 82.2603C82.1547 82.2603 82.1584 82.2603 82.162 82.2603C82.6579 82.2531 83.1246 82.049 83.4783 81.6773C83.8429 81.2947 84.0435 80.7846 84.0435 80.238L84.0508 60.6779C84.0508 60.3973 84.1675 60.1277 84.3753 59.9455C84.5722 59.7669 84.8347 59.6795 85.0973 59.7086C85.5749 59.756 85.9505 60.1933 85.9505 60.7034V80.2089C85.9505 81.2765 85.5567 82.2786 84.8384 83.0328C84.1346 83.7762 83.2012 84.1916 82.2021 84.2134C82.1948 84.2134 82.1912 84.2134 82.1839 84.2134H82.162C82.162 84.2134 82.1365 84.2134 82.1256 84.2134L82.1183 84.2098Z'
        }
        fill={selected ? theme.palette.primary.main : '#0C2007'}
      />
      <path
        d={
          'M94.0999 70.1043C94.6906 70.0023 95.0735 69.4156 94.9203 68.8363C93.5749 63.7203 89.0389 62.1316 84.204 62.966C79.5513 63.7677 76.0327 67.3678 74.8586 71.9044C74.6726 72.6295 75.2815 73.3072 76.0181 73.1834L94.0963 70.108L94.0999 70.1043Z'
        }
        fill={selected ? theme.palette.primary.main : '#0C2007'}
      />
      <path d={'M62.33 107.454V41.7479L17.9185 34.0303V99.6011L62.33 107.454Z'} fill={'white'} />
      <path
        d={
          'M62.33 108C62.2972 108 62.268 108 62.2352 107.993L17.8273 100.144C17.5648 100.097 17.3752 99.871 17.3752 99.605V34.0305C17.3752 33.8702 17.4481 33.7172 17.5685 33.6115C17.6924 33.5058 17.8565 33.4621 18.0133 33.4913L62.4212 41.2089C62.6837 41.2526 62.8733 41.4822 62.8733 41.7482V107.454C62.8733 107.614 62.8004 107.767 62.6764 107.873C62.5779 107.957 62.454 108 62.3263 108H62.33ZM18.4691 99.1459L61.7831 106.802V42.2073L18.4654 34.6791V99.1422L18.4691 99.1459Z'
        }
        fill={'#666666'}
      />
      <path d={'M28.5948 76.9912V81.8229L50.5088 85.5287V80.8027L28.5948 76.9912Z'} fill={theme.palette.primary.light} />
      <path
        d={'M49.4514 66.4093L44.93 59.9561L40.4305 64.8315L42.7386 65.236V76.6849L47.1178 77.4246V65.9975L49.4514 66.4093Z'}
        fill={theme.palette.primary.light}
      />
      <path
        d={'M38.673 64.6056L34.1517 58.1523L29.6522 63.0314L31.9639 63.4323V74.8812L36.3394 75.6209V64.1938L38.673 64.6056Z'}
        fill={theme.palette.primary.light}
      />
      <path
        d={
          'M54.0311 90.608C53.9983 90.608 53.9655 90.608 53.9363 90.6007L24.9813 85.452C24.7188 85.4046 24.5292 85.1787 24.5292 84.9127V53.6194C24.5292 53.4591 24.6021 53.306 24.7224 53.2004C24.8464 53.0947 25.0105 53.051 25.1673 53.0801L54.1222 58.1123C54.3848 58.156 54.5744 58.3855 54.5744 58.6515V90.0614C54.5744 90.2217 54.5015 90.3748 54.3775 90.4804C54.279 90.5643 54.1551 90.608 54.0274 90.608H54.0311ZM25.6231 84.4535L53.4842 89.4092V59.1107L25.6231 54.268V84.4535Z'
        }
        fill={theme.palette.primary.light}
      />
      <path d={'M121.709 31.7534L106.114 33.896L61.8159 26.55L78.3991 24.207L121.709 31.7534Z'} fill={'white'} />
      <path
        d={
          'M106.114 34.4421C106.085 34.4421 106.056 34.4421 106.023 34.4348L61.7247 27.0889C61.4585 27.0451 61.2653 26.8119 61.2689 26.5423C61.2689 26.2727 61.4731 26.0467 61.7393 26.0067L78.3225 23.6637C78.3772 23.6564 78.4356 23.6564 78.4939 23.6637L121.804 31.2101C122.07 31.2574 122.26 31.4906 122.256 31.7566C122.253 32.0263 122.052 32.2522 121.782 32.2886L106.191 34.4312C106.165 34.4312 106.14 34.4348 106.118 34.4348L106.114 34.4421ZM65.4184 26.5933L106.125 33.3417L118.158 31.6874L78.3881 24.7605L65.4147 26.5933H65.4184Z'
        }
        fill={'#666666'}
      />
      <path d={'M63.0447 26.4003L18.4254 34.1033L0.547729 32.8863L44.755 25.1104L63.0447 26.4003Z'} fill={'white'} />
      <path
        d={
          'M18.4253 34.6501C18.4253 34.6501 18.3998 34.6501 18.3889 34.6501L0.51125 33.433C0.234134 33.4148 0.0153583 33.1925 0.000773238 32.9156C-0.0138118 32.6387 0.17944 32.3945 0.45291 32.3472L44.6602 24.5712C44.704 24.5639 44.7477 24.5603 44.7951 24.5639L63.0848 25.8538C63.3619 25.8721 63.577 26.0943 63.5916 26.3713C63.6062 26.6482 63.4093 26.8887 63.1395 26.9361L18.5201 34.6391C18.491 34.6428 18.4582 34.6464 18.429 34.6464L18.4253 34.6501ZM5.06908 32.646L18.3962 33.5533L58.5087 26.6263L44.7842 25.6607L5.06908 32.646Z'
        }
        fill={'#666666'}
      />
      <path d={'M62.3191 41.8133L52.2736 52.6538L5.31702 44.4187L17.9331 34.0957L62.3191 41.8133Z'} fill={'white'} />
      <path
        d={
          'M52.2736 53.2002C52.2408 53.2002 52.2116 53.2002 52.1788 53.1929L5.22222 44.9579C5.01073 44.9214 4.84301 44.7647 4.78831 44.557C4.73362 44.3493 4.80654 44.1307 4.97062 43.9959L17.5867 33.6729C17.7107 33.5709 17.8711 33.5308 18.0279 33.5563L62.4139 41.2703C62.6108 41.3031 62.7748 41.4452 62.8368 41.6347C62.9025 41.8242 62.8551 42.0355 62.7201 42.1813L52.6747 53.0217C52.569 53.1346 52.4231 53.1966 52.2736 53.1966V53.2002ZM6.58592 44.087L52.0731 52.0633L61.2325 42.1776L18.0862 34.6786L6.58592 44.087Z'
        }
        fill={'#666666'}
      />
      <path d={'M106.982 34.0771L119.248 44.9358L72.9297 53.1708L62.3883 41.9405L106.982 34.0771Z'} fill={'white'} />
      <path
        d={
          'M72.9297 53.7176C72.7802 53.7176 72.6343 53.6556 72.5322 53.5463L61.9909 42.316C61.8523 42.1702 61.8086 41.9589 61.8706 41.7694C61.9325 41.5799 62.0966 41.4378 62.2935 41.405L106.884 33.5416C107.048 33.5125 107.215 33.5599 107.339 33.6692L119.605 44.5278C119.762 44.6663 119.828 44.8849 119.77 45.0853C119.711 45.2857 119.544 45.4388 119.339 45.4752L73.0208 53.7103C72.988 53.7176 72.9552 53.7176 72.926 53.7176H72.9297ZM63.4786 42.3051L73.1229 52.5843L118.038 44.597L106.814 34.6639L63.4786 42.3051Z'
        }
        fill={'#666666'}
      />
    </svg>
  );
};
