import type { CSSProperties, HTMLAttributes, PropsWithChildren } from 'react';
import React, { forwardRef } from 'react';
import type {
  FlexboxProps,
  PaddingProps,
  HeightProps,
  WidthProps,
  MaxHeightProps,
  MaxWidthProps,
  BorderProps,
  MinWidthProps,
  MinHeightProps,
  OverflowProps,
  MarginProps,
  PositionProps,
} from 'styled-system';
import {
  border,
  height,
  flexbox,
  padding,
  width,
  maxHeight,
  maxWidth,
  minWidth,
  minHeight,
  overflow,
  margin,
  position,
} from 'styled-system';
import styled, { css } from 'styled-components';
import { backgroundStyle, fullWidthStyle } from '../styles/shared.styles';
import { gapStyle } from './Flex.styles';

export interface FlexProps
  extends HTMLAttributes<HTMLDivElement>,
    FlexboxProps,
    PaddingProps,
    HeightProps,
    WidthProps,
    MaxHeightProps,
    MinHeightProps,
    MaxWidthProps,
    MinWidthProps,
    OverflowProps,
    MarginProps,
    PositionProps,
    BorderProps {
  /**
   * Adds px gap between a list of elements
   */
  gap?: CSSProperties['gap'];
  fullWidth?: boolean;
  backgroundColor?: string;
  bg?: string;
}

const StyledFlex = styled.div<FlexProps>`
  display: flex;

  ${backgroundStyle};
  ${gapStyle};
  ${fullWidthStyle};

  ${width};
  ${height};
  ${minWidth};
  ${maxWidth};
  ${minHeight};
  ${maxHeight};
  ${padding};
  ${flexbox};
  ${border};
  ${overflow};
  ${margin};
  ${position};

  ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;
    `};
`;

export const Flex = forwardRef<HTMLDivElement, PropsWithChildren<FlexProps>>(({ ...props }, ref) => (
  <StyledFlex ref={ref as any} {...props} />
));

Flex.displayName = 'Flex';
