/* eslint-disable @typescript-eslint/naming-convention */
import React, { useContext, useMemo } from 'react';
import { TableContainer, Table, TableHead, TableRow, Button, TableCell, TableBody, Paper, Checkbox, Typography } from '@mui/material';
import type { SvgIconComponent } from '@mui/icons-material';
import { Computer, DirectionsCarFilled, LibraryAdd, Paid, Place } from '@mui/icons-material';
import { Tooltip } from '@lama/app-components';
import { initiator } from '@lama/data-formatters';
import { Flex } from '@lama/design-system';
import { BasicScreen } from '../shared/BasicScreen';
import type { ScreenProps } from '../ScreenProps';
import { ApplicationContext } from '../../shared/contexts/ApplicationContext';

const assetTypes: Record<string, SvgIconComponent> = {
  location: Place,
  vehicle: DirectionsCarFilled,
  digital: Computer,
  account: Paid,
};

const createData = (assetType: string, type: string, title: string, marketValue: string, status: string) => ({
  assetType,
  TypeIcon: assetTypes[type],
  title,
  marketValue,
  status,
});

export const AssetsScreen: React.FC<ScreenProps> = ({ ...stepsNavigationProps }) => {
  const { application } = useContext(ApplicationContext);

  const initiatorPerson = useMemo(() => initiator(application), [application]);

  const formattedAddress = useMemo(() => {
    if (!initiatorPerson?.address) {
      return '';
    }
    const { address1, city, state, zip } = initiatorPerson.address;

    return `${address1} ${city}, ${state} ${zip}`;
  }, [initiatorPerson?.address]);

  const rows = useMemo(
    () => [
      createData('Personal Residence', 'location', formattedAddress, '750K', 'Mortgage'),
      createData('Company Vehicle', 'vehicle', '2021 GMC Sierra 1500', '30K', '1 Lien'),
      createData('Personal Savings Account', 'account', 'US Bank savings account 697666556', '75K', 'Free'),
    ],
    [formattedAddress],
  );

  return (
    <BasicScreen {...stepsNavigationProps} title={'Assets'} subtitle={'To improve your offer, please confirm that you own these assets'}>
      <Flex flexDirection={'column'} gap={4} alignItems={'center'}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>{'Asset'}</TableCell>
                <TableCell>{'Type'}</TableCell>
                <TableCell>{'Title'}</TableCell>
                <TableCell>{'Market Value'}</TableCell>
                <TableCell>{'Status'}</TableCell>
                <TableCell>{'Collateral'}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map(({ title, assetType, marketValue, status, TypeIcon }) => (
                <TableRow key={title}>
                  <TableCell>
                    <Typography>{assetType}</Typography>
                  </TableCell>
                  <TableCell>
                    <TypeIcon />
                  </TableCell>
                  <TableCell>
                    <Tooltip title={title}>
                      <Typography maxWidth={'100px'} maxHeight={'100px'} sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
                        {title}
                      </Typography>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    <Typography>{marketValue}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{status}</Typography>
                  </TableCell>
                  <TableCell>
                    <Checkbox />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Button size={'large'} variant={'outlined'} color={'primary'} startIcon={<LibraryAdd />} sx={{ minWidth: '50%' }}>
          {'Add Asset'}
        </Button>
      </Flex>
    </BasicScreen>
  );
};
