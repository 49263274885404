import type { LDSingleKindContext } from 'launchdarkly-js-sdk-common';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { isEmpty } from 'lodash-es';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { partnerState } from '../state/appState';
import { UserDetailsContext } from '../shared/contexts/UserDetailsContext';

export const useLaunchDarklyIdentify = () => {
  const ldClient = useLDClient();
  const [userIdentified, setUserIdentified] = useState(false);
  const [partnerIdentified, setPartnerIdentified] = useState(false);
  const partner = useRecoilValue(partnerState);
  const user = useContext(UserDetailsContext);

  const onUserIdentifyDone = useCallback(() => {
    setUserIdentified(true);
  }, []);

  const onPartnerIdentifyDone = useCallback(() => {
    setPartnerIdentified(true);
  }, []);

  useEffect(() => {
    if (!partner) {
      return;
    }

    if (!isEmpty(user) && !userIdentified) {
      const { email, firstName, lastName } = user;

      const ldContext =
        navigator.userAgent.includes('DatadogSynthetics') || email?.toLocaleLowerCase().includes('synthetic')
          ? ({
              kind: 'user',
              firstName: 'Synthetics',
              lastName: 'Test',
              email: 'synthetics@lama.ai',
              key: 'synthetics@lama.ai',
              custom: { partner: partner.id },
            } as LDSingleKindContext)
          : ({
              kind: 'user',
              firstName,
              lastName,
              email,
              key: email,
              custom: { partner: partner.id },
            } as LDSingleKindContext);

      void ldClient?.identify(ldContext, undefined, onUserIdentifyDone);
    } else if (!partnerIdentified && !userIdentified) {
      const ldContext = {
        kind: 'partner',
        key: partner.id,
      };

      void ldClient?.identify(ldContext, undefined, onPartnerIdentifyDone);
    }
  }, [user, userIdentified, partnerIdentified, partner, ldClient, onUserIdentifyDone, onPartnerIdentifyDone]);

  return;
};
