import { Flex, Text } from '@lama/design-system';
import { Container, Paper, TextField, Button } from '@mui/material';
import type { FC } from 'react';
import React, { useCallback, useState } from 'react';

export const DemoLoginPage: FC<{ setAccess: (val: boolean) => void }> = ({ setAccess }) => {
  const [password, setPassword] = useState('');

  const onSubmitClick = useCallback((): void => {
    const expectedPassword = window.location.hostname.includes('firstbank.lamabanking.com') ? 'HelloFirstBank' : 'lamadrama';

    if (password === expectedPassword) {
      setAccess(true);
      return;
    }

    setAccess(false);
    setPassword('');
  }, [password, setAccess]);

  const onPasswordChange = useCallback((event: React.ChangeEvent<HTMLInputElement>): void => {
    setPassword(event.target.value);
  }, []);

  return (
    <Container>
      <Flex flexDirection={'column'} width={'100%'} height={'100vh'} justifyContent={'center'} alignItems={'center'}>
        <Paper elevation={12}>
          <Flex flexDirection={'column'} gap={8} p={8}>
            <Flex flexDirection={'column'} gap={2}>
              <Text variant={'h4'}>{'Welcome!'}</Text>
              <Text variant={'h6'}>{'Please enter your access code'}</Text>
            </Flex>
            <Flex flexDirection={'column'} gap={4}>
              <TextField size={'small'} label={'Password'} type={'password'} value={password} onChange={onPasswordChange} />
              <Button variant={'outlined'} onClick={onSubmitClick}>
                {'Submit'}
              </Button>
            </Flex>
          </Flex>
        </Paper>
      </Flex>
    </Container>
  );
};
