import React from 'react';
import type { FC } from 'react';
import type { FinancialReference } from '@lama/contracts';
import { Flex, greyPalette, Grid, Text } from '@lama/design-system';
import { formatValue } from '@lama/data-formatters';

interface FinancialReferenceItemProps {
  item: FinancialReference;
}

export const FinancialReferenceItem: FC<FinancialReferenceItemProps> = ({
  item: { accountNumber, accountType, balance, institutionAddress, institutionName },
}) => {
  const itemData = [
    ...(institutionAddress ? [{ label: 'Address', values: institutionAddress, type: 'string' as const }] : []),
    ...(accountNumber ? [{ label: 'Account Number', values: accountNumber, type: 'string' as const }] : []),
    ...(accountType ? [{ label: 'Account Type', values: accountType }] : []),
    ...(balance ? [{ label: 'Balance', values: balance, type: 'currency' as const }] : []),
  ];
  return (
    <Flex gap={4} flexDirection={'column'} width={'100%'} flex={1}>
      <Text variant={'body1'} weight={700} color={'secondary'}>
        {institutionName}
      </Text>
      <Grid columns={3}>
        {itemData.map((line) => (
          // <KeyValuePair key={line.label} name={line.label} value={line.values} type={line.type} />
          <Flex flexDirection={'column'} gap={2} minWidth={0} key={line.label}>
            <Text variant={'body2'} color={greyPalette[500]}>
              {line.label}
            </Text>
            <Flex>
              <Text variant={'body2'}>{line.type === 'currency' ? formatValue(line.values, 'currency') : line.values}</Text>
            </Flex>
          </Flex>
        ))}
      </Grid>
    </Flex>
  );
};
