import { useCallback } from 'react';
import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import type { Account } from '@lama/contracts';
import { getAccounts } from '../../shared/clients/bankService';
import { useAuthentication } from '../authentication/useAuthentication';

export const useBankAccountsQuery = (businessId?: string, options?: UseQueryOptions<Account[]>) => {
  const { token, isAuthenticated } = useAuthentication();

  const getBankAccountsQuery = useCallback(async () => {
    if (!isAuthenticated || !businessId) {
      return [];
    }

    return getAccounts(businessId, token);
  }, [businessId, isAuthenticated, token]);

  const query = useQuery<Account[]>({ queryKey: ['bankAccounts', businessId], queryFn: getBankAccountsQuery, ...options });

  return query;
};
