import React, { useCallback, useContext } from 'react';
import { Box, Divider, Drawer, Link } from '@mui/material';
import { Flex } from '@lama/design-system';
import { AccountDetails } from '@lama/app-components';
import { useNavigate } from 'react-router-dom';
import { CloseButton } from '../../../shared/CloseButton';
import { useLogout } from '../../../../hooks/useLogout';
import { UserDetailsContext } from '../../../../shared/contexts/UserDetailsContext';
import { SideNavCategories } from './SideNavCategories';
import { SideNavContactInfo } from './SideNavContactInfo';

interface SidebarProps {
  open: boolean;
  logoUrl: string;
  onClose: () => void;
}

export const MobileSidebar: React.FC<SidebarProps> = ({ open, logoUrl, onClose }) => {
  const user = useContext(UserDetailsContext);
  const logout = useLogout();
  const navigate = useNavigate();

  const onCategoryClick = useCallback(() => {
    onClose();
  }, [onClose]);

  const onMyApplications = useCallback(() => {
    navigate('/applications');
    onClose();
  }, [navigate, onClose]);

  return (
    <Drawer
      sx={{
        width: '100vw',
        height: open ? '100vh' : 0,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        '& .MuiDrawer-paper': {
          height: '100vh',
          width: '100vw',
          boxSizing: 'border-box',
          mt: 0,
        },
      }}
      variant={'persistent'}
      anchor={'top'}
      data-testid={'sidebar'}
      open={open}
    >
      <Flex flexDirection={'column'} height={'100vh'} justifyContent={'space-between'}>
        <Flex flexDirection={'column'}>
          <Box paddingLeft={4} py={2} sx={{ cursor: 'pointer' }}>
            <Link href={'/'}>
              <img height={'32px'} src={logoUrl} />
            </Link>
          </Box>
          <CloseButton onClose={onClose} />
          <SideNavCategories onCategoryClick={onCategoryClick} />
        </Flex>
        <Flex flexDirection={'column'} gap={4} py={4}>
          <Flex flexDirection={'column'} gap={4} px={8}>
            {user ? (
              <AccountDetails
                logout={logout}
                firstName={user?.firstName ?? ''}
                lastName={user?.lastName ?? ''}
                email={user?.email ?? ''}
                popoverLocation={{ horizontal: 'right', vertical: 'top' }}
                actions={[{ label: 'My Applications', onClick: onMyApplications }]}
              />
            ) : null}
            <Divider />
          </Flex>
          <SideNavContactInfo />
        </Flex>
      </Flex>
    </Drawer>
  );
};
