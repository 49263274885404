import React from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Flex, Text } from '@lama/design-system';
import { SignAndSubmitIcon } from '../signAndSubmit/SigningIcon';

export interface ThankYouScreenProps {
  firstName: string;
}

export const ThankYouScreen: React.FC<ThankYouScreenProps> = ({ firstName }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Flex pl={6} pt={6} justifyContent={'center'}>
      <Flex flexDirection={'column'} gap={4} alignItems={isMobile ? 'center' : 'left'} width={'100%'}>
        <Text variant={isMobile ? 'h5' : 'h4'} textAlign={isMobile ? 'center' : 'left'}>
          {t('thankYou.title', { firstName })}
        </Text>
        <Text variant={'body1'} textAlign={isMobile ? 'center' : 'left'}>
          {t('thankYou.subtitle')}
        </Text>
        <Flex pt={isMobile ? 6 : 12}>
          <SignAndSubmitIcon />
        </Flex>
      </Flex>
    </Flex>
  );
};
