import { Flex, greyPalette, Text } from '@lama/design-system';
import { VisibilityOutlined } from '@mui/icons-material';
import { MenuItem } from '@mui/material';
import React from 'react';
import type { FC } from 'react';

interface OpenDocumentMenuItemProps {
  onClick?: (e: React.MouseEvent) => void;
}
export const OpenDocumentMenuItem: FC<OpenDocumentMenuItemProps> = ({ onClick: openDocument }) => (
  <MenuItem onClick={openDocument}>
    <Flex alignItems={'center'} gap={2} alignContent={'center'}>
      <VisibilityOutlined sx={{ color: greyPalette[700], width: '20px', height: '20px' }} />
      <Text variant={'body2'}>{'Open'}</Text>
    </Flex>
  </MenuItem>
);
