import { useSessionStorage } from 'react-use';
import { useRecoilValue } from 'recoil';
import { partnerState } from '../state/appState';

export const useDemoAccess = (): [boolean, (val: boolean) => void] => {
  const partner = useRecoilValue(partnerState);
  const [access, setAccess] = useSessionStorage('lamaAccess', false);

  if (!partner) {
    return [access, setAccess];
  }

  if (partner.status === 'production') {
    return [true, setAccess];
  }

  return [access, setAccess];
};
