import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useProductsByPartnerQuery } from './react-query/useProductsByPartnerQuery';

export const useProductFromParamsByPartner = (partnerId?: string) => {
  const { data: products, isPending: loadingProducts } = useProductsByPartnerQuery(partnerId);
  const { productName } = useParams();

  const product = useMemo(() => products?.find((p) => p.name === productName), [products, productName]);

  if (!partnerId) {
    return null;
  }

  if (loadingProducts) {
    return null;
  }

  return product ?? null;
};
