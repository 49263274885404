/* eslint-disable react/destructuring-assignment */
import type { FC } from 'react';
import React, { useMemo } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { Flex } from '@lama/design-system';
import styled from 'styled-components';
import { MultiStepper } from '../screens/shared/MultiStepper';
import { partnerState } from '../state/appState';
import { getLogoUrl } from '../shared/utils/getLogoUrl';
import { useAuthentication } from '../hooks/authentication/useAuthentication';
import { AccountMenu } from '../screens/managementScreen/AccountMenu';

type MainHeaderProps =
  | {
      showProgress: true;
      currentStep: string;
      groupedSteps: string[][];
      showAccountSettings: boolean;
    }
  | {
      showProgress?: false;
      showAccountSettings: boolean;
    };

const HeaderContainer = styled(Flex)`
  box-shadow: 0px 11px 15px rgba(219, 219, 219, 0.15);
`;

const Logo: FC<{ showProgress?: boolean }> = ({ showProgress }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const partner = useRecoilValue(partnerState);
  const logoUrl = useMemo(
    () => (partner ? getLogoUrl(partner.id, isMobile && showProgress ? 'small' : 'big') : ''),
    [isMobile, partner, showProgress],
  );

  return <img height={'30px'} src={logoUrl} />;
};

export const MainHeader: React.FC<MainHeaderProps> = (props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { isAuthenticated } = useAuthentication();

  return (
    <HeaderContainer flexDirection={'row'} alignItems={'center'} minHeight={'64px'} width={'100%'} px={4}>
      {props.showProgress ? (
        <>
          <Flex flex={1} justifyContent={'flex-start'}>
            <Logo showProgress={props.showProgress} />
          </Flex>
          <Flex flex={isMobile ? 2 : 1}>
            <MultiStepper currentStep={props.currentStep} groupedSteps={props.groupedSteps} />
          </Flex>
        </>
      ) : (
        <>
          <Flex flex={1} />
          <Flex flex={1} justifyContent={'center'}>
            <Logo showProgress={props.showProgress} />
          </Flex>
        </>
      )}
      <Flex flex={1} justifyContent={'flex-end'}>
        {isAuthenticated ? (
          <AccountMenu showAccountSettings={props.showAccountSettings} popoverLocation={{ horizontal: 'center', vertical: 'bottom' }} />
        ) : null}
      </Flex>
    </HeaderContainer>
  );
};
