import type { FC, ReactNode } from 'react';
import React from 'react';
import { Flex, Spinner } from '@lama/design-system';
import styled from 'styled-components';

const DisabledWrapper = styled(Flex)<{ disabled: boolean }>`
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
`;

export const LoadingWrapper: FC<{ children: ReactNode; loading: boolean; disabled: boolean }> = ({ children, loading, disabled }) => (
  <Flex position={'relative'} flexDirection={'column'}>
    {loading ? (
      <Flex position={'absolute'} zIndex={1} width={'100%'} height={'100%'} alignItems={'center'} justifyContent={'center'}>
        <Spinner size={'50px'} />
      </Flex>
    ) : null}
    <DisabledWrapper disabled={disabled} flexDirection={'column'}>
      {children}
    </DisabledWrapper>
  </Flex>
);
