import type { RequirementProperty } from '@lama/contracts';

import type { FC } from 'react';
import React, { useMemo } from 'react';

import { FieldArray } from 'formik';
import { Address } from './Address.js';

export const BusinessAddress: FC<{
  property: RequirementProperty;
  submitted?: boolean;
  enableChildPropertiesConfiguration?: boolean;
}> = ({ property, submitted, enableChildPropertiesConfiguration }) => {
  const addressFields = useMemo(
    () =>
      enableChildPropertiesConfiguration ? property.childProperties?.map((childProperty) => childProperty.fieldName) ?? [] : undefined,
    [property.childProperties, enableChildPropertiesConfiguration],
  );

  return (
    <FieldArray name={'addresses'}>
      {() => <Address submitted={submitted} fieldNamePrefix={'addresses[0]'} required={!property.optional} addressFields={addressFields} />}
    </FieldArray>
  );
};
