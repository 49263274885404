import React, { useMemo } from 'react';
import type { Entity } from '@lama/common-types';
import type { RequirementProperty } from '@lama/contracts';
import { useField } from 'formik';
import { useEffectOnce } from 'react-use';
import { Flex } from '@lama/design-system';
import type { ApplicationApiModel, OpportunityApiModel } from '@lama/clients';
import { GenericPropertiesGrid } from './GenericPropertiesGrid.js';

export const GenericObjectForm: React.FC<{
  property: RequirementProperty;
  submitted?: boolean;
  initialValue?: any;
  entityType: Entity;
  application: ApplicationApiModel;
  opportunity?: OpportunityApiModel;
}> = ({ property: { fieldName, childProperties }, submitted, entityType, application, opportunity }) => {
  const [field, , { setValue: setFormikValue }] = useField(fieldName);

  useEffectOnce(() => {
    if (!field.value) {
      void setFormikValue({});
    }
  });

  const subProperties = useMemo(
    () => childProperties?.map((p) => ({ ...p, fieldName: `${fieldName}.${p.fieldName}` })) ?? [],
    [childProperties, fieldName],
  );

  return (
    <Flex gap={4} flexWrap={'wrap'} flexDirection={'row'} width={'100%'}>
      <GenericPropertiesGrid
        properties={subProperties}
        submitted={submitted}
        entityType={entityType}
        entity={{}}
        application={application}
        opportunity={opportunity}
      />
    </Flex>
  );
};
