/* eslint-disable @typescript-eslint/naming-convention */
import type { FC } from 'react';
import React, { useMemo } from 'react';
import decode from 'jwt-decode';
import type { User, LamaJwtPayload } from '@lama/user-service-client';
import { useAuthentication } from '../../hooks/authentication/useAuthentication';
import { useGetUserQuery } from '../../hooks/react-query/useUserQuery';

export const UserDetailsContext = React.createContext<User | null>(null);

interface UserDetailsProviderProps {
  children: React.ReactNode;
}

const getUserIdFromToken = (token: string): string | undefined => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { 'lama.ai/userId': userId } = decode<LamaJwtPayload>(token);

  return userId;
};

export const UserDetailsProvider: FC<UserDetailsProviderProps> = ({ children }) => {
  const { token, isAuthenticated } = useAuthentication();
  const tokenUserId = useMemo(() => (isAuthenticated ? getUserIdFromToken(token) : undefined), [isAuthenticated, token]);

  const { data: user } = useGetUserQuery(tokenUserId);

  const userDetails = useMemo(() => {
    if (!user) {
      return null;
    }

    return user;
  }, [user]);

  return <UserDetailsContext.Provider value={userDetails}>{children}</UserDetailsContext.Provider>;
};
