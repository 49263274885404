import type { FC } from 'react';
import React from 'react';
import { Text } from '../Text';
import type { InputProps } from './Input.component';
import { StyledInputHelper } from './Styles/InputHelper.styles';

interface InputHelperProps {
  helper: InputProps['helper'];
}

export const InputHelper: FC<InputHelperProps> = ({ helper }) => (
  <StyledInputHelper>
    {typeof helper === 'string' ? (
      <Text variant={'body3'} color={'secondary'}>
        {helper}
      </Text>
    ) : (
      helper
    )}
  </StyledInputHelper>
);
