import React from 'react';

export const DownloadIcon = ({ size: externalSize = '14', color = '#616161' }: { size?: string; color?: string }) => (
  <svg width={externalSize} height={externalSize} viewBox={'0 0 14 14'} fill={'none'} xmlns={'http://www.w3.org/2000/svg'}>
    <path
      d={
        'M6.99998 9.84292C6.89955 9.84292 6.80607 9.82689 6.71954 9.79483C6.633 9.76279 6.55074 9.70777 6.47275 9.62977L3.88142 7.03846C3.75749 6.91453 3.69633 6.7695 3.69794 6.60338C3.69954 6.43725 3.76069 6.28955 3.88142 6.16027C4.01069 6.03101 4.15919 5.96424 4.32692 5.95996C4.49465 5.95568 4.64316 6.01818 4.77244 6.14746L6.375 7.75002V1.375C6.375 1.19765 6.43483 1.04915 6.55448 0.929501C6.67413 0.809834 6.82263 0.75 6.99998 0.75C7.17733 0.75 7.32583 0.809834 7.44548 0.929501C7.56513 1.04915 7.62496 1.19765 7.62496 1.375V7.75002L9.22752 6.14746C9.35145 6.02353 9.49862 5.96236 9.66902 5.96396C9.83942 5.96557 9.98926 6.03101 10.1185 6.16027C10.2393 6.28955 10.3018 6.43592 10.306 6.59938C10.3103 6.76283 10.2478 6.90919 10.1185 7.03846L7.52721 9.62977C7.44922 9.70777 7.36696 9.76279 7.28042 9.79483C7.19389 9.82689 7.10041 9.84292 6.99998 9.84292ZM2.25642 13.25C1.83547 13.25 1.47917 13.1041 1.1875 12.8125C0.895833 12.5208 0.75 12.1645 0.75 11.7435V10.1089C0.75 9.93159 0.809833 9.78309 0.9295 9.66344C1.04915 9.54379 1.19765 9.48396 1.375 9.48396C1.55235 9.48396 1.70085 9.54379 1.8205 9.66344C1.94015 9.78309 1.99998 9.93159 1.99998 10.1089V11.7435C1.99998 11.8077 2.02669 11.8664 2.0801 11.9199C2.13353 11.9733 2.19231 12 2.25642 12H11.7435C11.8077 12 11.8664 11.9733 11.9199 11.9199C11.9733 11.8664 12 11.8077 12 11.7435V10.1089C12 9.93159 12.0598 9.78309 12.1795 9.66344C12.2991 9.54379 12.4476 9.48396 12.625 9.48396C12.8023 9.48396 12.9508 9.54379 13.0705 9.66344C13.1901 9.78309 13.25 9.93159 13.25 10.1089V11.7435C13.25 12.1645 13.1041 12.5208 12.8125 12.8125C12.5208 13.1041 12.1645 13.25 11.7435 13.25H2.25642Z'
      }
      fill={color}
    />
  </svg>
);
