import React, { type FC, useCallback, useMemo } from 'react';
import { Link } from '@mui/material';
import { Flex } from '@lama/design-system';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { getUnfulfilledRequirementsCount } from '../../shared/utils/requirementUtils';
import { useApplicationRequirementsQuery } from '../../hooks/react-query/useApplicationRequirementsQuery';
import { useApplicationAlertsQuery } from '../../hooks/react-query/useApplicationAlertsQuery';
import { sideNavZindex } from './Layout/SideNav/SideNav';

const SubmitBannerContainer = styled(Flex)<{ sticky: boolean }>`
  background-color: ${({ theme }) => theme.colors.primary.main};
  position: ${({ sticky }) => (sticky ? 'sticky' : 'relative')};
  top: ${({ sticky }) => (sticky ? '0' : 'auto')};
  z-index: ${sideNavZindex + 100};
  padding: 12px;
  color: white;
`;

export const SubmitBanner: FC<{ sticky?: boolean }> = ({ sticky = true }) => {
  const { t } = useTranslation();
  const { applicationId } = useParams();
  const { data: requirements } = useApplicationRequirementsQuery(applicationId);
  const { data: applicationAlerts, isLoading: fetchingAlerts } = useApplicationAlertsQuery(applicationId);
  const navigate = useNavigate();
  const navigateToSubmit = useCallback(() => {
    navigate('../sign-and-submit');
  }, [navigate]);

  const displaySubmitBanner = useMemo(() => {
    if (fetchingAlerts || applicationAlerts?.length) {
      return false;
    }

    const allRequirementsCompleted = !!requirements && getUnfulfilledRequirementsCount(requirements) === 0;
    if (!allRequirementsCompleted) {
      return false;
    }

    const needToSubmit = requirements.some(({ submitted, isInvalidSubmission }) => !submitted || isInvalidSubmission);

    return needToSubmit;
  }, [fetchingAlerts, applicationAlerts?.length, requirements]);

  return displaySubmitBanner ? (
    <SubmitBannerContainer gap={4} justifyContent={'center'} alignItems={'center'} sticky={sticky}>
      {t`submitBanner.title`}
      <Link onClick={navigateToSubmit} variant={'subtitle2'} sx={{ cursor: 'pointer', minWidth: 'fit-content' }} color={'inherit'}>
        {'Continue >'}
      </Link>
    </SubmitBannerContainer>
  ) : null;
};
