/* eslint-disable unicorn/no-keyword-prefix */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { useCallback, useMemo } from 'react';
import type { AutocompleteRenderInputParams, AutocompleteRenderOptionState } from '@mui/material';
import { Autocomplete, Checkbox, TextField } from '@mui/material';
import { useField, useFormikContext } from 'formik';
import { difference, uniq } from 'lodash-es';
import type { FC, HTMLAttributes } from 'react';
import type { HierarchicalOption } from '@lama/contracts';
import { HelperText } from './HelperTexts/HelperText';

export const FormikHierarchicalMultiPicker: FC<{
  name: string;
  label: string;
  maxItems?: number;
  options: HierarchicalOption[];
  highlight?: boolean;
  required?: boolean;
  helperText?: string;
  treeMode?: boolean;
}> = ({ name, label, maxItems = Number.MAX_SAFE_INTEGER, options, required, helperText, treeMode = false }) => {
  const { submitCount } = useFormikContext();
  const [{ value }, { error, touched }, { setValue: setFieldValue }] = useField<string[] | string | undefined>(name);
  const currentValues = useMemo(() => (value ? (Array.isArray(value) ? value : [value]) : []), [value]);

  const isChildOption = useCallback(
    (option: string) => options.some((o) => o.children?.some((child) => child.value === option)),
    [options],
  );

  const getOptionParent = useCallback(
    (option: string) => options.find((o) => o.children?.some((child) => child.value === option))?.value,
    [options],
  );

  const uniqueValuesSet = useMemo(() => new Set(options.filter((o) => o.unique).map((o) => o.value)), [options]);

  const handleChange = useCallback(
    async (_: any, newValues: string[]) => {
      const uniqueValues = newValues.filter((v) => uniqueValuesSet.has(v));
      if (uniqueValues.length) {
        void setFieldValue(uniqueValues[0]);
        return;
      }

      let updatedValues = newValues;

      if (treeMode) {
        const removedValues = difference(currentValues, newValues);

        const valueWithoutChildrenOfRemovedParents = newValues.filter((option) => {
          const parent = getOptionParent(option);
          return !parent || !removedValues.includes(parent);
        });

        const valuesWithParents = uniq(
          valueWithoutChildrenOfRemovedParents.flatMap((option) => {
            const parent = getOptionParent(option);
            return parent ? [parent, option] : option;
          }),
        );

        updatedValues = valuesWithParents;
      }

      void setFieldValue(updatedValues);
    },
    [treeMode, uniqueValuesSet, setFieldValue, currentValues, getOptionParent],
  );

  const renderOption = useCallback(
    (props: HTMLAttributes<HTMLLIElement>, option: string, { selected }: AutocompleteRenderOptionState) => {
      const isChild = isChildOption(option);

      return (
        <li key={`hierarchical_option_${option}`} {...props} style={{ paddingLeft: isChild ? 16 : 0 }}>
          <Checkbox style={{ marginRight: 8 }} checked={selected} />
          {option}
        </li>
      );
    },
    [isChildOption],
  );

  const inputError = useMemo(() => (!!touched || submitCount > 0) && Boolean(error), [error, submitCount, touched]);
  const inputHelperText = useMemo(() => {
    if (inputError) {
      return error;
    }

    return <HelperText>{helperText}</HelperText>;
  }, [error, helperText, inputError]);

  const renderInput = useCallback(
    (params: AutocompleteRenderInputParams) => <TextField helperText={inputHelperText} label={label} {...params} required={required} />,
    [inputHelperText, label, required],
  );

  const flattenedOptions = options.flatMap((option) => {
    if (option.children) {
      return [option.value, ...option.children.map((child) => child.value)];
    }
    return option.value;
  });

  const currentValuesIncludeUniqueValue = useMemo(
    () => currentValues.some((v) => uniqueValuesSet.has(v)),
    [currentValues, uniqueValuesSet],
  );

  const checkDisable = useCallback(
    (option: string) => {
      if (currentValues.includes(option)) {
        return false;
      }

      if (currentValuesIncludeUniqueValue) {
        return true;
      }

      const parent = getOptionParent(option);
      const parentSelected = parent && currentValues.includes(parent);
      const isChild = isChildOption(option);
      const optionPotentialSelectionCount = (parentSelected ? 0 : 1) + (isChild ? 1 : 0);

      return currentValues.length + optionPotentialSelectionCount > maxItems;
    },
    [currentValues, currentValuesIncludeUniqueValue, getOptionParent, isChildOption, maxItems],
  );

  return (
    <Autocomplete
      value={currentValues}
      options={flattenedOptions}
      renderInput={renderInput}
      getOptionDisabled={checkDisable}
      renderOption={renderOption}
      onChange={handleChange}
      disableCloseOnSelect
      limitTags={1}
      multiple
      fullWidth
    />
  );
};
