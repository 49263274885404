import React, { forwardRef, useMemo } from 'react';
import { capitalCase } from 'change-case-all';
import type { AvatarProps as MuiAvatarProps } from '@mui/material';
import { Avatar as MuiAvatar, colors } from '@mui/material';

const avatarColors = [
  colors.indigo[400],
  colors.grey[400],
  colors.lightBlue[400],
  colors.blue[400],
  colors.cyan[400],
  colors.teal[400],
  colors.deepPurple[400],
  colors.orange[400],
  colors.pink[400],
];

const hashString = (string: string) => {
  let hash = 0;
  for (let i = 0; i < string.length; i++) {
    hash = Math.trunc(Math.imul(31, hash) + string.codePointAt(i)!);
  }
  return hash;
};

const stringToColor = (string: string) => {
  const hash = hashString(string);
  const colorIndex = Math.abs(hash) % avatarColors.length;
  return avatarColors[colorIndex];
};

export const getAvatarText = (firstName: string, lastName: string) =>
  `${capitalCase(firstName?.[0] ?? '')}${capitalCase(lastName?.[0] ?? '')}`;

interface AvatarProps extends MuiAvatarProps {
  firstName: string;
  lastName: string;
  size?: number;
  imgSrc?: string;
  imgSize?: string;
  icon?: React.ReactNode;
  color?: string;
}

export const Avatar = forwardRef<HTMLDivElement, AvatarProps>(
  ({ size = 32, lastName, firstName, imgSrc, imgSize = '32px', alt, icon, color, ...props }, ref) => {
    const backgroundColor = useMemo(() => {
      if (imgSrc) {
        return colors.grey[200];
      }
      if (icon && color) {
        return color;
      }
      return stringToColor(lastName);
    }, [imgSrc, icon, color, lastName]);
    return (
      <MuiAvatar ref={ref} sx={{ fontSize: '1rem', width: size, height: size, bgcolor: backgroundColor }} {...props}>
        {icon ?? (imgSrc ? <img src={imgSrc} alt={alt} width={imgSize} height={imgSize} /> : getAvatarText(firstName, lastName))}
      </MuiAvatar>
    );
  },
);
