import React from 'react';
import { Flex, Text } from '@lama/design-system';
import type { FC } from 'react';
import styled, { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const StyledLink = styled(Link)`
  display: flex;
  text-decoration: underline;
  text-decoration-color: ${({ theme }) => theme.colors.primary.light};
  &:hover {
    text-decoration-color: ${({ theme }) => theme.colors.primary.main};
  }
`;

export const ExistingUserLoginHint: FC = () => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Flex alignItems={'center'} gap={1}>
      <Text variant={'body3'} color={'secondary'}>
        {t('existingUserLoginHint.preCta')}
      </Text>
      <StyledLink to={'/login'}>
        <Text variant={'body3'} color={theme.colors.primary.main}>
          {t('existingUserLoginHint.cta')}
        </Text>
      </StyledLink>
    </Flex>
  );
};
