import { useCallback } from 'react';
import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import type { UnderwritingDecision } from '@lama/contracts';
import { useAuthentication } from '../../../hooks/authentication/useAuthentication';
import { getApplicationRejectionDecision } from '../../../shared/clients/applicationService';

export const useGetApplicationRejectionDecisionQuery = (
  applicationId: string | undefined,
  options?: UseQueryOptions<UnderwritingDecision | null>,
) => {
  const { token, isAuthenticated } = useAuthentication();

  const getApplicationRejectionDecisionQuery = useCallback(async () => {
    if (!applicationId || !isAuthenticated || !token) {
      return null;
    }

    return getApplicationRejectionDecision(applicationId, token);
  }, [applicationId, isAuthenticated, token]);

  const query = useQuery<UnderwritingDecision | null>({
    queryKey: ['applicationRejectionDecision', applicationId],
    queryFn: getApplicationRejectionDecisionQuery,
    ...options,
  });

  return query;
};
