import type { PropertySourceTypes } from '@lama/contracts';
import { sourceToDisplayName } from '@lama/contracts';
import { isNil } from 'lodash-es';
import type { FC } from 'react';
import React, { useMemo } from 'react';
import type { PropertyValue } from '@lama/properties';
import { SourceHelperText } from './SourceHelperText';

interface FormikPickerHelperProps {
  selectedPropertyValue?: PropertyValue;
  sourcesValues?: PropertyValue[];
}

const getSourceDisplayName = (source: PropertySourceTypes) => sourceToDisplayName[source] ?? source;

export const FormikMultiSourceHelperText: FC<FormikPickerHelperProps> = ({ sourcesValues, selectedPropertyValue }) => {
  const differentExternalPropertyValue = useMemo(
    () =>
      sourcesValues?.find(
        (valueSource) =>
          valueSource.source.source !== 'userInput' && valueSource.value !== selectedPropertyValue?.value && !isNil(valueSource.value),
      ),
    [selectedPropertyValue?.value, sourcesValues],
  );

  const missingExternalPropertyValue = useMemo(
    () =>
      sourcesValues?.find(
        (valueSource) => valueSource.source.source !== 'userInput' && isNil(valueSource.value) && valueSource.status === 'Fetched',
      ),
    [sourcesValues],
  );

  if (missingExternalPropertyValue) {
    return (
      <SourceHelperText
        source={missingExternalPropertyValue.source.source}
        text={`Data not found in ${getSourceDisplayName(missingExternalPropertyValue.source.source)}`}
        variant={'missing'}
      />
    );
  }

  if (!selectedPropertyValue || !sourcesValues || isNil(selectedPropertyValue?.value)) {
    return null;
  }

  const selectedSourceType = selectedPropertyValue.source.source;

  if (selectedSourceType === 'userInput' && !differentExternalPropertyValue) {
    return null;
  }

  if (selectedSourceType === 'userInput' && differentExternalPropertyValue) {
    return (
      <SourceHelperText
        source={differentExternalPropertyValue.source.source}
        text={`Value differs from ${getSourceDisplayName(differentExternalPropertyValue.source.source)} source (${
          differentExternalPropertyValue.value
        })`}
        variant={'diff'}
      />
    );
  }

  return (
    <SourceHelperText source={selectedSourceType} text={`Data automatically fetched from ${getSourceDisplayName(selectedSourceType)}`} />
  );
};
