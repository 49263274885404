import { useTheme } from '@mui/material';
import { useField } from 'formik';
import React, { useCallback } from 'react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { googleApiKey } from '../../framework/environment';

export const FormikGooglePlacesAutocompleteInput: React.FC<{ placeholder: string; name: string }> = ({ placeholder, name }) => {
  const [, , { setValue: setFormikValue }] = useField(name);
  // const [value, setValue] = useState<string | null>(null);
  const theme = useTheme();

  const onChange = useCallback(
    async ({ value: { description } }: { value: { description: string } }) => {
      // TODO: destructure the event to get detailed address information
      await setFormikValue(description);
    },
    [setFormikValue],
  );

  return (
    <GooglePlacesAutocomplete
      apiKey={googleApiKey}
      minLengthAutocomplete={3}
      apiOptions={{
        libraries: ['places'],
      }}
      withSessionToken
      selectProps={{
        onChange,
        theme: (innerTheme: any) => ({
          ...innerTheme,
          borderRadius: '4px',
          colors: {
            ...innerTheme.colors,
            primary: theme.palette.primary.main,
          },
        }),
        placeholder,
        menuPortalTarget: document.body,
        styles: {
          control: (base: any) => ({
            ...base,
            height: '56px',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            '&:hover': {
              borderColor: '#252525',
            },
            cursor: 'carret',
          }),
          placeholder: (base: any) => ({
            ...base,
            fontFamily: 'Poppins',
          }),
          input: (base: any) => ({
            ...base,
            fontFamily: 'Poppins',
          }),
          singleValue: (base: any, state: any) => ({
            ...base,
            fontFamily: 'Poppins',
            display: state.selectProps.menuIsOpen ? 'none' : 'block',
          }),
          noOptionsMessage: (base: any) => ({
            ...base,
            fontFamily: 'Poppins',
          }),
        },
      }}
      autocompletionRequest={{
        componentRestrictions: {
          country: ['us'],
        },
        types: ['establishment'],
      }}
    />
  );
};
