import type React from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthentication } from '../../hooks/authentication/useAuthentication';

export const AuthCallbackScreen: React.FC = () => {
  const { targetUrl } = useAuthentication();
  const navigate = useNavigate();

  useEffect(() => {
    if (targetUrl) {
      navigate(targetUrl);
    }
  }, [navigate, targetUrl]);

  return null;
};
