/* eslint-disable @typescript-eslint/naming-convention */
import { Box } from '@mui/material';
import type { FC } from 'react';
import { Text } from '@lama/design-system';
import React, { useMemo, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import type { ProductPublicData } from '@lama/contracts';
import { compact, partition, sortBy, uniq } from 'lodash-es';
import type { ApplicationApiModel } from '@lama/clients';
import { LoadingPage } from '@lama/app-components';
import { partnerState } from '../../state/appState';
import type { ScreenProps } from '../ScreenProps';
import { BasicScreen } from '../shared/BasicScreen';
import { ApplicationContext } from '../../shared/contexts/ApplicationContext';
import { useProductsByPartnerQuery } from '../../hooks/react-query/useProductsByPartnerQuery';
import { ProductCard } from './ProductCard';

const useOfFundsMatches: Record<string, string[]> = {
  'Working Capital': ['lineOfCredit', 'invoiceFinancing', 'mca', 'sba7a', 'inventoryFinancing'],
  'Growth Capital': ['term', 'sba7a', 'equipmentFinancing', 'cre'],
  Payroll: ['lineOfCredit', 'invoiceFinancing', 'mca'],
  Inventory: ['mca', 'inventoryFinancing', 'lineOfCredit', 'invoiceFinancing', 'sba7a'],
  Equipment: ['equipmentFinancing', 'term', 'sba7a'],
  'Commercial Real Estate': ['cre', 'term'],
  Construction: ['cre', 'term'],
  Vehicles: ['equipmentFinancing', 'term', 'sba7a'],
  Taxes: ['term', 'lineOfCredit', 'invoiceFinancing', 'mca'],
  Marketing: ['term', 'lineOfCredit', 'invoiceFinancing', 'mca'],
  Acquisition: ['term', 'lineOfCredit', 'sba7a'],
  Other: ['term', 'lineOfCredit', 'sba7a', 'invoiceFinancing', 'mca', 'equipmentFinancing', 'cre', 'inventoryFinancing'],
};

const filterProductsByMatch = ({
  products,
  useOfFunds,
}: {
  products?: ProductPublicData[];
  useOfFunds?: ApplicationApiModel['useOfFunds'];
}) =>
  products?.filter((product) => {
    if (useOfFunds) {
      const productFits = compact(uniq(useOfFunds.flatMap((useOfFund) => useOfFundsMatches[useOfFund])));

      if (!productFits.includes(product.name)) {
        return false;
      }
    }

    return true;
  });

type ProductSelectionScreenProps = ScreenProps & {
  title?: string;
  subtitle?: string;
};

export const ProductSelectionScreen: FC<ProductSelectionScreenProps> = ({ title, subtitle, ...rest }) => {
  const { t } = useTranslation();
  const partner = useRecoilValue(partnerState);
  const {
    application: { useOfFunds },
  } = useContext(ApplicationContext);
  const { data: products, isPending: loadingProducts } = useProductsByPartnerQuery(partner?.id);
  const [selectedProduct, setSelectedProduct] = React.useState<string | undefined>();

  const onProductSelected = useCallback((productId: string) => {
    setSelectedProduct(productId);
  }, []);

  const relevantProducts = useMemo(() => {
    const filteredProducts = filterProductsByMatch({ products: products ?? [], useOfFunds });

    return filteredProducts?.length ? filteredProducts : products;
  }, [products, useOfFunds]);

  const fastestSpeedOfFundsProductId = useMemo(
    () => sortBy(relevantProducts?.map((product) => product.terms?.ranges?.speedOfFunds?.minDays))[0],
    [relevantProducts],
  );

  const lowestInterestRateProductId = useMemo(
    () => sortBy(relevantProducts?.map((product) => product.terms?.ranges?.apr?.min))[0],
    [relevantProducts],
  );

  const getBannerText = useCallback(
    (productId: string) => {
      const currProduct = relevantProducts?.find((product) => product.id === productId);

      if (currProduct?.terms?.ranges?.speedOfFunds?.minDays === fastestSpeedOfFundsProductId) {
        return 'Fastest funds';
      }

      if (currProduct?.terms?.ranges?.apr?.min === lowestInterestRateProductId) {
        return 'Lowest interest rate';
      }

      return;
    },
    [fastestSpeedOfFundsProductId, lowestInterestRateProductId, relevantProducts],
  );

  const relevantProductsWithBannerText = useMemo(() => {
    const [productsWithBanners, productsWithoutBanners] = partition(
      relevantProducts?.map((product) => ({
        ...product,
        bannerText: getBannerText(product.id),
      })),
      (product) => product.bannerText,
    );

    return [...productsWithBanners, ...productsWithoutBanners];
  }, [relevantProducts, getBannerText]);

  if (loadingProducts) {
    return <LoadingPage />;
  }

  return (
    <BasicScreen
      title={title ?? t('productSelection.title')}
      subtitle={subtitle ?? t('productSelection.subtitle')}
      maxWidth={'lg'}
      {...rest}
    >
      {products?.length ? (
        <Box display={'grid'} gap={3} gridTemplateColumns={'repeat(auto-fit, 308px)'} justifyContent={'center'}>
          {relevantProductsWithBannerText?.map(({ bannerText, ...product }) => (
            <ProductCard
              key={product.id}
              product={product}
              isSelected={product.id === selectedProduct}
              onClick={onProductSelected}
              bannerText={bannerText}
            />
          ))}
        </Box>
      ) : (
        <Text variant={'h6'}>{'No products available'}</Text>
      )}
    </BasicScreen>
  );
};
