import { useCallback } from 'react';
import type { UseMutationOptions } from '@tanstack/react-query';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import type { Entity } from '@lama/common-types';
import { v4 as uuidv4 } from 'uuid';
import { createDocumentServiceClient } from '@lama/document-service-client';
import type { Document } from '@lama/document-service-client';
import { displayToast } from '@lama/app-components';
import { uploadToS3 } from '../../shared/clients/s3';
import { documentServiceUrl } from '../../framework/environment';
import { useAuthentication } from '../authentication/useAuthentication';

interface UploadFileParams {
  file: File;
  description: string;
  requirementId?: string;
  requirementKey?: string;
  topic: Document['topic'];
  applicationId: string;
  entityId: string;
  entityType: Entity;
  sharedRequirementId?: string;
  extractable?: boolean;
  relatedItemId?: string;
  relatedItemType?: string;
}

export const useUploadDocumentMutation = (applicationId: string, options?: UseMutationOptions<void, unknown, UploadFileParams>) => {
  const { token, isAuthenticated } = useAuthentication();
  const queryClient = useQueryClient();

  const uploadDocumentMutation = useCallback(
    async ({
      file,
      description,
      requirementId,
      entityId,
      entityType,
      topic,
      sharedRequirementId,
      requirementKey,
      extractable,
      relatedItemId,
      relatedItemType,
    }: UploadFileParams) => {
      if (!isAuthenticated) {
        return;
      }

      const documentServiceClient = createDocumentServiceClient(documentServiceUrl as string);

      const documentId = uuidv4();
      const signedUploadUrl = await documentServiceClient.getSignedUploadUrl(
        {
          id: documentId,
          applicationId,
          relatedEntityId: entityId,
          relatedEntityType: entityType,
          topic,
          description,
          filename: file.name,
          requirementId,
          requirementKey,
          sharedRequirementId,
          extractable,
          relatedItemId,
          relatedItemType,
        },
        token,
      );

      await uploadToS3(signedUploadUrl, file);
      await documentServiceClient.updateDocument({ documentId, payload: { status: 'Uploaded' } }, token);
    },
    [applicationId, isAuthenticated, token],
  );

  return useMutation({
    mutationFn: uploadDocumentMutation,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['applicationDocuments', applicationId] });
      await queryClient.invalidateQueries({ queryKey: ['applicationRequirements', applicationId] });
    },
    onError: () => {
      displayToast('Something went wrong while uploading the document. Please contact support.', 'error');
    },
    ...options,
  });
};
