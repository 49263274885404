/* eslint-disable react/jsx-indent */
import React, { useCallback } from 'react';
import type { PopoverOrigin } from '@mui/material';
import { Divider, MenuItem, ClickAwayListener, Popover } from '@mui/material';
import { capitalCase } from 'change-case-all';
import { Flex, Text } from '@lama/design-system';
import { grey } from '@mui/material/colors';
import { Avatar } from '../Avatar/Avatar.js';

export interface AccountAction {
  label: string;
  onClick: () => void;
}

const accountPopoverStyle = () => ({
  // eslint-disable-next-line @typescript-eslint/naming-convention
  '.MuiPopover-paper': {
    border: '1px solid',
    borderColor: grey[300],
    boxShadow: '0px 11px 15px 0px #DBDBDB26',
    margin: '8px',
  },
});

export interface AccountPopoverProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  logout: () => void;
  topbarRef: React.RefObject<HTMLDivElement>;
  firstName: string;
  lastName: string;
  email: string;
  partner?: string;
  popoverLocation?: PopoverOrigin;
  actions?: AccountAction[];
}

export const AccountPopover: React.FC<AccountPopoverProps> = ({
  open,
  topbarRef,
  setOpen,
  firstName,
  lastName,
  email,
  partner,
  logout,
  popoverLocation,
  actions,
}) => {
  const handleClickAway = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  return (
    <Popover
      elevation={0}
      open={open}
      anchorEl={topbarRef.current}
      anchorOrigin={popoverLocation ?? { vertical: 'bottom', horizontal: 'center' }}
      sx={accountPopoverStyle}
    >
      <ClickAwayListener onClickAway={handleClickAway}>
        <Flex flexDirection={'column'} p={2} gap={2}>
          <Flex flexDirection={'row'} alignItems={'center'} gap={2} p={2}>
            <Avatar firstName={firstName} lastName={lastName} size={36} />
            <Flex flexDirection={'column'} color={'secondary'} maxWidth={'150px'}>
              <Text variant={'body1'} color={'text.primary'} ellipsis>
                {`${capitalCase(firstName)} ${capitalCase(lastName)}`}
              </Text>
              {partner ? (
                <Text variant={'body4'} color={grey[500]} ellipsis>
                  {`(${partner})`}
                </Text>
              ) : null}
              <Text variant={'body4'} color={grey[500]} ellipsis>
                {email}
              </Text>
            </Flex>
          </Flex>
          <Divider />
          {actions?.length
            ? actions.map((action) => (
                <MenuItem
                  key={action.label}
                  value={action.label}
                  onClick={action.onClick}
                  sx={{
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    '&:hover': { bgcolor: grey[100] },
                    borderRadius: '4px',
                    px: 2,
                  }}
                >
                  <Text variant={'body1'}>{action.label}</Text>
                </MenuItem>
              ))
            : null}
          <MenuItem
            key={'logout'}
            value={'logout'}
            onClick={logout}
            sx={{
              // eslint-disable-next-line @typescript-eslint/naming-convention
              '&:hover': { bgcolor: grey[100] },
              borderRadius: '4px',
              px: 2,
            }}
          >
            <Text variant={'body1'}>{'Sign out'}</Text>
          </MenuItem>
        </Flex>
      </ClickAwayListener>
    </Popover>
  );
};
