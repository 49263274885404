import type { ApplicationApiModel } from '@lama/clients';
import type { EvaluatedApplicationRequirement, ProductPublicData } from '@lama/contracts';
import type { FC } from 'react';
import React, { useMemo } from 'react';

export const ApplicationContext = React.createContext<{
  application: ApplicationApiModel;
  requirements: EvaluatedApplicationRequirement[];
  product: ProductPublicData;
}>(null!);

interface ApplicationProviderProps {
  product: ProductPublicData;
  application: ApplicationApiModel;
  requirements: EvaluatedApplicationRequirement[];
  children: React.ReactNode;
}

export const ApplicationProvider: FC<ApplicationProviderProps> = ({ product, application, requirements, children }) => {
  const value = useMemo(
    () => ({
      application,
      requirements,
      product,
    }),
    [application, product, requirements],
  );

  return <ApplicationContext.Provider value={value}>{children}</ApplicationContext.Provider>;
};
