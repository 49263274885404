import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import type { Theme } from '@mui/material';
import { useTheme, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { BadgeCounter } from '../../../shared/BadgeCounter';

const listItemButtonStyle = (theme: Theme) => ({
  // eslint-disable-next-line @typescript-eslint/naming-convention
  '&:hover': { backgroundColor: theme.colors.greyHover },
  // eslint-disable-next-line @typescript-eslint/naming-convention
  '&.Mui-selected': {
    backgroundColor: theme.palette.primary,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    '&:hover': { backgroundColor: theme.colors.greyHover },
  },
  border: 'none',
  borderRadius: '8px',
});

export const SidebarItem: React.FC<{
  text: string;
  navigateTo: string;
  icon?: React.ReactNode;
  disabled?: boolean;
  selected?: boolean;
  count?: number;
  onCategoryClick?: () => void;
}> = ({ text, navigateTo, icon, disabled = false, selected = false, count, onCategoryClick }) => {
  const navigate = useNavigate();
  const theme = useTheme();

  const onItemClick = useCallback(() => {
    navigate(`/${navigateTo}`);
    onCategoryClick?.();
  }, [navigate, navigateTo, onCategoryClick]);

  return (
    <ListItem disableGutters sx={{ py: 1, px: 2 }}>
      <ListItemButton onClick={onItemClick} sx={listItemButtonStyle} disabled={disabled} selected={selected}>
        {icon ? <ListItemIcon sx={{ minWidth: '34px' }}>{icon}</ListItemIcon> : null}
        <ListItemText
          primaryTypographyProps={{
            color: selected ? theme.palette.primary.main : theme.text.secondary,
            noWrap: true,
            variant: 'h6',
            maxWidth: '90%',
          }}
          primary={text}
        />
        {count ? <BadgeCounter count={count} /> : null}
      </ListItemButton>
    </ListItem>
  );
};
