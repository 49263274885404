import { css } from 'styled-components';
import { get } from 'lodash-es';

export const fullWidthStyle = ({ fullWidth }: any) =>
  fullWidth &&
  css`
    width: 100%;
  `;

export const backgroundStyle = ({ theme, backgroundColor, bg }: any) => {
  const themeColor = get(theme.colors, backgroundColor ?? bg);
  return (
    (backgroundColor || bg) &&
    css`
      background: ${typeof themeColor === 'string' ? themeColor : backgroundColor || bg};
    `
  );
};
