import { Box, useMediaQuery, useTheme } from '@mui/material';
import React, { useMemo, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { applicationBorrowingBusinessSelector, personFullName, formatValue } from '@lama/data-formatters';
import { monthsInBusinessSelector } from '@lama/selectors';
import { Flex, Text } from '@lama/design-system';
import { grey } from '@mui/material/colors';
import { useRecoilValue } from 'recoil';
import { useNavigate } from 'react-router-dom';
import type { ScreenProps } from '../ScreenProps';
import { BasicScreen } from '../shared/BasicScreen';
import { ApplicationContext } from '../../shared/contexts/ApplicationContext';
import { HandsIcon } from '../applicationRejected/assets/Hands';
import { UserDetailsContext } from '../../shared/contexts/UserDetailsContext';
import { partnerState } from '../../state/appState';
import { ConclusionImage } from './ConclusionImage';
import { CloudDataCollectionImage } from './CloudDataCollectionImage';
import { ConclusionImageV2 } from './ConclusionImageV2';

const conclusionImageByName: Record<string, React.FC> = {
  default: ConclusionImageV2,
  cloud: CloudDataCollectionImage,
  old: ConclusionImage,
};

interface ConclusionScreenProps extends ScreenProps {
  title?: string;
  subtitle?: string;
  imageName?: string;
}

export const FintechMeetupConclusionScreen: React.FC<ConclusionScreenProps> = ({
  title,
  subtitle,
  imageName = 'default',
  ...restProps
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { application, product } = useContext(ApplicationContext);
  const Image = useMemo(() => conclusionImageByName[imageName], [imageName]);
  const monthsInBusiness = useMemo(() => {
    const business = applicationBorrowingBusinessSelector(application);

    if (!business) {
      return 0;
    }

    return monthsInBusinessSelector(business);
  }, [application]);
  const userContext = useContext(UserDetailsContext);
  const partner = useRecoilValue(partnerState);
  const navigate = useNavigate();

  const shouldDecline = useMemo(() => monthsInBusiness && monthsInBusiness < 24, [monthsInBusiness]);

  useEffect(() => {
    if (!shouldDecline) {
      setTimeout(() => {
        navigate(`/applications/${application.id}/overview`);
      }, 5000);
    }
  }, [application.id, navigate, shouldDecline]);

  if (shouldDecline) {
    return (
      <Flex
        flexDirection={'column'}
        gap={10}
        alignItems={'center'}
        flex={isMobile ? 1 : 0.7}
        color={grey[500]}
        py={10}
        px={isMobile ? 0 : 4}
        justifyContent={'center'}
      >
        <HandsIcon color={theme.palette.primary.light} />
        <Flex flexDirection={'column'} gap={8} alignItems={'center'} justifyContent={'center'}>
          <Text variant={'h4'} color={'black'} textAlign={'center'}>
            {"You don't meet the loan requirements"}
          </Text>
          <Flex
            flexDirection={'column'}
            gap={4}
            justifyContent={'center'}
            borderRadius={'8px'}
            border={isMobile ? 'none' : '1px solid #E0E0E0'}
            p={isMobile ? 0 : 8}
            width={isMobile ? '85%' : '75%'}
          >
            <Text variant={'body2'} color={'secondary'} textAlign={isMobile ? 'center' : 'inherit'}>
              {`Dear ${
                userContext?.firstName && userContext?.lastName
                  ? personFullName(userContext as { firstName: string; middleName?: string; lastName: string })
                  : 'applicant'
              },`}
            </Text>
            <Text variant={'body2'} color={'secondary'} textAlign={isMobile ? 'center' : 'inherit'}>
              {`Thank you for applying for a ${product.prettyName}${product.prettyName.includes('Loan') ? '' : ' Loan'} for ${formatValue(
                application.requestedAmount ?? 0,
                'currencyCompact',
              )}. However, we regret to advise you that we cannot grant your request at this time. Below are the specific reasons why your application was denied:`}
            </Text>
            <Text variant={'body2'} color={'secondary'} textAlign={isMobile ? 'center' : 'inherit'}>
              <li>{'Insufficient cash flow or ability to repay all business and personal debts'}</li>
            </Text>
            <Text variant={'body2'} color={'secondary'} textAlign={isMobile ? 'center' : 'inherit'}>
              {`Sincerely, ${partner?.displayName}`}
            </Text>
            <Text variant={'body2'} color={'grey.500'} textAlign={isMobile ? 'center' : 'inherit'}>
              {partner?.branding.communication.rejectionEmailFooter}
            </Text>
          </Flex>
        </Flex>
      </Flex>
    );
  }

  return (
    <BasicScreen {...restProps} title={title ?? t('conclusion.title')} subtitle={subtitle} nextVisible={false} backVisible={false}>
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <Image />
      </Box>
    </BasicScreen>
  );
};
