import { useTheme } from '@mui/material';
import type { FC } from 'react';
import React from 'react';

export const InactiveApplicationImage: FC = () => {
  const theme = useTheme();

  return (
    <svg width={'389'} height={'287'} viewBox={'0 0 389 287'} fill={'none'} xmlns={'http://www.w3.org/2000/svg'}>
      <rect x={'315'} width={'100'} height={'315'} rx={'50'} transform={'rotate(90 315 0)'} fill={'#F6F9F9'} />
      <rect x={'389'} y={'180'} width={'100'} height={'330'} rx={'50'} transform={'rotate(90 389 180)'} fill={'#F6F9F9'} />
      <circle cx={'202'} cy={'146'} r={'139.5'} fill={'white'} stroke={'#E0E0E0'} strokeDasharray={'8 8'} />
      <mask
        id={'mask0_27390_4513'}
        style={{ maskType: 'alpha' }}
        maskUnits={'userSpaceOnUse'}
        x={'62'}
        y={'6'}
        width={'280'}
        height={'280'}
      >
        <circle cx={'202'} cy={'146'} r={'139.5'} fill={'white'} stroke={'#E0E0E0'} strokeDasharray={'8 8'} />
      </mask>
      <g mask={'url(#mask0_27390_4513)'}>
        <g clipPath={'url(#clip0_27390_4513)'}>
          <path
            d={
              'M246.424 76L338 207.891C329.04 230.576 293.42 260.867 276.729 273.177C201.887 313.272 137.059 286.366 114 267.901L223.365 76H246.424Z'
            }
            fill={theme.palette.primary.main}
            fillOpacity={'0.6'}
          />
          <path
            d={
              'M233.107 148.87H171.845C170.377 148.87 169.184 147.676 169.184 146.209V145.292H235.768V146.209C235.768 147.676 234.574 148.87 233.107 148.87Z'
            }
            fill={theme.palette.primary.main}
          />
          <path
            d={
              'M169.184 145.359H207.282L195.179 109.717C194.453 108.6 193.193 107.925 191.836 107.925H159.631C158.17 107.925 157.227 109.342 157.705 110.596C157.896 111.095 158.1 111.586 158.267 112.094L169.187 145.363L169.184 145.359Z'
            }
            fill={theme.palette.primary.main}
          />
          <path
            d={
              'M183.322 126.161C183.776 127.669 183.081 129.21 181.771 129.605C180.461 129.999 179.03 129.1 178.579 127.592C178.124 126.084 178.819 124.543 180.13 124.148C181.44 123.753 182.871 124.653 183.322 126.161Z'
            }
            fill={'white'}
          />
          <path
            d={
              'M199.885 145.547H178.894C178.617 145.547 178.393 145.323 178.393 145.045C178.393 144.767 178.617 144.543 178.894 144.543H199.885C200.163 144.543 200.387 144.767 200.387 145.045C200.387 145.323 200.163 145.547 199.885 145.547Z'
            }
            fill={'white'}
          />
          <path
            d={
              'M171.931 145.547H168.876C168.599 145.547 168.375 145.323 168.375 145.045C168.375 144.767 168.599 144.543 168.876 144.543H171.931C172.209 144.543 172.433 144.767 172.433 145.045C172.433 145.323 172.209 145.547 171.931 145.547Z'
            }
            fill={'white'}
          />
          <path
            d={'M133.29 264.136L156.601 167.377L179.916 264.136H185.501L159.89 157.038L153.319 157.028L127.705 264.136H133.29Z'}
            fill={'#E0E0E0'}
          />
          <path d={'M330.93 148.867H120.496V157.085H330.93V148.867Z'} fill={'#E0E0E0'} />
          <path
            d={'M278.538 264.136L301.849 167.363L325.16 264.136H330.749L305.135 157.025L298.567 157.015L272.949 264.136H278.538Z'}
            fill={'#E0E0E0'}
          />
          <path
            d={
              'M294.289 92.5114C294.079 92.5114 293.881 92.3743 293.811 92.1636L293.326 90.6557C293.243 90.3916 293.387 90.1107 293.651 90.0238C293.915 89.9402 294.196 90.0839 294.282 90.3481L294.767 91.856C294.851 92.1202 294.707 92.401 294.443 92.488C294.393 92.5047 294.339 92.5114 294.289 92.5114Z'
            }
            fill={theme.palette.primary.main}
          />
          <path
            d={
              'M295.988 97.7944C295.778 97.7944 295.581 97.6573 295.51 97.4466L295.026 95.9387C294.942 95.6746 295.086 95.3937 295.35 95.3068C295.614 95.2198 295.895 95.3669 295.982 95.6311L296.466 97.139C296.55 97.4032 296.406 97.684 296.142 97.771C296.092 97.7877 296.039 97.7944 295.988 97.7944Z'
            }
            fill={theme.palette.primary.main}
          />
          <path
            d={
              'M291.502 95.4936C291.291 95.4936 291.094 95.3599 291.024 95.1459C290.94 94.8817 291.084 94.6009 291.348 94.5139L292.855 94.0291C293.12 93.9455 293.4 94.0893 293.487 94.3535C293.571 94.6176 293.427 94.8985 293.163 94.9854L291.655 95.4702C291.605 95.4869 291.552 95.4936 291.502 95.4936Z'
            }
            fill={theme.palette.primary.main}
          />
          <path
            d={
              'M296.783 93.792C296.572 93.792 296.375 93.6582 296.305 93.4442C296.221 93.1801 296.365 92.8992 296.629 92.8123L298.137 92.3275C298.401 92.2439 298.682 92.3877 298.768 92.6518C298.852 92.9159 298.708 93.1968 298.444 93.2837L296.937 93.7685C296.887 93.7853 296.833 93.792 296.783 93.792Z'
            }
            fill={theme.palette.primary.main}
          />
          <path
            fillRule={'evenodd'}
            clipRule={'evenodd'}
            d={
              'M233.347 48.8101L233.382 10.2124L234.822 10.3263L234.735 48.8096H235.112C239.492 48.8096 243.051 52.3693 243.051 56.7507V64.4874C245.296 64.7015 247.055 66.5958 247.055 68.8971V75.8645H222.01V68.607C222.01 66.4459 223.67 64.6675 225.782 64.4802V56.4641C225.782 52.2709 229.162 48.8573 233.347 48.8101Z'
            }
            fill={'#9E9E9E'}
          />
        </g>
        <path
          d={
            'M137.951 125.905C136.324 125.905 135 124.581 135 122.952C135 121.324 136.324 120 137.951 120C139.579 120 140.903 121.324 140.903 122.952C140.903 124.581 139.579 125.905 137.951 125.905ZM137.951 121C136.875 121 136.003 121.876 136.003 122.949C136.003 124.022 136.879 124.898 137.951 124.898C139.024 124.898 139.9 124.022 139.9 122.949C139.9 121.876 139.024 121 137.951 121Z'
          }
          fill={theme.palette.primary.main}
        />
        <path
          d={
            'M127.968 97.2774C127.691 97.2774 127.467 97.0534 127.467 96.7759V94.1846C127.467 93.9071 127.691 93.6831 127.968 93.6831C128.246 93.6831 128.47 93.9071 128.47 94.1846V96.7759C128.47 97.0534 128.246 97.2774 127.968 97.2774Z'
          }
          fill={theme.palette.primary.main}
        />
        <path
          d={
            'M127.968 103.319C127.691 103.319 127.467 103.095 127.467 102.818V100.223C127.467 99.9457 127.691 99.7217 127.968 99.7217C128.246 99.7217 128.47 99.9457 128.47 100.223V102.818C128.47 103.095 128.246 103.319 127.968 103.319Z'
          }
          fill={theme.palette.primary.main}
        />
        <path
          d={
            'M126.244 99.0026H123.65C123.372 99.0026 123.148 98.7786 123.148 98.501C123.148 98.2235 123.372 97.9995 123.65 97.9995H126.244C126.521 97.9995 126.745 98.2235 126.745 98.501C126.745 98.7786 126.521 99.0026 126.244 99.0026Z'
          }
          fill={theme.palette.primary.main}
        />
        <path
          d={
            'M132.283 99.0026H129.689C129.411 99.0026 129.188 98.7786 129.188 98.501C129.188 98.2235 129.411 97.9995 129.689 97.9995H132.283C132.56 97.9995 132.784 98.2235 132.784 98.501C132.784 98.7786 132.56 99.0026 132.283 99.0026Z'
          }
          fill={theme.palette.primary.main}
        />
      </g>
      <circle cx={'202'} cy={'146'} r={'139.5'} stroke={'#BDBDBD'} strokeDasharray={'8 8'} />
      <path
        d={
          'M335.048 285.893H270.423C266.542 285.893 263.641 282.332 264.423 278.53L274.227 230.854C275.052 226.845 278.579 223.966 282.67 223.966H338.103L335.048 285.889V285.893Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M335.047 286.394H270.422C268.423 286.394 266.548 285.505 265.285 283.953C264.021 282.402 263.526 280.389 263.931 278.43L273.735 230.754C274.604 226.531 278.361 223.468 282.669 223.468H338.102C338.239 223.468 338.369 223.525 338.466 223.625C338.56 223.726 338.61 223.859 338.603 223.997L335.548 285.919C335.535 286.187 335.314 286.398 335.047 286.398V286.394ZM282.673 224.471C278.835 224.471 275.493 227.2 274.721 230.958L264.917 278.634C264.576 280.296 264.994 282.008 266.067 283.322C267.14 284.639 268.727 285.391 270.426 285.391H334.572L337.581 224.471H282.676H282.673Z'
        }
        fill={theme.palette.primary.main}
      />
      <path
        d={
          'M343.32 285.893H330.778C326.898 285.893 323.996 282.332 324.778 278.53L335.578 226.019C335.822 224.826 336.875 223.97 338.092 223.97H341.317C342.654 223.97 343.767 224.996 343.878 226.33L348.297 280.496C348.534 283.405 346.238 285.893 343.323 285.893H343.32Z'
        }
        fill={theme.palette.primary.main}
      />
      <path
        d={
          'M343.319 286.394H330.778C328.775 286.394 326.904 285.505 325.64 283.953C324.377 282.402 323.882 280.389 324.286 278.43L335.086 225.919C335.377 224.498 336.644 223.468 338.091 223.468H341.317C342.904 223.468 344.245 224.705 344.375 226.287L348.794 280.453C348.918 281.977 348.396 283.499 347.36 284.622C346.324 285.746 344.853 286.391 343.322 286.391L343.319 286.394ZM338.091 224.471C337.115 224.471 336.266 225.167 336.069 226.123L325.269 278.634C324.928 280.299 325.346 282.008 326.419 283.322C327.492 284.639 329.08 285.395 330.778 285.395H343.319C344.589 285.395 345.759 284.88 346.621 283.947C347.48 283.014 347.898 281.804 347.795 280.54L343.376 226.374C343.289 225.311 342.383 224.475 341.317 224.475H338.091V224.471Z'
        }
        fill={theme.palette.primary.main}
      />
      <path
        d={
          'M326.24 249.498H275.64C275.363 249.498 275.139 249.274 275.139 248.997C275.139 248.719 275.363 248.495 275.64 248.495H326.24C327.513 248.495 328.606 247.629 328.9 246.392L333.359 227.558C333.423 227.287 333.693 227.12 333.964 227.187C334.235 227.25 334.399 227.521 334.335 227.792L329.876 246.626C329.475 248.321 327.981 249.502 326.24 249.502V249.498Z'
        }
        fill={theme.palette.primary.main}
      />
      <path
        d={
          'M306.713 248.997L305.295 254.714C305.065 255.68 304.202 256.366 303.21 256.366H293.189C292.099 256.366 291.307 255.326 291.601 254.273L293.068 248.997H306.716H306.713Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M303.21 256.867H293.189C292.51 256.867 291.885 256.556 291.477 256.018C291.066 255.476 290.936 254.794 291.116 254.139L292.584 248.863C292.644 248.646 292.841 248.495 293.069 248.495H306.716C306.87 248.495 307.017 248.565 307.111 248.689C307.204 248.809 307.241 248.97 307.201 249.12L305.784 254.838C305.499 256.032 304.443 256.871 303.21 256.871V256.867ZM293.446 249.498L292.082 254.41C291.986 254.758 292.056 255.125 292.273 255.413C292.49 255.701 292.825 255.868 293.186 255.868H303.206C303.972 255.868 304.63 255.346 304.804 254.604L306.068 249.502H293.443L293.446 249.498Z'
        }
        fill={theme.palette.primary.main}
      />
      <path
        d={
          'M321.623 281.607H267.869C267.591 281.607 267.367 281.383 267.367 281.105C267.367 280.828 267.591 280.604 267.869 280.604H321.623C321.901 280.604 322.125 280.828 322.125 281.105C322.125 281.383 321.901 281.607 321.623 281.607Z'
        }
        fill={theme.palette.primary.main}
      />
      <path
        d={
          'M295.439 221.807H291.244C290.111 221.807 289.191 222.726 289.191 223.86H297.491C297.491 222.726 296.572 221.807 295.439 221.807Z'
        }
        fill={theme.palette.primary.main}
      />
      <path
        d={
          'M297.494 224.361H289.195C288.917 224.361 288.693 224.137 288.693 223.86C288.693 222.452 289.84 221.305 291.247 221.305H295.442C296.849 221.305 297.996 222.452 297.996 223.86C297.996 224.137 297.772 224.361 297.494 224.361ZM289.776 223.358H296.909C296.702 222.746 296.121 222.308 295.442 222.308H291.247C290.569 222.308 289.987 222.75 289.78 223.358H289.776Z'
        }
        fill={theme.palette.primary.main}
      />
      <path
        d={
          'M329.069 221.807H324.875C323.741 221.807 322.822 222.726 322.822 223.86H331.122C331.122 222.726 330.203 221.807 329.069 221.807Z'
        }
        fill={theme.palette.primary.main}
      />
      <path
        d={
          'M331.121 224.361H322.822C322.544 224.361 322.32 224.137 322.32 223.86C322.32 222.452 323.467 221.305 324.874 221.305H329.069C330.476 221.305 331.623 222.452 331.623 223.86C331.623 224.137 331.399 224.361 331.121 224.361ZM323.407 223.358H330.54C330.332 222.746 329.751 222.308 329.072 222.308H324.877C324.199 222.308 323.617 222.75 323.41 223.358H323.407Z'
        }
        fill={theme.palette.primary.main}
      />
      <path
        d={
          'M327.973 221.696L328.648 217.677C329.126 214.828 327.207 212.518 324.359 212.518H299.2C296.352 212.518 293.655 214.828 293.173 217.677L292.498 221.696H294.423L294.948 218.57C295.322 216.353 297.422 214.554 299.638 214.554H323.233C325.449 214.554 326.943 216.353 326.572 218.57L326.047 221.696H327.973Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M327.972 222.198H326.047C325.9 222.198 325.759 222.134 325.662 222.021C325.565 221.907 325.525 221.76 325.552 221.613L326.077 218.486C326.234 217.55 326.02 216.681 325.475 216.036C324.94 215.404 324.145 215.056 323.236 215.056H299.641C297.662 215.056 295.78 216.671 295.446 218.654L294.921 221.78C294.881 222.021 294.67 222.198 294.426 222.198H292.501C292.354 222.198 292.213 222.134 292.116 222.021C292.02 221.907 291.979 221.76 292.006 221.613L292.681 217.594C293.199 214.518 296.124 212.017 299.203 212.017H324.362C325.876 212.017 327.21 212.608 328.116 213.678C329.038 214.772 329.403 216.219 329.145 217.758L328.47 221.776C328.43 222.017 328.219 222.194 327.975 222.194L327.972 222.198ZM326.638 221.195H327.547L328.152 217.597C328.363 216.353 328.076 215.193 327.347 214.33C326.635 213.488 325.572 213.023 324.359 213.023H299.199C296.589 213.023 294.109 215.15 293.667 217.764L293.089 221.198H293.998L294.453 218.49C294.864 216.046 297.19 214.056 299.637 214.056H323.232C324.442 214.056 325.512 214.531 326.241 215.39C326.979 216.266 327.273 217.426 327.066 218.657L326.638 221.198V221.195Z'
        }
        fill={theme.palette.primary.main}
      />
      <path
        d={
          'M323.111 210.856H301.01C300.121 210.856 299.275 211.579 299.125 212.468L298.8 214.401C298.65 215.29 299.252 216.012 300.141 216.012H322.242C323.131 216.012 323.976 215.29 324.127 214.401L324.451 212.468C324.602 211.579 324 210.856 323.111 210.856Z'
        }
        fill={theme.palette.primary.main}
      />
      <path
        d={
          'M322.243 216.514H300.142C299.567 216.514 299.055 216.286 298.704 215.872C298.35 215.451 298.206 214.899 298.307 214.317L298.631 212.384C298.821 211.244 299.868 210.352 301.011 210.352H323.112C323.687 210.352 324.198 210.579 324.549 210.994C324.903 211.415 325.047 211.967 324.947 212.548L324.622 214.481C324.432 215.621 323.386 216.514 322.243 216.514ZM301.007 211.355C300.362 211.355 299.727 211.903 299.617 212.548L299.293 214.481C299.246 214.768 299.306 215.033 299.47 215.223C299.627 215.407 299.864 215.511 300.142 215.511H322.243C322.888 215.511 323.523 214.962 323.633 214.317L323.957 212.384C324.004 212.097 323.944 211.833 323.78 211.642C323.623 211.458 323.386 211.355 323.108 211.355H301.007Z'
        }
        fill={theme.palette.primary.main}
      />
      <path
        d={
          'M330.663 281.607C330.489 281.607 330.318 281.516 330.228 281.352C330.091 281.112 330.175 280.804 330.415 280.667L338.825 275.876V227.671C338.825 227.394 339.049 227.17 339.326 227.17C339.604 227.17 339.828 227.394 339.828 227.671V276.167C339.828 276.347 339.731 276.514 339.574 276.601L330.91 281.54C330.833 281.583 330.746 281.607 330.663 281.607Z'
        }
        fill={'white'}
      />
      <defs>
        <clipPath id={'clip0_27390_4513'}>
          <rect width={'277.452'} height={'278.927'} fill={'white'} transform={'translate(71.3613 7.46729)'} />
        </clipPath>
      </defs>
    </svg>
  );
};
