import type { FunctionComponent } from 'react';
import React, { useContext, useMemo } from 'react';
import { ThemeContext } from 'styled-components';
import type { ProgressBarProps as InternalProgressBarProps } from '@ramonak/react-progress-bar';
import InternalProgressBar from '@ramonak/react-progress-bar';

export interface ProgressBarProps extends InternalProgressBarProps {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'data-testid'?: string;
}

export const ProgressBar: FunctionComponent<ProgressBarProps> = ({
  isLabelVisible = false,
  width = '100%',
  maxCompleted = 100,
  ...props
}) => {
  const { colors } = useContext(ThemeContext);

  const progressBarColor = useMemo(() => colors.primary.main ?? '#714EF5', [colors]);

  return (
    <InternalProgressBar
      width={width}
      bgColor={props.bgColor ?? `linear-gradient(270deg, ${progressBarColor}, #F3F0FF)`}
      height={'5px'}
      labelAlignment={'center'}
      baseBgColor={'#F5F5F5'}
      labelColor={'F5F5F5'}
      isLabelVisible={isLabelVisible}
      transitionDuration={'150ms'}
      animateOnRender
      maxCompleted={maxCompleted}
      {...props}
    />
  );
};

ProgressBar.displayName = 'ProgressBar';
