import type { FC, InputHTMLAttributes, ReactElement, SVGAttributes } from 'react';
import React, { forwardRef, useCallback, useState } from 'react';
import type { NumericFormatProps } from 'react-number-format';
import { isNil } from 'lodash-es';

import { Flex } from '../Flex';
import type { InputSizes } from './Styles/Input.styles';
import { StyledInputContainer } from './Styles/Input.styles';
import { InputByType } from './InputByType';
import { InputLabel } from './inputLabel';
import { InputHelper } from './InputHelper';
import { InputIcon } from './InputIcon.component';

type OverridedInputProps =
  | 'customInput'
  | 'defaultValue'
  | 'fullWidth'
  | 'helperText'
  | 'label'
  | 'name'
  | 'onBlur'
  | 'onChange'
  | 'onClick'
  | 'onFocus'
  | 'size'
  | 'value';

export interface InputProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, OverridedInputProps>,
    Omit<NumericFormatProps, OverridedInputProps | 'type'> {
  name?: string;
  label?: string;
  disabled?: boolean;
  value?: number | string;
  defaultValue?: number | string;
  helper?: React.ReactNode | string;
  // non transient prop to not confilict with base-html
  $size?: InputSizes;
  startIcon?: FC<SVGAttributes<SVGElement>> | ReactElement;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  error?: boolean;
  fullWidth?: boolean;
  htmlFor?: string;
}

export const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ type = 'text', name, onBlur, onFocus, label, helper, $size = 'l', startIcon, ...props }, ref) => {
    const [focused, setFocused] = useState(false);

    const internalOnFocus = useCallback(
      (event: React.FocusEvent<HTMLInputElement>) => {
        setFocused(true);
        onFocus?.(event);
      },
      [onFocus],
    );

    const internalOnBlur = useCallback(
      (event: React.FocusEvent<HTMLInputElement>) => {
        setFocused(false);
        onBlur?.(event);
      },
      [onBlur],
    );

    return (
      <Flex flexDirection={'column'} gap={1} flex={1} width={'auto'} height={props.height}>
        <StyledInputContainer flexDirection={'column'} type={type} label={label} gap={1} $size={$size} focused={focused} {...props}>
          {label ? (
            <InputLabel
              $size={$size}
              name={name}
              label={label}
              focused={focused}
              error={props.error}
              disabled={props.disabled}
              htmlFor={name}
            />
          ) : null}
          {startIcon ? (
            <Flex alignItems={'center'} pl={2} gap={1} width={'100%'} onFocus={internalOnFocus} onBlur={internalOnBlur}>
              {startIcon ? <InputIcon icon={startIcon} $size={$size} /> : null}
              <InputByType
                ref={ref}
                id={name}
                role={'textbox'}
                type={type}
                name={name}
                onFocus={internalOnFocus}
                onBlur={internalOnBlur}
                focused={focused}
                $size={$size}
                {...props}
              />
            </Flex>
          ) : (
            <InputByType
              ref={ref}
              id={name}
              role={'textbox'}
              type={type}
              name={name}
              onFocus={internalOnFocus}
              onBlur={internalOnBlur}
              focused={focused}
              $size={$size}
              {...props}
            />
          )}
        </StyledInputContainer>
        {!isNil(helper) && <InputHelper helper={helper} />}
      </Flex>
    );
  },
);

Input.displayName = 'Input';
