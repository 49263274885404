import React from 'react';
import { Slide, Fade, Box } from '@mui/material';
import type { ReactNode } from 'react';
import { Flex } from '@lama/design-system';

interface BaseScreenWrapperProps {
  children: ReactNode;
  animate?: boolean;
}

export const BaseScreenWrapper: React.FC<BaseScreenWrapperProps> = ({ children, animate = true }) => {
  const slideInAnimationTimeout = animate ? 750 : 0;
  const fadeInAnimationTimeout = animate ? 1000 : 400;

  return (
    <Slide in direction={'left'} timeout={slideInAnimationTimeout}>
      <Box width={'100%'}>
        <Fade in timeout={fadeInAnimationTimeout}>
          <Flex flexDirection={'column'} height={'100%'}>
            {children}
          </Flex>
        </Fade>
      </Box>
    </Slide>
  );
};
