import type { FC } from 'react';
import React from 'react';
import { Flex } from '@lama/design-system';
import { ApplicationSummaryBar } from '../shared/ApplicationSummaryBar';
import { AccountMenu } from './AccountMenu';

export const TopBar: FC = () => (
  <Flex
    flexShrink={0}
    gap={8}
    flexDirection={'row'}
    height={'64px'}
    justifyContent={'flex-end'}
    alignItems={'center'}
    pl={8}
    pr={4}
    width={'100%'}
  >
    <Flex flexDirection={'row'} height={'100%'} justifyContent={'space-between'} alignItems={'center'} px={8}>
      <Flex flexDirection={'row'} gap={6} alignItems={'center'}>
        <ApplicationSummaryBar />
        <AccountMenu showAccountSettings={false} popoverLocation={{ horizontal: 'center', vertical: 'bottom' }} />
      </Flex>
    </Flex>
  </Flex>
);
