import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import type { FC } from 'react';
import type { FormikHelpers, FormikProps } from 'formik';
import { Formik } from 'formik';
import * as yup from 'yup';
import { isEmpty } from 'lodash-es';
import type { EvaluatedApplicationRequirement } from '@lama/contracts';
import type { SourcedProperty } from '@lama/properties';
import { getApplicationEntityByType, getSourcedProperty } from '@lama/properties';
import {
  getInitialFormValues,
  GenericPropertiesGrid,
  formValuesToEntityPartial,
  createUpdatePayload,
  getValidationSchema,
  LoadingPage,
  GenericPropertiesProvider,
  getBusinessIdByEntity,
  ConfirmLeave,
  Indication,
} from '@lama/app-components';
import { captureMessage } from '@sentry/react';
import { useMediaQuery, useTheme } from '@mui/material';
import type { ApplicationApiModel } from '@lama/clients';
import { applicationBorrowingBusinessSelector } from '@lama/data-formatters';
import { useUpdateApplication } from '../../../hooks/react-query/useUpdateApplication';
import { ApplicationContext } from '../../../shared/contexts/ApplicationContext';
import type { ScreenProps } from '../../ScreenProps';
import { BasicScreen } from '../BasicScreen';
import { useGetCurrentRequirement } from '../../../hooks/useGetCurrentRequirement';
import { ErrorScreen } from '../../errorScreen/ErrorScreen';
import { useApplicationRequirementsQuery } from '../../../hooks/react-query/useApplicationRequirementsQuery';
import { useUpdateBusiness } from '../../../hooks/react-query/useUpdateBusiness';
import { useUpdatePerson } from '../../../hooks/react-query/useUpdatePerson';
import { customComponents } from './customComponentsMap';
import { getPropertiesAsOptional, getPropertiesAsLarge } from './genericFormUtils';

export const GenericForm = ({
  formikProps: { dirty, setFieldValue, isSubmitting },
  requirement,
  submitted,
  fieldsOverrides,
  entity,
  application,
}: {
  formikProps: FormikProps<any>;
  requirement: EvaluatedApplicationRequirement;
  submitted: boolean;
  fieldsOverrides?: Record<string, any>;
  entity: Record<string, any>;
  application: ApplicationApiModel;
}) => {
  useEffect(() => {
    if (!isEmpty(fieldsOverrides)) {
      Object.entries(fieldsOverrides).forEach(([key, value]) => {
        void setFieldValue(key, value);
      });
    }
  }, [fieldsOverrides, setFieldValue]);

  return (
    <ConfirmLeave shouldBlock={dirty || isSubmitting}>
      <GenericPropertiesGrid
        properties={requirement.properties}
        entityType={requirement.entityType}
        entity={entity}
        submitted={submitted}
        application={application}
      />
    </ConfirmLeave>
  );
};

export const GenericRequirementForm: FC<ScreenProps> = ({
  flow,
  nextEnabled,
  onNextClick,
  onBackClick,
  animate,
  ...stepsNavigationProps
}) => {
  const { application, product } = useContext(ApplicationContext);
  const { isFetching: fetchingRequirements } = useApplicationRequirementsQuery(application.id);
  const requirement = useGetCurrentRequirement();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const flowRequirement = useMemo(() => {
    if (!requirement) {
      return;
    }

    let { properties } = requirement;

    if (flow === 'onboarding' && !product.blockOnboardingOnMissingRequiredFields) {
      properties = getPropertiesAsOptional(properties);
    }

    if (isMobile) {
      properties = getPropertiesAsLarge(properties);
    }

    return {
      ...requirement,
      properties,
    };
  }, [flow, isMobile, product.blockOnboardingOnMissingRequiredFields, requirement]);

  const { mutateAsync: updateApplication, isPending: updatingApplication } = useUpdateApplication(application.id);
  const { mutateAsync: updateBusiness, isPending: updatingBusiness } = useUpdateBusiness(application.id);
  const { mutateAsync: updatePerson, isPending: updatingPerson } = useUpdatePerson(application.id);

  const [submitted, setSubmitted] = useState(false);

  const canEditRequirement = useMemo(() => !flowRequirement?.submitted || !!flowRequirement.isInvalidSubmission, [flowRequirement]);

  const requirementEntity = useMemo(() => {
    const entities = flowRequirement
      ? getApplicationEntityByType(application, flowRequirement.entityType, flowRequirement.entityGroups)
      : [];
    return entities.find(({ id }) => id === flowRequirement?.entityId);
  }, [application, flowRequirement]);

  const propertiesWithDecidedSource = useMemo<SourcedProperty[]>(() => {
    const yearsBack = new Date().getUTCFullYear() - application.leadingReferenceYear;

    return flowRequirement?.properties.map((p) => getSourcedProperty(p, requirementEntity, yearsBack)) ?? [];
  }, [application, flowRequirement?.properties, requirementEntity]);

  const initialFormValues = useMemo(
    () => getInitialFormValues(propertiesWithDecidedSource, requirementEntity),
    [propertiesWithDecidedSource, requirementEntity],
  );

  const onSubmit = useCallback(
    async (values: Record<string, any>, { resetForm }: FormikHelpers<any>) => {
      if (!flowRequirement) {
        return;
      }

      setSubmitted(true);
      resetForm({ values });

      const { entityType, entityId, properties } = flowRequirement;
      const entityPartial = formValuesToEntityPartial(values, initialFormValues, properties);

      const updatePayload = createUpdatePayload(application, entityType, entityId, entityPartial);

      if (!isEmpty(updatePayload.updateApplicationPayload)) {
        await updateApplication({ updateApplicationPayload: updatePayload.updateApplicationPayload });
      }

      if (!isEmpty(updatePayload.updateBusinessPayload)) {
        const businessEntityId = getBusinessIdByEntity(application, entityType, entityId);

        if (businessEntityId) {
          await updateBusiness({ businessId: businessEntityId, updateBusinessPayload: updatePayload.updateBusinessPayload });
        }
      }

      if (!isEmpty(updatePayload.updatePersonPayload)) {
        await updatePerson({ personId: entityId, updatePersonPayload: updatePayload.updatePersonPayload });
      }

      onNextClick({ businessUnformed: applicationBorrowingBusinessSelector(application)?.unformed });
    },
    [flowRequirement, initialFormValues, application, onNextClick, updateApplication, updateBusiness, updatePerson],
  );

  const validationSchema = useMemo(
    () =>
      flowRequirement
        ? getValidationSchema({
            properties: flowRequirement.properties,
            blockOnMissingRequiredFields: flow === 'onboarding' && !!product.blockOnboardingOnMissingRequiredFields,
          })
        : yup.object(),
    [flow, flowRequirement, product.blockOnboardingOnMissingRequiredFields],
  );

  const backClick = useCallback(() => {
    onBackClick?.({ useOfFunds: application.useOfFunds });
  }, [onBackClick, application.useOfFunds]);

  if (!flowRequirement) {
    if (fetchingRequirements) {
      return <LoadingPage />;
    }

    captureMessage(`GenericRequirementForm: requirement is undefined, in flow: ${flow}, application: ${application.id}`);
    return <ErrorScreen />;
  }

  return (
    <GenericPropertiesProvider customComponents={customComponents} customSourceToValues={product.customOptionsLists ?? {}}>
      <Formik validationSchema={validationSchema} initialValues={initialFormValues} enableReinitialize onSubmit={onSubmit}>
        {(formikProps) => (
          <BasicScreen
            {...stepsNavigationProps}
            flow={flow}
            animate={animate ?? false}
            title={flowRequirement.name}
            subtitle={flowRequirement.description}
            onNextClick={formikProps.handleSubmit}
            onBackClick={backClick}
            nextEnabled={canEditRequirement ? !!nextEnabled || (formikProps.dirty && formikProps.isValid) : false}
            nextLoading={updatingApplication || updatingBusiness || updatingPerson}
          >
            {flow === 'onboarding' ? null : <Indication screenName={flowRequirement.screen} application={application} />}
            <GenericForm
              formikProps={formikProps}
              requirement={flowRequirement}
              submitted={submitted}
              entity={requirementEntity ?? {}}
              application={application}
            />
          </BasicScreen>
        )}
      </Formik>
    </GenericPropertiesProvider>
  );
};
