import type { ApplicationCreateApiModel } from '@lama/clients';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { displayToast } from '@lama/app-components';
import { createApplication } from '../../shared/clients/applicationService';
import { useAuthentication } from '../authentication/useAuthentication';

export const useCreateApplicationMutation = () => {
  const { token, isAuthenticated } = useAuthentication();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ createApplicationPayload }: { createApplicationPayload: ApplicationCreateApiModel }) => {
      if (!isAuthenticated) {
        return null;
      }

      return createApplication(createApplicationPayload, token);
    },
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: ['application'],
        }),
        queryClient.invalidateQueries({
          queryKey: ['applications'],
        }),
      ]);
    },
    onError: () => {
      displayToast('There was a problem processing the request. Please try again.', 'error');
    },
  });
};
