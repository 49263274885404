import React from 'react';
import type { ScreenProps } from '../ScreenProps';

interface EmbeddedConclusionScreenProps extends ScreenProps {
  backgroundImage?: string;
}

export const EmbeddedConclusionScreen: React.FC<EmbeddedConclusionScreenProps> = ({ backgroundImage }) => (
  <div
    style={{
      cursor: 'pointer',
      width: '100vw',
      height: '100vh',
      backgroundImage: `url(${backgroundImage})`,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      position: 'absolute',
      top: 0,
    }}
  />
);
