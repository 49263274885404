import React from 'react';
import { InputAdornment } from '@mui/material';
import { AodOutlined } from '@mui/icons-material';
import type { FormikInputProps } from './PropertyFormikInput';
import { PropertyFormikInput } from './PropertyFormikInput.js';

export const FormikPhoneNumberField: React.FC<FormikInputProps> = (props) => (
  <PropertyFormikInput
    label={'Phone Number'}
    InputProps={{
      startAdornment: (
        <InputAdornment position={'start'}>
          <AodOutlined color={'primary'} />
        </InputAdornment>
      ),
    }}
    {...props}
  />
);
