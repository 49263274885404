import { useMediaQuery, useTheme } from '@mui/material';
import type { FC, ReactNode } from 'react';
import React, { useContext, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { partnerState } from '../../../state/appState';
import { useSentryIdentification } from '../hooks/useSentryIdentification';
import { UserDetailsContext } from '../../../shared/contexts/UserDetailsContext';
import { getLogoUrl } from '../../../shared/utils/getLogoUrl';
import { DesktopLayout } from './DesktopLayout';
import { MobileLayout } from './MobileLayout';

export const Layout: FC<{ children: ReactNode }> = ({ children }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const partner = useRecoilValue(partnerState);

  const logoUrl = useMemo(() => (partner ? getLogoUrl(partner.id, isMobile ? 'small' : 'big') : ''), [isMobile, partner]);

  const user = useContext(UserDetailsContext);

  useSentryIdentification(user, partner?.name);

  if (isMobile) {
    return <MobileLayout logoUrl={logoUrl}>{children}</MobileLayout>;
  }

  return <DesktopLayout logoUrl={logoUrl}>{children}</DesktopLayout>;
};
