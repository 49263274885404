import { useCallback } from 'react';
import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import type { User } from '@lama/user-service-client';
import { useAuthentication } from '../authentication/useAuthentication';
import { userServiceClient } from '../../shared/clients/userServiceClient';

export const useGetUserQuery = (userId: string | undefined, options?: UseQueryOptions<User | null>) => {
  const { token, isAuthenticated } = useAuthentication();

  const getUserQuery = useCallback(async () => {
    if (!userId || !isAuthenticated) {
      return null;
    }

    return userServiceClient.getUser({ userId }, token);
  }, [isAuthenticated, token, userId]);

  const query = useQuery<User | null>({ queryKey: ['user', userId], queryFn: getUserQuery, ...options });

  return query;
};
