import { useMediaQuery, useTheme } from '@mui/material';
import React from 'react';

interface FinancialStatementsIconProps {
  size?: string;
}

export const FinancialStatementsIcon: React.FC<FinancialStatementsIconProps> = ({ size = '48px' }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const finalSize = isMobile ? '32px' : size;

  return (
    <svg width={finalSize} height={finalSize} viewBox={'0 0 48 48'} fill={'none'} xmlns={'http://www.w3.org/2000/svg'}>
      <path
        d={
          'M23.5262 40.0539C32.8215 39.1728 39.6431 30.917 38.7628 21.614C37.8825 12.311 29.6336 5.48368 20.3384 6.36471C11.0431 7.24575 4.22144 15.5015 5.10173 24.8045C5.98203 34.1075 14.2309 40.9349 23.5262 40.0539Z'
        }
        fill={'#0C2007'}
      />
      <path
        d={
          'M36.4756 31.5011C41.3494 23.8591 39.1105 13.7099 31.4749 8.83202C23.8393 3.9542 13.6985 6.19495 8.82474 13.8369C3.95097 21.4788 6.18986 31.6281 13.8254 36.5059C21.461 41.3837 31.6018 39.143 36.4756 31.5011Z'
        }
        fill={'#44CFA6'}
      />
      <path
        d={
          'M35.6412 27.2647C38.1651 20.0876 34.3979 12.2218 27.2268 9.69576C20.0558 7.16974 12.1964 10.9401 9.6725 18.1171C7.14858 25.2942 10.9158 33.1601 18.0869 35.6861C25.2579 38.2121 33.1173 34.4417 35.6412 27.2647Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M21.527 32.429L21.4968 30.1652C19.8984 30.1135 18.3474 29.7081 17.4341 29.195L18.1105 26.3966C19.1186 26.9313 20.5318 27.4142 22.0785 27.3927C23.4356 27.3754 24.3575 26.8364 24.3446 25.8835C24.3317 24.978 23.5605 24.4174 21.7898 23.844C19.2306 23.0204 17.4729 21.8519 17.4427 19.5407C17.4125 17.4408 18.8687 15.7764 21.4107 15.2417L21.3805 12.9779L23.7156 12.9478L23.7458 15.0433C25.3442 15.0951 26.4169 15.4142 27.2097 15.785L26.5548 18.4886C25.9301 18.2342 24.8272 17.6995 23.1124 17.7254C21.5658 17.7469 21.0746 18.4196 21.0832 19.0879C21.0918 19.8727 21.9363 20.3642 23.9698 21.0973C26.8176 22.0588 27.9808 23.3567 28.0067 25.4997C28.0369 27.6212 26.5591 29.4537 23.8276 29.9669L23.8621 32.3988L21.527 32.429Z'
        }
        fill={'#44CFA6'}
      />
    </svg>
  );
};
