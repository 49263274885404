import type React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useAsync } from 'react-use';
import { useQueryParams } from '../../hooks/useQueryParams';

export const LenderLoginScreen: React.FC = () => {
  const { loginWithRedirect } = useAuth0();
  const { returnTo } = useQueryParams(['returnTo']);

  useAsync(async () => {
    await loginWithRedirect({ appState: { returnTo: returnTo ?? '/' } });
  }, [loginWithRedirect, returnTo]);

  return null;
};
