import React from 'react';
import type { PropertySourceTypes } from '@lama/contracts';
import type { FC } from 'react';
import { Box } from '@mui/material';
import { DefaultSourceIcon } from './assets/DefaultSourceIcon';
import { SosIcon } from './assets/SosIcon';
import { TreasuryIcon } from './assets/TreasuryIcon';
import { CreditReportIcon } from './assets/CreditReportIcon';

const sourceToIcon: Partial<Record<PropertySourceTypes, FC<{ color?: string }>>> = {
  SoS: SosIcon,
  treasury: TreasuryIcon,
  creditReport: CreditReportIcon,
};

const getSourceIcon = (source: PropertySourceTypes): FC<{ color?: string }> | undefined => sourceToIcon[source] ?? DefaultSourceIcon;

export const SourceIcon: FC<{ source: PropertySourceTypes; color?: string }> = ({ source, color }) => {
  const Icon = getSourceIcon(source);

  return Icon ? (
    <Box display={'flex'} minWidth={'20px'} minHeight={'20px'} component={'span'}>
      <Icon color={color} />
    </Box>
  ) : null;
};
