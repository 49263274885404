import { Box } from '@mui/material';
import type { ReactNode } from 'react';
import React from 'react';
import { Flex } from '@lama/design-system';
import { TopBar } from '../Topbar';
import { SubmitBanner } from '../SubmitBanner';
import { SideNav } from './SideNav/SideNav';

export const DesktopLayout: React.FC<{ children: ReactNode; logoUrl: string }> = ({ children, logoUrl }) => (
  <>
    <SubmitBanner />
    <Box sx={{ display: 'flex', pb: 8 }}>
      <SideNav logoUrl={logoUrl} />
      <Flex flexDirection={'column'} width={'100%'}>
        <TopBar />
        <Flex flexDirection={'column'} flex={1} alignItems={'center'}>
          {children}
        </Flex>
      </Flex>
    </Box>
  </>
);
