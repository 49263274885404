import React, { useCallback, useState } from 'react';
import type { ProductPublicData, Screen } from '@lama/contracts';
import type { ApplicationApiModel } from '@lama/clients';
import { Navigate } from 'react-router-dom';
import { LoadingPage } from '@lama/app-components';
import { compact } from 'lodash-es';
import { useStepsUrlNavigation } from '../../hooks/useStepsUrlNavigation';
import { screenMap } from '../screensConfiguration';
import { ApplicationProvider } from '../../shared/contexts/ApplicationContext';
import { useApplicationRequirementsQuery } from '../../hooks/react-query/useApplicationRequirementsQuery';
import { isOrAfterApplicationStatus } from '../../shared/utils/isAfterApplicationStatus';
import { ErrorScreen } from '../errorScreen/ErrorScreen';
import { applicationServiceClient } from '../../shared/clients/applicationService';
import { useAuthentication } from '../../hooks/authentication/useAuthentication';

export const FlowScreen: React.FC<{ application: ApplicationApiModel; product: ProductPublicData }> = ({ application, product }) => {
  const {
    screensConfiguration: { screenParams, screenConditions },
    onboardingRequirements,
  } = product;

  const [failingDisqualifyingConditionMessages, setFailingDisqualifyingConditionMessages] = useState<string[]>([]);

  const { data: requirements, isPending: loadingRequirements } = useApplicationRequirementsQuery(application.id);
  const { navigateNext, navigateBack, currentStep } = useStepsUrlNavigation(product, application);
  const { token, isAuthenticated } = useAuthentication();

  const currentScreenName: Screen = screenMap[currentStep as Screen]
    ? (currentStep as Screen)
    : onboardingRequirements?.some((r) => r.screen === currentStep)
    ? 'genericForm'
    : 'getStarted';

  const onNextClick = useCallback(async () => {
    if (!isAuthenticated || !token) {
      navigateNext();
      return;
    }

    const currentRequirement = requirements?.find((r) => r.screen === currentStep);

    const disqualifyingConditions =
      screenConditions?.[currentStep]?.disqualifyingConditionIds ?? currentRequirement?.disqualifyingConditionIds;

    if (!disqualifyingConditions?.length) {
      navigateNext();
      return;
    }

    const { evaluatedConditions } = await applicationServiceClient.evaluateApplication(application.id, token, disqualifyingConditions);

    const failedConditionMessages = compact(
      evaluatedConditions?.filter((condition) => condition.reason === 'fail').map(({ condition }) => condition.note),
    );

    setFailingDisqualifyingConditionMessages(failedConditionMessages);

    if (!failedConditionMessages?.length) {
      navigateNext();
      return;
    }
  }, [isAuthenticated, token, currentStep, application.id, screenConditions, navigateNext, requirements]);

  const onBackClick = useCallback(() => {
    setFailingDisqualifyingConditionMessages([]);
    navigateBack();
  }, [navigateBack]);

  const Component = screenMap[currentScreenName];

  if (loadingRequirements) {
    return <LoadingPage />;
  }

  if (isOrAfterApplicationStatus(application.status, 'InReview')) {
    return <Navigate to={`/applications/${application.id}/overview`} />;
  }

  if (!requirements) {
    return <ErrorScreen />;
  }

  return (
    <ApplicationProvider application={application} product={product} requirements={requirements}>
      <Component
        {...screenParams[currentStep]}
        flow={'onboarding'}
        onNextClick={onNextClick}
        onBackClick={onBackClick}
        nextEnabled
        failingDisqualifyingConditionMessages={failingDisqualifyingConditionMessages}
      />
    </ApplicationProvider>
  );
};
