import React from 'react';
import { isNil } from 'lodash-es';
import type { UsePopoverReturn } from './Popover';

export const PopoverContext = React.createContext<UsePopoverReturn>(null!);

export const usePopoverContext = (): UsePopoverReturn => {
  const context = React.useContext(PopoverContext);

  if (isNil(context)) {
    throw new Error('Popover components must be wrapped in <Popover />');
  }

  return context;
};
