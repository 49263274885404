/* eslint-disable @typescript-eslint/naming-convention */
import type { FC } from 'react';
import React, { useCallback, useMemo } from 'react';
import type { FilterOptionsState, TextFieldProps } from '@mui/material';
import { Autocomplete, TextField, ListItem } from '@mui/material';
import { useField, useFormikContext } from 'formik';
import type { PropertyValue } from '@lama/properties';
import type { PropertySourceTypes, LabeledValue } from '@lama/contracts';
import { Flex } from '@lama/design-system';
import { useConfirmModal } from '../../ConfirmationModal/useConfirmModal.js';
import { useSelectedPropertyValue } from '../hooks/useSelectedValueSource.js';
import { SourceIcon } from '../HelperTexts/SourceIcon.js';
import { FormikMultiSourceHelperText } from '../HelperTexts/FormikMultiSourceHelperText.js';
import { HelperText } from '../HelperTexts/HelperText.js';

type PickerProps = TextFieldProps & {
  name: string;
  values: LabeledValue[] | string[] | readonly string[];
  highlight?: boolean;
  renderOption?: FC<{ value: string }> | (({ value }: { value: string }) => string);
  sourcesValues?: PropertyValue[];
  onChangeCallback?: (value: string) => void;
};

interface LabeledValueWithSources {
  labeledValue: LabeledValue;
  sources: PropertySourceTypes[];
}

const defaultLabeledValueWithSources: LabeledValueWithSources = {
  labeledValue: { value: '', label: '' },
  sources: [],
};

const getOptionLabel = (option: LabeledValueWithSources) => option.labeledValue.label;

const isOptionEqualToValue = (option: LabeledValueWithSources, value?: LabeledValueWithSources) =>
  option.labeledValue.value === value?.labeledValue.value;

const filterDefaultValueOption = (options: LabeledValueWithSources[], state: FilterOptionsState<LabeledValueWithSources>) =>
  options.filter(
    (option) => option.labeledValue.value !== '' && option.labeledValue.label.toLowerCase().startsWith(state.inputValue.toLowerCase()),
  );

export const FormikPicker: FC<PickerProps> = ({
  values,
  renderOption,
  name,
  sourcesValues,
  helperText,
  label,
  required,
  onChangeCallback,
  ...rest
}) => {
  const { submitCount } = useFormikContext();
  const [{ value: fieldValue }, { error, touched }, { setValue: setFieldValue }] = useField(name);
  const { confirm } = useConfirmModal();

  const options: LabeledValueWithSources[] = useMemo(
    () =>
      values.map((value) => {
        const externalSourcesWithValues = sourcesValues
          ?.filter((sourceValue) => sourceValue.value === value && sourceValue.source.source !== 'userInput')
          .map((PropertyValue) => PropertyValue.source);

        const labeledValue = typeof value === 'string' ? { value, label: value } : value;

        return {
          labeledValue,
          sources: externalSourcesWithValues?.map((valueSource) => valueSource.source) ?? [],
        };
      }),
    [values, sourcesValues],
  );

  const currentValue = useMemo(
    () => options.find((v) => v.labeledValue.value === fieldValue) ?? defaultLabeledValueWithSources,
    [fieldValue, options],
  );

  const selectedPropertyValue = useSelectedPropertyValue(sourcesValues, fieldValue);

  const onChange = useCallback(
    async (_event: React.ChangeEvent<{}>, value: LabeledValueWithSources | null) => {
      if (!value) {
        void setFieldValue('');
        return;
      }

      const pickedUserInputValue = !sourcesValues?.find(
        (sourceValue) => sourceValue.source.source !== 'userInput' && sourceValue.value === value.labeledValue.value,
      );

      const isCurrentValueExternalValue = selectedPropertyValue && selectedPropertyValue.source.source !== 'userInput';

      const isOverridingExternalValue = isCurrentValueExternalValue && pickedUserInputValue;

      const shouldChange = isOverridingExternalValue
        ? await confirm({
            title: 'Are you sure you want to overwrite this value?',
            message: "Only overwrite it if you're sure the data that was automatically fetched is incorrect.",
          })
        : true;

      if (shouldChange) {
        void setFieldValue(value.labeledValue.value);
        onChangeCallback?.(value.labeledValue.value);
      }
    },
    [confirm, onChangeCallback, selectedPropertyValue, setFieldValue, sourcesValues],
  );

  const renderOptionInner = useCallback(
    (renderOptionProps: any, v: LabeledValueWithSources) => (
      <ListItem {...renderOptionProps} key={v.labeledValue.value}>
        <Flex flexDirection={'row'} gap={2}>
          {v.sources.length ? (
            <Flex flexDirection={'row'} alignItems={'center'} justifyContent={'center'} gap={2}>
              {v.sources.map((source) => (source ? <SourceIcon key={source} source={source} /> : null))}
            </Flex>
          ) : null}
          {renderOption ? renderOption({ value: v.labeledValue.value }) : v.labeledValue.label}
        </Flex>
      </ListItem>
    ),
    [renderOption],
  );

  const inputError = useMemo(() => (!!touched || submitCount > 0) && Boolean(error), [error, submitCount, touched]);
  const inputHelperText = useMemo(() => {
    if (inputError) {
      return error;
    }

    return (
      <HelperText>
        <FormikMultiSourceHelperText selectedPropertyValue={selectedPropertyValue} sourcesValues={sourcesValues} />
        {helperText}
      </HelperText>
    );
  }, [error, helperText, inputError, selectedPropertyValue, sourcesValues]);

  const renderInput = useCallback(
    (params: any) => (
      <TextField
        {...rest}
        {...params}
        InputProps={{
          ...params.InputProps,
          ...rest.InputProps,
        }}
        error={inputError}
        label={label}
        required={required}
        helperText={inputHelperText}
      />
    ),
    [rest, inputError, label, required, inputHelperText],
  );

  const optionsWithDefaultValue = useMemo(() => [defaultLabeledValueWithSources, ...options], [options]);

  return (
    <Autocomplete
      disableClearable
      options={optionsWithDefaultValue}
      value={currentValue}
      renderOption={renderOptionInner}
      getOptionLabel={getOptionLabel}
      renderInput={renderInput}
      onChange={onChange}
      isOptionEqualToValue={isOptionEqualToValue}
      filterOptions={filterDefaultValueOption}
      fullWidth
      disabled={rest.disabled}
    />
  );
};
