import type { PixelSuffix } from '@lama/design-system';
import { useTheme } from '@mui/material';
import React from 'react';
import type { FC } from 'react';

export const HashLinkIcon: FC<{ color?: string; size?: PixelSuffix }> = ({ color, size }) => {
  const theme = useTheme();

  return (
    <svg width={size ?? '17'} height={size ?? '11'} viewBox={'0 0 17 11'} fill={'none'} xmlns={'http://www.w3.org/2000/svg'}>
      <path
        d={
          'M3.99984 2.49996H1.49984C1.0415 2.49996 0.666504 2.12496 0.666504 1.66662C0.666504 1.20829 1.0415 0.833289 1.49984 0.833289H3.99984C4.45817 0.833289 4.83317 1.20829 4.83317 1.66662C4.83317 2.12496 4.45817 2.49996 3.99984 2.49996ZM3.99984 4.99996H1.49984C1.0415 4.99996 0.666504 5.37495 0.666504 5.83329C0.666504 6.29162 1.0415 6.66662 1.49984 6.66662H3.99984C4.45817 6.66662 4.83317 6.29162 4.83317 5.83329C4.83317 5.37495 4.45817 4.99996 3.99984 4.99996ZM15.5665 10.2416L12.9665 7.64162C12.2498 8.10829 11.3915 8.37496 10.4665 8.32495C8.4915 8.23329 6.79984 6.69162 6.53317 4.73329C6.1665 1.94162 8.57484 -0.416711 11.3915 0.0582892C13.0165 0.333289 14.3665 1.59996 14.7248 3.20829C14.9998 4.42496 14.7332 5.55829 14.1415 6.45829L16.7498 9.06662C17.0748 9.39162 17.0748 9.91662 16.7498 10.2416C16.4248 10.5666 15.8915 10.5666 15.5665 10.2416ZM13.1665 4.16662C13.1665 2.79162 12.0415 1.66662 10.6665 1.66662C9.2915 1.66662 8.1665 2.79162 8.1665 4.16662C8.1665 5.54162 9.2915 6.66662 10.6665 6.66662C12.0415 6.66662 13.1665 5.54162 13.1665 4.16662ZM1.49984 10.8333H8.1665C8.62484 10.8333 8.99984 10.4583 8.99984 9.99996C8.99984 9.54162 8.62484 9.16662 8.1665 9.16662H1.49984C1.0415 9.16662 0.666504 9.54162 0.666504 9.99996C0.666504 10.4583 1.0415 10.8333 1.49984 10.8333Z'
        }
        fill={color ?? theme.palette.primary.main}
      />
    </svg>
  );
};
