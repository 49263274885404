import React, { useMemo, useCallback } from 'react';
import type { FC } from 'react';
import { Button, Flex, Modal, ModalContent } from '@lama/design-system';
import type { FormikHelpers } from 'formik';
import { Formik } from 'formik';
import type { EvaluatedApplicationRequirement, EvaluatedOpportunityRequirement, RequirementProperty } from '@lama/contracts';
import type { ApplicationApiModel, OpportunityApiModel } from '@lama/clients';
import { formValuesToEntityPartial, getValidationSchema } from '../GenericProperties/genericFormUtils.js';
import { GenericPropertiesGrid } from '../GenericProperties/GenericPropertiesGrid.js';
import type { DialogMode } from '../BaseDialog/BaseDialog.js';
import { FormikDocumentPlaceholders } from './FormikDocumentPlaceholders.js';
import type { ItemDocumentsProps } from './types.js';

interface GenericAddOrEditDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (values: any) => Promise<void>;
  initialValues?: Record<string, any>;
  mode: DialogMode;
  itemProperties: RequirementProperty[] | undefined;
  parentFieldName: string;
  parentDisplayName: string;
  entity?: Record<string, any>;
  application: ApplicationApiModel;
  opportunity?: OpportunityApiModel;
  isLoading?: boolean;
  requirement: EvaluatedApplicationRequirement | EvaluatedOpportunityRequirement;
  ItemDocumentsComponent: FC<ItemDocumentsProps>;
}

export const GenericAddOrEditDialog: FC<GenericAddOrEditDialogProps> = ({
  open,
  onClose,
  onSubmit,
  initialValues = {},
  itemProperties = [],
  parentFieldName,
  entity = {},
  application,
  opportunity,
  isLoading,
  requirement,
  ItemDocumentsComponent,
  mode,
}) => {
  const action = mode === 'edit' ? 'Edit' : 'Add';

  const validationSchema = useMemo(
    () => getValidationSchema({ properties: itemProperties, blockOnMissingRequiredFields: false, parentFieldName }),
    [itemProperties, parentFieldName],
  );

  const onSubmitInternal = useCallback(
    async (values: Record<string, any>, { resetForm }: FormikHelpers<any>) => {
      const partial = formValuesToEntityPartial(values, initialValues, itemProperties);
      await onSubmit({ ...partial, id: initialValues?.id });
      resetForm();
    },
    [initialValues, itemProperties, onSubmit],
  );

  const requirementHasDocumentPlaceholders = useMemo(
    () => !!requirement.sources.uploadFilesSource?.length,
    [requirement.sources.uploadFilesSource],
  );

  return (
    <Modal open={open} onClose={onClose} title={`${action} Item`} alignTitle={'center'} size={'l'} fullWidth>
      <ModalContent scrollable>
        <Formik validationSchema={validationSchema} initialValues={initialValues} onSubmit={onSubmitInternal} validateOnChange>
          {({ handleSubmit, isValid }) => (
            <Flex flexDirection={'column'} gap={12} width={'100%'}>
              <GenericPropertiesGrid
                properties={itemProperties}
                entityType={requirement.entityType}
                entity={entity}
                application={application}
                opportunity={opportunity}
              />
              {requirementHasDocumentPlaceholders ? (
                <FormikDocumentPlaceholders requirement={requirement} ItemDocumentsComponent={ItemDocumentsComponent} />
              ) : null}
              <Flex justifyContent={'center'}>
                <Button variant={'primary'} color={'primary'} onClick={handleSubmit} disabled={!isValid} loading={isLoading}>
                  {action}
                </Button>
              </Flex>
            </Flex>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  );
};
