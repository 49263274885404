import React, { useCallback, useMemo, useState, type FC } from 'react';
import { SendOtpComponent } from './SendOtpComponent';
import { VerifyOtpComponent } from './VerifyOtpComponent';

interface OtpVerificationProps {
  initialEmail?: string | null;
  initialPhoneNumber?: string | null;
  onOtpVerified?: (token: string) => void;
  autoSend?: boolean;
}

const otpSentInLastXMinutes = (lastAutosendOtpTimestamp: string | null, minutes: number) => {
  if (!lastAutosendOtpTimestamp) {
    return false;
  }
  const lastSent = new Date(lastAutosendOtpTimestamp);
  const now = new Date();
  const diff = now.getTime() - lastSent.getTime();
  return diff < 1000 * 60 * minutes;
};

export const OtpVerification: FC<OtpVerificationProps> = ({ initialEmail, initialPhoneNumber, onOtpVerified, autoSend }) => {
  const [connection, setConnection] = useState<'email' | 'sms'>('email');
  const otpSentInLast3Minutes = useMemo(() => otpSentInLastXMinutes(localStorage.getItem('lastAutoSendOtpTimestamp'), 3), []);
  const lastOtpEmail = useMemo(() => localStorage.getItem('lastOtpEmail'), []);
  const [otpSent, setOtpSent] = useState(otpSentInLast3Minutes);

  const [to, setTo] = useState(lastOtpEmail ?? (connection === 'email' ? initialEmail : initialPhoneNumber));

  const onConnectionToggle = useCallback((value: string) => {
    if (value === 'email' || value === 'sms') {
      setConnection(value);
    }
  }, []);

  const onOtpSent = useCallback((sentTo: string) => {
    localStorage.setItem('lastAutoSendOtpTimestamp', new Date().toISOString());
    localStorage.setItem('lastOtpEmail', sentTo);
    setOtpSent(true);
    setTo(sentTo);
  }, []);

  return otpSent ? (
    <VerifyOtpComponent connection={connection} to={to ?? ''} onOtpVerified={onOtpVerified} />
  ) : (
    <SendOtpComponent
      connection={connection}
      email={initialEmail}
      phoneNumber={initialPhoneNumber}
      onConnectionToggle={onConnectionToggle}
      onOtpSent={onOtpSent}
      autoSend={autoSend}
    />
  );
};
