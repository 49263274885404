import React from 'react';
// import { v4 as uuidv4 } from 'uuid';
import { useAsyncFn } from 'react-use';
// import { useRecoilValue } from 'recoil';
// import { useAuthentication } from '../../hooks/authentication/useAuthentication';
import { LoadingPage } from '@lama/app-components';
import type { ScreenProps } from '../ScreenProps';
// import { initiatorPersonState } from '../../state/applicationState';

interface EmbeddedScreenProps extends ScreenProps {
  name?: string;
  email?: string;
  requestedAmount?: number;
  partner?: string;
  backgroundImage?: string;
}

export const EmbeddedInitialScreen: React.FC<EmbeddedScreenProps> = (props) => {
  const { onNextClick, backgroundImage } = props;
  // const initiatorPerson = useRecoilValue(initiatorPersonState);

  // const product = useRecoilValue(productState);
  // const [applicationDetails, setApplicationDetails] = useRecoilState(applicationDetailsState);
  // const { registerUser } = useAuthentication();

  // const setPeople = useSetRecoilState(peopleState);

  const [{ loading }, handleClick] = useAsyncFn(async () => {
    // const { name, email, requestedAmount, partner } = props;
    // const [firstName, lastName] = name!.split(' ');
    // let updatedInitiator = { ...initiatorPerson };
    try {
      // if (!initiatorPerson.email) {
      //   const uniqueEmail = `${email!.split('@')[0]}+${Math.random().toString(36).slice(6)}@${email!.split('@')[1]}`;
      //   updatedInitiator = { ...initiatorPerson, firstName, lastName, email: uniqueEmail };
      //   await registerUser(uuidv4(), uniqueEmail, firstName, lastName, updatedInitiator.id, 'embedded');
      // }
      // const updatedPeople = [updatedInitiator];
      // setPeople(updatedPeople);
      // const createApplicationPayload: ApplicationCreateApiModel = {
      //   id: applicationDetails.id,
      //   business: {
      //     id: uuidv4(),
      //     people: updatedPeople,
      //   },
      //   requestedAmount,
      //   originalProductId: product?.id ?? '',
      //   people: [],
      //   originatingPartner: partner ?? 'default',
      //   initiator: updatedPeople[0].id,
      // };

      // await saveApplication(createApplicationPayload);
      // setApplicationDetails({ ...applicationDetails, ...createApplicationPayload });
      onNextClick();
    } catch (error) {
      console.log(error);
      onNextClick();
      return;
    }
  }, [onNextClick]);

  return (
    <div
      onClick={handleClick}
      style={{
        cursor: 'pointer',
        width: '100vw',
        height: '100vh',
        backgroundImage: `url(${backgroundImage})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        position: 'absolute',
        top: 0,
      }}
    >
      {loading ? <LoadingPage /> : null}
    </div>
  );
};
