import { useTheme } from '@mui/material';
import type { FC } from 'react';
import React from 'react';

export const CreditReportIcon: FC<{ color?: string }> = ({ color }) => {
  const theme = useTheme();
  return (
    <svg width={'20'} height={'20'} viewBox={'0 0 12 12'} fill={'none'} xmlns={'http://www.w3.org/2000/svg'}>
      <mask id={'mask0_23825_37317'} style={{ maskType: 'alpha' }} maskUnits={'userSpaceOnUse'} x={'0'} y={'0'} width={'20'} height={'20'}>
        <rect width={'20'} height={'20'} fill={'#D9D9D9'} />
      </mask>
      <g mask={'url(#mask0_23825_37317)'}>
        <path
          d={
            'M5.30191 7.67308C5.47948 7.85064 5.70816 7.93493 5.98796 7.92595C6.26777 7.91698 6.47306 7.81602 6.60383 7.62308L8.62046 4.70204C8.69206 4.59428 8.68395 4.49649 8.59613 4.40867C8.50831 4.32085 8.41044 4.31266 8.30251 4.38409L5.37691 6.39617C5.18076 6.52693 5.07483 6.72805 5.05913 6.99953C5.04343 7.271 5.12435 7.49551 5.30191 7.67308ZM5.99999 2.25002C6.32884 2.25002 6.6431 2.27982 6.94278 2.33944C7.24246 2.39906 7.53461 2.49105 7.81923 2.61542C7.92051 2.6558 8.02131 2.72791 8.12163 2.83175C8.22194 2.93559 8.24758 3.03399 8.19854 3.12694C8.14951 3.21988 8.04358 3.28158 7.88076 3.31203C7.71795 3.34248 7.58333 3.33751 7.47693 3.29713C7.24295 3.19649 7.00449 3.1218 6.76154 3.07308C6.51859 3.02436 6.26474 3 5.99999 3C4.89165 3 3.9479 3.38959 3.16874 4.16875C2.38957 4.94792 1.99999 5.89167 1.99999 7C1.99999 7.35 2.0479 7.69584 2.14374 8.0375C2.23957 8.37917 2.37499 8.7 2.54999 9H9.44999C9.64165 8.68334 9.78124 8.35417 9.86874 8.0125C9.95624 7.67084 9.99999 7.31667 9.99999 6.95C9.99999 6.7141 9.97643 6.47195 9.92931 6.22355C9.8822 5.97515 9.80832 5.73653 9.70768 5.50768C9.66729 5.40127 9.65896 5.29743 9.68268 5.19615C9.70639 5.09487 9.76245 5.01099 9.85086 4.9445C9.93296 4.88407 10.0226 4.86732 10.1197 4.89424C10.2168 4.92116 10.2855 4.98013 10.3259 5.07115C10.4702 5.38526 10.5756 5.6944 10.6423 5.99857C10.7089 6.30273 10.7448 6.61475 10.75 6.93462C10.7551 7.38718 10.7041 7.81491 10.5971 8.21779C10.49 8.62067 10.3288 9.01474 10.1134 9.39999C10.0474 9.50832 9.95447 9.59374 9.8346 9.65624C9.71473 9.71874 9.58652 9.74999 9.44999 9.74999H2.54999C2.41665 9.74999 2.29085 9.71794 2.17259 9.65383C2.05432 9.58973 1.95897 9.49871 1.88654 9.38077C1.69551 9.04743 1.54167 8.68397 1.425 8.29039C1.30833 7.8968 1.25 7.46667 1.25 7C1.25 6.3468 1.37391 5.73276 1.62174 5.15787C1.86956 4.58297 2.20786 4.07945 2.63664 3.64729C3.06541 3.21513 3.56955 2.87422 4.14904 2.62454C4.72852 2.37486 5.3455 2.25002 5.99999 2.25002Z'
          }
          fill={color ?? theme.palette.primary.main}
        />
      </g>
    </svg>
  );
};
