import type { TextFieldProps } from '@mui/material';
import { TextField, Box, Typography } from '@mui/material';
import { Flex } from '@lama/design-system';
import type { FC } from 'react';
import React, { useCallback, useState } from 'react';

type LimitedTextFieldProps = Omit<TextFieldProps, 'onChange'> & {
  maxCharacters: number;
  onChange: (value: string) => void;
  initialValue?: string;
};

export const LimitedTextField: FC<LimitedTextFieldProps> = ({ maxCharacters, onChange, initialValue, ...props }) => {
  const [value, setValue] = useState(initialValue ?? '');

  const onInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setValue(event.target.value);
      onChange(event.target.value);
    },
    [onChange],
  );

  return (
    <Flex flexDirection={'column'} gap={2}>
      <TextField
        multiline
        minRows={3}
        maxRows={8}
        onChange={onInputChange}
        value={value}
        inputProps={{ maxLength: maxCharacters }}
        {...props}
      />
      <Box display={'flex'} justifyContent={'flex-end'}>
        <Typography color={'textSecondary'}>
          {value.length}
          {' / '}
          {maxCharacters}
        </Typography>
      </Box>
    </Flex>
  );
};
