import React from 'react';
import { FormikEmailInputField, type PropertyComponentProps } from '@lama/app-components';

export const customComponents: Record<string, React.FC<PropertyComponentProps>> = {
  email: ({ property: { fieldName, displayName, optional, helperText }, submitted, initialValue }) => (
    <FormikEmailInputField
      name={fieldName}
      label={displayName}
      highlight={submitted}
      required={!optional}
      helperText={helperText}
      disabled={!!initialValue}
    />
  ),
};
