import React, { useCallback } from 'react';
import type { InputBaseComponentProps } from '@mui/material';
import { NumericFormat, type NumberFormatValues } from 'react-number-format';

export const CustomNumericFormat = React.forwardRef<HTMLInputElement, InputBaseComponentProps>(
  ({ inputRef: _inputRef, defaultValue: _defaultValue, onChange, name, ...other }, ref) => {
    const onValueChange = useCallback(
      ({ value }: NumberFormatValues) => {
        if (onChange) {
          // @ts-ignore this is a bug in mui InputBaseComponent types
          onChange({ target: { value, name } });
        }
      },
      [name, onChange],
    );

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={onValueChange}
        inputMode={'numeric'}
        thousandSeparator
        valueIsNumericString
      />
    );
  },
);
