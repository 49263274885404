import React, { useMemo } from 'react';
import type { FC } from 'react';
import type { Document } from '@lama/document-service-client';
import type { EvaluatedApplicationRequirement } from '@lama/contracts';
import { Flex, Text, greyPalette } from '@lama/design-system';
import { RequirementDocuments } from '../shared/genericRequirements/documentRequirement/RequirementDocuments';
import { isDocumentRelatedToRequirement } from '../../shared/utils/isDocumentRelatedToRequirement';

interface RequirementsDocumentsListProps {
  requirements: EvaluatedApplicationRequirement[];
  title: string;
  applicationDocuments: Document[];
}

export const RequirementsDocumentsList: FC<RequirementsDocumentsListProps> = ({ title, applicationDocuments, requirements }) => {
  const hasDocuments = useMemo(
    () =>
      requirements.some(
        (requirement) =>
          !!requirement.sources.uploadFilesSource?.length ||
          applicationDocuments.some((doc) => isDocumentRelatedToRequirement(doc, requirement)),
      ),
    [requirements, applicationDocuments],
  );

  if (!hasDocuments) {
    return null;
  }

  return (
    <Flex flexDirection={'column'} gap={6}>
      <Text color={greyPalette[500]}>{title}</Text>
      <Flex flexDirection={'column'} gap={4}>
        {requirements.map((requirement) => (
          <RequirementDocuments
            requirement={requirement}
            requirementDocuments={applicationDocuments.filter(
              (document) =>
                document.requirementId === requirement.id ||
                (document.sharedRequirementId && document.sharedRequirementId === requirement.referenceRequirementId) ||
                (document.requirementKey && document.requirementKey === requirement?.key),
            )}
            key={requirement.id}
          />
        ))}
      </Flex>
    </Flex>
  );
};
