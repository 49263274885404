import React, { forwardRef } from 'react';
import type { HTMLAttributes, PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';
import type {
  PaddingProps,
  HeightProps,
  WidthProps,
  MaxHeightProps,
  MinHeightProps,
  MaxWidthProps,
  MinWidthProps,
  OverflowProps,
  MarginProps,
  PositionProps,
  BorderProps,
} from 'styled-system';
import {
  border,
  height,
  flexbox,
  padding,
  width,
  maxHeight,
  maxWidth,
  minWidth,
  minHeight,
  overflow,
  margin,
  position,
} from 'styled-system';
import { backgroundStyle, fullWidthStyle } from '../styles/shared.styles';

const StyledGrid = styled.div<{ columns: number }>`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(${(props) => props.columns}, 1fr);
  grid-gap: 1rem;

  ${backgroundStyle};
  ${fullWidthStyle};

  ${width};
  ${height};
  ${minWidth};
  ${maxWidth};
  ${minHeight};
  ${maxHeight};
  ${padding};
  ${flexbox};
  ${border};
  ${overflow};
  ${margin};
  ${position};

  ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;
    `};
`;

export interface GridProps
  extends HTMLAttributes<HTMLDivElement>,
    PaddingProps,
    HeightProps,
    WidthProps,
    MaxHeightProps,
    MinHeightProps,
    MaxWidthProps,
    MinWidthProps,
    OverflowProps,
    MarginProps,
    PositionProps,
    BorderProps {
  columns: number;
  fullWidth?: boolean;
  backgroundColor?: string;
  bg?: string;
}

export const Grid = forwardRef<HTMLDivElement, PropsWithChildren<GridProps>>(({ ...props }, ref) => (
  <StyledGrid ref={ref as any} {...props} />
));
