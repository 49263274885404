import React from 'react';
import { useField } from 'formik';
import type { FormikInputProps } from './PropertyFormikInput';
import { FormikEmailInputField } from './FormikEmailInputField';

export const ImmutableEmailInputField: React.FC<FormikInputProps> = ({ name, ...restOfProps }) => {
  const [, { initialValue }] = useField(name);

  return <FormikEmailInputField name={name} disabled={!!initialValue} {...restOfProps} />;
};
