/* eslint-disable react-hooks/rules-of-hooks */
import { Button, Flex, Modal, ModalContent, ModalFooter, Text } from '@lama/design-system';
import type { FC, ReactNode } from 'react';
import React, { useCallback, useMemo } from 'react';
import { unstable_useBlocker } from 'react-router-dom';

const ConfirmationModal: FC<{
  title: string;
  message: string;
  confirmCTA: string;
  cancelCTA: string;
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}> = ({ title, message, confirmCTA, cancelCTA, open, onClose, onConfirm }) => (
  <Modal
    title={title}
    open={open}
    size={'s'}
    onClose={onClose}
    aria-labelledby={'alert-dialog-title'}
    aria-describedby={'alert-dialog-description'}
    hideCloseButton
  >
    <Flex flexDirection={'column'} minWidth={400}>
      <ModalContent>
        <Text variant={'body1'}>{message}</Text>
      </ModalContent>
      <ModalFooter>
        <Flex gap={2}>
          <Button variant={'tertiary'} color={'neutral'} onClick={onClose}>
            {cancelCTA}
          </Button>
          <Button variant={'primary'} color={'danger'} onClick={onConfirm}>
            {confirmCTA}
          </Button>
        </Flex>
      </ModalFooter>
    </Flex>
  </Modal>
);

const DEFAULT_TITLE = 'You have unsaved changes';
const DEFAULT_MESSAGE = 'Are you sure you want to continue?';
const DEFAULT_CONFIRM_CTA = 'Discard Changes';
const DEFAULT_CANCEL_CTA = 'Cancel';

export const ConfirmLeave: FC<{
  shouldBlock: boolean;
  children: ReactNode;
  message?: string;
  title?: string;
  confirmCTA?: string;
  cancelCTA?: string;
}> = ({
  shouldBlock,
  children,
  message = DEFAULT_MESSAGE,
  title = DEFAULT_TITLE,
  confirmCTA = DEFAULT_CONFIRM_CTA,
  cancelCTA = DEFAULT_CANCEL_CTA,
}) => {
  // the blocker can only be used inside a memory router, which is not always the case in tests
  if (import.meta.env.MODE === 'test') {
    return children;
  }

  const blocker = unstable_useBlocker(shouldBlock);

  const onClose = useCallback(() => {
    blocker.reset?.();
  }, [blocker]);

  const onConfirm = useCallback(() => {
    blocker.proceed?.();
  }, [blocker]);

  const modalOpen = useMemo(() => blocker.state === 'blocked', [blocker.state]);

  return (
    <>
      <ConfirmationModal
        open={modalOpen}
        title={title}
        message={message}
        confirmCTA={confirmCTA}
        cancelCTA={cancelCTA}
        onClose={onClose}
        onConfirm={onConfirm}
      />
      {children}
    </>
  );
};
