import { Flex, greyPalette, Spinner, Text } from '@lama/design-system';
import { DeleteOutline } from '@mui/icons-material';
import { MenuItem } from '@mui/material';
import React from 'react';
import type { FC } from 'react';

interface DeleteMenuItemProps {
  onClick: (e: React.MouseEvent) => Promise<void>;
  loading: boolean;
}
export const DeleteMenuItem: FC<DeleteMenuItemProps> = ({ onClick: onDelete, loading: deletingDocument }) => (
  <MenuItem onClick={onDelete}>
    <Flex alignItems={'center'} gap={2} alignContent={'center'}>
      {deletingDocument ? <Spinner size={'16px'} /> : <DeleteOutline sx={{ color: greyPalette[700], width: '20px', height: '20px' }} />}
      <Text variant={'body2'}>{'Delete'}</Text>
    </Flex>
  </MenuItem>
);
