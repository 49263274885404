import React from 'react';

export const MoveToFolderIcon = ({ size = '24', color = '#616161' }: { size?: string; color?: string }) => (
  <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill={'none'} xmlns={'http://www.w3.org/2000/svg'}>
    <path
      d={
        'M2.5 7.83332V4.83332C2.5 4.4355 2.65804 4.05397 2.93934 3.77266C3.22064 3.49136 3.60218 3.33332 4 3.33332H6.925C7.17586 3.33086 7.42334 3.39137 7.64477 3.50929C7.86621 3.62721 8.05453 3.79879 8.1925 4.00832L8.8 4.90832C8.93658 5.11572 9.12252 5.28596 9.34113 5.40378C9.55973 5.52159 9.80417 5.58328 10.0525 5.58332H16C16.3978 5.58332 16.7794 5.74136 17.0607 6.02266C17.342 6.30397 17.5 6.6855 17.5 7.08332V14.5833C17.5 14.9811 17.342 15.3627 17.0607 15.644C16.7794 15.9253 16.3978 16.0833 16 16.0833H4C3.60218 16.0833 3.22064 15.9253 2.93934 15.644C2.65804 15.3627 2.5 14.9811 2.5 14.5833V13.8333M2.5 10.8333H10'
      }
      stroke={color}
      strokeWidth={'1.4'}
      strokeLinecap={'round'}
      strokeLinejoin={'round'}
    />
    <path
      d={'M7.75 13.0833L10 10.8333L7.75 8.58325'}
      stroke={'#616161'}
      strokeWidth={'1.4'}
      strokeLinecap={'round'}
      strokeLinejoin={'round'}
    />
  </svg>
);
