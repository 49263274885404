/* eslint-disable react/jsx-no-bind */
import React, { useCallback, useMemo } from 'react';
import type { FC } from 'react';
import { useToggle } from 'react-use';
import styled from 'styled-components';
import { bluePalette } from '../../theme/colors.js';
import { Flex } from '../Flex/Flex.component.js';
import { EditMode } from './EditMode.js';
import { ReadMode } from './ReadMode.js';
import './richInput.css';
import type { RichInputProps } from './types.js';

const StyledRichInputContainer = styled(Flex)<Partial<RichInputProps>>`
  cursor: ${({ editMode, allowEditing }) => {
    if (!allowEditing) {
      return 'text';
    }

    return editMode ? 'text' : 'pointer';
  }};

  .mention {
    background-color: ${bluePalette[50]};
    border-radius: 0.4rem;
    box-decoration-break: clone;
    color: ${bluePalette[700]};
    padding: 0.1rem 0.3rem;
  }

  .mention-current-user {
    background-color: ${({ theme }) => theme.colors.primary.light};
    border-radius: 0.4rem;
    box-decoration-break: clone;
    color: ${({ theme }) => theme.colors.primary.main};
    padding: 0.1rem 0.3rem;
  }
`;

export const RichInput: FC<RichInputProps> = ({
  onSubmit,
  onDelete,
  value,
  placeholder,
  editMode: externalEditMode,
  toggleEditMode: toggleExternalEditMode,
  currentUserId,
  enableMentions = false,
  allowEditing = true,
  suggestions,
  onCancel,
  color,
  editOnClick = true,
  onImageSelected,
  ...rest
}) => {
  const [innerEditMode, toggleInternalEditMode] = useToggle(false);

  const editMode = useMemo(() => externalEditMode ?? innerEditMode, [innerEditMode, externalEditMode]);

  const toggleEditMode = useMemo(() => toggleExternalEditMode ?? toggleInternalEditMode, [toggleExternalEditMode, toggleInternalEditMode]);

  const toggleEditModeOnClick = useCallback(() => {
    if (!editMode && allowEditing && onSubmit && editOnClick) {
      toggleEditMode();
    }
  }, [editMode, allowEditing, onSubmit, editOnClick, toggleEditMode]);

  return (
    <StyledRichInputContainer
      flexDirection={'column'}
      editMode={editMode}
      flex={1}
      gap={2}
      maxWidth={'100%'}
      allowEditing={allowEditing}
      onClick={toggleEditModeOnClick}
      {...rest}
    >
      {editMode ? (
        <EditMode
          enableMentions={enableMentions}
          currentUserId={currentUserId}
          suggestions={suggestions}
          toggleEditMode={toggleEditMode}
          value={value}
          onSubmit={onSubmit}
          onCancel={onCancel}
          onImageSelected={onImageSelected}
        />
      ) : (
        <ReadMode
          allowEditing={allowEditing}
          onDelete={onDelete}
          enableMentions={enableMentions}
          currentUserId={currentUserId}
          suggestions={suggestions}
          value={value}
          placeholder={placeholder}
          color={color}
        />
      )}
    </StyledRichInputContainer>
  );
};
