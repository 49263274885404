import React from 'react';
import type { FC } from 'react';
import type { OtherIncome } from '@lama/contracts';
import { Flex, greyPalette, Grid, Text } from '@lama/design-system';
import { formatValue } from '@lama/data-formatters';

interface OtherIncomeItemProps {
  item: OtherIncome;
}

export const OtherIncomeItem: FC<OtherIncomeItemProps> = ({ item: { amount, frequency, description } }) => {
  const itemData = [
    ...(amount ? [{ label: 'Amount', values: amount, type: 'currency' as const }] : []),
    ...(frequency ? [{ label: 'Frequency', values: frequency, type: 'number' }] : []),
    ...(description ? [{ label: 'Description', values: description, type: 'string' as const }] : []),
  ];
  return (
    <Flex gap={4} flexDirection={'column'}>
      <Grid columns={3}>
        {itemData.map((line) => (
          <Flex flexDirection={'column'} gap={2} minWidth={0} key={line.label}>
            <Text variant={'body2'} color={greyPalette[500]}>
              {line.label}
            </Text>
            <Flex>
              <Text variant={'body2'}>{line.type === 'currency' ? formatValue(line.values, 'currency') : line.values}</Text>
            </Flex>
          </Flex>
        ))}
      </Grid>
    </Flex>
  );
};
