import type { FC } from 'react';
import React, { useContext, useMemo } from 'react';
import { Navigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useEffectOnce } from 'react-use';
import { isNil } from 'lodash-es';
import { LoadingPage } from '@lama/app-components';
import { UserDetailsContext } from './shared/contexts/UserDetailsContext';
import { useAuthentication } from './hooks/authentication/useAuthentication';
import { currentOnboardingApplicationIdState, partnerState } from './state/appState';
import { useApplicationsByUserIdQuery } from './hooks/react-query/useApplicationsByUserIdQuery';

export const RedirectPage: FC = () => {
  const partner = useRecoilValue(partnerState);
  const user = useContext(UserDetailsContext);

  const { data: applications, isPending: isLoadingApplications } = useApplicationsByUserIdQuery(partner?.id, user?.id);
  const { isAuthenticated, isPending: isAuthenticating, token } = useAuthentication();

  const setCurrentOnboardingApplicationId = useSetRecoilState(currentOnboardingApplicationIdState);

  useEffectOnce(() => {
    setCurrentOnboardingApplicationId(null);
  });

  const personApplications = useMemo(
    () => applications?.filter(({ originatingPartner }) => originatingPartner === partner?.id),
    [applications, partner?.id],
  );

  if (isLoadingApplications || isAuthenticating || (token && isNil(user))) {
    return <LoadingPage />;
  }

  if (!isAuthenticated || !personApplications?.length) {
    return <Navigate to={{ pathname: '/products', search: window.location.search }} replace />;
  }

  if (personApplications.length === 1) {
    return <Navigate to={{ pathname: `/applications/${personApplications[0].id}`, search: window.location.search }} replace />;
  }

  return <Navigate to={{ pathname: '/applications', search: window.location.search }} replace />;
};
