import { Flex, greyPalette, Text } from '@lama/design-system';
import { EditOutlined } from '@mui/icons-material';
import { MenuItem } from '@mui/material';
import React from 'react';
import type { FC } from 'react';

interface RenameMenuItemProps {
  onClick: (e: React.MouseEvent) => void;
}
export const RenameMenuItem: FC<RenameMenuItemProps> = ({ onClick: renameDocument }) => (
  <MenuItem onClick={renameDocument}>
    <Flex alignItems={'center'} gap={2} alignContent={'center'}>
      <EditOutlined sx={{ color: greyPalette[700], width: '20px', height: '20px' }} />
      <Text variant={'body2'}>{'Rename'}</Text>
    </Flex>
  </MenuItem>
);
