import React from 'react';
import { Grid } from '@mui/material';
import { PropertyFormikInput, FormikStatePicker, DisabledCountryInput, FormikCountyPicker } from '../../FormikFields/index.js';

export const Address: React.FC<{
  submitted?: boolean;
  fieldNamePrefix?: string;
  required?: boolean;
  addressFields?: string[];
}> = ({ submitted, fieldNamePrefix = '', required, addressFields = ['address1', 'city', 'state', 'zip'] }) => (
  <Grid container spacing={2}>
    {addressFields.includes('address1') && (
      <Grid item xs={12}>
        <PropertyFormikInput
          name={`${fieldNamePrefix}.address1`}
          label={'Address Line'}
          required={required}
          highlight={submitted}
          fullWidth
        />
      </Grid>
    )}
    {addressFields.includes('city') ? (
      <Grid item xs={12} md={6}>
        <PropertyFormikInput name={`${fieldNamePrefix}.city`} label={'City'} required={required} highlight={submitted} fullWidth />
      </Grid>
    ) : null}
    {addressFields.includes('state') ? (
      <Grid item xs={12} md={6}>
        <FormikStatePicker
          name={`${fieldNamePrefix}.state`}
          countyFieldName={`${fieldNamePrefix}.county`}
          required={required}
          highlight={submitted}
          fullWidth
        />
      </Grid>
    ) : null}
    {addressFields.includes('county') ? (
      <Grid item xs={12} md={6}>
        <FormikCountyPicker
          name={`${fieldNamePrefix}.county`}
          stateFieldName={`${fieldNamePrefix}.state`}
          label={'County'}
          required
          highlight={submitted}
          fullWidth
        />
      </Grid>
    ) : null}
    {addressFields.includes('zip') ? (
      <Grid item xs={12} md={6}>
        <PropertyFormikInput name={`${fieldNamePrefix}.zip`} label={'Zip Code'} required={required} highlight={submitted} fullWidth />
      </Grid>
    ) : null}
    {addressFields.includes('censusTract') ? (
      <Grid item xs={12} md={6}>
        <PropertyFormikInput
          name={`${fieldNamePrefix}.censusTract`}
          label={'Census Tract'}
          required={required}
          highlight={submitted}
          fullWidth
        />
      </Grid>
    ) : null}
    <Grid item xs={12} md={6}>
      <DisabledCountryInput fullWidth />
    </Grid>
  </Grid>
);
