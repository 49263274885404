import React from 'react';
import type { FC } from 'react';
import type { RequirementProperty } from '@lama/contracts';
import { useField } from 'formik';
import { Typography } from '@mui/material';
import { Flex } from '@lama/design-system';
import { LimitedTextField } from '../../FormikFields/index.js';

const maxCharacters = 1400;

export const GenericDescription: FC<{
  property: RequirementProperty;
  submitted?: boolean;
}> = ({ property }) => {
  const [field, { touched, error }, { setValue: setFieldValue }] = useField(property.fieldName);

  return (
    <Flex flexDirection={'column'} gap={2}>
      {property.helperText ? (
        <Typography variant={'body2'} color={'textSecondary'}>
          {property.helperText}
          {property.optional ? null : (
            <Typography component={'span'} color={'error'}>
              {' *'}
            </Typography>
          )}
        </Typography>
      ) : null}
      <LimitedTextField
        {...field}
        maxCharacters={maxCharacters}
        error={!!touched && Boolean(error)}
        helperText={!!touched && error}
        onChange={setFieldValue}
        initialValue={field.value}
      />
    </Flex>
  );
};
