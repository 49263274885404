/* eslint-disable react/jsx-no-bind */
import type { FC } from 'react';
import React, { useContext, useMemo } from 'react';
import { Button, Flex, Text } from '@lama/design-system';
import { Formik } from 'formik';
import { LoadingPage, PropertyFormikInput } from '@lama/app-components';
import { useAsyncFn } from 'react-use';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { emailValidation, firstNameValidation, lastNameValidation, phoneNumberValidation } from '@lama/yup-validations';
import { UserDetailsContext } from '../../shared/contexts/UserDetailsContext';
import { useUpdateUserMutation } from '../../hooks/react-query/useUpdateUserMutation';
import { SimpleHeader } from '../shared/SimpleHeader';

interface AccountSettingsFormValues {
  email?: string;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
}

const validationSchema = yup.object({
  email: emailValidation,
  firstName: firstNameValidation,
  lastName: lastNameValidation,
  phoneNumber: phoneNumberValidation,
});

export const AccountSettingsScreen: FC = () => {
  const user = useContext(UserDetailsContext);

  const { mutateAsync: updateUser } = useUpdateUserMutation({ userId: user?.id });

  const initialValues = useMemo(() => {
    const { email, firstName, lastName, phoneNumber } = user ?? {};

    if (!user) {
      return {
        email: '',
        firstName: '',
        lastName: '',
        phoneNumber: '',
      };
    }

    return {
      email,
      firstName,
      lastName,
      phoneNumber,
    };
  }, [user]);

  const [{ loading: savingUser }, onSubmit] = useAsyncFn(
    async (values: AccountSettingsFormValues) => {
      const userPayload = {
        email: values.email,
        firstName: values.firstName,
        lastName: values.lastName,
        phoneNumber: values.phoneNumber,
      };

      await updateUser(userPayload);

      toast.success('Account settings updated successfully');
    },
    [updateUser],
  );

  return (
    <>
      <SimpleHeader showAccountSettings={false} />
      <Flex flexDirection={'column'} flex={1} height={'100%'} width={'100'} alignItems={'center'}>
        <Flex flexDirection={'row'} width={'50%'} mt={'100px'}>
          {user ? (
            <Flex flexDirection={'column'} flex={1} px={8} py={4} gap={6}>
              <Text variant={'h4'}>{'Account Settings'}</Text>
              <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
                {({ values, handleSubmit, isValid, dirty }) => (
                  <Flex flex={1} flexDirection={'column'} py={2} gap={12} width={'100%'}>
                    <Flex flexDirection={'column'} gap={4}>
                      <Flex flexDirection={'row'} gap={4}>
                        <PropertyFormikInput name={'firstName'} label={'First Name'} value={values.firstName} fullWidth />
                        <PropertyFormikInput name={'lastName'} label={'Last Name'} value={values.lastName} fullWidth />
                      </Flex>
                      <Flex flexDirection={'row'} gap={4}>
                        <PropertyFormikInput name={'email'} label={'Email'} value={values.email} fullWidth disabled />
                        <PropertyFormikInput name={'phoneNumber'} label={'Phone Number'} value={values.phoneNumber} fullWidth />
                      </Flex>
                    </Flex>
                    <Flex flex={1} alignItems={'center'} justifyContent={'flex-end'}>
                      <Button
                        width={'120px'}
                        onClick={() => {
                          handleSubmit();
                        }}
                        variant={'primary'}
                        loading={savingUser}
                        disabled={!dirty || !isValid}
                      >
                        {'Save'}
                      </Button>
                    </Flex>
                  </Flex>
                )}
              </Formik>
            </Flex>
          ) : (
            <LoadingPage />
          )}
        </Flex>
      </Flex>
    </>
  );
};
