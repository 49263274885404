import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

export const useQueryParams = (params: string[]) => {
  const [search] = useSearchParams();

  return useMemo(
    () =>
      params.reduce<Record<string, string | null>>((acc, param) => {
        acc[param] = search.get(param);
        return acc;
      }, {}),
    [search, params],
  );
};
