import { Grid } from '@mui/material';
import { Formik } from 'formik';
import React, { useCallback, useContext, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import * as yup from 'yup';
import { useAsyncFn, useEffectOnce } from 'react-use';
import { FormikEmailInputField, PropertyFormikInput, FormikPhoneNumberField, LoadingPage } from '@lama/app-components';
import { emailValidation, firstNameValidation, lastNameValidation, phoneNumberValidation } from '@lama/yup-validations';
import { useTranslation } from 'react-i18next';
import { Flex } from '@lama/design-system';
import { v4 as uuidv4 } from 'uuid';
import type { RegistrationScreenProps, UserCreationDetails } from '../ScreenProps';
import { BasicScreen } from '../shared/BasicScreen';
import { partnerState } from '../../state/appState';
import { useProductFromParamsByPartner } from '../../hooks/useProductFromParamsByPartner';
import { useQueryParams } from '../../hooks/useQueryParams';
import { UserDetailsContext } from '../../shared/contexts/UserDetailsContext';
import { ExistingUserLoginHint } from '../shared/ExistingUserLoginHint';

const validationSchema = yup.object({
  firstName: firstNameValidation,
  lastName: lastNameValidation,
  email: emailValidation,
  phoneNumber: phoneNumberValidation,
});

interface InitialDetailsScreenProps extends RegistrationScreenProps {
  title?: string;
  subtitle?: string;
}

export const InitialDetailsScreen: React.FC<InitialDetailsScreenProps> = ({
  onNextClick = () => {},
  submitUser,
  nextLoading,
  title,
  subtitle,
  ...stepsNavigationProps
}) => {
  const partner = useRecoilValue(partnerState);
  const product = useProductFromParamsByPartner(partner?.name);
  const user = useContext(UserDetailsContext);
  const { t } = useTranslation();

  const {
    firstName: firstNameFromQueryString,
    lastName: lastNameFromQueryString,
    email: emailFromQueryString,
    phone: phoneFromQueryString,
  } = useQueryParams(['firstName', 'lastName', 'email', 'phone']);

  const handleUserCreation = useCallback(
    async (userInput: UserCreationDetails) => {
      const success = await submitUser(userInput);

      if (success) {
        onNextClick();
      }
    },
    [submitUser, onNextClick],
  );

  useEffectOnce(() => {
    if (user?.firstName && user?.lastName && user?.email) {
      void handleUserCreation(user);
    }
  });

  const initialValues = useMemo(
    () => ({
      firstName: firstNameFromQueryString ?? user?.firstName ?? '',
      lastName: lastNameFromQueryString ?? user?.lastName ?? '',
      email: emailFromQueryString ?? user?.email ?? '',
      phoneNumber: phoneFromQueryString ?? user?.phoneNumber ?? '',
    }),
    [
      emailFromQueryString,
      firstNameFromQueryString,
      lastNameFromQueryString,
      phoneFromQueryString,
      user?.email,
      user?.firstName,
      user?.lastName,
      user?.phoneNumber,
    ],
  );

  const [{ loading: registeringUser }, handleClick] = useAsyncFn(
    async ({ firstName, lastName, email, phoneNumber }: typeof initialValues) => {
      await handleUserCreation({ firstName, lastName, email, phoneNumber, id: uuidv4() });
    },
    [handleUserCreation],
  );

  if (!product) {
    <LoadingPage />;
  }

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleClick}>
      {({ handleSubmit }) => (
        <BasicScreen
          {...stepsNavigationProps}
          title={title || t('initialDetails.title')}
          subtitle={subtitle || t('initialDetails.subtitle')}
          onNextClick={handleSubmit}
          backVisible={false}
          nextLoading={registeringUser || nextLoading}
        >
          <Flex flexDirection={'column'} gap={10} alignItems={'center'} justifyContent={'center'}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <PropertyFormikInput name={'firstName'} label={'First Name'} fullWidth disabled={!!user} required />
              </Grid>
              <Grid item xs={12} md={6}>
                <PropertyFormikInput name={'lastName'} label={'Last Name'} fullWidth disabled={!!user} required />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormikEmailInputField name={'email'} fullWidth disabled={!!user} required />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormikPhoneNumberField name={'phoneNumber'} fullWidth disabled={!!user} />
              </Grid>
            </Grid>
            {user ? null : <ExistingUserLoginHint />}
          </Flex>
        </BasicScreen>
      )}
    </Formik>
  );
};
