import React, { useRef, useEffect, useCallback } from 'react';
import type { FC } from 'react';
import RailzConnect from '@railzai/railz-connect';
import { Dialog, IconButton, useMediaQuery, useTheme } from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { railzWidgetId } from '../../../framework/environment';
import { createDataProviderCredentials } from '../../../shared/clients/businessService';
import { useAuthentication } from '../../../hooks/authentication/useAuthentication';

export const RailzComponent: FC<{ businessId: string }> = ({ businessId }) => {
  const railzRef = useRef<HTMLDivElement>(null);
  const { isAuthenticated, token } = useAuthentication();

  const successCallback = useCallback(
    async (event: string, data: { serviceName: string }) => {
      if (isAuthenticated && event === 'authorization') {
        await createDataProviderCredentials(businessId, data.serviceName, token);
      }
    },
    [businessId, isAuthenticated, token],
  );

  useEffect(() => {
    const widget = new RailzConnect();

    widget.mount({
      parentElement: railzRef.current,
      widgetId: railzWidgetId,
      businessName: businessId,
      onSuccess: successCallback,
      options: {
        components: { search: false, sandbox: false },
        sandbox: false,
      },
      // Add additional Railz Connect configuration parameters here
    });
  }, [businessId, successCallback]);

  return <div ref={railzRef} />;
};

export const RailzDialog: FC<{ businessId: string; open: boolean; onClose: () => void }> = ({ businessId, open, onClose }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Dialog open={open} maxWidth={'sm'} onClose={onClose} fullScreen={isMobile} scroll={'body'} fullWidth>
      <IconButton sx={{ position: 'absolute', right: 8, top: 8 }} onClick={onClose}>
        <CloseRoundedIcon fontSize={'large'} sx={{ color: theme.palette.grey[300] }} />
      </IconButton>
      <RailzComponent businessId={businessId} />
    </Dialog>
  );
};
