// InputLabel.tsx
import type { FC } from 'react';
import React, { useEffect, useState } from 'react';
import { StyledInputLabel, StyledLabelCover } from './Styles/InputLabel.styles';
import type { StylingProps } from './Styles/Input.styles';

export const InputLabel: FC<StylingProps> = ({ label, focused, name, htmlFor }) => {
  const labelRef = React.useRef<HTMLLabelElement>(null);
  const [coverWidth, setCoverWidth] = useState(0);

  useEffect(() => {
    if (labelRef.current) {
      setCoverWidth(labelRef.current.getBoundingClientRect().width);
    }
  }, [label]);

  return label ? (
    <>
      <StyledInputLabel as={'label'} ref={labelRef} focused={focused} label={label} name={name} htmlFor={htmlFor} ellipsis>
        {label}
      </StyledInputLabel>
      <StyledLabelCover coverWidth={coverWidth} />
    </>
  ) : null;
};
