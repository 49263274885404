import type { FC } from 'react';
import React, { useMemo } from 'react';
import type { Collateral } from '@lama/contracts';
import { formatValue } from '@lama/data-formatters';
import { Flex, Text } from '@lama/design-system';
import { useTheme } from '@mui/material';
import { compact } from 'lodash-es';

interface CollateralItemProps {
  item: Collateral;
}

export const CollateralItem: FC<CollateralItemProps> = ({ item: { type, totalValue, method, description } }) => {
  const theme = useTheme();

  const title = useMemo(() => {
    const titleParts = [type, totalValue ? formatValue(totalValue, 'currency') : undefined, method];
    return compact(titleParts).join(' • ');
  }, [type, totalValue, method]);

  return (
    <Flex flexDirection={'column'} gap={1}>
      <Text variant={'body2'} color={theme.palette.text.secondary}>
        {title}
      </Text>
      <Text variant={'body2'}>{description}</Text>
    </Flex>
  );
};
