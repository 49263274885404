import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { getParamsFromRedirectUrl } from './getParamsFromRedirectUrl';

export const useFetchAccessTokenFromUrl = (setAccessToken: (t: string) => void) => {
  const { hash, pathname } = useLocation();
  const { token: tokenFromUrl, targetUrl } = getParamsFromRedirectUrl(hash, pathname);

  useEffect(() => {
    if (tokenFromUrl) {
      setAccessToken(tokenFromUrl);
    }
  }, [setAccessToken, tokenFromUrl]);

  return { targetUrl };
};
