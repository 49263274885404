import { useMediaQuery, useTheme } from '@mui/material';
import type { FC } from 'react';
import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Text } from '@lama/design-system';

import { personFullName } from '@lama/data-formatters';

import { ApplicationSummaryBar } from '../shared/ApplicationSummaryBar';
import { GradientProgress } from '../shared/GradientProgress';
import { ApplicationContext } from '../../shared/contexts/ApplicationContext';
import { UserDetailsContext } from '../../shared/contexts/UserDetailsContext';
import { getUnfulfilledRequirementsCount } from '../../shared/utils/requirementUtils';
import { RequirementsToggle } from './RequirementsToggle';

export interface OverviewHeaderProps {
  progress: number;
  showFulfilled: boolean;
  toggleTaskView: (event: React.MouseEvent<HTMLElement>, value: string | null) => void;
}

const Title: FC<Pick<OverviewHeaderProps, 'progress'>> = ({ progress }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const userDetails = useContext(UserDetailsContext);
  const fullName = useMemo(() => {
    if (!userDetails?.firstName || !userDetails?.lastName) {
      return '';
    }

    const { firstName, lastName } = userDetails;

    return personFullName({ firstName, lastName });
  }, [userDetails]);

  const newTitle = (
    <>
      <Text variant={'h6'} color={'text.secondary'} textAlign={isMobile ? 'center' : 'left'}>
        {t('overview.title', { name: fullName })}
      </Text>
      {progress === 100 ? (
        <Text variant={isMobile ? 'h5' : 'h4'} textAlign={isMobile ? 'center' : 'left'}>
          {t('overview.completed.title')}
        </Text>
      ) : null}
    </>
  );

  return newTitle;
};

const Subtitle: FC = () => {
  const { t } = useTranslation();
  const { requirements } = useContext(ApplicationContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const unfulfilledRequirementsCount = useMemo(() => getUnfulfilledRequirementsCount(requirements), [requirements]);

  return (
    <Flex justifyContent={isMobile ? 'center' : 'space-between'} gap={12}>
      <Text variant={isMobile ? 'h5' : 'h4'} textAlign={isMobile ? 'center' : 'left'}>
        {'You have '}
        <Text variant={isMobile ? 'h5' : 'h4'} color={'primary.main'}>
          {t('overview.inProgress.task', { count: unfulfilledRequirementsCount })}
        </Text>
        {` ${t('overview.inProgress.subtitle')}`}
      </Text>
    </Flex>
  );
};

const SimplifiedApplicationSummaryBar: FC = () => (
  <Flex justifyContent={'center'}>
    <ApplicationSummaryBar showBusinessName={false} />
  </Flex>
);

const Progress: FC<Pick<OverviewHeaderProps, 'progress'>> = ({ progress }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const status = progress === 100 ? 'completed' : 'inProgress';

  return (
    <Flex flexDirection={isMobile ? 'column' : 'row'} justifyContent={'space-between'} alignItems={'center'} gap={2} width={'100%'}>
      <GradientProgress progress={progress} />
      {isMobile ? (
        <Flex justifyContent={'space-between'} width={'100%'}>
          <Text variant={'body3'}>{'0%'}</Text>
          <Text variant={'body3'} color={'primary'}>{`${progress}%`}</Text>
        </Flex>
      ) : (
        <Text variant={'body1'} noWrap>
          {t(`overview.${status}.progress`, { progress })}
        </Text>
      )}
    </Flex>
  );
};

const ToggleButton: FC<Pick<OverviewHeaderProps, 'showFulfilled' | 'toggleTaskView'>> = ({ showFulfilled, toggleTaskView }) => (
  <Flex flexDirection={'column'}>
    <RequirementsToggle showFulfilled={showFulfilled} toggleTaskView={toggleTaskView} />
  </Flex>
);

const NewOverviewHeaderMobileLayout: FC<OverviewHeaderProps> = ({ progress, showFulfilled, toggleTaskView }) => (
  <>
    <SimplifiedApplicationSummaryBar />
    <Title progress={progress} />
    {progress < 100 ? (
      <>
        <Subtitle />
        <Progress progress={progress} />
        <ToggleButton showFulfilled={showFulfilled} toggleTaskView={toggleTaskView} />
      </>
    ) : (
      <Progress progress={progress} />
    )}
  </>
);

const NewOverviewHeaderDesktopLayout: FC<OverviewHeaderProps> = ({ progress, showFulfilled, toggleTaskView }) => (
  <>
    <Title progress={progress} />
    {progress < 100 ? (
      <Flex justifyContent={'space-between'} gap={2}>
        <Subtitle />
        <ToggleButton showFulfilled={showFulfilled} toggleTaskView={toggleTaskView} />
      </Flex>
    ) : null}
    <Progress progress={progress} />
  </>
);

export const OverviewHeader: FC<OverviewHeaderProps> = (props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const layout = isMobile ? <NewOverviewHeaderMobileLayout {...props} /> : <NewOverviewHeaderDesktopLayout {...props} />;

  return (
    <Flex flexDirection={'column'} gap={4} width={'100%'}>
      {layout}
    </Flex>
  );
};
