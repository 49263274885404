/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import type { HTMLMotionProps } from 'framer-motion';
import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';
import { margin, padding } from 'styled-system';
import { greyPalette } from '../../theme';
import type { ModalSize, ModalProps } from './Modal.component';

const modalSizes: Record<ModalSize, { maxWidth?: string }> = {
  l: { maxWidth: '800px' },
  m: { maxWidth: '600px' },
  s: { maxWidth: '450px' },
};

export const widthStyles = css<Partial<ModalProps>>`
  ${({ size, fullWidth }) =>
    size &&
    css`
      ${fullWidth ? `width: ${modalSizes[size].maxWidth};` : `max-width: ${modalSizes[size].maxWidth};`}
    `}
`;

export const StyledModal = styled(motion.div)<HTMLMotionProps<'div'> & Partial<ModalProps>>`
  position: fixed;
  top: ${({ top, fullscreen }) => (fullscreen ? '0' : top ?? '50%')};
  left: ${({ fullscreen }) => (fullscreen ? '0' : '50%')};
  transform: ${({ fullscreen, top }) => (fullscreen ? 'none' : `translate(-50%, -${top ? '0%' : '50%'}) !important`)};
  padding: ${({ fullscreen }) => (fullscreen ? '16px' : '24px')};
  background: white;
  box-shadow: ${({ fullscreen, disableBackdrop }) => (fullscreen || disableBackdrop ? 'none' : ' 0px 4px 16px 4px rgba(58, 54, 74, 0.19)')};
  display: flex;
  flex-direction: column;
  border-radius: ${({ fullscreen }) => (fullscreen ? '0' : '8px')};
  z-index: 1301;
  min-width: fit-content;
  overflow: ${({ fullscreen }) => (fullscreen ? 'auto' : 'visible')};
  width: ${({ fullscreen }) => (fullscreen ? '100%' : 'auto')};
  height: ${({ fullscreen }) => (fullscreen ? '100%' : 'auto')};

  ${({ size, fullscreen }) =>
    !fullscreen &&
    size &&
    css`
      ${widthStyles}
      max-height: 80%
    `}

  ${padding}
  ${margin}
`;

export const Backdrop = styled(motion.div)<Partial<ModalProps>>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${({ disableBackdrop }) => (disableBackdrop ? 'transparent' : 'rgba(0, 0, 0, 0.5)')};
  z-index: 1300;
`;

export const CloseButton = styled.button`
  position: absolute;
  user-select: none;
  top: 18px;
  right: 18px;
  background: none;
  border: none;
  cursor: pointer;
  color: ${greyPalette[500]};
  font-size: 2rem;
`;
