import React from 'react';
import type { PixelSuffix } from '@lama/design-system';

export const DismissedDocumentIcon = ({ size = '24px', color = '#616161' }: { size?: PixelSuffix; color?: string }) => (
  <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill={'none'} xmlns={'http://www.w3.org/2000/svg'}>
    <path
      d={'M10.6821 3.18188V6.28794C10.6821 7.20842 11.4283 7.95461 12.3488 7.95461H15.4549'}
      stroke={color}
      strokeWidth={'1.125'}
      strokeLinecap={'round'}
      strokeLinejoin={'round'}
    />
    <path
      fillRule={'evenodd'}
      clipRule={'evenodd'}
      d={
        'M4.20996 5.81698V15.4544C4.20996 15.9652 4.41289 16.4551 4.77411 16.8164C5.13533 17.1776 5.62525 17.3805 6.1361 17.3805H14.3179C14.7165 17.3805 15.1023 17.257 15.4241 17.0312L14.5979 16.205C14.5092 16.2381 14.4145 16.2555 14.3179 16.2555H6.1361C5.92362 16.2555 5.71985 16.1711 5.56961 16.0209C5.41937 15.8706 5.33496 15.6668 5.33496 15.4544V6.94198L4.20996 5.81698ZM15.1191 13.512V8.18736L10.6758 3.74414H6.1361C5.92746 3.74414 5.72722 3.82552 5.57781 3.97071L4.78228 3.17518C5.1427 2.81903 5.62909 2.61914 6.1361 2.61914H10.9088C11.058 2.61914 11.2011 2.6784 11.3066 2.78389L16.0793 7.55662C16.1848 7.66211 16.2441 7.80518 16.2441 7.95437V14.637L15.1191 13.512Z'
      }
      fill={color}
    />
    <path d={'M2.5 2.5L17.5 17.5'} stroke={color} strokeWidth={'1.36364'} strokeLinecap={'round'} strokeLinejoin={'round'} />
  </svg>
);
