import { useTheme } from '@mui/material';
import type { FC } from 'react';
import React from 'react';

export const Sba7aLogo: FC<{ selected: boolean }> = ({ selected }) => {
  const theme = useTheme();

  return (
    <svg width={'119'} height={'105'} viewBox={'0 0 119 105'} fill={'none'} xmlns={'http://www.w3.org/2000/svg'}>
      <path
        d={
          'M31.456 96.7799C33.6546 95.1447 35.9426 93.621 38.2739 92.1656C43.0067 89.2148 47.9437 86.5761 52.9225 84.0207C55.5417 82.6754 58.2414 81.4593 60.9128 80.2076C63.5991 78.9485 66.2929 77.7013 68.9866 76.4541C71.4477 75.3153 73.9088 74.1752 76.3669 73.029C83.5502 55.2913 91.4406 35.1824 90.295 35.1824L3.46244 32.7563C1.41304 32.7563 -0.136687 34.4035 0.00948613 36.4282L4.29175 95.8701C4.43792 97.8949 6.22481 99.542 8.27421 99.542H27.654C28.8965 98.5906 30.2016 97.712 31.4575 96.7784L31.456 96.7799Z'
        }
        fill={'#666666'}
      />
      <path
        d={
          'M93.0156 32.9644H5.24484C3.30731 32.9644 1.84856 34.5163 1.98728 36.4295L6.26954 95.8715C6.40677 97.7847 8.08925 99.3367 10.0268 99.3367H97.7976C99.7351 99.3367 101.194 97.7847 101.055 95.8715L96.7729 36.4295C96.6356 34.5163 94.9532 32.9644 93.0156 32.9644Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M90.2503 35.8765H8.71721C6.91839 35.8765 5.56256 37.3169 5.69084 39.0949L9.66882 94.312C9.7971 96.09 11.3602 97.5305 13.1591 97.5305H94.6921C96.491 97.5305 97.8468 96.09 97.7185 94.312L93.7405 39.0949C93.6123 37.3169 92.0491 35.8765 90.2503 35.8765Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M44.6981 99.6001H9.65245C8.23994 99.6001 7.17348 100.761 7.2764 102.188C7.37931 103.615 8.61283 104.776 10.0253 104.776H46.1166C47.5291 104.776 44.6966 99.6001 44.6966 99.6001H44.6981Z'
        }
        fill={'#666666'}
      />
      <path
        d={
          'M46.1166 105H10.0253C8.49796 105 7.1645 103.746 7.05413 102.205C6.99894 101.445 7.24505 100.73 7.74621 100.194C8.23693 99.6673 8.9141 99.3789 9.65242 99.3789H44.8308L44.895 99.4949C45.2828 100.202 47.1875 103.784 46.6267 104.726C46.5223 104.903 46.3418 105 46.1181 105H46.1166ZM9.65242 99.8234C9.03939 99.8234 8.47857 100.063 8.07286 100.497C7.65672 100.943 7.45386 101.537 7.49861 102.172C7.59258 103.485 8.72616 104.552 10.0238 104.552H46.1151C46.2061 104.552 46.2254 104.519 46.2389 104.497C46.5491 103.977 45.4647 101.493 44.5624 99.8219H9.65242V99.8234Z'
        }
        fill={'#666666'}
      />
      <path
        d={
          'M115.675 99.6001H32.949C31.5335 99.6001 30.4701 100.758 30.573 102.188C30.6759 103.617 31.9064 104.776 33.3219 104.776H116.048C117.464 104.776 118.527 103.618 118.424 102.188C118.321 100.76 117.091 99.6001 115.675 99.6001Z'
        }
        fill={'#666666'}
      />
      <path
        d={
          'M116.048 104.999H33.3219C31.7946 104.999 30.4611 103.746 30.3508 102.204C30.2956 101.443 30.5417 100.728 31.0428 100.192C31.5336 99.6668 32.2107 99.377 32.9505 99.377H115.675C117.203 99.377 118.536 100.63 118.647 102.172C118.702 102.933 118.456 103.648 117.954 104.185C117.464 104.709 116.787 104.999 116.047 104.999H116.048ZM32.9491 99.8229C32.3345 99.8229 31.7737 100.062 31.3695 100.495C30.9548 100.939 30.7505 101.535 30.7967 102.172C30.8907 103.484 32.0243 104.552 33.3219 104.552H116.048C116.663 104.552 117.224 104.312 117.628 103.88C118.042 103.435 118.247 102.839 118.201 102.203C118.107 100.89 116.973 99.8214 115.675 99.8214H32.9491V99.8229Z'
        }
        fill={'#666666'}
      />
      <path
        d={
          'M97.7976 99.5583H10.0268C7.97741 99.5583 6.19201 97.9111 6.04584 95.8864L5.35674 86.7455C5.34779 86.6221 5.44027 86.5151 5.56257 86.5062C5.68488 86.4973 5.79377 86.5894 5.80272 86.7113L6.49182 95.8537C6.62158 97.651 8.20711 99.1123 10.0253 99.1123H97.7976C98.6761 99.1123 99.4756 98.7748 100.048 98.1609C100.615 97.5529 100.894 96.7457 100.833 95.8864L98.0959 58.1855C98.0869 58.0622 98.1794 57.9566 98.3032 57.9477C98.4255 57.9403 98.5329 58.0309 98.5419 58.1543L101.279 95.8552C101.35 96.8393 101.03 97.7655 100.376 98.4656C99.7172 99.1718 98.8014 99.5597 97.7976 99.5597V99.5583Z'
        }
        fill={'#666666'}
      />
      <path
        d={
          'M90.0355 95.2105H19.1671C18.8926 95.2105 18.6659 95.0009 18.6435 94.7288L11.1201 0.788041C11.0962 0.483294 11.3364 0.223145 11.6436 0.223145H82.5121C82.7865 0.223145 83.0132 0.432751 83.0356 0.704793L90.559 94.6456C90.5829 94.9503 90.3428 95.2105 90.0355 95.2105Z'
        }
        fill={theme.palette.primary.light}
      />
      <path
        d={
          'M42.3221 57.3236H22.5276C22.3038 57.3236 22.1084 57.1526 22.0935 56.9415L21.8743 54.0412C21.8579 53.8301 22.0264 53.6592 22.2501 53.6592H42.0431C42.2669 53.6592 42.4608 53.8301 42.4772 54.0412L42.6964 56.9415C42.7128 57.1526 42.5443 57.3236 42.3206 57.3236H42.3221Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M42.8904 63.3055H23.0959C22.8721 63.3055 22.6782 63.1345 22.6618 62.9235L22.4426 60.0232C22.4262 59.8121 22.5947 59.6411 22.8184 59.6411H42.6114C42.8352 59.6411 43.0306 59.8121 43.0455 60.0232L43.2647 62.9235C43.2811 63.1345 43.1126 63.3055 42.8889 63.3055H42.8904Z'
        }
        fill={'white'}
      />
      <path d={'M80.3613 44.8126H45.9062L45.6273 40.894H80.0823L80.3613 44.8126Z'} fill={'white'} />
      <path d={'M80.063 38.8576H45.608L45.329 34.939H79.784L80.063 38.8576Z'} fill={'white'} />
      <path d={'M80.9564 51.07H46.5014L46.2225 47.1514H80.6775L80.9564 51.07Z'} fill={'white'} />
      <path d={'M81.4024 57.3268H46.9474L46.6685 53.4082H81.1235L81.4024 57.3268Z'} fill={'white'} />
      <path d={'M81.8483 63.5836H47.3933L47.1129 59.665H81.5679L81.8483 63.5836Z'} fill={'white'} />
      <path d={'M82.4718 70.2672H23.2569L22.978 66.3501H82.1929L82.4718 70.2672Z'} fill={'white'} />
      <path d={'M82.9178 76.524H23.7029L23.424 72.6069H82.6389L82.9178 76.524Z'} fill={'white'} />
      <path d={'M83.4413 82.8141H24.2264L23.9475 78.8955H83.1609L83.4413 82.8141Z'} fill={'white'} />
      <path d={'M83.8858 89.0709H24.671L24.392 85.1523H83.6069L83.8858 89.0709Z'} fill={'white'} />
      <path d={'M41.5569 28.0046H39.1227L38.3635 13.1255H40.7992L41.5569 28.0046Z'} fill={'white'} />
      <path
        d={
          'M40.8037 13.2026H38.368L38.016 12.7388C37.9831 12.6957 38.016 12.6392 38.0741 12.6392H40.9185C41.0214 12.6392 41.0901 12.7373 41.0423 12.8146L40.8022 13.2041L40.8037 13.2026Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M39.1108 27.9507H41.5465L41.8985 28.4145C41.9313 28.4576 41.8985 28.5141 41.8403 28.5141H38.9959C38.893 28.5141 38.8244 28.416 38.8721 28.3387L39.1123 27.9492L39.1108 27.9507Z'
        }
        fill={'white'}
      />
      <path d={'M45.5424 28.0046H43.1067L42.3489 13.1255H44.7847L45.5424 28.0046Z'} fill={'white'} />
      <path
        d={
          'M44.7876 13.2026H42.3519L41.9999 12.7388C41.9671 12.6957 41.9999 12.6392 42.0581 12.6392H44.9025C45.0054 12.6392 45.074 12.7373 45.0263 12.8146L44.7861 13.2041L44.7876 13.2026Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M43.0962 27.9507H45.5319L45.8839 28.4145C45.9167 28.4576 45.8839 28.5141 45.8257 28.5141H42.9813C42.8784 28.5141 42.8098 28.416 42.8575 28.3387L43.0977 27.9492L43.0962 27.9507Z'
        }
        fill={'white'}
      />
      <path d={'M49.5278 28.0046H47.0921L46.3344 13.1255H48.7686L49.5278 28.0046Z'} fill={'white'} />
      <path
        d={
          'M48.773 13.2026H46.3373L45.9853 12.7388C45.9525 12.6957 45.9853 12.6392 46.0435 12.6392H48.8879C48.9908 12.6392 49.0594 12.7373 49.0117 12.8146L48.7715 13.2041L48.773 13.2026Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M47.0816 27.9507H49.5173L49.8693 28.4145C49.9021 28.4576 49.8693 28.5141 49.8111 28.5141H46.9667C46.8638 28.5141 46.7952 28.416 46.8429 28.3387L47.0831 27.9492L47.0816 27.9507Z'
        }
        fill={'white'}
      />
      <path d={'M53.5118 28.0046H51.0775L50.3198 13.1255H52.754L53.5118 28.0046Z'} fill={'white'} />
      <path
        d={
          'M52.7585 13.2026H50.3228L49.9708 12.7388C49.938 12.6957 49.9708 12.6392 50.029 12.6392H52.8734C52.9763 12.6392 53.0449 12.7373 52.9972 12.8146L52.757 13.2041L52.7585 13.2026Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M51.0656 27.9507H53.5013L53.8533 28.4145C53.8861 28.4576 53.8533 28.5141 53.7952 28.5141H50.9507C50.8478 28.5141 50.7792 28.416 50.8269 28.3387L51.0671 27.9492L51.0656 27.9507Z'
        }
        fill={'white'}
      />
      <path d={'M57.2988 28.0046H54.8631L54.1054 13.1255H56.5396L57.2988 28.0046Z'} fill={'white'} />
      <path
        d={
          'M56.5441 13.2026H54.1084L53.7564 12.7388C53.7236 12.6957 53.7564 12.6392 53.8145 12.6392H56.6589C56.7619 12.6392 56.8305 12.7373 56.7827 12.8146L56.5426 13.2041L56.5441 13.2026Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M54.8526 27.9507H57.2884L57.6404 28.4145C57.6732 28.4576 57.6404 28.5141 57.5822 28.5141H54.7378C54.6349 28.5141 54.5663 28.416 54.614 28.3387L54.8541 27.9492L54.8526 27.9507Z'
        }
        fill={'white'}
      />
      <path d={'M61.2828 28.0046H58.8485L58.0908 13.1255H60.525L61.2828 28.0046Z'} fill={'white'} />
      <path
        d={
          'M60.5295 13.2026H58.0938L57.7418 12.7388C57.709 12.6957 57.7418 12.6392 57.7999 12.6392H60.6444C60.7473 12.6392 60.8159 12.7373 60.7682 12.8146L60.528 13.2041L60.5295 13.2026Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M58.8366 27.9507H61.2723L61.6243 28.4145C61.6571 28.4576 61.6243 28.5141 61.5661 28.5141H58.7217C58.6188 28.5141 58.5502 28.416 58.5979 28.3387L58.8381 27.9492L58.8366 27.9507Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M62.2881 29.3229H37.9996C37.8773 29.3229 37.7728 29.2277 37.7669 29.1118L37.7594 28.9587C37.7535 28.8427 37.8474 28.7476 37.9697 28.7476H62.2583C62.3806 28.7476 62.485 28.8427 62.491 28.9587L62.4984 29.1118C62.5044 29.2277 62.4104 29.3229 62.2881 29.3229Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M62.9548 30.6695H37.1658C37.0121 30.6695 36.8824 30.5521 36.8749 30.4064L36.8302 29.5248C36.8227 29.3792 36.9405 29.2617 37.0942 29.2617H62.8832C63.0369 29.2617 63.1666 29.3792 63.1741 29.5248L63.2188 30.4064C63.2263 30.5521 63.1084 30.6695 62.9548 30.6695Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M61.963 29.4953H38.189C38.0652 29.4953 37.9653 29.3957 37.9653 29.2723C37.9653 29.1489 38.0652 29.0493 38.189 29.0493H61.963C62.0868 29.0493 62.1867 29.1489 62.1867 29.2723C62.1867 29.3957 62.0868 29.4953 61.963 29.4953Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M61.4647 12.4636H36.9599C36.6706 12.4636 36.5527 12.0965 36.8003 11.9627L48.7268 5.57042C48.8089 5.52731 48.9103 5.52731 48.9968 5.57042L61.5751 11.9627C61.8361 12.095 61.7556 12.4636 61.4662 12.4636H61.4647Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M40.0027 50.716H24.1385C23.0392 50.716 22.1219 49.9103 22.0205 48.8563L20.9227 37.438C20.8078 36.2368 21.7878 35.2007 23.0407 35.2007H38.9049C40.0042 35.2007 40.9215 36.0064 41.0229 37.0604L42.1207 48.4787C42.2356 49.6799 41.2556 50.716 40.0027 50.716Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M36.8963 45.9444C38.4144 43.1738 37.1929 39.5931 34.168 37.9467C31.143 36.3002 27.4601 37.2115 25.942 39.982C24.4239 42.7526 25.6454 46.3333 28.6703 47.9797C31.6952 49.6262 35.3781 48.7149 36.8963 45.9444Z'
        }
        fill={selected ? theme.palette.primary.main : '#0C2007'}
      />
      <path
        d={
          'M33.0889 42.5642C33.5813 41.6656 33.1851 40.5043 32.204 39.9703C31.223 39.4363 30.0285 39.7318 29.5361 40.6304C29.0438 41.5289 29.44 42.6902 30.4211 43.2242C31.4021 43.7582 32.5966 43.4627 33.0889 42.5642Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M27.6705 46.1227C30.154 43.1184 33.3161 43.6624 36.074 45.7957C36.3096 45.9785 36.3589 46.3011 36.1814 46.5107C34.3691 48.6365 30.4836 49.408 27.6944 46.7783C27.505 46.5999 27.5169 46.3085 27.6705 46.1242V46.1227Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M17.4428 79.973C17.328 79.973 17.2295 79.8838 17.2206 79.7678L10.9665 1.66347C10.9322 1.23683 11.0798 0.81018 11.3722 0.495028C11.6645 0.179875 12.0777 0 12.5088 0H81.7768C82.5777 0 83.2549 0.622873 83.319 1.41819L86.2887 38.5051C86.2992 38.6285 86.2067 38.7355 86.0829 38.7445C85.9606 38.7519 85.8517 38.6627 85.8428 38.5393L82.8731 1.45238C82.8283 0.887482 82.3465 0.444484 81.7768 0.444484H12.5073C12.197 0.444484 11.9106 0.569356 11.7003 0.796801C11.49 1.02425 11.3871 1.31859 11.4125 1.62779L17.6666 79.7322C17.677 79.8555 17.5845 79.9626 17.4607 79.9715C17.4548 79.9715 17.4488 79.9715 17.4428 79.9715V79.973Z'
        }
        fill={'#666666'}
      />
      <path
        d={
          'M29.7303 19.6735C29.6736 19.6735 29.6154 19.6512 29.5722 19.6081L18.7047 8.77693C18.6167 8.68922 18.6167 8.548 18.7047 8.46178C18.7927 8.37407 18.9344 8.37407 19.0209 8.46178L29.8884 19.2929C29.9764 19.3806 29.9764 19.5219 29.8884 19.6081C29.8451 19.6512 29.787 19.6735 29.7303 19.6735Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M30.4522 24.8374C30.3955 24.8374 30.3374 24.8151 30.2941 24.772L22.456 16.96C22.368 16.8723 22.368 16.7311 22.456 16.6449C22.544 16.5572 22.6857 16.5572 22.7722 16.6449L30.6103 24.4568C30.6983 24.5445 30.6983 24.6857 30.6103 24.772C30.5671 24.8151 30.5089 24.8374 30.4522 24.8374Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M75.9373 15.6149C75.8806 15.6149 75.8225 15.5926 75.7792 15.5495L68.66 8.45411C68.572 8.3664 68.572 8.22518 68.66 8.13895C68.748 8.05273 68.8897 8.05125 68.9762 8.13895L76.0954 15.2344C76.1834 15.3221 76.1834 15.4633 76.0954 15.5495C76.0522 15.5926 75.994 15.6149 75.9373 15.6149Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M77.8853 21.9089C77.8286 21.9089 77.7704 21.8866 77.7272 21.8435L69.2715 13.4161C69.1835 13.3284 69.1835 13.1872 69.2715 13.1009C69.3595 13.0132 69.5012 13.0132 69.5877 13.1009L78.0434 21.5283C78.1314 21.616 78.1314 21.7572 78.0434 21.8435C78.0001 21.8866 77.9419 21.9089 77.8853 21.9089Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M51.2296 95.2846C51.1058 95.2846 51.0059 95.185 51.0059 95.0631C51.0059 94.9397 51.1043 94.8401 51.2281 94.8386L82.7716 94.6899C82.8954 94.6974 82.9953 94.7895 82.9968 94.9114C82.9968 95.0348 82.8984 95.1344 82.7746 95.1359L51.2311 95.2846H51.2296Z'
        }
        fill={'#666666'}
      />
    </svg>
  );
};
