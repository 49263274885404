import React from 'react';
import { Box, Drawer, Link, useTheme } from '@mui/material';
import type { FC } from 'react';
import DragHandleRoundedIcon from '@mui/icons-material/DragHandleRounded';
import { Flex } from '@lama/design-system';

interface MobileTopbarProps {
  logoUrl: string;
  openSidebar: () => void;
}

export const MobileTopbar: FC<MobileTopbarProps> = ({ logoUrl, openSidebar }) => {
  const theme = useTheme();

  return (
    <Drawer
      sx={{
        width: '100vw',
        height: '50px',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        '& .MuiDrawer-paper': {
          height: '50px',
          boxSizing: 'border-box',
          border: 'none',
          boxShadow: '0px 11px 15px 0px #DBDBDB26',
        },
      }}
      variant={'permanent'}
      anchor={'top'}
    >
      <Flex flexDirection={'row'} height={'100%'} justifyContent={'space-between'} alignItems={'center'} px={4}>
        <Link href={'/'} maxHeight={'24px'}>
          <img height={'24px'} src={logoUrl} />
        </Link>
        <Box onClick={openSidebar} maxHeight={'35px'}>
          <DragHandleRoundedIcon fontSize={'large'} sx={{ color: theme.palette.text.secondary }} />
        </Box>
      </Flex>
    </Drawer>
  );
};
