import React, { useCallback } from 'react';
import { ChevronRight, ChevronLeft } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Button } from '@mui/material';
import { Flex } from '@lama/design-system';
import { DismissRequirementButton } from './genericRequirements/documentRequirement/DismissRequirementButton';

export interface StepsNavigationProps {
  nextEnabled?: boolean;
  nextVisible?: boolean;
  nextLoading?: boolean;
  nextButtonText?: string;
  backEnabled?: boolean;
  backVisible?: boolean;
  onNextClick?: (options?: any) => void;
  onBackClick?: (options?: any) => void;
  nextIconVisible?: boolean;
  animate?: boolean;
  dismissible?: boolean;
}

export const StepsNavigation: React.FC<StepsNavigationProps> = ({
  nextEnabled = true,
  nextVisible = true,
  nextLoading = false,
  backEnabled = true,
  backVisible = true,
  nextButtonText = 'Next',
  nextIconVisible = true,
  onBackClick = () => {
    /* do nothing. */
  },
  onNextClick = () => {
    /* do nothing. */
  },
  dismissible = false,
}) => {
  const nextOrBackVisible = nextVisible || backVisible;

  const handleNextClick = useCallback(() => {
    onNextClick();
  }, [onNextClick]);

  const handleBackClick = useCallback(() => {
    onBackClick();
  }, [onBackClick]);

  if (!nextOrBackVisible) {
    if (dismissible) {
      return (
        <Flex flexDirection={'column'} alignItems={'center'}>
          <DismissRequirementButton />
        </Flex>
      );
    }

    return null;
  }

  return (
    <Flex flexDirection={'row-reverse'} justifyContent={'space-between'}>
      <Flex flexDirection={'row-reverse'} gap={6}>
        {nextVisible ? (
          <LoadingButton
            loading={nextLoading}
            disabled={!nextEnabled}
            endIcon={nextIconVisible ? <ChevronRight /> : null}
            size={'large'}
            variant={'contained'}
            color={'primary'}
            onClick={handleNextClick}
            sx={{ width: '130px', height: '40px' }}
          >
            {nextButtonText}
          </LoadingButton>
        ) : null}
        {dismissible ? <DismissRequirementButton /> : null}
      </Flex>
      {backVisible ? (
        <Button
          disabled={!backEnabled}
          size={'large'}
          variant={'contained'}
          color={'neutral'}
          sx={{ minWidth: '40px', width: '40px', height: '40px' }}
          onClick={handleBackClick}
        >
          <ChevronLeft />
        </Button>
      ) : null}
    </Flex>
  );
};
