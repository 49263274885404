import { useMutation, useQueryClient } from '@tanstack/react-query';

import { displayToast } from '@lama/app-components';
import { applicationServiceClient } from '../../shared/clients/applicationService';
import { useAuthentication } from '../authentication/useAuthentication';

export const useSubmitApplicationMutation = (applicationId: string) => {
  const queryClient = useQueryClient();
  const { token, isAuthenticated } = useAuthentication();

  return useMutation({
    mutationFn: async () => {
      if (!isAuthenticated) {
        return null;
      }

      await applicationServiceClient.submitApplication(applicationId, token);
    },
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: ['applicationRequirements', applicationId],
        }),
        queryClient.invalidateQueries({
          queryKey: ['application', applicationId],
        }),
      ]);
    },
    onError: () => {
      displayToast('Something went wrong. Please contact support.', 'error');
    },
  });
};
