import { Flex, greyPalette, Text } from '@lama/design-system';
import { MenuItem } from '@mui/material';
import React from 'react';
import type { FC } from 'react';
import { DownloadIcon } from '../assets/DownloadIcon';

interface DownloadMenuItemProps {
  onClick: (e: React.MouseEvent) => void;
}
export const DownloadMenuItem: FC<DownloadMenuItemProps> = ({ onClick: downloadDocument }) => (
  <MenuItem onClick={downloadDocument}>
    <Flex alignItems={'center'} gap={2} alignContent={'center'}>
      <DownloadIcon color={greyPalette[700]} size={'20px'} />
      <Text variant={'body2'}>{'Download'}</Text>
    </Flex>
  </MenuItem>
);
