import type { FC } from 'react';
import React, { useContext, useMemo } from 'react';
import { Flex, Text } from '@lama/design-system';
import { getRequirementsByCategory, LoadingPage } from '@lama/app-components';
import { ApplicationContext } from '../../shared/contexts/ApplicationContext';
import type { ScreenProps } from '../ScreenProps';
import { useDocumentsQuery } from '../../hooks/react-query/useDocumentsQuery';
import { RequirementsDocumentsList } from './RequirementsDocumentsList';
import { MiscellaneousDocumentsList } from './MiscellaneousDocumentsList';

export const DocumentHubScreen: FC<ScreenProps> = () => {
  const { requirements, application } = useContext(ApplicationContext);

  const { data: relatedDocuments, isPending: loadingDocuments } = useDocumentsQuery(application.id);

  const requirementsByCategory = useMemo(() => getRequirementsByCategory(requirements, application), [requirements, application]);

  const miscellaneousDocuments = useMemo(
    () => relatedDocuments?.filter((document) => !document.requirementId && !document.sharedRequirementId && !document.topic),
    [relatedDocuments],
  );

  if (loadingDocuments) {
    return <LoadingPage />;
  }

  return (
    <Flex flexDirection={'column'} gap={10} width={'100%'}>
      <Text variant={'h4'}>{'Document Hub'}</Text>
      <Flex flexDirection={'column'} gap={10}>
        <MiscellaneousDocumentsList miscellaneousDocuments={miscellaneousDocuments} />
        {requirementsByCategory.map(({ categoryId, categoryLongName, requirements: categoryRequirements }) =>
          categoryRequirements.length ? (
            <RequirementsDocumentsList
              key={categoryId}
              title={categoryLongName}
              requirements={categoryRequirements}
              applicationDocuments={relatedDocuments ?? []}
            />
          ) : null,
        )}
      </Flex>
    </Flex>
  );
};
