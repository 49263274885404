import React from 'react';
import type { FC } from 'react';
import type { ButtonProps } from '@mui/material';
import { Button } from '@mui/material';
import { AddRounded } from '@mui/icons-material';
import { Spinner } from '@lama/design-system';

export interface ModifyItemButtonProps extends ButtonProps {
  onClick?: () => void;
  showIcon?: boolean;
  text: string;
  loading?: boolean;
}

export const ModifyItemButton: FC<ModifyItemButtonProps> = ({ loading = false, text, showIcon = true, ...props }) => (
  <Button
    {...(showIcon ? { startIcon: <AddRounded /> } : {})}
    {...(loading ? { startIcon: <Spinner size={'24px'} /> } : {})}
    size={'large'}
    variant={'contained'}
    color={'primary'}
    disabled={loading}
    role={'button'}
    {...props}
  >
    {loading ? 'Loading...' : text}
  </Button>
);
