import React from 'react';

export const DeletePermanently = ({ size = '24', color = '#616161' }: { size?: string; color?: string }) => (
  <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill={'none'} xmlns={'http://www.w3.org/2000/svg'}>
    <path
      d={
        'M13.7287 3C14.0907 3.00009 14.4436 3.11409 14.7372 3.32585C15.0308 3.53761 15.2504 3.83638 15.3648 4.17986L15.8331 5.58742H18.6621C18.8908 5.58742 19.1102 5.67829 19.2719 5.84003C19.4337 6.00178 19.5245 6.22115 19.5245 6.44989C19.5245 6.67864 19.4337 6.89801 19.2719 7.05976C19.1102 7.2215 18.8908 7.31237 18.6621 7.31237L18.6595 7.3736L17.9117 17.8466C17.865 18.4992 17.5729 19.1098 17.0941 19.5557C16.6153 20.0015 15.9854 20.2494 15.3312 20.2495H8.19335C7.53912 20.2494 6.90922 20.0015 6.43042 19.5557C5.95163 19.1098 5.65949 18.4992 5.61283 17.8466L4.86506 7.37274L4.86247 7.31237C4.63373 7.31237 4.41436 7.2215 4.25261 7.05976C4.09087 6.89801 4 6.67864 4 6.44989C4 6.22115 4.09087 6.00178 4.25261 5.84003C4.41436 5.67829 4.63373 5.58742 4.86247 5.58742H7.69139L8.15971 4.17986C8.27416 3.83624 8.49386 3.53737 8.78768 3.3256C9.08149 3.11383 9.43451 2.99991 9.79669 3H13.7287ZM16.9345 7.31237H6.59001L7.33346 17.7233C7.34894 17.9408 7.44625 18.1444 7.60579 18.2931C7.76534 18.4417 7.97528 18.5244 8.19335 18.5245H15.3312C15.5493 18.5244 15.7592 18.4417 15.9187 18.2931C16.0783 18.1444 16.1756 17.9408 16.1911 17.7233L16.9345 7.31237ZM13.7287 4.72495H9.79582L9.50862 5.58742H14.0159L13.7287 4.72495Z'
      }
      fill={color}
    />
    <path
      d={
        'M11.6688 13.4708L9.97708 15.1625C9.87014 15.2694 9.73403 15.3229 9.56875 15.3229C9.40347 15.3229 9.26736 15.2694 9.16042 15.1625C9.05347 15.0556 9 14.9194 9 14.7542C9 14.5889 9.05347 14.4528 9.16042 14.3458L10.8521 12.6542L9.16042 10.9771C9.05347 10.8701 9 10.734 9 10.5688C9 10.4035 9.05347 10.2674 9.16042 10.1604C9.26736 10.0535 9.40347 10 9.56875 10C9.73403 10 9.87014 10.0535 9.97708 10.1604L11.6688 11.8521L13.3458 10.1604C13.4528 10.0535 13.5889 10 13.7542 10C13.9194 10 14.0556 10.0535 14.1625 10.1604C14.2792 10.2771 14.3375 10.4156 14.3375 10.576C14.3375 10.7365 14.2792 10.8701 14.1625 10.9771L12.4708 12.6542L14.1625 14.3458C14.2694 14.4528 14.3229 14.5889 14.3229 14.7542C14.3229 14.9194 14.2694 15.0556 14.1625 15.1625C14.0458 15.2792 13.9073 15.3375 13.7469 15.3375C13.5865 15.3375 13.4528 15.2792 13.3458 15.1625L11.6688 13.4708Z'
      }
      fill={color}
    />
  </svg>
);
