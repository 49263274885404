import { useTheme } from '@mui/material';
import type { FC } from 'react';
import React from 'react';

export const McaLogo: FC<{ selected: boolean }> = ({ selected }) => {
  const theme = useTheme();

  return (
    <svg width={'76'} height={'89'} viewBox={'0 0 76 89'} fill={'none'} xmlns={'http://www.w3.org/2000/svg'}>
      <path
        d={
          'M72.2413 69.04V78.078C72.2507 80.8156 69.0865 83.5626 62.7205 85.6862C49.9934 89.9333 29.2267 90.1114 15.9276 86.1409C15.7073 86.0753 15.4917 86.0096 15.2807 85.944C8.53978 83.8204 5.15524 81.0031 5.15055 78.1717L5.14117 69.1338C5.14117 71.9605 8.5304 74.7825 15.2714 76.906C15.487 76.9717 15.7026 77.0373 15.9183 77.1029C29.222 81.0734 49.9887 80.8953 62.7112 76.6482C69.0724 74.5247 72.2413 71.7777 72.2366 69.04H72.2413Z'
        }
        fill={'#BDBDBD'}
      />
      <path
        d={
          'M61.4598 61.0707C61.6801 61.1364 61.8957 61.202 62.1113 61.2723C75.3776 65.4491 75.6495 72.3307 62.7161 76.6481C49.9889 80.8951 29.2222 81.0733 15.9232 77.1028C15.7028 77.0371 15.4872 76.9715 15.2762 76.9059C2.00997 72.7291 1.73808 65.8475 14.6715 61.5254C27.394 57.2784 48.1607 57.1002 61.4644 61.0707H61.4598Z'
        }
        fill={theme.palette.primary.light}
      />
      <path
        d={
          'M74.0977 56.5098V65.5477C74.1071 68.2853 70.9429 71.0323 64.577 73.1559C51.8498 77.403 31.0831 77.5811 17.784 73.6106C17.5637 73.545 17.3481 73.4794 17.1371 73.4137C10.3962 71.2902 7.01165 68.4728 7.00696 65.6415V56.6035C7.00696 59.4302 10.3915 62.2522 17.1278 64.3758C17.3434 64.4414 17.559 64.507 17.7747 64.5727C31.0784 68.5432 51.8451 68.365 64.5676 64.1179C70.9288 61.9944 74.0977 59.2474 74.093 56.5098H74.0977Z'
        }
        fill={'#BDBDBD'}
      />
      <path
        d={
          'M63.3157 48.5404C63.536 48.6061 63.7516 48.6717 63.9673 48.742C77.2335 52.9188 77.5054 59.8004 64.572 64.1178C51.8448 68.3649 31.0782 68.543 17.7791 64.5725C17.5588 64.5069 17.3431 64.4412 17.1322 64.3756C3.86589 60.1988 3.59401 53.3172 16.5275 48.9952C29.25 44.7481 50.0166 44.5699 63.3204 48.5404H63.3157Z'
        }
        fill={theme.palette.primary.light}
      />
      <path
        d={
          'M68.7819 43.8809V52.9188C68.7913 55.6564 65.6271 58.4034 59.2611 60.527C46.534 64.7741 25.7673 64.9522 12.4682 60.9817C12.2479 60.9161 12.0323 60.8504 11.8213 60.7848C5.08036 58.6613 1.69582 55.8439 1.69113 53.0126V43.9746C1.69113 46.8013 5.07567 49.6233 11.8119 51.7469C12.0276 51.8125 12.2432 51.8781 12.4588 51.9438C25.7626 55.9143 46.5293 55.7361 59.2518 51.489C65.613 49.3655 68.7819 46.6185 68.7772 43.8809H68.7819Z'
        }
        fill={'#BDBDBD'}
      />
      <path
        d={
          'M58 35.9069C58.2204 35.9725 58.436 36.0381 58.6516 36.1084C71.9179 40.2852 72.1898 47.1668 59.2563 51.4889C46.5292 55.736 25.7625 55.9141 12.4634 51.9436C12.2431 51.878 12.0275 51.8123 11.8165 51.7467C-1.44975 47.5699 -1.72164 40.6883 11.2118 36.3663C23.9343 32.1192 44.701 31.941 58.0047 35.9115L58 35.9069Z'
        }
        fill={theme.palette.primary.light}
      />
      <path
        d={
          'M75.3721 33.4736L75.3815 42.5116C75.3815 45.2492 72.2172 47.9962 65.856 50.1198C53.1288 54.3668 32.3622 54.545 19.0631 50.5745C18.8428 50.5088 18.6271 50.4432 18.4162 50.3776C11.6752 48.254 8.29067 45.4367 8.28599 42.6053L8.27661 33.5674C8.27661 36.3941 11.6658 39.2161 18.4068 41.3396C18.6224 41.4053 18.8381 41.4709 19.0537 41.5365C32.3575 45.507 53.1241 45.3289 65.8466 41.0818C72.2079 38.9583 75.3768 36.2113 75.3721 33.4736V33.4736Z'
        }
        fill={'#BDBDBD'}
      />
      <path
        d={
          'M64.5907 25.5043C64.8111 25.5699 65.0267 25.6356 65.2423 25.7059C78.5086 29.8827 78.7805 36.7642 65.847 41.0816C53.1199 45.3287 32.3532 45.5069 19.0541 41.5363C18.8338 41.4707 18.6182 41.4051 18.4072 41.3395C5.14095 37.1627 4.86906 30.2811 17.8025 25.959C30.525 21.7119 51.2916 21.5338 64.5954 25.5043H64.5907Z'
        }
        fill={theme.palette.primary.light}
      />
      <path
        d={
          'M67.5769 23.1707V32.2086C67.5863 34.9463 64.4221 37.6933 58.0561 39.8168C45.3289 44.0639 24.5623 44.242 11.2632 40.2715C11.0429 40.2059 10.8273 40.1403 10.6163 40.0747C3.87535 37.9464 0.486121 35.1291 0.486121 32.2977L0.476746 23.2598C0.476746 26.0865 3.86597 28.9085 10.6069 31.032C10.8226 31.0977 11.0382 31.1633 11.2538 31.2289C24.5576 35.1994 45.3242 35.0213 58.0467 30.7742C64.408 28.6507 67.5769 25.9036 67.5722 23.166L67.5769 23.1707Z'
        }
        fill={'#BDBDBD'}
      />
      <path
        d={
          'M56.7947 15.1969C57.015 15.2625 57.2306 15.3282 57.4463 15.3985C70.7126 19.5752 70.9844 26.4568 58.051 30.7742C45.3238 35.0213 24.5572 35.1994 11.2581 31.2289C11.0378 31.1633 10.8221 31.0977 10.6112 31.032C-2.6551 26.8553 -2.92699 19.9737 10.0065 15.6563C22.729 11.4092 43.4956 11.2311 56.7994 15.2016L56.7947 15.1969Z'
        }
        fill={theme.palette.primary.light}
      />
      <path
        d={
          'M73.2536 11.5557V20.5936C73.263 23.3312 70.0988 26.0782 63.7328 28.2018C51.0057 32.4489 30.239 32.627 16.9399 28.6565C16.7196 28.5909 16.504 28.5252 16.293 28.4596C9.55207 26.3361 6.16753 23.5187 6.16284 20.6874V11.6494C6.16284 14.4761 9.54738 17.2981 16.2836 19.4217C16.4993 19.4873 16.7149 19.5529 16.9306 19.6186C30.2343 23.5891 51.001 23.4109 63.7235 19.1638C70.0847 17.0403 73.2536 14.2933 73.2489 11.5557H73.2536Z'
        }
        fill={selected ? theme.palette.primary.main : '#0C2007'}
      />
      <path
        d={
          'M39.5813 32.2372C31.42 32.2372 23.2539 31.2715 16.738 29.3261C16.5177 29.2605 16.2974 29.1948 16.077 29.1245C9.23295 26.9682 5.45933 23.9727 5.45464 20.6866V11.6487H5.44995C5.44995 11.2596 5.76403 10.9455 6.15311 10.9455C6.54219 10.9455 6.85627 11.2596 6.85627 11.6487C6.85627 14.2363 10.3721 16.8239 16.4942 18.7506C16.7052 18.8162 16.9161 18.8818 17.1318 18.9475C30.2387 22.8617 51.0381 22.6601 63.5028 18.4974C69.2546 16.5755 72.5501 14.0488 72.5454 11.5596C72.5454 11.1705 72.8595 10.8564 73.2486 10.8564C73.6377 10.8564 73.9517 11.1705 73.9517 11.5596L73.9611 20.5975C73.9611 23.7805 70.4125 26.715 63.9528 28.8714C57.24 31.1121 48.413 32.2419 39.5766 32.2419L39.5813 32.2372ZM6.86096 15.0285V20.6819C6.87033 23.2696 10.3814 25.8572 16.5036 27.7838C16.7146 27.8495 16.9255 27.9151 17.1411 27.9807C30.248 31.895 51.0475 31.6934 63.5122 27.5307C69.264 25.6087 72.5595 23.082 72.5548 20.5929V14.9348C70.9563 16.7864 68.0311 18.4646 63.9481 19.8288C51.0381 24.1368 30.2949 24.3384 16.7286 20.2882C16.5083 20.2225 16.288 20.1569 16.0677 20.0866C11.6612 18.699 8.52979 16.9646 6.85627 15.0285H6.86096Z'
        }
        fill={'#666666'}
      />
      <path
        d={
          'M62.4722 3.5844C62.6925 3.65002 62.9082 3.71565 63.1238 3.78597C76.3901 7.96274 76.662 14.8443 63.7285 19.1617C51.0013 23.4088 30.2347 23.5869 16.9356 19.6164C16.7153 19.5508 16.4996 19.4852 16.2887 19.4195C3.02242 15.2428 2.75053 8.36119 15.684 4.0391C28.4065 -0.207979 49.1731 -0.38611 62.4769 3.5844H62.4722Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M39.5724 23.1987C31.4111 23.1987 23.2451 22.233 16.7291 20.2876C16.5088 20.222 16.2885 20.1563 16.0681 20.086C9.2475 17.9391 5.47388 14.953 5.44575 11.6762C5.41763 8.4839 8.97092 5.53063 15.4541 3.36959C28.3641 -0.938434 49.1026 -1.14001 62.6689 2.9055C62.8939 2.97113 63.1095 3.04145 63.3298 3.10708C70.1505 5.25406 73.9241 8.24014 73.9522 11.5169C73.9804 14.7092 70.427 17.6578 63.9439 19.8235C57.2311 22.0643 48.4041 23.194 39.5677 23.194L39.5724 23.1987ZM39.8584 1.44762C31.1204 1.44762 22.3825 2.53986 15.8994 4.70559C10.1288 6.63225 6.83332 9.16831 6.85207 11.6669C6.87551 14.2451 10.3866 16.8281 16.49 18.7453C16.701 18.811 16.9119 18.8766 17.1276 18.9422C30.2345 22.8565 51.0339 22.6549 63.4986 18.4922C69.2692 16.5655 72.5647 14.0295 72.5412 11.5309C72.5178 8.95268 69.0067 6.36974 62.9032 4.45246C62.6923 4.38683 62.4814 4.3212 62.261 4.25557C55.9701 2.38048 47.9119 1.44762 39.849 1.44762H39.8584Z'
        }
        fill={'#666666'}
      />
      <path
        d={
          'M47.9353 5.19767L46.3274 6.60399C47.7853 6.7915 48.8822 7.01182 49.7307 7.24152C50.3214 7.40091 50.7901 7.56029 51.1792 7.71967L48.0197 9.41194C47.6118 9.26662 47.0634 9.04161 46.2477 8.82598C45.4977 8.62441 44.5226 8.42283 43.2241 8.28689C40.7818 8.02438 39.4786 8.38533 38.963 8.83535C38.3583 9.36506 39.2911 9.84321 41.9069 10.6964C45.6149 11.8496 46.4165 12.9277 44.7664 14.3669C43.1398 15.7919 39.3802 16.7529 34.6878 16.6029L32.8221 18.2342L29.1375 17.8358L30.8767 16.317C29.4891 16.1388 28.2093 15.881 27.1733 15.6044C26.3717 15.3888 25.7155 15.1591 25.2654 14.9388L28.5328 13.1902C29.1141 13.4574 29.8641 13.7387 30.7735 13.9825C31.6829 14.2262 32.7517 14.4372 33.9752 14.5684C36.1175 14.7981 37.9879 14.6059 38.7192 13.9637C39.413 13.3543 38.6489 12.8434 36.3238 12.1449C32.958 11.1323 31.1251 10.0401 32.8971 8.48846C34.5096 7.08214 38.1004 6.22897 42.5116 6.32742L44.2507 4.80859L47.9353 5.20705V5.19767Z'
        }
        fill={selected ? theme.palette.primary.main : '#0C2007'}
      />
    </svg>
  );
};
