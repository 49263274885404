/* eslint-disable @typescript-eslint/naming-convention */
interface PhotoResult {
  getUrl: (options: Record<string, unknown>) => string;
}

interface ApiAddressPart {
  long_name?: string;
  short_name?: string;
  types: string[];
}

interface AddressPart {
  shortName?: string;
  longName?: string;
}

interface PlaceApiResult {
  place_id: string;
  name: string;
  types: string[];
  address_components: ApiAddressPart[];
  photos?: PhotoResult[];
  formatted_phone_number?: string;
  website?: string;
}

interface AddressPartByType {
  street_number?: AddressPart;
  route?: AddressPart;
  locality?: AddressPart;
  administrative_area_level_1?: AddressPart;
  postal_code?: AddressPart;
}

export type PlaceDetails = PlaceApiResult & {
  imageUrl?: string;
  shortAddress: string;
  addressPartsByType: AddressPartByType;
};

const addressPartsToString = (parts?: AddressPartByType | null): string => {
  if (!parts) {
    return '';
  }
  const { street_number, route, locality, administrative_area_level_1, postal_code } = parts;

  return [
    [street_number?.shortName, route?.shortName].filter(Boolean).join(' '),
    locality?.shortName,
    [administrative_area_level_1?.shortName, postal_code?.shortName].filter(Boolean).join(' '),
  ]
    .filter(Boolean)
    .join(', ');
};

const getAddressPartsByType = (parts?: ApiAddressPart[]): AddressPartByType | null =>
  parts
    ? Object.fromEntries(
        parts.map(({ types: [firstType], short_name, long_name }) => [firstType, { shortName: short_name, longName: long_name }]),
      )
    : null;

const apiResultToPlaceDetails = (placeApiResult: PlaceApiResult | null) => {
  if (placeApiResult) {
    let imageUrl: string | undefined;
    if (placeApiResult.photos && placeApiResult.photos.length > 0) {
      const [photo] = placeApiResult.photos;
      if (photo) {
        imageUrl = photo.getUrl({ maxWidth: 200, maxHeight: 200 });
      }
    }
    const addressPartsByType = getAddressPartsByType(placeApiResult.address_components);
    return {
      ...placeApiResult,
      addressPartsByType: addressPartsByType ?? {},
      imageUrl,
      shortAddress: addressPartsToString(addressPartsByType),
    };
  }
  return null;
};

export const getPlacesDetails = async (placesService: any, placeIds: string[]): Promise<PlaceDetails[]> => {
  const promises = placeIds.map(
    (placeId) =>
      new Promise<PlaceDetails | null>((resolve) => {
        placesService.getDetails(
          { placeId, fields: ['address_component', 'photo', 'url', 'name', 'formatted_phone_number', 'place_id', 'type', 'website'] },
          (placeDetails: PlaceApiResult) => {
            const result = apiResultToPlaceDetails(placeDetails);
            resolve(result);
          },
        );
      }),
  );

  const results = await Promise.all(promises);

  return results.filter(Boolean) as PlaceDetails[];
};
