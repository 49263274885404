import type { FC } from 'react';
import React, { useCallback } from 'react';
import styled from 'styled-components';
import { EditorContent } from '@tiptap/react';
import { Flex } from '../Flex';
import { greyPalette } from '../../theme';
import { StyledEditorContainer } from './RichInput.styles';
import type { RichInputProps } from './types';
import { DeleteIcon } from './assets/DeleteIcon';
import { useRichEditor } from './useRichEditor';

type ReadModeTextProps = Pick<
  RichInputProps,
  'allowEditing' | 'color' | 'currentUserId' | 'enableMentions' | 'minHeight' | 'onDelete' | 'p' | 'placeholder' | 'suggestions' | 'value'
>;

const StyledDeleteIconContainer = styled(Flex)`
  padding: 4px;
  border-radius: 50%;
  &:hover {
    background-color: ${greyPalette[100]};
  }
  cursor: pointer;
`;

export const ReadMode: FC<ReadModeTextProps> = ({
  value,
  placeholder,
  currentUserId,
  enableMentions,
  suggestions = [],
  allowEditing,
  onDelete,
  color,
}) => {
  const editor = useRichEditor({
    enableMentions,
    currentUserId,
    suggestions,
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    content: value || placeholder,
    editable: false,
    autofocus: false,
  });

  const internalOnDelete = useCallback(
    async (event: React.MouseEvent) => {
      event.stopPropagation();
      await onDelete?.();
    },
    [onDelete],
  );

  if (!editor) {
    return null;
  }

  return (
    <StyledEditorContainer pr={2} allowEditing={allowEditing} gap={2} width={'100%'} color={color}>
      <EditorContent editor={editor} />
      {onDelete ? (
        <Flex justifyContent={'flex-end'}>
          <StyledDeleteIconContainer role={'button'} aria-label={'Delete'} onClick={internalOnDelete}>
            <DeleteIcon size={20} />
          </StyledDeleteIconContainer>
        </Flex>
      ) : null}
    </StyledEditorContainer>
  );
};
