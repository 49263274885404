import React from 'react';
import { useMediaQuery, useTheme } from '@mui/material';

export const SingleIcon: React.FC<{ selected: boolean }> = ({ selected }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const height = isMobile ? 78 : 88;
  const width = isMobile ? 73 : 81;

  return (
    <svg width={width} height={height} viewBox={'0 0 81 88'} fill={'none'} xmlns={'http://www.w3.org/2000/svg'}>
      <path
        d={
          'M60.9922 10.9681V27.1277C66.9939 24.8471 73.4607 25.9124 79.1023 30.0235L77.7519 27.5778L73.4307 19.4905L79.1173 13.8639C73.4757 9.75278 66.9939 8.68747 60.9922 10.9681Z'
        }
        fill={selected ? theme.palette.primary.main : 'white'}
        stroke={'black'}
        strokeWidth={'1.3'}
        strokeLinejoin={'round'}
      />
      <path
        d={
          'M41.4863 5.94169V22.1013C47.6381 19.0404 54.9452 19.1755 60.9919 22.5064V6.34681C54.9452 3.01586 47.6381 2.88083 41.4863 5.94169Z'
        }
        fill={selected ? theme.palette.primary.main : 'white'}
        stroke={'black'}
        strokeWidth={'1.3'}
        strokeLinejoin={'round'}
      />
      <path d={'M41.4863 0V87.0247'} stroke={'black'} strokeWidth={'1.3'} strokeLinejoin={'round'} />
      <path
        d={
          'M16.7295 26.6476C20.6006 26.6476 23.4814 23.4817 23.4814 19.5806V17.2249C23.4814 13.3238 20.6006 10.1579 16.7295 10.1579C12.8584 10.1579 9.97754 13.3238 9.97754 17.2249V19.5806C9.97754 23.4817 12.8584 26.6476 16.7295 26.6476Z'
        }
        fill={'white'}
        stroke={'black'}
        strokeWidth={'1.3'}
        strokeMiterlimit={'10'}
      />
      <path
        d={
          'M12.979 31.509C5.85197 31.509 0.975586 33.7746 0.975586 40.5115V55.5158C0.975586 58.7417 3.75137 61.5175 6.97729 61.5175L8.47772 63.483V87.0247H26.4828V39.0111H39.9867C44.518 39.0111 47.4888 34.8399 47.4888 31.509H12.979Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M26.4828 87.0247V39.0111H39.9867C44.518 39.0111 47.4888 34.8399 47.4888 31.509H12.979C5.85197 31.509 0.975586 33.7746 0.975586 40.5115V55.5158C0.975586 58.7417 3.75137 61.5175 6.97729 61.5175'
        }
        stroke={'black'}
        strokeWidth={'1.3'}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
      />
      <path d={'M8.47754 40.5115V87.0247'} stroke={'black'} strokeWidth={'1.3'} strokeLinecap={'round'} strokeLinejoin={'round'} />
      <path d={'M17.4795 60.017V87.0247'} stroke={'black'} strokeWidth={'1.3'} strokeLinejoin={'round'} />
    </svg>
  );
};
